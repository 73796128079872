import request from "@/utils/request";
import qs from 'qs'

/* 获取用户列表 */
export const apiGetUserList = (params) => {
    return request.get("/sys/user/list", params)
}
/* 新增用户 */
export const apiSaveUser = (params) => {
    return request.post("/sys/user/save", params)
}
/* 编辑用户 */
export const apiUpdateUser = (params) => {
    return request.post(`/sys/user/update`, params)
}
/* 删除用户 */
export const apiDeleteUser = (params) => {
    return request.post(`/sys/user/remove?${qs.stringify(params, { indices: false })}`)
}
/* 重置密码 */
export const apiResetPassword = (params) => {
    return request.post(`/sys/user/resetPassword?${qs.stringify(params, { indices: false })}`)
}
/* 获取用户详情 */
export const apiGetUserInfo = (params) => {
    return  request.get("/sys/user/infoUser", params)
}
/* 用户启用禁用 */
export  const apiSetUserEnable = (params) => {
    return request.post(`/sys/user/update/state?${qs.stringify(params, { indices: false })}`)
}
