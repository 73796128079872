import store from '@/store'
export default {
    inserted: (el, binding) => {
        let authorities = store.state.authorities;
        if (authorities.indexOf(binding.value.join(",")) == -1) {
            el.style["display"] = 'none';
        }
    },
    update: function (el, binding,) {
        let authorities = store.state.authorities;
        if (authorities.indexOf(binding.value.join(",")) == -1) {
            el.style["display"] = 'none';
        } else {
            el.style["display"] = 'block';
        }
    }
}