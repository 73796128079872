<template>
  <div class="my-more-text">
    <div v-if="text">
      <div v-if="isOpen">
        <span>{{ text }}</span>
        <span class="close-more" @click="handleClose">收起</span>
      </div>
      <div v-else>
        <span>{{ text.substr(0, 40) }}</span>
        <span class="show-more" v-if="text.length >= 40" @click="handleOpen">...查看更多</span>
      </div>
    </div>
    <div v-else>暂无</div>
  </div>
</template>
<script>
export default {
  data() {
    return {};
  },
  model: {
    prop: "isOpen",
    event: 'input',
  },
  props: {
    text: {
      default: "",
      type: String,
    },
    isOpen: {
      default: false,
      type: Boolean,
    },
  },
  components: {},
  computed: {},
  mounted() {},
  methods: {
    /* 处理收起 */
    handleClose() {
        this.$emit("input", false)
    },
    /* 处理展开 */
    handleOpen() {
        this.$emit("input", true)
    },
  },
};
</script>
<style lang="scss" scoped>
.my-more-text {
  line-height: 26px;
  .close-more, .show-more {
    color: #409EFF;
    cursor: pointer;
  }
  .show-more{
    margin-left: 2px;
  }
  .close-more{
    margin-left: 6px;
  }
}
</style>
