<template>
  <div class="left-nav">
    <div class="top">
      <img class="logo" :src="require('@/assets/image/logo.png')" alt="" />
      <span class="title" v-show="navIsOpen">{{sysName}}管理系统</span>
    </div>
    <el-menu
      class="menu-list"
      :collapse="!navIsOpen"
      :default-active="defaultActive"
      background-color="rgb(48, 65, 86)"
      text-color="#ffffff"
      active-text-color="#ffffff"
      :unique-opened="false"
      @open="handleOpen"
      @close="handleClose"
      @select="handleMnenuSelect"
    >
      <template v-for="item in menuList">
        <menu-item :item="item" :newHighSeasNum="newHighSeasNum" :newAddUseNum="newAddUseNum" :key="item.id" v-if="item.type != 2"></menu-item>
      </template>
    </el-menu>
  </div>
</template>
<script>
import { menuList } from '@/api/data'
import { envType } from "@/config/index";
import { mapState } from "vuex";
export default {
  data() {
    return {
      sysName: process.env.VUE_APP_SYS_NAME,
      defaultActive: null,
      menuList: [],
    };
  },
  inject: ["main"],
  watch: {
    $route(route) {
      this.handleGetDefaultActive();
    },
    fixNavmenuActiveKey() {
      this.defaultActive = null;
      this.$nextTick(() => {
        this.handleGetDefaultActive();
      });
    },
  },
  components: {
    "menu-item": {
      name: "menu-item",
      props: ["item", 'newAddUseNum', 'newHighSeasNum'],
      template: `
          <el-submenu :index="String(item.id)" v-if="!item.url && (item.children && item.children.length > 0)" :key="item.id">
            <template slot="title">
              <i :class="item.icon ? item.icon : 'iconfont icon-sys-dian'" class="menu-icon"></i>
              <span slot="title">{{item.name}}</span>
            </template>
            <template v-for="(subItem) in item.children">
              <menu-item :item="subItem" :newAddUseNum="newAddUseNum" :newHighSeasNum="newHighSeasNum"></menu-item>
            </template>
          </el-submenu>
          <el-menu-item :index="item.url" v-else :key="item.id">
            <i :class="item.icon ? item.icon : 'iconfont icon-sys-dian'" class="menu-icon"></i>
            <span slot="title">
              {{item.name}}
              <span class="new-use-c" v-if="item.url == '/sales/saleStaff/parttimeIntention' && newAddUseNum > 0">{{newAddUseNum > 99 ? '99+' : newAddUseNum}}</span>
              <span class="new-use-c" v-if="item.url == '/sales/saleStaff/openSeaList' && newHighSeasNum > 0">{{newHighSeasNum > 99 ? '99+' : newHighSeasNum}}</span>
            </span>
          </el-menu-item>
      `,
    },
  },
  computed: {
    newAddUseNum() {
      return this.main.newAddUseNum
    },
    newHighSeasNum() {
      return this.main.newHighSeasNum
    },
    ...mapState(["fixNavmenuActiveKey", "navIsOpen"]),
  },
  mounted() {
    this.handleGetDefaultActive();
  },
  created() {
    if (envType == 'local' || envType == 'dev') {
      //this.menuList = menuList;
      let menuList = sessionStorage.getItem("menusList");
      if (menuList && menuList != "null") {
        this.menuList = JSON.parse(menuList);
      } 
    } else {
      let menuList = sessionStorage.getItem("menusList");
      if (menuList && menuList != "null") {
        this.menuList = JSON.parse(menuList);
      }
    }
  },
  methods: {
    handleOpen() {},
    handleClose() {},
    /* 处理激活路由 */
    handleGetDefaultActive() {
      const route = this.$route;
      const { meta, path } = route;
      if (meta.activeMenu) {
        this.defaultActive = null;
        this.$nextTick(() => {
          this.defaultActive = meta.activeMenu;
        });
      }
      this.defaultActive = path;
    },
    handleMnenuSelect(index, indexPath) {
      this.$router.push({
        path: index,
      });
    },
  },
};
</script>
<style lang="scss" scoped>
// 导航背景色 #545c64
// 导航激活颜色  #E6A23C
$background-color: rgb(48, 65, 86);
$background-active-color: #409EFF;
.left-nav {
  height: 100vh;
  //background-color: rgb(48, 65, 86);
  background-color: $background-color;
  color: #ffffff;
  box-sizing: border-box;
  .top {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 50px;
    .logo {
      width: 20px;
      position: relative;
      top: 1px;
    }
    .title {
      margin-left: 6px;
    }
  }
  .menu-list {
    //background-color: rgb(48, 65, 86);
    background-color: $background-color;;
    overflow-y: auto;
    overflow-x: none;
    height: calc(100vh - 32px);
    &::-webkit-scrollbar {
      width: 2px;
      background-color: rgb(48, 65, 86);
    }
    &::-webkit-scrollbar-thumb {
      background-color: rgb(48, 65, 86);
    }
  }
  /deep/ {
    .el-menu {
      border: none !important;
      &.el-menu--collapse {
        .menu-icon {
          margin-left: 4px;
        }
      }
      &:not(.el-menu--collapse) {
        width: 220px;
      }
    }
    .el-menu-item {
      text-align: left;
      &:hover {
        background-color: rgb(33, 44, 58) !important;
      }
      &.is-active {
        .menu-icon {
          //color: #409eff;
          color: #ffffff;
        }
        background-color: $background-active-color !important;
      }
    }
    .el-submenu__title {
      text-align: left;
      &:hover {
        background-color: rgb(33, 44, 58) !important;
      }
    }
    .menu-icon {
      color: #ffffff;
    }
  }
}
</style>
