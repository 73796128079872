import request from "@/utils/request";
import qs from 'qs'

/* 根据parentValue查询 */
export const apiFindMacroByParentValue = (params) => {
    return request.get('/sys/macro/findByParentValue', params)
} 
/* 获取常用的字典值 */
export const apiFindCommonMacros = () => {
    return request.get('/sys/macro/findCommonMacros')
}
/* 根据id查询详情 */
export const apiGetMacroInfo = (params) => {
    return request.get('/sys/macro/info', params)
}
/* 根据code和value查询 */
export const apiGeMacrotInfoByParentCodeAndValue = (params) => {
    return request.get('/sys/macro/infoByParentCodeAndValue', params)
}
/* 通用字典列表 */
export const apiGetMacroList = () => {
    return request.get('/sys/macro/list')
}
/* 根据value查询子节点，树形目录 */
export const apiGetMacroListByValue = (params) => {
    return request.get('/sys/macro/list/parentCode', params)
}
/* 删除字典 */
export const apiRemoveMacro = (params) => {
    return request.post(`/sys/macro/remove?${qs.stringify(params, { indices: false })}`)
}
/* 新增字典 */
export const apiSaveMacro = (params) => {
    return request.post('/sys/macro/save', params)
}
/* 树形列表 */
export const apiGetMacroTreeList = () => {
    return request.get('/sys/macro/select')
}
/* 修改字典 */
export const apiUpdateMacro = (params) => {
    return request.post('/sys/macro/update', params)
}
/* 根据多个parentCode查询多个value */
export const apiGetValueByCode = (params) => {
    return request.post("/sys/macro/getValueByCode", params)
}