export const sexList = [
    {
        name: '男',
        type: 1,
    },
    {
        name: '女',
        type: 2,
    },
]
export const teacherTitleList = [
    {
        name: '初级',
        type: 1,
    },
    {
        name: '中级',
        type: 2,
    },
    {
        name: '高级',
        type: 3,
    },
    {
        name: '特级',
        type: 4,
    },
]
export const teachEducationList = [
    {
        name: '专科',
        type: 1,
    },
    {
        name: '本科',
        type: 2,
    },
    {
        name: '硕士',
        type: 3,
    },
    {
        name: '博士',
        type: 4,
    },
]
export const teachAgeList = [
    {
        name: '0~5',
        type: 1,
    },
    {
        name: '5~10',
        type: 2,
    },
    {
        name: '10~15',
        type: 3,
    },
    {
        name: '15~20',
        type: 4,
    },
    {
        name: '20以上',
        type: 5,
    },
]
export const teachingDirectionList = [
    {
        name: '学科类',
        type: 1,
    },
    {
        name: '素质教育类',
        type: 2,
    },
]

export const teacherIdentityList = [
    {
        name: '老师',
        type: 1,
    },
    {
        name: '在校大学生',
        type: 2,
    },
    {
        name: '老师',
        type: 3,
    },
    {
        name: '在校大学生',
        type: 4,
    },
]
export const classFormList = [
    {
        name: '线上',
        type: 1,
    },
    {
        name: '线下',
        type: 2,
    },
    {
        name: '均可',
        type: 3,
    },
]
export const studentAchievementList = [
    {
        name: '提高型',
        type: 1,
    },
    {
        name: '中上等成绩',
        type: 2,
    },
    {
        name: '中等类待提高',
        type: 3,
    },
    {
        name: '基础型',
        type: 4,
    },
]
export const teahcerBelogList = [
    {
        name: '全部',
        type: null,
    },
    {
        name: '正式表',
        type: 1,
    },
    {
        name: '储备表',
        type: 2,
    },
]
export const teahcerRegisterList = [
    {
        name: '全部',
        type: null,
    },
    {
        name: '已注册',
        type: 1,
    },
    {
        name: '未注册',
        type: 2,
    },
]
export const teacherAuthList = [
    {
        name: '全部',
        type: null,
    },
    {
        name: '审核中',
        type: 1,
    },
    {
        name: '审核成功',
        type: 2,
    },
    {
        name: '审核失败',
        type: 3,
    },
]

export const studentEvaListData = [
    {
        title: "学生在本堂课听课的认真程度？",
        prop: "teaEarnestId",
        list: [
            {
                name: "走神情况严重",
                value: 1,
            },
            {
                name: "注意力集中时间较短",
                value: 2,
            },
            {
                name: "正常水平",
                value: 3,
            },
            {
                name: "能够跟上老师的节奏",
                value: 4,
            },
            {
                name: "全神贯注",
                value: 5,
            },
        ]
    },
    {
        title: "上次课作业的完成程度？",
        prop: "teaFinishId",
        list: [
            {
                name: "上次课未留作业",
                value: 1,
            },
            {
                name: "完成的太少，没有主动去完成老师布置的作业",
                value: 2,
            },
            {
                name: "部分完成，个别难度大的题目等待老师讲解",
                value: 3,
            },
            {
                name: "无论题目难度大小，全部完成",
                value: 4,
            },
        ]
    },
    {
        title: "学生与老师的互动效果？",
        prop: "teaInteractionId",
        list: [
            {
                name: "交流少，不主动，不流畅",
                value: 1,
            },
            {
                name: "只被动回答老师的提问",
                value: 2,
            },
            {
                name: "善于思考，积极回答",
                value: 3,
            },
            {
                name: "思维清晰，主动提问",
                value: 4,
            },
        ]
    },
    {
        title: "学生对本堂课内容的掌握程度？",
        prop: "teaUnderstandId",
        list: [
            {
                name: "记忆层面，只记住知识点",
                value: 1,
            },
            {
                name: "理解层面，可以熟练做题",
                value: 2,
            },
            {
                name: "完全掌握，能够举一反三",
                value: 3,
            },
        ]
    },
]

export const menuList = [
    {
        name: "工作台",
        url: "",
        icon: "",
        authority: "BACK:#",
        id: 90001, 
        children: [
            {
                name: "收入明细",
                url: "/workBench/incomeDetail",
                icon: "",
                authority: "BACK:#",
                id: 90002,
                children: [],
            },
            {
                name: "销售概览",
                url: "/workBench/salePreview",
                icon: "",
                authority: "BACK:#",
                id: 90003,
                children: [],
            },
            {
                name: "签单行为概览",
                url: "/workBench/orderPreview",
                icon: "",
                authority: "BACK:#",
                id: 90004,
                children: [],
            },
            {
                name: "教师概览",
                url: "/workBench/teacherPreview",
                icon: "",
                authority: "BACK:#",
                id: 90005,
                children: [],
            },
            {
                name: "试听概览",
                url: "/workBench/tryPreview",
                icon: "",
                authority: "BACK:#",
                id: 90006,
                children: [],
            },
        ], 
    },
    {
        name: "平台系统管理",
        url: "",
        icon: "",
        authority: "BACK:#",
        id: 117,
        children: [
            {
                name: "机构管理",
                url: "/sysManage/organManage",
                icon: "",
                authority: "BACK:#",
                id: 118,
                children: [],
            },
            {
                name: "通用字典",
                url: "/sysManage/macroManage",
                icon: "",
                authority: "BACK:#",
                id: 119,
                children: [],
            },
            {
                name: "公共资源库",
                url: "/sysManage/publicSourceManage",
                icon: "",
                authority: "BACK:#",
                id: 120,
                children: [],
            },
            {
                name: "行政区域",
                url: "/sysManage/areaManage",
                icon: "",
                authority: "BACK:#",
                id: 121,
                children: [],
            },
            {
                name: "菜单管理",
                url: "/sysManage/menuManage",
                icon: "",
                authority: "BACK:#",
                id: 122,
                children: [],
            },
            {
                name: "角色管理",
                url: "/sysManage/roleManage",
                icon: "",
                authority: "BACK:#",
                id: 122,
                children: [],
            },
            {
                name: "用户管理",
                url: "/sysManage/userManage",
                icon: "",
                authority: "BACK:#",
                id: 123,
                children: [],
            },
        ],
    },
    {
        name: "讲师",
        url: "",
        icon: "",
        authority: "BACK:#",
        id: 124,
        children: [
            {
                name: "讲师管理",
                url: "",
                icon: "",
                authority: "BACK:#",
                id: 125,
                children: [
                    {
                        name: "讲师列表",
                        url: "/teacher/teacherManage/teacherList",
                        icon: "",
                        authority: "BACK:#",
                        id: 126,
                        children: [],
                    },
                ],
            },
            {
                name: "讲师审核",
                url: "",
                icon: "",
                authority: "BACK:#",
                id: 127,
                children: [
                    {
                        name: "讲师列表",
                        url: "/teacher/teacherAudit/teacherList",
                        icon: "",
                        authority: "BACK:#",
                        id: 128,
                        children: [],
                    },
                    {
                        name: "讲师顾问",
                        url: "/teacher/teacherAudit/adviserList",
                        icon: "",
                        authority: "BACK:#",
                        id: 129,
                        children: [],
                    },
                    {
                        name: "讲师预览",
                        url: "/teacher/teacherAudit/teacherPreview",
                        icon: "",
                        authority: "BACK:#",
                        id: 129.1,
                        children: [],
                    },
                ],
            },
        ],
    },
    {
        name: "销售",
        url: "",
        icon: "",
        authority: "BACK:#",
        id: 130,
        children: [
            {
                name: "销售专员",
                url: "",
                icon: "",
                authority: "BACK:#",
                id: 131,
                children: [
                    {
                        name: "每日看板",
                        url: "/sales/saleStaff/dayPreview",
                        icon: "",
                        authority: "BACK:#",
                        id: 1132.01,
                        children: [],
                    },
                    {
                        name: "公海",
                        url: "/sales/saleStaff/openSeaList",
                        icon: "",
                        authority: "BACK:#",
                        id: 1132,
                        children: [],
                    },
                    {
                        name: "签约学生",
                        url: "/sales/saleStaff/userList",
                        icon: "",
                        authority: "BACK:#",
                        id: 132,
                        children: [],
                    },
                    {
                        name: "兼职意向",
                        url: "/sales/saleStaff/parttimeIntention",
                        icon: "",
                        authority: "BACK:#",
                        id: 133,
                        children: [],
                    },
                    {
                        name: "订单列表",
                        url: "/sales/saleStaff/orderList",
                        icon: "",
                        authority: "BACK:#",
                        id: 134,
                        children: [],
                    },
                    {
                        name: "意向列表",
                        url: "/sales/saleStaff/intentionList",
                        icon: "",
                        authority: "BACK:#",
                        id: 135,
                        children: [],
                    },
                    {
                        name: "我的试听",
                        url: "/sales/saleStaff/myTryCourse",
                        icon: "",
                        authority: "BACK:#",
                        id: 1351,
                        children: [],
                    },
                    {
                        name: "监课管理",
                        url: "/sales/saleStaff/monitorClass",
                        icon: "",
                        authority: "BACK:#",
                        id: 1352,
                        children: [],
                    },
                    {
                        name: "回放管理",
                        url: "/sales/saleStaff/playbackList",
                        icon: "",
                        authority: "BACK:#",
                        id: 1353,
                        children: [],
                    },
                    {
                        name: "兼职列表",
                        url: "/sales/saleStaff/parttimeList",
                        icon: "",
                        authority: "BACK:#",
                        id: 136,
                        children: [],
                    },
                    {
                        name: "兼职统计",
                        url: "/sales/saleStaff/parttimeStatistics",
                        icon: "",
                        authority: "BACK:#",
                        id: 1137,
                        children: [],
                    },
                    {
                        name: "手拨列表",
                        url: "/sales/saleStaff/handCallList",
                        icon: "",
                        authority: "BACK:#",
                        id: 1138,
                        children: [],
                    },
                    {
                        name: "拨打记录",
                        url: "/sales/saleStaff/callHistoryList",
                        icon: "",
                        authority: "BACK:#",
                        id: 1139,
                        children: [],
                    },
                    {
                        name: "兼职拨打记录",
                        url: "/sales/saleStaff/parttimeCallHistoryList",
                        icon: "",
                        authority: "BACK:#",
                        id: 1139.0,
                        children: [],
                    },
                ],
            },
            {
                name: "销售主管",
                url: "",
                icon: "",
                authority: "BACK:#",
                id: 137,
                children: [
                    {
                        name: "每日看板",
                        url: "/sales/saleLeader/dayPreview",
                        icon: "",
                        authority: "BACK:#",
                        id: 140.01,
                        children: [],
                    },
                    {
                        name: "签约列表",
                        url: "/sales/saleLeader/userList",
                        icon: "",
                        authority: "BACK:#",
                        id: 140,
                        children: [],
                    },
                    {
                        name: "销售统计",
                        url: "/sales/saleLeader/saleStatistics",
                        icon: "",
                        authority: "BACK:#",
                        id: 1140,
                        children: [],
                    },
                    {
                        name: "意向列表",
                        url: "/sales/saleLeader/intentionStatistics",
                        icon: "",
                        authority: "BACK:#",
                        id: 141,
                        children: [],
                    },
                    {
                        name: "本周报表",
                        url: "/sales/saleLeader/weekReport",
                        icon: "",
                        authority: "BACK:#",
                        id: 141.1,
                        children: [],
                    },
                    {
                        name: "谈单分析",
                        url: "/sales/saleLeader/negotiationOrderList",
                        icon: "",
                        authority: "BACK:#",
                        id: 141.3,
                        children: [],
                    },
                    {
                        name: "拨打记录",
                        url: "/sales/saleLeader/callHistoryList",
                        icon: "",
                        authority: "BACK:#",
                        id: 141.2,
                        children: [],
                    },
                    {
                        name: "销售个人库",
                        url: "/sales/saleLeader/salePersonalList",
                        icon: "",
                        authority: "BACK:#",
                        id: 141.22,
                        children: [],
                    },
                    {
                        name: "部门订单",
                        url: "/sales/saleLeader/orderList",
                        icon: "",
                        authority: "BACK:#",
                        id: 141.4,
                        children: [],
                    },
                    {
                        name: "监课管理",
                        url: "/sales/saleLeader/monitorClass",
                        icon: "",
                        authority: "BACK:#",
                        id: 141.5,
                        children: [],
                    },
                    {
                        name: "回放管理",
                        url: "/sales/saleLeader/playbackList",
                        icon: "",
                        authority: "BACK:#",
                        id: 141.6,
                        children: [],
                    },
                    {
                        name: "排试听课",
                        url: "/sales/saleLeader/arrangeTryCourse",
                        icon: "",
                        authority: "BACK:#",
                        id: 141.7,
                        children: [],
                    },
                    {
                        name: "试听列表",
                        url: "/sales/saleLeader/myTryCourse",
                        icon: "",
                        authority: "BACK:#",
                        id: 141.8,
                        children: [],
                    },
                ],
            },
            {
                name: "我的兼职",
                url: "",
                icon: "",
                authority: "BACK:#",
                id: 142,
                children: [
                    {
                        name: "外呼列表",
                        url: "/sales/myParttime/userList",
                        icon: "",
                        authority: "BACK:#",
                        id: 143,
                        children: [],
                    },
                    {
                        name: "意向表",
                        url: "/sales/myParttime/intentionList",
                        icon: "",
                        authority: "BACK:#",
                        id: 145,
                        children: [],
                    },
                    {
                        name: "手拨列表",
                        url: "/sales/myParttime/handCallList",
                        icon: "",
                        authority: "BACK:#",
                        id: 146,
                        children: [],
                    },
                    {
                        name: "拨打记录",
                        url: "/sales/myParttime/callHistoryList",
                        icon: "",
                        authority: "BACK:#",
                        id: 1461,
                        children: [],
                    },
                ],
            },
            {
                name: "数据管理员",
                url: "",
                icon: "",
                authority: "BACK:#",
                id: 242,
                children: [
                    {
                        name: "黑名单",
                        url: "/sales/dataManage/blackList",
                        icon: "",
                        authority: "BACK:#",
                        id: 138,
                        children: [],
                    },
                    {
                        name: "总电话池",
                        url: "/sales/dataManage/callPool",
                        icon: "",
                        authority: "BACK:#",
                        id: 1243,
                        children: [],
                    },
                    {
                        name: "呼出记录",
                        url: "/sales/dataManage/callLogList",
                        icon: "",
                        authority: "BACK:#",
                        id: 243,
                        children: [],
                    },
                    {
                        name: "公海",
                        url: "/sales/dataManage/openSeaList",
                        icon: "",
                        authority: "BACK:#",
                        id: 11321,
                        children: [],
                    },
                    {
                        name: "数据列表",
                        url: "/sales/dataManage/importList",
                        icon: "",
                        authority: "BACK:#",
                        id: 243,
                        children: [],
                    },
                    {
                        name: "数据统计",
                        url: "/sales/dataManage/importListStatistics",
                        icon: "",
                        authority: "BACK:#",
                        id: 244,
                        children: [],
                    },
                    {
                        name: "任务列表",
                        url: "/sales/dataManage/callTaskList",
                        icon: "",
                        authority: "BACK:#",
                        id: 245,
                        children: [],
                    },
                    {
                        name: "坐席监控",
                        url: "/sales/dataManage/callMonitor",
                        icon: "",
                        authority: "BACK:#",
                        id: 245.1,
                        children: [],
                    },
                    {
                        name: "新数据池子",
                        url: "/sales/dataManage/firstHandCallPool",
                        icon: "",
                        authority: "BACK:#",
                        id: 246,
                        children: [],
                    },
                    {
                        name: "真实数据池子",
                        url: "/sales/dataManage/successHandCallPool",
                        icon: "",
                        authority: "BACK:#",
                        id: 247,
                        children: [],
                    },
                    {
                        name: "禁止轮转列表",
                        url: "/sales/dataManage/noWanderList",
                        icon: "",
                        authority: "BACK:#",
                        id: 247.1,
                        children: [],
                    },
                    {
                        name: "坐席分配",
                        url: "/sales/dataManage/callMonitor",
                        icon: "",
                        authority: "BACK:#",
                        id: 247.001,
                        children: [],
                    },
                    {
                        name: "电话配置",
                        url: "/sales/dataManage/callPlatformSet",
                        icon: "",
                        authority: "BACK:#",
                        id: 247.0011,
                        children: [],
                    },
                    {
                        name: "销售个人库",
                        url: "/sales/dataManage/salePersonalList",
                        icon: "",
                        authority: "BACK:#",
                        id: 247.002,
                        children: [],
                    },
                    {
                        name: "毕业生列表",
                        url: "/sales/dataManage/biYeShengList",
                        icon: "",
                        authority: "BACK:#",
                        id: 247.003,
                        children: [],
                    },
                ],
            },
            {
                name: "销售总监",
                url: "",
                icon: "",
                authority: "BACK:#",
                id: 252,
                children: [
                    {
                        name: "意向列表",
                        url: "/sales/saleMajordomo/intentionStatistics",
                        icon: "",
                        authority: "BACK:#",
                        id: 253,
                        children: [],
                    },
                    {
                        name: "部门意向",
                        url: "/sales/saleMajordomo/saleSectionIntertion",
                        icon: "",
                        authority: "BACK:#",
                        id: 254,
                        children: [],
                    },
                    {
                        name: "签约列表",
                        url: "/sales/saleMajordomo/userList",
                        icon: "",
                        authority: "BACK:#",
                        id: 255,
                        children: [],
                    },
                    {
                        name: "业绩设置",
                        url: "/sales/saleMajordomo/saleStaffPerformanceSet",
                        icon: "",
                        authority: "BACK:#",
                        id: 257,
                        children: [],
                    },
                    {
                        name: "拨打记录",
                        url: "/sales/saleMajordomo/callHistoryList",
                        icon: "",
                        authority: "BACK:#",
                        id: 257.1,
                        children: [],
                    },
                    {
                        name: "销售个人库",
                        url: "/sales/saleMajordomo/salePersonalList",
                        icon: "",
                        authority: "BACK:#",
                        id: 257.2,
                        children: [],
                    },
                ],
            },
        ],
    },
    {
        name: "财务",
        url: "",
        icon: "",
        authority: "BACK:#",
        id: 600,
        children: [
            {
                name: "每日看板",
                url: "/finance/dayPreview",
                icon: "",
                authority: "BACK:#",
                id: 601,
                children: [],
            },
            {
                name: "课酬课耗统计",
                url: "/finance/classConsumeStatistics",
                icon: "",
                authority: "BACK:#",
                id: 602,
                children: [],
            },
            {
                name: "充值审核",
                url: "/finance/orderList",
                icon: "",
                authority: "BACK:#",
                id: 603,
                children: [],
            },
            {
                name: "试听跟班审核",
                url: "/finance/tryClassList",
                icon: "",
                authority: "BACK:#",
                id: 603.1,
                children: [],
            },
            {
                name: "收入统计",
                url: "/finance/incomeStatistics",
                icon: "",
                authority: "BACK:#",
                id: 604,
                children: [],
            },
            {
                name: "课时统计",
                url: "/finance/classhourStatistics",
                icon: "",
                authority: "BACK:#",
                id: 605,
                children: [],
            },
            {
                name: "退费审核",
                url: "/finance/refundList",
                icon: "",
                authority: "BACK:#",
                id: 606,
                children: [],
            },
            {
                name: "讲师课酬",
                url: "/finance/teacherWageList",
                icon: "",
                authority: "BACK:#",
                id: 607,
                children: [],
            },
            {
                name: "学员课时",
                url: "/finance/studentClasshour",
                icon: "",
                authority: "BACK:#",
                id: 608,
                children: [],
            },
            {
                name: "课酬设置",
                url: "/finance/classPayList",
                icon: "",
                authority: "BACK:#",
                id: 613,
                children: [],
            },
            {
                name: "销售业绩",
                url: "/finance/salePerformanceList",
                icon: "",
                authority: "BACK:#",
                id: 609,
                children: [],
            },
            {
                name: "教务业绩",
                url: "/finance/teachBusinessPerformanceList",
                icon: "",
                authority: "BACK:#",
                id: 610,
                children: [],
            },
            {
                name: "学员套餐",
                url: "/finance/studentPackageList",
                icon: "",
                authority: "BACK:#",
                id: 611,
                children: [],
            },
            {
                name: "剩余课时",
                url: "/finance/remainClasshourList",
                icon: "",
                authority: "BACK:#",
                id: 612,
                children: [],
            },
            {
                name: "讲师概览",
                url: "/finance/teacherWorkPreview",
                icon: "",
                authority: "BACK:#",
                id: 613,
                children: [],
            },
        ],
    },
    {
        name: "教务",
        url: "",
        icon: "",
        authority: "BACK:#",
        id: 700,
        children: [
            {
                name: "教务专员",
                url: "",
                icon: "",
                authority: "BACK:#",
                id: 800,
                children: [
                    {
                        name: "每日看板",
                        url: "/teachBusiness/teachBusinessAttache/dayPreview",
                        icon: "",
                        authority: "BACK:#",
                        id: 801,
                        children: [],
                    },
                    {
                        name: "我的收入",
                        url: "/teachBusiness/teachBusinessAttache/myIncome",
                        icon: "",
                        authority: "BACK:#",
                        id: 802,
                        children: [],
                    },
                    {
                        name: "新增学员",
                        url: "/teachBusiness/teachBusinessAttache/addStudent",
                        icon: "",
                        authority: "BACK:#",
                        id: 803,
                        children: [],
                    },

                    {
                        name: "我的学员",
                        url: "/teachBusiness/teachBusinessAttache/myStudentList",
                        icon: "",
                        authority: "BACK:#",
                        id: 804,
                        children: [],
                    },
                    {
                        name: "课表管理",
                        url: "/teachBusiness/teachBusinessAttache/courseList",
                        icon: "",
                        authority: "BACK:#",
                        id: 805,
                        children: [],
                    },
                    {
                        name: "监课管理",
                        url: "/teachBusiness/teachBusinessAttache/monitorClass",
                        icon: "",
                        authority: "BACK:#",
                        id: 806,
                        children: [],
                    },
                    {
                        name: "回放管理",
                        url: "/teachBusiness/teachBusinessAttache/playbackList",
                        icon: "",
                        authority: "BACK:#",
                        id: 806.999,
                        children: [],
                    },
                    {
                        name: "班级管理",
                        url: "/teachBusiness/teachBusinessAttache/classList",
                        icon: "",
                        authority: "BACK:#",
                        id: 806.0,
                        children: [],
                    },
                    {
                        name: "上课记录",
                        url: "/teachBusiness/teachBusinessAttache/courseRecord",
                        icon: "",
                        authority: "BACK:#",
                        id: 806.1,
                        children: [],
                    },
                    {
                        name: "我的订单",
                        url: "/teachBusiness/teachBusinessAttache/orderList",
                        icon: "",
                        authority: "BACK:#",
                        id: 806.2,
                        children: [],
                    },
                    {
                        name: "我的试听",
                        url: "/teachBusiness/teachBusinessAttache/myTryCourse",
                        icon: "",
                        authority: "BACK:#",
                        id: 806.3,
                        children: [],
                    },
                    {
                        name: "排试听课",
                        url: "/teachBusiness/teachBusinessAttache/arrangeTryCourse",
                        icon: "",
                        authority: "BACK:#",
                        id: 806.4,
                        children: [],
                    },
                    {
                        name: "套餐添加",
                        url: "/teachBusiness/teachBusinessAttache/packageList",
                        icon: "",
                        authority: "BACK:#",
                        id: 806.5,
                        children: [],
                    },
                    {
                        name: "教师管理-正式表",
                        url: "/teachBusiness/teachBusinessAttache/teacherListFormal",
                        icon: "",
                        authority: "BACK:#",
                        id: 808,
                    },
                    {
                        name: "教师管理-储备表",
                        url: "/teachBusiness/teachBusinessAttache/teacherListReserve",
                        icon: "",
                        authority: "BACK:#",
                        id: 808,
                    },
                ],
            },
            {
                name: "教务主管",
                url: "",
                icon: "",
                authority: "BACK:#",
                id: 900,
                children: [
                    {
                        name: "每日看板",
                        url: "/teachBusiness/teachBusinessSupervisor/dayPreview",
                        icon: "",
                        authority: "BACK:#",
                        id: 901,
                        children: [],
                    },
                    {
                        name: "部门学员",
                        url: "/teachBusiness/teachBusinessSupervisor/studentList",
                        icon: "",
                        authority: "BACK:#",
                        id: 902,
                        children: [],
                    },
                    {
                        name: "部门课表",
                        url: "/teachBusiness/teachBusinessSupervisor/courseList",
                        icon: "",
                        authority: "BACK:#",
                        id: 903,
                        children: [],
                    },
                    {
                        name: "监课管理",
                        url: "/teachBusiness/teachBusinessSupervisor/monitorClass",
                        icon: "",
                        authority: "BACK:#",
                        id: 904,
                        children: [],
                    },
                    {
                        name: "回放管理",
                        url: "/teachBusiness/teachBusinessSupervisor/playbackList",
                        icon: "",
                        authority: "BACK:#",
                        id: 904.001,
                        children: [],
                    },
                    {
                        name: "退款审核",
                        url: "/teachBusiness/teachBusinessSupervisor/refundAudit",
                        icon: "",
                        authority: "BACK:#",
                        id: 906,
                        children: [],
                    },
                    {
                        name: "人员管理",
                        url: "/teachBusiness/teachBusinessSupervisor/teachBusinessList",
                        icon: "",
                        authority: "BACK:#",
                        id: 907,
                        children: [],
                    },
                    {
                        name: "部门业绩",
                        url: "/teachBusiness/teachBusinessSupervisor/performanceList",
                        icon: "",
                        authority: "BACK:#",
                        id: 908,
                        children: [],
                    },
                    {
                        name: "员工收入",
                        url: "/teachBusiness/teachBusinessSupervisor/myIncome",
                        icon: "",
                        authority: "BACK:#",
                        id: 908.01,
                        children: [],
                    },
                    {
                        name: "班级管理",
                        url: "/teachBusiness/teachBusinessSupervisor/classList",
                        icon: "",
                        authority: "BACK:#",
                        id: 908.04,
                        children: [],
                    },
                    {
                        name: "上课记录",
                        url: "/teachBusiness/teachBusinessSupervisor/courseRecord",
                        icon: "",
                        authority: "BACK:#",
                        id: 908.05,
                        children: [],
                    },
                    {
                        name: "部门订单",
                        url: "/teachBusiness/teachBusinessSupervisor/orderList",
                        icon: "",
                        authority: "BACK:#",
                        id: 908.06,
                        children: [],
                    },
                    {
                        name: "部门试听",
                        url: "/teachBusiness/teachBusinessSupervisor/myTryCourse",
                        icon: "",
                        authority: "BACK:#",
                        id: 908.07,
                        children: [],
                    },
                    {
                        name: "排试听课",
                        url: "/teachBusiness/teachBusinessSupervisor/arrangeTryCourse",
                        icon: "",
                        authority: "BACK:#",
                        id: 908.08,
                        children: [],
                    },
                    {
                        name: "套餐添加",
                        url: "/teachBusiness/teachBusinessSupervisor/packageList",
                        icon: "",
                        authority: "BACK:#",
                        id: 908.08,
                        children: [],
                    },
                ],
            },
        ],
    },
];
// 详细学校列表
export const schoolDetailList = [
    {
        "type": 1,
        "name": "学校名称",
        "phone": "联系电话",
        "address": "学校地址",
        "typeName": "学校类别",
        "id": 1
    },
    {
        "type": 1,
        "name": "北京市文汇中学",
        "phone": 87757410,
        "address": "北京市东城区广渠门外忠实里东区9号楼",
        "typeName": "小学",
        "id": 2
    },
    {
        "type": 1,
        "name": "北京市东城区文汇小学",
        "phone": 67783273,
        "address": "北京市东城区广渠门外忠实里南街乙58号",
        "typeName": "小学",
        "id": 3
    },
    {
        "type": 1,
        "name": "北京市第一七一中学附属青年湖小学",
        "phone": 84126076,
        "address": "北京市东城区安德里北街20号",
        "typeName": "小学",
        "id": 4
    },
    {
        "type": 1,
        "name": "北京市第五中学分校附属方家胡同小学",
        "phone": 64014841,
        "address": "北京市东城区方家胡同17号",
        "typeName": "小学",
        "id": 5
    },
    {
        "type": 1,
        "name": "北京市和平北路学校（北京市东城体育学校）",
        "phone": 64264968,
        "address": "北京市东城区安定门外大街168号",
        "typeName": "小学",
        "id": 6
    },
    {
        "type": 1,
        "name": "北京市第一一五中学",
        "phone": 67020121,
        "address": "北京市东城区天坛东路13号",
        "typeName": "小学",
        "id": 7
    },
    {
        "type": 1,
        "name": "北京市第一六六中学附属校尉胡同小学",
        "phone": 65597995,
        "address": "北京市东城区校尉胡同8号",
        "typeName": "小学",
        "id": 8
    },
    {
        "type": 1,
        "name": "北京市第一零九中学",
        "phone": 67119431,
        "address": "北京市东城区幸福大街43号",
        "typeName": "小学",
        "id": 9
    },
    {
        "type": 1,
        "name": "北京市广渠门中学附属花市小学",
        "phone": 67182946,
        "address": "北京市东城区东花市北里西区一号楼",
        "typeName": "小学",
        "id": 10
    },
    {
        "type": 1,
        "name": "北京市东直门中学附属雍和宫小学",
        "phone": 64045703,
        "address": "北京市东城区藏经馆胡同27号",
        "typeName": "小学",
        "id": 11
    },
    {
        "type": 1,
        "name": "北京景山学校",
        "phone": 64066787,
        "address": "东城区北官厅胡同11号",
        "typeName": "小学",
        "id": 12
    },
    {
        "type": 1,
        "name": "北京市东直门中学",
        "phone": 64014988,
        "address": "北京市东城区东直门内北顺城街2号",
        "typeName": "小学",
        "id": 13
    },
    {
        "type": 1,
        "name": "北京景山学校(灯市口校区)",
        "phone": 65223416,
        "address": "东城区灯市口大街53号",
        "typeName": "小学",
        "id": 14
    },
    {
        "type": 1,
        "name": "北京市东城区特殊教育学校",
        "phone": 84283449,
        "address": "北京市东城区安外小黄庄路一区16楼",
        "typeName": "小学",
        "id": 15
    },
    {
        "type": 1,
        "name": "北京市第一中学",
        "phone": 64043280,
        "address": "东城区鼓楼东大街宝钞胡同甲12号",
        "typeName": "小学",
        "id": 16
    },
    {
        "type": 1,
        "name": "东城区培智中心学校",
        "phone": 67020405,
        "address": "体育馆西路33号",
        "typeName": "小学",
        "id": 17
    },
    {
        "type": 1,
        "name": "北京市东城区景泰小学",
        "phone": 87819508,
        "address": "北京市东城区永定门东街7-2",
        "typeName": "小学",
        "id": 18
    },
    {
        "type": 1,
        "name": "东四七条小学",
        "phone": 64043873,
        "address": "北京市东城区东四七条31号",
        "typeName": "小学",
        "id": 19
    },
    {
        "type": 1,
        "name": "北京市东城区新鲜胡同小学",
        "phone": 65252498,
        "address": "北京市东城区朝内南小街新鲜胡同36号",
        "typeName": "小学",
        "id": 20
    },
    {
        "type": 1,
        "name": "北京市东城区史家小学分校",
        "phone": 84070087,
        "address": "北京市东城区北门仓1号",
        "typeName": "小学",
        "id": 21
    },
    {
        "type": 1,
        "name": "北京市东城区天坛东里小学",
        "phone": 67024124,
        "address": "北京市东城区天坛东里内8号",
        "typeName": "小学",
        "id": 22
    },
    {
        "type": 1,
        "name": "北京市东城区板厂小学",
        "phone": 67114386,
        "address": "北京市东城区板厂南里7号",
        "typeName": "小学",
        "id": 23
    },
    {
        "type": 1,
        "name": "北京市东城区西中街小学",
        "phone": 64172386,
        "address": "北京市东直门外十字坡东里10号楼",
        "typeName": "小学",
        "id": 24
    },
    {
        "type": 1,
        "name": "北京市东城区天坛南里小学",
        "phone": 67021473,
        "address": "北京市东城区天坛南里西区16号",
        "typeName": "小学",
        "id": 25
    },
    {
        "type": 1,
        "name": "北京市东城区宝华里小学",
        "phone": 67221380,
        "address": "北京市东城区永外沙子口路63号",
        "typeName": "小学",
        "id": 26
    },
    {
        "type": 1,
        "name": "北京市东城区新开路东总布小学",
        "phone": 65251340,
        "address": "北京市东城区新开路胡同55号",
        "typeName": "小学",
        "id": 27
    },
    {
        "type": 1,
        "name": "北京市东城区史家实验学校（北京市东城区曙光小学）",
        "phone": 64169554,
        "address": "北京市东城区东中街铜厂子胡同8号",
        "typeName": "小学",
        "id": 28
    },
    {
        "type": 1,
        "name": "北京光明小学（幸福校区）",
        "phone": 67162311,
        "address": "北京市东城区福光路5号",
        "typeName": "小学",
        "id": 29
    },
    {
        "type": 1,
        "name": "北京光明小学（广渠校区）",
        "phone": 67495076,
        "address": "北京市东城区广渠家园14号楼",
        "typeName": "小学",
        "id": 30
    },
    {
        "type": 1,
        "name": "北京市东城区和平里第三小学",
        "phone": 84281424,
        "address": "北京市东城区和平里兴化路9号",
        "typeName": "小学",
        "id": 31
    },
    {
        "type": 1,
        "name": "北京市东城区黑芝麻胡同小学优质教育资源带黑芝麻校区",
        "phone": "84034383-8000",
        "address": "北京市东城区黑芝麻胡同11号",
        "typeName": "小学",
        "id": 32
    },
    {
        "type": 1,
        "name": "北京市东城区黑芝麻胡同小学优质教育资源带圆恩寺校区",
        "phone": "84034383-6000",
        "address": "北京市东城区后圆恩寺胡同甲20号",
        "typeName": "小学",
        "id": 33
    },
    {
        "type": 1,
        "name": "北京市东城区黑芝麻胡同小学优质教育资源带织染局校区",
        "phone": 64040463,
        "address": "北京市东城区水簸箕胡同甲5号",
        "typeName": "小学",
        "id": 34
    },
    {
        "type": 1,
        "name": "北京市东城区和平里第一小学",
        "phone": 84220243,
        "address": "北京市东城和平里中街甲21号",
        "typeName": "小学",
        "id": 35
    },
    {
        "type": 1,
        "name": "北京市东城区和平里第九小学",
        "phone": "010-64213889",
        "address": "北京市东城区和平里七区20号楼",
        "typeName": "小学",
        "id": 36
    },
    {
        "type": 1,
        "name": "北京市东城区西总布小学",
        "phone": 65263874,
        "address": "北京市东城区西总布胡同19号",
        "typeName": "小学",
        "id": 37
    },
    {
        "type": 1,
        "name": "北京市东城区回民小学",
        "phone": 85110074,
        "address": "东城区朝阳门内大街124号",
        "typeName": "小学",
        "id": 38
    },
    {
        "type": 1,
        "name": "北京市东城区回民实验小学",
        "phone": 67122965,
        "address": "北京市东城区东花市大街99号",
        "typeName": "小学",
        "id": 39
    },
    {
        "type": 1,
        "name": "北京市东城区定安里小学",
        "phone": 67212203,
        "address": "北京市东城区定安里26号",
        "typeName": "小学",
        "id": 40
    },
    {
        "type": 1,
        "name": "府学胡同小学",
        "phone": 64045995,
        "address": "北京市东城区府学胡同65号",
        "typeName": "小学",
        "id": 41
    },
    {
        "type": 1,
        "name": "府学胡同小学香饵校区",
        "phone": 64045995,
        "address": "北京市东城区香饵胡同9号",
        "typeName": "小学",
        "id": 42
    },
    {
        "type": 1,
        "name": "府学胡同小学低年级部",
        "phone": 64029960,
        "address": "北京市东城区东四十四条100号",
        "typeName": "小学",
        "id": 43
    },
    {
        "type": 1,
        "name": "府学胡同小学美术馆后街校区",
        "phone": 84034452,
        "address": "北京市东城区美术馆后街57号",
        "typeName": "小学",
        "id": 44
    },
    {
        "type": 1,
        "name": "府学胡同小学什锦花园校区",
        "phone": 64042123,
        "address": "北京市东城区美术馆后街48号",
        "typeName": "小学",
        "id": 45
    },
    {
        "type": 1,
        "name": "东四十四条小学",
        "phone": 64031726,
        "address": "东城区东四十三条胡同73号",
        "typeName": "小学",
        "id": 46
    },
    {
        "type": 1,
        "name": "北京市东城区师范学校附属小学",
        "phone": 64263926,
        "address": "北京市东城区安定门外东河沿乙7号",
        "typeName": "小学",
        "id": 47
    },
    {
        "type": 1,
        "name": "北京市东城区和平里第二小学",
        "phone": 64211804,
        "address": "北京市东城区和平里民旺南胡同20号",
        "typeName": "小学",
        "id": 48
    },
    {
        "type": 1,
        "name": "北京市东城区和平里第四小学",
        "phone": 64211026,
        "address": "北京市东城区和平里交林夹道1号",
        "typeName": "小学",
        "id": 49
    },
    {
        "type": 1,
        "name": "北京市东城区灯市口小学",
        "phone": 65250582,
        "address": "北京市东城区灯市口北巷14号",
        "typeName": "小学",
        "id": 50
    },
    {
        "type": 1,
        "name": "中央工艺美院附中艺美小学",
        "phone": 64674998,
        "address": "北京市东城区东直门外胡家园20号",
        "typeName": "小学",
        "id": 51
    },
    {
        "type": 1,
        "name": "北京市东城区培新小学",
        "phone": 67111890,
        "address": "北京市东城区幸福巷4号",
        "typeName": "小学",
        "id": 52
    },
    {
        "type": 1,
        "name": "北京市东城区培新小学（红桥校区）",
        "phone": 67188082,
        "address": "北京市东城区东马尾帽胡同22号",
        "typeName": "小学",
        "id": 53
    },
    {
        "type": 1,
        "name": "北京市东城区培新小学（永生校区）",
        "phone": 67120198,
        "address": "北京市东城区永生巷6号旁门",
        "typeName": "小学",
        "id": 54
    },
    {
        "type": 1,
        "name": "北京市东城区灯市口小学优质资源带北池子校区",
        "phone": 65251287,
        "address": "北京市东城区北池子大街46号",
        "typeName": "小学",
        "id": 55
    },
    {
        "type": 1,
        "name": "北京市东城区灯市口小学优质资源带东高房校区",
        "phone": 64032310,
        "address": "北京市东城区沙滩北街东高房胡同13号",
        "typeName": "小学",
        "id": 56
    },
    {
        "type": 1,
        "name": "北京市东城区体育馆路小学",
        "phone": 67122559,
        "address": "东城区法华南里21号",
        "typeName": "小学",
        "id": 57
    },
    {
        "type": 1,
        "name": "北京市东城区前门小学",
        "phone": 67016684,
        "address": "北京市东城区崇文门西河沿甲211号",
        "typeName": "小学",
        "id": 58
    },
    {
        "type": 1,
        "name": "北京市东城区新怡小学",
        "phone": 67086196,
        "address": "北京市东城区新怡家园9号",
        "typeName": "小学",
        "id": 59
    },
    {
        "type": 1,
        "name": "北京市东城区金台书院小学",
        "phone": 67020173,
        "address": "北京市东城区东晓市街203号",
        "typeName": "小学",
        "id": 60
    },
    {
        "type": 1,
        "name": "北京市东城区安外三条小学",
        "phone": 84132605,
        "address": "北京市东城区上龙北巷3号",
        "typeName": "小学",
        "id": 61
    },
    {
        "type": 1,
        "name": "北京市汇文第一小学",
        "phone": 65241376,
        "address": "北京市东城区丁香胡同7号",
        "typeName": "小学",
        "id": 62
    },
    {
        "type": 1,
        "name": "北京市崇文小学",
        "phone": "010-67125642",
        "address": "东城区花市枣苑12号",
        "typeName": "小学",
        "id": 63
    },
    {
        "type": 1,
        "name": "东城区新景小学",
        "phone": "010-87186761",
        "address": "东城区西花市南里西区7号楼",
        "typeName": "小学",
        "id": 64
    },
    {
        "type": 1,
        "name": "分司厅小学",
        "phone": 64041261,
        "address": "东城区小经厂胡同2号",
        "typeName": "小学",
        "id": 65
    },
    {
        "type": 1,
        "name": "分司厅小学(北锣校区）",
        "phone": 64065396,
        "address": "东城区安定门内千福巷5号",
        "typeName": "小学",
        "id": 66
    },
    {
        "type": 1,
        "name": "分司厅小学(一年级部）",
        "phone": 64015380,
        "address": "东城区琉璃寺胡同11号",
        "typeName": "小学",
        "id": 67
    },
    {
        "type": 1,
        "name": "北京市东城区东四九条小学",
        "phone": 64043778,
        "address": "北京市东城区东四九条67号",
        "typeName": "小学",
        "id": 68
    },
    {
        "type": 1,
        "name": "北京第一师范学校附属小学",
        "phone": 67680764,
        "address": "北京市东城区永定门外安乐林路17号",
        "typeName": "小学",
        "id": 69
    },
    {
        "type": 1,
        "name": "北京市东城区革新里小学",
        "phone": 67222093,
        "address": "北京市东城区永外沙子口管村5号",
        "typeName": "小学",
        "id": 70
    },
    {
        "type": 1,
        "name": "北京市东城区精忠街小学",
        "phone": 67021505,
        "address": "精忠街11号",
        "typeName": "小学",
        "id": 71
    },
    {
        "type": 1,
        "name": "北京市东城区史家胡同小学（高年级部）",
        "phone": "010-64065588",
        "address": "北京市东城区朝内北小街南弓匠营胡同2号",
        "typeName": "小学",
        "id": 72
    },
    {
        "type": 1,
        "name": "北京市东城区史家胡同小学（一年级部）",
        "phone": "010-64065588",
        "address": "北京市东城区金宝街65号",
        "typeName": "小学",
        "id": 73
    },
    {
        "type": 1,
        "name": "北京市东城区史家胡同小学（二年级部）",
        "phone": "010-64065589",
        "address": "北京市东城区史家胡同59号",
        "typeName": "小学",
        "id": 74
    },
    {
        "type": 1,
        "name": "北京市东城区地坛小学",
        "phone": 64262206,
        "address": "北京市东城区和平里九区甲2号",
        "typeName": "小学",
        "id": 75
    },
    {
        "type": 1,
        "name": "北京市东城区东交民巷小学（西校区）",
        "phone": 65131284,
        "address": "东城区台基厂大街14号",
        "typeName": "小学",
        "id": 76
    },
    {
        "type": 1,
        "name": "北京市东城区北新桥小学",
        "phone": 64616471,
        "address": "北京市东城区东直门大街乙2号",
        "typeName": "小学",
        "id": 77
    },
    {
        "type": 1,
        "name": "北京市东城区东交民巷小学(东校区)",
        "phone": 65131595,
        "address": "东城区船板胡同63号",
        "typeName": "小学",
        "id": 78
    },
    {
        "type": 1,
        "name": "北京第一师范学校附属小学（高年级部）",
        "phone": 87810800,
        "address": "北京市东城区永定门外桃杨路7号",
        "typeName": "小学",
        "id": 79
    },
    {
        "type": 1,
        "name": "北京光明小学（本校区）",
        "phone": 67123839,
        "address": "北京市东城区光明路甲12号",
        "typeName": "小学",
        "id": 80
    },
    {
        "type": 1,
        "name": "北京市育才学校",
        "phone": "52829888-8006",
        "address": "西城区东经路21号",
        "typeName": "小学",
        "id": 81
    },
    {
        "type": 1,
        "name": "北京师范大学亚太实验学校",
        "phone": 69759604,
        "address": "北京市昌平区八仙庄曹碾村西北",
        "typeName": "小学",
        "id": 82
    },
    {
        "type": 1,
        "name": "北京市育才学校太平街校区",
        "phone": "52829888- 1101",
        "address": "西城区太平街西巷6号",
        "typeName": "小学",
        "id": 83
    },
    {
        "type": 1,
        "name": "北京市第八中学附属小学",
        "phone": 63533362,
        "address": "北京市西城区福州馆街3号",
        "typeName": "小学",
        "id": 84
    },
    {
        "type": 1,
        "name": "北京小学天宁寺分校（东址）",
        "phone": 52990930,
        "address": "北京市西城区天宁寺前街8号",
        "typeName": "小学",
        "id": 85
    },
    {
        "type": 1,
        "name": "北京小学天宁寺分校（西址）",
        "phone": 52990930,
        "address": "北京市西城区天宁寺前街35号",
        "typeName": "小学",
        "id": 86
    },
    {
        "type": 1,
        "name": "北京小学红山分校",
        "phone": 58371566,
        "address": "西城区广安门外大街305号二区12号",
        "typeName": "小学",
        "id": 87
    },
    {
        "type": 1,
        "name": "北京小学广安门内分校",
        "phone": 58370302,
        "address": "西城区北线阁街2号",
        "typeName": "小学",
        "id": 88
    },
    {
        "type": 1,
        "name": "西城区厂桥小学（北址）",
        "phone": 83222708,
        "address": "北京市西城区护国寺东巷7号",
        "typeName": "小学",
        "id": 89
    },
    {
        "type": 1,
        "name": "西城区厂桥小学（南址）",
        "phone": 83222708,
        "address": "北京市西城区西安门西大街167号",
        "typeName": "小学",
        "id": 90
    },
    {
        "type": 1,
        "name": "北京市西城区椿树馆小学",
        "phone": 63461626,
        "address": "北京市西城区广外南街43号",
        "typeName": "小学",
        "id": 91
    },
    {
        "type": 1,
        "name": "北京市西城区登莱小学",
        "phone": 63548579,
        "address": "北京市西城区登莱胡同29号",
        "typeName": "小学",
        "id": 92
    },
    {
        "type": 1,
        "name": "北京第二实验小学白云路分校",
        "phone": 68017178,
        "address": "北京市西城区白云路2号",
        "typeName": "小学",
        "id": 93
    },
    {
        "type": 1,
        "name": "北京第二实验小学广外分校",
        "phone": 63366418,
        "address": "西城区广安门外红居南街2号",
        "typeName": "小学",
        "id": 94
    },
    {
        "type": 1,
        "name": "北京第二实验小学涭水河分校（原址）",
        "phone": 66036191,
        "address": "北京市西城区受水河胡同45号旁门",
        "typeName": "小学",
        "id": 95
    },
    {
        "type": 1,
        "name": "北京第二实验小学涭水河分校（现临时周转地址）",
        "phone": 66036191,
        "address": "北京市西城区前门西大街137号",
        "typeName": "小学",
        "id": 96
    },
    {
        "type": 1,
        "name": "西城区阜成门外第一小学",
        "phone": 68047867,
        "address": "北京市西城区阜成门外大街甲10号",
        "typeName": "小学",
        "id": 97
    },
    {
        "type": 1,
        "name": "西城区复兴门外第一小学低年级部",
        "phone": 68027078,
        "address": "西城区复外地藏庵23号",
        "typeName": "小学",
        "id": 98
    },
    {
        "type": 1,
        "name": "西城区复兴门外第一小学高年级部",
        "phone": 68027078,
        "address": "西城区南礼士路三条七号",
        "typeName": "小学",
        "id": 99
    },
    {
        "type": 1,
        "name": "北京市西城区红莲小学",
        "phone": 63483216,
        "address": "北京市西城区红莲中里14号",
        "typeName": "小学",
        "id": 100
    },
    {
        "type": 1,
        "name": "西城区宏庙小学",
        "phone": 66179839,
        "address": "北京市西城区底单北大街宏庙胡同13号",
        "typeName": "小学",
        "id": 101
    },
    {
        "type": 1,
        "name": "西城区华嘉小学（西校区）",
        "phone": 66021673,
        "address": "北京市西城区西廊下胡同34号",
        "typeName": "小学",
        "id": 102
    },
    {
        "type": 1,
        "name": "西城区华嘉小学（东校区）",
        "phone": 66021673,
        "address": "北京市西城区东廊下胡同16号对面",
        "typeName": "小学",
        "id": 103
    },
    {
        "type": 1,
        "name": "西城区黄城根小学（本址）",
        "phone": 66168193,
        "address": "北京市西城区西黄城根北街3号",
        "typeName": "小学",
        "id": 104
    },
    {
        "type": 1,
        "name": "西城区黄城根小学（后广平校区）",
        "phone": 66561223,
        "address": "北京市西城区后广平胡同9号",
        "typeName": "小学",
        "id": 105
    },
    {
        "type": 1,
        "name": "西城区黄城根小学（官园校区）",
        "phone": 66170549,
        "address": "北京市西城区官园胡同1号",
        "typeName": "小学",
        "id": 106
    },
    {
        "type": 1,
        "name": "西城区黄城根小学（育幼校区）",
        "phone": 66171787,
        "address": "北京市西城区育幼胡同5号",
        "typeName": "小学",
        "id": 107
    },
    {
        "type": 1,
        "name": "西城区进步小学",
        "phone": 68351098,
        "address": "西直门外大街榆树馆胡同1号",
        "typeName": "小学",
        "id": 108
    },
    {
        "type": 1,
        "name": "北京雷锋小学",
        "phone": 64043695,
        "address": "西城区旧鼓楼大街西绦胡同甲2号",
        "typeName": "小学",
        "id": 109
    },
    {
        "type": 1,
        "name": "北京雷锋小学（东街校区）",
        "phone": 83282375,
        "address": "西城区新街口东街5号",
        "typeName": "小学",
        "id": 110
    },
    {
        "type": 1,
        "name": "西城区力学小学",
        "phone": 66036987,
        "address": "北京市西城区力学胡同47号",
        "typeName": "小学",
        "id": 111
    },
    {
        "type": 1,
        "name": "北京市西城区柳荫街小学（煤厂胡同址）",
        "phone": 66181442,
        "address": "北京市西城区西煤厂胡同7号",
        "typeName": "小学",
        "id": 112
    },
    {
        "type": 1,
        "name": "北京市西城区柳荫街小学(大翔凤址）",
        "phone": 66113501,
        "address": "北京市西城区大翔凤胡同16号",
        "typeName": "小学",
        "id": 113
    },
    {
        "type": 1,
        "name": "北京第一实验小学前门分校",
        "phone": 63179191,
        "address": "北京市西城区和平门外东街甲5号",
        "typeName": "小学",
        "id": 114
    },
    {
        "type": 1,
        "name": "北京市三帆中学附属小学",
        "phone": 62382998,
        "address": "北京市西城区裕中西里29号",
        "typeName": "小学",
        "id": 115
    },
    {
        "type": 1,
        "name": "西城区三里河第三小学北址",
        "phone": 68527953,
        "address": "北京市西城区三里河三区36号",
        "typeName": "小学",
        "id": 116
    },
    {
        "type": 1,
        "name": "西城区三里河第三小学南址",
        "phone": 68525523,
        "address": "北京市西城区三里河三区32号",
        "typeName": "小学",
        "id": 117
    },
    {
        "type": 1,
        "name": "西城区三义里小学（三义里5号址）",
        "phone": 63365524,
        "address": "北京市西城区广外三义里5号",
        "typeName": "小学",
        "id": 118
    },
    {
        "type": 1,
        "name": "西城区三义里小学（三义里4号址）",
        "phone": 63365524,
        "address": "北京市西城区广外三义里4号",
        "typeName": "小学",
        "id": 119
    },
    {
        "type": 1,
        "name": "西城区什刹海小学本校",
        "phone": 64042613,
        "address": "北京市西城区地安门内大街恭俭胡同41号",
        "typeName": "小学",
        "id": 120
    },
    {
        "type": 1,
        "name": "西城区什刹海小学分校",
        "phone": 64041898,
        "address": "北京市西城区地安门内大街米粮库胡同16号",
        "typeName": "小学",
        "id": 121
    },
    {
        "type": 1,
        "name": "北京市第十五中学附属小学",
        "phone": 63521644,
        "address": "北京市西城区白纸坊东街27号",
        "typeName": "小学",
        "id": 122
    },
    {
        "type": 1,
        "name": "北京第二实验小学（新文化街校区）",
        "phone": 66190600,
        "address": "新文化街111号（新文化街校区）",
        "typeName": "小学",
        "id": 123
    },
    {
        "type": 1,
        "name": "北京第二实验小学（王府校区）",
        "phone": 66190700,
        "address": "新文化街53号（王府校区）",
        "typeName": "小学",
        "id": 124
    },
    {
        "type": 1,
        "name": "西城师范附小",
        "phone": 66190800,
        "address": "车公庄大街1号（官园校区）",
        "typeName": "小学",
        "id": 125
    },
    {
        "type": 1,
        "name": "北京第二实验小学（长安校区）",
        "phone": 66054186,
        "address": "东绒线胡同41号（长安校区）",
        "typeName": "小学",
        "id": 126
    },
    {
        "type": 1,
        "name": "北京第二实验小学（德胜校区）",
        "phone": 62014277,
        "address": "德外大街安德路142号（德胜校区）",
        "typeName": "小学",
        "id": 127
    },
    {
        "type": 1,
        "name": "北京市西城区顺城街第一小学",
        "phone": 66015829,
        "address": "北京市西城区前门西大街135号",
        "typeName": "小学",
        "id": 128
    },
    {
        "type": 1,
        "name": "西城区炭儿胡同小学",
        "phone": 63044156,
        "address": "北京市西城区炭儿胡同11号",
        "typeName": "小学",
        "id": 129
    },
    {
        "type": 1,
        "name": "西城区陶然亭小学",
        "phone": 63522049,
        "address": "北京市西城区龙泉胡同5号",
        "typeName": "小学",
        "id": 130
    },
    {
        "type": 1,
        "name": "西城区五路通小学",
        "phone": 62018331,
        "address": "北京市西城区德外大街什坊街甲6号",
        "typeName": "小学",
        "id": 131
    },
    {
        "type": 1,
        "name": "西城区五路通小学教育学院校区",
        "phone": 62025791,
        "address": "北京西城区德胜门外黄寺大街什坊街2号",
        "typeName": "小学",
        "id": 132
    },
    {
        "type": 1,
        "name": "西城区师范学校附属小学（六铺炕校区）",
        "phone": 62071890,
        "address": "北京市西城区六铺炕北小街3号",
        "typeName": "小学",
        "id": 133
    },
    {
        "type": 1,
        "name": "北京市西城区师范学校附属小学（展览路校区）",
        "phone": 62071890,
        "address": "北京市西城区车公庄大街1号",
        "typeName": "小学",
        "id": 134
    },
    {
        "type": 1,
        "name": "北京师范大学京师附小本校",
        "phone": 66181672,
        "address": "西四北四条47号",
        "typeName": "小学",
        "id": 135
    },
    {
        "type": 1,
        "name": "北京师范大学京师附小一分校",
        "phone": 66181672,
        "address": "西四北四条28号",
        "typeName": "小学",
        "id": 136
    },
    {
        "type": 1,
        "name": "北京师范大学京师附小二分校",
        "phone": 66181672,
        "address": "西四北三条25号",
        "typeName": "小学",
        "id": 137
    },
    {
        "type": 1,
        "name": "北京师范大学京师附小三分校",
        "phone": 66181672,
        "address": "西四北三条乙26号",
        "typeName": "小学",
        "id": 138
    },
    {
        "type": 1,
        "name": "北京市西城区北礼士路第一小学",
        "phone": 68355412,
        "address": "北京市西城区北礼士路133号",
        "typeName": "小学",
        "id": 139
    },
    {
        "type": 1,
        "name": "西城区香厂路小学本校",
        "phone": 52990929,
        "address": "北京市西城区香厂路31号",
        "typeName": "小学",
        "id": 140
    },
    {
        "type": 1,
        "name": "西城区香厂路小学分校",
        "phone": 52990929,
        "address": "北京市西城区板章胡同5号",
        "typeName": "小学",
        "id": 141
    },
    {
        "type": 1,
        "name": "西城区新世纪实验小学",
        "phone": 63036387,
        "address": "西城区南纬路2号院",
        "typeName": "小学",
        "id": 142
    },
    {
        "type": 1,
        "name": "北京市宣武师范学校附属第一小学（本校）",
        "phone": 63519004,
        "address": "北京市西城区右安门内大街26号",
        "typeName": "小学",
        "id": 143
    },
    {
        "type": 1,
        "name": "北京市宣武师范学校附属第一小学（右安校区）",
        "phone": 63525510,
        "address": "北京市西城区右安门内大街新安中里2巷10号",
        "typeName": "小学",
        "id": 144
    },
    {
        "type": 1,
        "name": "北京市宣武师范学校附属第一小学（里仁校区）",
        "phone": 63551771,
        "address": "北京市西城区里仁街10号",
        "typeName": "小学",
        "id": 145
    },
    {
        "type": 1,
        "name": "北京市宣武回民小学低年级部",
        "phone": 52990934,
        "address": "西城区牛街西里一区5号",
        "typeName": "小学",
        "id": 146
    },
    {
        "type": 1,
        "name": "北京市宣武回民小学高年级部",
        "phone": 52990935,
        "address": "西城区枣林斜街55号",
        "typeName": "小学",
        "id": 147
    },
    {
        "type": 1,
        "name": "北京市西城区鸦儿胡同小学（北校区）",
        "phone": 64040172,
        "address": "西城区鼓楼西大街63号",
        "typeName": "小学",
        "id": 148
    },
    {
        "type": 1,
        "name": "北京市西城区鸦儿胡同小学（南校区）",
        "phone": 64040172,
        "address": "西城区鸦儿胡同小学25号",
        "typeName": "小学",
        "id": 149
    },
    {
        "type": 1,
        "name": "北京第二实验小学玉桃园分校",
        "phone": 62252545,
        "address": "北京市西城区西直门内大街玉桃园小区三区10号",
        "typeName": "小学",
        "id": 150
    },
    {
        "type": 1,
        "name": "北京市西城区育民小学（北校址）",
        "phone": 68013581,
        "address": "北京市西城区真武庙头条8号",
        "typeName": "小学",
        "id": 151
    },
    {
        "type": 1,
        "name": "北京市西城区育民小学（南校址）",
        "phone": 68013581,
        "address": "北京市西城区真武庙头条8号",
        "typeName": "小学",
        "id": 152
    },
    {
        "type": 1,
        "name": "西城区育翔小学马甸总校区",
        "phone": 82025014,
        "address": "北京市西城区马甸南村乙14号",
        "typeName": "小学",
        "id": 153
    },
    {
        "type": 1,
        "name": "西城区育翔小学阳光校区",
        "phone": 82237968,
        "address": "北京市西城区黄寺大街阳光丽景小区23号院8号楼",
        "typeName": "小学",
        "id": 154
    },
    {
        "type": 1,
        "name": "西城区育翔小学德胜里校区",
        "phone": 62013204,
        "address": "北京市西城区德胜里3号",
        "typeName": "小学",
        "id": 155
    },
    {
        "type": 1,
        "name": "展览路第一小学",
        "phone": 68351125,
        "address": "百万庄中里7号",
        "typeName": "小学",
        "id": 156
    },
    {
        "type": 1,
        "name": "展览路第一小学分校",
        "phone": 68351125,
        "address": "百万庄中里32号",
        "typeName": "小学",
        "id": 157
    },
    {
        "type": 1,
        "name": "西城区志成小学（南址）",
        "phone": 66161427,
        "address": "北京市西城区赵登禹路58号",
        "typeName": "小学",
        "id": 158
    },
    {
        "type": 1,
        "name": "西城区志成小学（西址）",
        "phone": 66181702,
        "address": "北京市西城区前半壁街48号",
        "typeName": "小学",
        "id": 159
    },
    {
        "type": 1,
        "name": "西城区中古友谊小学（三里河校区）",
        "phone": 68580077,
        "address": "三里河一区39号",
        "typeName": "小学",
        "id": 160
    },
    {
        "type": 1,
        "name": "西城区中古友谊小学（南礼士路校区）",
        "phone": 68530710,
        "address": "南礼士路三条2号",
        "typeName": "小学",
        "id": 161
    },
    {
        "type": 1,
        "name": "西城区自忠小学（本校）",
        "phone": 66030179,
        "address": "西城区府右街丙27号",
        "typeName": "小学",
        "id": 162
    },
    {
        "type": 1,
        "name": "西城区自忠小学（分校）",
        "phone": 66011158,
        "address": "西城区府右街1号",
        "typeName": "小学",
        "id": 163
    },
    {
        "type": 1,
        "name": "西城区实验小学",
        "phone": "52990909-0",
        "address": "北京市西城区南菜园35号",
        "typeName": "小学",
        "id": 164
    },
    {
        "type": 1,
        "name": "西城区西单小学",
        "phone": 66020526,
        "address": "北京市西城区中京畿道1号",
        "typeName": "小学",
        "id": 165
    },
    {
        "type": 1,
        "name": "西城区北长街小学",
        "phone": 66055723,
        "address": "西城区北长街71号",
        "typeName": "小学",
        "id": 166
    },
    {
        "type": 1,
        "name": "西城区奋斗小学（西址）",
        "phone": 66012277,
        "address": "北京市西城区闹市口大街月台胡同15号",
        "typeName": "小学",
        "id": 167
    },
    {
        "type": 1,
        "name": "西城区奋斗小学（东址）",
        "phone": 63538249,
        "address": "北京市西城区宣武门西大街甲8号",
        "typeName": "小学",
        "id": 168
    },
    {
        "type": 1,
        "name": "西城区奋斗小学（北址）",
        "phone": 68351892,
        "address": "北京市西城区阜成门外北营房中街57号",
        "typeName": "小学",
        "id": 169
    },
    {
        "type": 1,
        "name": "西城区白纸坊小学",
        "phone": 63548961,
        "address": "北京市西城区白广路乙27",
        "typeName": "小学",
        "id": 170
    },
    {
        "type": 1,
        "name": "西城区西什库小学",
        "phone": 66177252,
        "address": "北京市西城区刘兰塑胡同14号",
        "typeName": "小学",
        "id": 171
    },
    {
        "type": 1,
        "name": "西城区青年湖小学",
        "phone": 63464896,
        "address": "西城区鸭子桥北里13号",
        "typeName": "小学",
        "id": 172
    },
    {
        "type": 1,
        "name": "北京第一实验小学（和平门校区）",
        "phone": 63037631,
        "address": "南新华街17号",
        "typeName": "小学",
        "id": 173
    },
    {
        "type": 1,
        "name": "北京第一实验小学（虎坊桥校区)",
        "phone": 52990921,
        "address": "梁家园胡同5号",
        "typeName": "小学",
        "id": 174
    },
    {
        "type": 1,
        "name": "北京第一实验小学（广外校区)",
        "phone": 52990923,
        "address": "中新佳园7号楼",
        "typeName": "小学",
        "id": 175
    },
    {
        "type": 1,
        "name": "北京市陈经纶中学崇实分校",
        "phone": 87352952,
        "address": "北京市朝阳区南磨房乡紫南家园小区田华小学",
        "typeName": "小学",
        "id": 176
    },
    {
        "type": 1,
        "name": "北京市朝阳区安华学校",
        "phone": 64265837,
        "address": "北京朝阳区安华西里二区12号",
        "typeName": "小学",
        "id": 177
    },
    {
        "type": 1,
        "name": "北京市陈经纶中学新教育实验分校",
        "phone": 57914238,
        "address": "朝阳区常营地区住欣家园102号",
        "typeName": "小学",
        "id": 178
    },
    {
        "type": 1,
        "name": "北京市朝阳外国语学校北苑分校",
        "phone": 84922680,
        "address": "北京市朝阳区安外北苑村临2号",
        "typeName": "小学",
        "id": 179
    },
    {
        "type": 1,
        "name": "北京师范大学三帆中学朝阳学校",
        "phone": 84294863,
        "address": "北京市朝阳区南湖南路12号",
        "typeName": "小学",
        "id": 180
    },
    {
        "type": 1,
        "name": "北京教育学院朝阳分院附属学校",
        "phone": 65021293,
        "address": "北京朝外慈云里甲1号",
        "typeName": "小学",
        "id": 181
    },
    {
        "type": 1,
        "name": "北京市朝阳区芳草地国际学校富力分校",
        "phone": 59645296,
        "address": "北京市朝阳区鲁店北路30号",
        "typeName": "小学",
        "id": 182
    },
    {
        "type": 1,
        "name": "首都师范大学附属实验学校",
        "phone": 64745843,
        "address": "望京花园西区133号",
        "typeName": "小学",
        "id": 183
    },
    {
        "type": 1,
        "name": "首都师范大学附属实验学校花西校区",
        "phone": 64727530,
        "address": "花家地西里105号",
        "typeName": "小学",
        "id": 184
    },
    {
        "type": 1,
        "name": "首都师范大学附属实验学校西园校区",
        "phone": 64703545,
        "address": "朝阳区望京西园四区428楼",
        "typeName": "小学",
        "id": 185
    },
    {
        "type": 1,
        "name": "北京市陈经纶中学帝景分校",
        "phone": 58633769,
        "address": "朝阳区广渠路28号院307楼",
        "typeName": "小学",
        "id": 186
    },
    {
        "type": 1,
        "name": "北京市中央商务区实验学校中学部",
        "phone": 67781103,
        "address": "北京市朝阳区西大望路下甸甲1号",
        "typeName": "小学",
        "id": 187
    },
    {
        "type": 1,
        "name": "北京市中央商务区实验学校小学部",
        "phone": 65813892,
        "address": "北京市朝阳区西大望路5号",
        "typeName": "小学",
        "id": 188
    },
    {
        "type": 1,
        "name": "北京市日坛中学东润分校",
        "phone": 64360881,
        "address": "朝阳区南十里居38号",
        "typeName": "小学",
        "id": 189
    },
    {
        "type": 1,
        "name": "北京市日坛中学实验学校晨光校区",
        "phone": 65562704,
        "address": "北京市朝阳区十里堡北里晨光家园220号",
        "typeName": "小学",
        "id": 190
    },
    {
        "type": 1,
        "name": "北京市樱花园实验学校",
        "phone": 64442478,
        "address": "北京市朝阳区樱花园小区16号楼",
        "typeName": "小学",
        "id": 191
    },
    {
        "type": 1,
        "name": "北京市三里屯一中（百子园校区）",
        "phone": 87510283,
        "address": "朝阳区后现代城百子园20号",
        "typeName": "小学",
        "id": 192
    },
    {
        "type": 1,
        "name": "北京市陈经纶中学劲松分校",
        "phone": 1087373401,
        "address": "北京市朝阳区磨房北里小区230号",
        "typeName": "小学",
        "id": 193
    },
    {
        "type": 1,
        "name": "北京市日坛中学实验学校西校区",
        "phone": 65561804,
        "address": "北京市朝阳区八里庄西里十号",
        "typeName": "小学",
        "id": 194
    },
    {
        "type": 1,
        "name": "北京市陈经纶中学劲松分校",
        "phone": 67315049,
        "address": "北京市朝阳区平乐园小区103号楼",
        "typeName": "小学",
        "id": 195
    },
    {
        "type": 1,
        "name": "北京市润丰学校",
        "phone": 85519898,
        "address": "北京市朝阳区青年路西里六号院",
        "typeName": "小学",
        "id": 196
    },
    {
        "type": 1,
        "name": "中国教育科学研究院朝阳实验学校",
        "phone": 64420124,
        "address": "朝阳区安贞西里一区13号楼",
        "typeName": "小学",
        "id": 197
    },
    {
        "type": 1,
        "name": "北京市忠德学校",
        "phone": 51397602,
        "address": "北京市朝阳区东苇路9号",
        "typeName": "小学",
        "id": 198
    },
    {
        "type": 1,
        "name": "北京市朝阳区安民学校黎各庄校区",
        "phone": null,
        "address": "北京市朝阳区金盏乡黎各庄村安民学校",
        "typeName": "小学",
        "id": 199
    },
    {
        "type": 1,
        "name": "北京市陈经纶中学分校东湖湾校区",
        "phone": 64707538,
        "address": "北京市朝阳区南湖东园223号",
        "typeName": "小学",
        "id": 200
    },
    {
        "type": 1,
        "name": "北京市陈经纶中学分校南湖东园校区",
        "phone": 64775890,
        "address": "北京市朝阳区南湖东园223号",
        "typeName": "小学",
        "id": 201
    },
    {
        "type": 1,
        "name": "北京市陈经纶中学嘉铭分校",
        "phone": 84857601,
        "address": "北苑路86号院3区7号楼",
        "typeName": "小学",
        "id": 202
    },
    {
        "type": 1,
        "name": "陈经纶中学嘉铭分校欧陆校区",
        "phone": 84857601,
        "address": "北苑路170号9号院",
        "typeName": "小学",
        "id": 203
    },
    {
        "type": 1,
        "name": "北京景山学校朝阳学校",
        "phone": 58540926,
        "address": "北京市朝阳区来广营创达路62号",
        "typeName": "小学",
        "id": 204
    },
    {
        "type": 1,
        "name": "北京工业大学附属中学首城校区",
        "phone": 67397742,
        "address": "朝阳区营盘沟路8号",
        "typeName": "小学",
        "id": 205
    },
    {
        "type": 1,
        "name": "中央美术学院附属实验学校",
        "phone": 64371451,
        "address": "北京市朝阳区酒仙桥路甲一号",
        "typeName": "小学",
        "id": 206
    },
    {
        "type": 1,
        "name": "北京市朝阳区爱迪外国语学校",
        "phone": 84392266,
        "address": "北京市朝阳区楼梓庄路8号",
        "typeName": "小学",
        "id": 207
    },
    {
        "type": 1,
        "name": "北京中学",
        "phone": 1050981098,
        "address": "北京市朝阳区西坝河东里1号",
        "typeName": "小学",
        "id": 208
    },
    {
        "type": 1,
        "name": "北京第二外国语学院附属中学",
        "phone": 65756119,
        "address": "北京市朝阳区管庄镇杨闸环岛东京通苑小区院内管庄路63号附近",
        "typeName": "小学",
        "id": 209
    },
    {
        "type": 1,
        "name": "北京市陈经纶中学（保利分校）",
        "phone": 57922568,
        "address": "北京市朝阳区常营高安屯路35号",
        "typeName": "小学",
        "id": 210
    },
    {
        "type": 1,
        "name": "北京第二外国语学院附属中学本部（管庄校区）",
        "phone": 65756119,
        "address": "北京市朝阳区管庄镇杨闸环岛东京通苑小区院内管庄路63号附近",
        "typeName": "小学",
        "id": 211
    },
    {
        "type": 1,
        "name": "北京第二外国语学院附属中学（杨闸校区）",
        "phone": 65762291,
        "address": "京通快速路入口与建国路交叉口东北100米",
        "typeName": "小学",
        "id": 212
    },
    {
        "type": 1,
        "name": "北京第二外国语学院附属中学（小学部八里桥校区）",
        "phone": 85704407,
        "address": "北京朝阳区八里桥北（朝阳路北50米）",
        "typeName": "小学",
        "id": 213
    },
    {
        "type": 1,
        "name": "北京第二外国语学院附属中学（小学部瑞祥民族校区）",
        "phone": 85707893,
        "address": "北京朝阳区瑞祥里小区17号楼",
        "typeName": "小学",
        "id": 214
    },
    {
        "type": 1,
        "name": "中央美术学院附属实验学校",
        "phone": "64371282-8000",
        "address": "北京市朝阳区大山子西里10号",
        "typeName": "小学",
        "id": 215
    },
    {
        "type": 1,
        "name": "北京市和平街第一中学",
        "phone": 64293036,
        "address": "北京市朝阳区和平街八区16楼",
        "typeName": "小学",
        "id": 216
    },
    {
        "type": 1,
        "name": "北京市和平街第一中学莲葩园校区",
        "phone": 84957677,
        "address": "北京市朝阳区北苑家园莲葩园1号",
        "typeName": "小学",
        "id": 217
    },
    {
        "type": 1,
        "name": "北京市和平街第一中学朝来校区",
        "phone": 84955041,
        "address": "北京市朝阳区朝来家园赢秋苑21号",
        "typeName": "小学",
        "id": 218
    },
    {
        "type": 1,
        "name": "北京市东方德才学校",
        "phone": "010-85970292",
        "address": "北京市小庄东里38号",
        "typeName": "小学",
        "id": 219
    },
    {
        "type": 1,
        "name": "北京市私立新亚中学",
        "phone": 67473875,
        "address": "朝阳区十八里店村886号",
        "typeName": "小学",
        "id": 220
    },
    {
        "type": 1,
        "name": "清华大学附属中学朝阳学校",
        "phone": 84482386,
        "address": "朝阳区新源街31号",
        "typeName": "小学",
        "id": 221
    },
    {
        "type": 1,
        "name": "北京市朝阳区垂杨柳中心小学劲松分校",
        "phone": 67782086,
        "address": "北京市朝阳区广渠门外东架松24号",
        "typeName": "小学",
        "id": 222
    },
    {
        "type": 1,
        "name": "北京市朝阳区酒仙桥中心小学",
        "phone": 1064373914,
        "address": "北京市朝阳区酒仙桥路乙28号",
        "typeName": "小学",
        "id": 223
    },
    {
        "type": 1,
        "name": "北京市朝阳区楼梓庄小学",
        "phone": 84316551,
        "address": "北京市朝阳区金盏乡楼梓庄村",
        "typeName": "小学",
        "id": 224
    },
    {
        "type": 1,
        "name": "北京市朝阳区孛罗营小学",
        "phone": "87393180-0",
        "address": "北京市朝阳区王四营乡孛罗营村194号",
        "typeName": "小学",
        "id": 225
    },
    {
        "type": 1,
        "name": "北京市朝阳区将台洼小学",
        "phone": 84301535,
        "address": "北京市朝阳区东风乡将台洼小学",
        "typeName": "小学",
        "id": 226
    },
    {
        "type": 1,
        "name": "北京市朝阳区平房小学",
        "phone": 85573217,
        "address": "北京市朝阳区平房村南后街96号",
        "typeName": "小学",
        "id": 227
    },
    {
        "type": 1,
        "name": "北京市朝阳区北皋中心小学",
        "phone": 64371370,
        "address": "北京市朝阳区崔各庄乡马南里小区北皋中心小学",
        "typeName": "小学",
        "id": 228
    },
    {
        "type": 1,
        "name": "北京市朝阳区呼家楼中心小学万科青青分校",
        "phone": 85301847,
        "address": "北京市朝阳区南豆各庄5号院万科青青家园内",
        "typeName": "小学",
        "id": 229
    },
    {
        "type": 1,
        "name": "中国传媒大学附属小学",
        "phone": 65791268,
        "address": "北京市朝阳区定福庄南里2号",
        "typeName": "小学",
        "id": 230
    },
    {
        "type": 1,
        "name": "北京师范大学奥林匹克花园实验小学",
        "phone": 51079799,
        "address": "北京市朝阳区东坝京奥家园288号楼",
        "typeName": "小学",
        "id": 231
    },
    {
        "type": 1,
        "name": "北京市朝阳区奶子房小学",
        "phone": 84709118,
        "address": "北京市朝阳区奶子房西村",
        "typeName": "小学",
        "id": 232
    },
    {
        "type": 1,
        "name": "北京市朝阳区西直河小学",
        "phone": 67383841,
        "address": "北京市朝阳区十八里店乡西直河村",
        "typeName": "小学",
        "id": 233
    },
    {
        "type": 1,
        "name": "北京市朝阳区黄胄艺术实验小学",
        "phone": 1064654119,
        "address": "北京市朝阳区西坝河西里15号",
        "typeName": "小学",
        "id": 234
    },
    {
        "type": 1,
        "name": "北京市朝阳劲松第四小学",
        "phone": 67782219,
        "address": "劲松四区417楼",
        "typeName": "小学",
        "id": 235
    },
    {
        "type": 1,
        "name": "北京市朝阳区白家庄小学本部北校区",
        "phone": 65946046,
        "address": "北京市朝阳区三里屯南路40号",
        "typeName": "小学",
        "id": 236
    },
    {
        "type": 1,
        "name": "北京市朝阳区白家庄小学本部南校区",
        "phone": 65076055,
        "address": "朝阳区核桃园北里甲2号",
        "typeName": "小学",
        "id": 237
    },
    {
        "type": 1,
        "name": "北京市朝阳区白家庄小学望京新城校区",
        "phone": 64706630,
        "address": "朝阳区望京西园四区408楼",
        "typeName": "小学",
        "id": 238
    },
    {
        "type": 1,
        "name": "北京市朝阳区团结湖第二小学",
        "phone": 85989324,
        "address": "北京市朝阳区团结湖中路南二条1号楼",
        "typeName": "小学",
        "id": 239
    },
    {
        "type": 1,
        "name": "北京市朝阳区白家庄小学望京科技园校区",
        "phone": 64747289,
        "address": "朝阳区望京西园一区117楼",
        "typeName": "小学",
        "id": 240
    },
    {
        "type": 1,
        "name": "北京市朝阳区将台路小学",
        "phone": 64349027,
        "address": "北京市朝阳区芳园里社区将台路小学",
        "typeName": "小学",
        "id": 241
    },
    {
        "type": 1,
        "name": "中国科学院附属实验学校",
        "phone": "010-8284436",
        "address": "北京市朝阳区科学园南里七区703",
        "typeName": "小学",
        "id": 242
    },
    {
        "type": 1,
        "name": "北京市朝阳区双桥第二小学",
        "phone": 1085395632,
        "address": "北京市朝阳区双桥中路临乙5号",
        "typeName": "小学",
        "id": 243
    },
    {
        "type": 1,
        "name": "北京市朝阳区八里庄中心小学",
        "phone": 65574553,
        "address": "北京市朝阳区慈云寺北里114号",
        "typeName": "小学",
        "id": 244
    },
    {
        "type": 1,
        "name": "北京市朝阳区上辛堡小学",
        "phone": 84791067,
        "address": "北京市朝阳区孙河乡上辛堡村上辛堡小学",
        "typeName": "小学",
        "id": 245
    },
    {
        "type": 1,
        "name": "北京市朝阳区八里庄中心小学北里校区",
        "phone": 85833007,
        "address": "北京市朝阳区八里庄北里114号",
        "typeName": "小学",
        "id": 246
    },
    {
        "type": 1,
        "name": "北京市朝阳区实验小学罗马嘉园分校",
        "phone": 58627466,
        "address": "北京市朝阳区朝阳北路109号",
        "typeName": "小学",
        "id": 247
    },
    {
        "type": 1,
        "name": "北京市朝阳区日坛小学四惠校区",
        "phone": 65584121,
        "address": "北京市朝阳区通惠家园惠生园20号",
        "typeName": "小学",
        "id": 248
    },
    {
        "type": 1,
        "name": "北京市朝阳区日坛小学东恒校区",
        "phone": 58624104,
        "address": "北京市朝阳区十里堡甲3号院13号",
        "typeName": "小学",
        "id": 249
    },
    {
        "type": 1,
        "name": "首都师范大学附属朝阳实验小学翠城南校区",
        "phone": 67386623,
        "address": "北京市朝阳区垡头翠成馨园E区414号楼",
        "typeName": "小学",
        "id": 250
    },
    {
        "type": 1,
        "name": "北京市朝阳区新升小学",
        "phone": 67330546,
        "address": "北京市朝阳区周庄嘉园东里甲28号",
        "typeName": "小学",
        "id": 251
    },
    {
        "type": 1,
        "name": "北京市朝阳区芳草地国际学校",
        "phone": 85613856,
        "address": "北京市朝阳区日坛北路1号",
        "typeName": "小学",
        "id": 252
    },
    {
        "type": 1,
        "name": "中国音乐学院附属北京实验学校安慧里校区低年级部",
        "phone": 1064913239,
        "address": "北京市朝阳区安慧里小区4区12号楼",
        "typeName": "小学",
        "id": 253
    },
    {
        "type": 1,
        "name": "中国音乐学院附属北京实验学校安慧里校区高年级部",
        "phone": 1064915842,
        "address": "北京市朝阳区安慧里小区一区10号楼",
        "typeName": "小学",
        "id": 254
    },
    {
        "type": 1,
        "name": "中国音乐学院附属北京实验学校北辰绿色家园分校",
        "phone": 1084997651,
        "address": "北京市朝阳区仰山路9号",
        "typeName": "小学",
        "id": 255
    },
    {
        "type": 1,
        "name": "北京市朝阳区芳草地国际学校双花园校区",
        "phone": 85613856,
        "address": "北京市朝阳区双花园南里二区15号院1号楼",
        "typeName": "小学",
        "id": 256
    },
    {
        "type": 1,
        "name": "中国音乐学院附属北京实验学校慧忠北里校区",
        "phone": 1064864377,
        "address": "北京市朝阳区慧忠北里409楼",
        "typeName": "小学",
        "id": 257
    },
    {
        "type": 1,
        "name": "北京市朝阳区芳草地国际学校万和城校区",
        "phone": 85613856,
        "address": "北京市朝阳区北四环东路71号",
        "typeName": "小学",
        "id": 258
    },
    {
        "type": 1,
        "name": "北京市朝阳区芳草地国际学校甘露园分校",
        "phone": 85786909,
        "address": "北京市朝阳区甘露园南里一区18号",
        "typeName": "小学",
        "id": 259
    },
    {
        "type": 1,
        "name": "首都师范大学附属朝阳实验小学",
        "phone": 67362695,
        "address": "北京市朝阳区垡头西里三区9号",
        "typeName": "小学",
        "id": 260
    },
    {
        "type": 1,
        "name": "首都师范大学附属朝阳实验小学翠北校区",
        "phone": "67368277-8051",
        "address": "北京市朝阳区垡头翠成馨园219号楼",
        "typeName": "小学",
        "id": 261
    },
    {
        "type": 1,
        "name": "北京市朝阳区垂杨柳中心小学（本部校区高部）",
        "phone": 87379715,
        "address": "北京市朝阳区松榆西里2、3号楼",
        "typeName": "小学",
        "id": 262
    },
    {
        "type": 1,
        "name": "北京市朝阳区垂杨柳中心小学（本部校区低部）",
        "phone": 87370915,
        "address": "北京市朝阳区松榆西里40号楼",
        "typeName": "小学",
        "id": 263
    },
    {
        "type": 1,
        "name": "北京市第八十中学枣营分校小学部",
        "phone": 65034393,
        "address": "北京市朝阳区枣营北里12号楼",
        "typeName": "小学",
        "id": 264
    },
    {
        "type": 1,
        "name": "北京第二外国语学院附属小学",
        "phone": 65709825,
        "address": "北京市朝阳区京通苑39号楼",
        "typeName": "小学",
        "id": 265
    },
    {
        "type": 1,
        "name": "北京市朝阳区垂杨柳中心小学金都分校高部",
        "phone": 67760742,
        "address": "北京市朝阳区百子湾西里112号",
        "typeName": "小学",
        "id": 266
    },
    {
        "type": 1,
        "name": "北京市朝阳区垂杨柳中心小学金都分校低部",
        "phone": 67717582,
        "address": "北京市朝阳区百子湾西里430号",
        "typeName": "小学",
        "id": 267
    },
    {
        "type": 1,
        "name": "北京市朝阳区于家围小学",
        "phone": 85366128,
        "address": "北京市朝阳区豆各庄乡石槽村西",
        "typeName": "小学",
        "id": 268
    },
    {
        "type": 1,
        "name": "北京市朝阳区实验小学新源里分校",
        "phone": 64673010,
        "address": "北京市朝阳区新源西里中街17号",
        "typeName": "小学",
        "id": 269
    },
    {
        "type": 1,
        "name": "北京市朝阳区垂杨柳中心小学（杨柳校区高部）",
        "phone": 67720128,
        "address": "朝阳区垂杨柳北里",
        "typeName": "小学",
        "id": 270
    },
    {
        "type": 1,
        "name": "北京市朝阳区垂杨柳中心小学（杨柳校区中部）",
        "phone": 67784949,
        "address": "北京市朝阳区劲松一区124楼",
        "typeName": "小学",
        "id": 271
    },
    {
        "type": 1,
        "name": "北京市朝阳区垂杨柳中心小学（杨柳校区低部）",
        "phone": 67783259,
        "address": "朝阳区垂杨柳南里3号楼南",
        "typeName": "小学",
        "id": 272
    },
    {
        "type": 1,
        "name": "北京市朝阳区南磨房中心小学（高部）",
        "phone": 87327628,
        "address": "朝阳区双龙南里226号楼",
        "typeName": "小学",
        "id": 273
    },
    {
        "type": 1,
        "name": "北京市朝阳区南磨房中心小学（低部）",
        "phone": 67317538,
        "address": "朝阳区松榆东里3号楼",
        "typeName": "小学",
        "id": 274
    },
    {
        "type": 1,
        "name": "北京市朝阳师范学校附属小学和平街分校",
        "phone": 64277790,
        "address": "北京市朝阳区和平街十二区和平街中心小学（本校区）",
        "typeName": "小学",
        "id": 275
    },
    {
        "type": 1,
        "name": "北京市朝阳区安民学校辛庄校区",
        "phone": 85813062,
        "address": "北京市朝阳区东风乡辛庄村安民学校",
        "typeName": "小学",
        "id": 276
    },
    {
        "type": 1,
        "name": "北京市朝阳区安民学校马泉营校区",
        "phone": 84705243,
        "address": "北京市朝阳区崔各庄乡马泉营村安民学校",
        "typeName": "小学",
        "id": 277
    },
    {
        "type": 1,
        "name": "北京市朝阳区安民学校东八间校区",
        "phone": 84314163,
        "address": "北京市朝阳区将台乡东八间房村安民学校",
        "typeName": "小学",
        "id": 278
    },
    {
        "type": 1,
        "name": "北京市朝阳区安民学校西坝校区",
        "phone": 57026037,
        "address": "北京市朝阳区东坝乡七棵树村安民学校",
        "typeName": "小学",
        "id": 279
    },
    {
        "type": 1,
        "name": "北京市朝阳区安民学校姚家园校区",
        "phone": 85576556,
        "address": "北京市朝阳区平房乡姚家园村安民学校",
        "typeName": "小学",
        "id": 280
    },
    {
        "type": 1,
        "name": "北京市朝阳区安民学校单店校区",
        "phone": 65496159,
        "address": "北京市朝阳区东坝乡单店村安民学校单店校区",
        "typeName": "小学",
        "id": 281
    },
    {
        "type": 1,
        "name": "北京市朝阳区花家地实验小学",
        "phone": 64737775,
        "address": "北京市朝阳区望京花家地小区18号楼",
        "typeName": "小学",
        "id": 282
    },
    {
        "type": 1,
        "name": "北京市朝阳区安民学校定福庄校区",
        "phone": 65723379,
        "address": "北京市朝阳区三间房乡定福庄北里安民学校",
        "typeName": "小学",
        "id": 283
    },
    {
        "type": 1,
        "name": "北京市朝阳区安民学校小武基校区",
        "phone": 87369572,
        "address": "北京市朝阳区小武基村小武基校区安民学校",
        "typeName": "小学",
        "id": 284
    },
    {
        "type": 1,
        "name": "北京市朝阳区第二实验小学管庄校区低部",
        "phone": 65710502,
        "address": "北京市朝阳区管庄建东苑校区19号",
        "typeName": "小学",
        "id": 285
    },
    {
        "type": 1,
        "name": "北京市朝阳区花家地实验小学方舟校区",
        "phone": 58233436,
        "address": "北京市朝阳区望京方舟苑小区12号楼",
        "typeName": "小学",
        "id": 286
    },
    {
        "type": 1,
        "name": "北京市朝阳区呼家楼中心小学本部",
        "phone": 65024073,
        "address": "朝阳区呼家楼西里七巷12号",
        "typeName": "小学",
        "id": 287
    },
    {
        "type": 1,
        "name": "北京市朝阳区花家地实验小学西里校区",
        "phone": 84718682,
        "address": "北京市朝阳区望京花家地西里113楼",
        "typeName": "小学",
        "id": 288
    },
    {
        "type": 1,
        "name": "北京市陈经纶中学分校",
        "phone": 64785583,
        "address": "北京市朝阳区利泽西园210号",
        "typeName": "小学",
        "id": 289
    },
    {
        "type": 1,
        "name": "北京市朝阳区第二实验小学双桥校区高部",
        "phone": 85367236,
        "address": "北京市朝阳区双桥路4号",
        "typeName": "小学",
        "id": 290
    },
    {
        "type": 1,
        "name": "北京市朝阳区花家地实验小学朝来校区",
        "phone": 84953717,
        "address": "北京市朝阳区来广营乡红军营东路蕴实园6号",
        "typeName": "小学",
        "id": 291
    },
    {
        "type": 1,
        "name": "北京市朝阳区呼家楼中心小学东校区",
        "phone": 65954923,
        "address": "金台路人民日报社2号院",
        "typeName": "小学",
        "id": 292
    },
    {
        "type": 1,
        "name": "北京市朝阳区第二实验小学双桥校区低部",
        "phone": 65427168,
        "address": "北京市朝阳区管庄乡小寺村西",
        "typeName": "小学",
        "id": 293
    },
    {
        "type": 1,
        "name": "北京市朝阳区芳草地国际学校民族校区",
        "phone": 85613856,
        "address": "北京市朝阳区雅宝里1号",
        "typeName": "小学",
        "id": 294
    },
    {
        "type": 1,
        "name": "北京市朝阳区酒仙桥第二小学",
        "phone": 64372893,
        "address": "北京市朝阳区酒仙桥红霞路9号",
        "typeName": "小学",
        "id": 295
    },
    {
        "type": 1,
        "name": "北京市朝阳区呼家楼中心小学西校区",
        "phone": 65003408,
        "address": "呼家楼西里甲5号",
        "typeName": "小学",
        "id": 296
    },
    {
        "type": 1,
        "name": "北京师范大学朝阳附属学校",
        "phone": 82723198,
        "address": "朝阳区倚林佳园26号楼",
        "typeName": "小学",
        "id": 297
    },
    {
        "type": 1,
        "name": "北京市朝阳区第二实验小学远洋一方校区",
        "phone": 56497613,
        "address": "北京市朝阳区双桥东路远洋新悦小区东侧",
        "typeName": "小学",
        "id": 298
    },
    {
        "type": 1,
        "name": "北京市朝阳区第二实验小学北辰福第校区",
        "phone": 1057923938,
        "address": "北京市朝阳区常营中路7号",
        "typeName": "小学",
        "id": 299
    },
    {
        "type": 1,
        "name": "北京市朝阳区高家园小学",
        "phone": 84565875,
        "address": "北京市朝阳区高家园小区112号",
        "typeName": "小学",
        "id": 300
    },
    {
        "type": 1,
        "name": "北京市朝阳师范学校附属小学太阳宫校区",
        "phone": "52018675-8000",
        "address": "北京市朝阳区太阳宫夏家园8号楼东侧",
        "typeName": "小学",
        "id": 301
    },
    {
        "type": 1,
        "name": "北京市朝阳区呼家楼中心小学南校区",
        "phone": 65022087,
        "address": "呼家楼新街大院内",
        "typeName": "小学",
        "id": 302
    },
    {
        "type": 1,
        "name": "北京市朝阳区半壁店小学",
        "phone": 87743386,
        "address": "北京市朝阳区广渠东路半壁店村半壁店小学",
        "typeName": "小学",
        "id": 303
    },
    {
        "type": 1,
        "name": "北京第二外国语学院附属小学定福分校",
        "phone": 65761188,
        "address": "北京市朝阳区三间房西村",
        "typeName": "小学",
        "id": 304
    },
    {
        "type": 1,
        "name": "北京市朝阳区定辛庄小学",
        "phone": 85370574,
        "address": "北京市朝阳区黑庄户乡定辛庄村",
        "typeName": "小学",
        "id": 305
    },
    {
        "type": 1,
        "name": "北京市朝阳区康乐园小学",
        "phone": 85762453,
        "address": "北京市朝阳区康家园小区14号楼",
        "typeName": "小学",
        "id": 306
    },
    {
        "type": 1,
        "name": "北京市朝阳区王四营中心小学",
        "phone": 51351007,
        "address": "北京市朝阳区王四营乡观音景园201楼",
        "typeName": "小学",
        "id": 307
    },
    {
        "type": 1,
        "name": "北京市朝阳区王四营中心小学官庄校区",
        "phone": 67292785,
        "address": "北京市朝阳区王四营乡大柳树村",
        "typeName": "小学",
        "id": 308
    },
    {
        "type": 1,
        "name": "北京市朝阳区兴隆小学",
        "phone": 85756182,
        "address": "北京市朝阳区兴隆家园31号",
        "typeName": "小学",
        "id": 309
    },
    {
        "type": 1,
        "name": "北京明远教育书院实验小学中园校区",
        "phone": 64706415,
        "address": "朝阳区望京南湖中园239楼",
        "typeName": "小学",
        "id": 310
    },
    {
        "type": 1,
        "name": "北京明远教育书院实验小学知语校区",
        "phone": 64300966,
        "address": "朝阳区望京南湖中园知语城309楼",
        "typeName": "小学",
        "id": 311
    },
    {
        "type": 1,
        "name": "北京明远教育书院实验小学望花路校区",
        "phone": 64733888,
        "address": "朝阳区望花路东7号楼",
        "typeName": "小学",
        "id": 312
    },
    {
        "type": 1,
        "name": "北京明远教育书院实验小学东园校区",
        "phone": "010-64704708",
        "address": "北京市朝阳区望京南湖东园109楼",
        "typeName": "小学",
        "id": 313
    },
    {
        "type": 1,
        "name": "北京明远教育书院实验小学青年城校区",
        "phone": 84954606,
        "address": "北京市朝阳区来广营乡红军营东路8号院青年城小区西门",
        "typeName": "小学",
        "id": 314
    },
    {
        "type": 1,
        "name": "北京市朝阳区石佛营小学",
        "phone": 1085826203,
        "address": "北京市朝阳区石佛营东里125号",
        "typeName": "小学",
        "id": 315
    },
    {
        "type": 1,
        "name": "北京市朝阳区团结湖小学丽水嘉园校区",
        "phone": 65918907,
        "address": "北京市朝阳区六里屯13号",
        "typeName": "小学",
        "id": 316
    },
    {
        "type": 1,
        "name": "北京市朝阳区牌坊小学南校区",
        "phone": 67609857,
        "address": "肖村后街15号",
        "typeName": "小学",
        "id": 317
    },
    {
        "type": 1,
        "name": "北京市朝阳区牌坊小学",
        "phone": 87612739,
        "address": "朝阳区少角中街1号院",
        "typeName": "小学",
        "id": 318
    },
    {
        "type": 1,
        "name": "北京市十八里店小学南校",
        "phone": 87303606,
        "address": "北京市朝阳区十八里店乡横街子910号张家店小学",
        "typeName": "小学",
        "id": 319
    },
    {
        "type": 1,
        "name": "北京第二实验小学朝阳学校",
        "phone": "010-87152715",
        "address": "北京市朝阳区茂兴东路11号",
        "typeName": "小学",
        "id": 320
    },
    {
        "type": 1,
        "name": "北京市朝阳区三里屯小学",
        "phone": "010-64131482",
        "address": "北京市朝阳区三里屯东街4号",
        "typeName": "小学",
        "id": 321
    },
    {
        "type": 1,
        "name": "北京市十一学校一分校",
        "phone": 68156809,
        "address": "北京市海淀区万寿路街道翠微路19号",
        "typeName": "小学",
        "id": 322
    },
    {
        "type": 1,
        "name": "北京市第二十中学附属实验学校",
        "phone": 82982704,
        "address": "北京市海淀区西三旗街道永泰庄路",
        "typeName": "小学",
        "id": 323
    },
    {
        "type": 1,
        "name": "首都师范大学附属玉泉学校",
        "phone": 88851095,
        "address": "北京市海淀区闵庄南路",
        "typeName": "小学",
        "id": 324
    },
    {
        "type": 1,
        "name": "北京市海淀北部新区实验学校",
        "phone": 62407841,
        "address": "北京市海淀区苏家坨镇西小营村",
        "typeName": "小学",
        "id": 325
    },
    {
        "type": 1,
        "name": "北京市育鸿学校",
        "phone": 66984717,
        "address": "北京市海淀区复兴路14号",
        "typeName": "小学",
        "id": 326
    },
    {
        "type": 1,
        "name": "北京市健翔学校人大校区",
        "phone": 62561849,
        "address": "海淀区人大南路6号",
        "typeName": "小学",
        "id": 327
    },
    {
        "type": 1,
        "name": "北京航空航天大学实验学校小学部",
        "phone": 82317160,
        "address": "北京市海淀区学院路37号",
        "typeName": "小学",
        "id": 328
    },
    {
        "type": 1,
        "name": "北京市育鸿学校（小学部）",
        "phone": 66984493,
        "address": "北京市海淀区复兴路14号南院",
        "typeName": "小学",
        "id": 329
    },
    {
        "type": 1,
        "name": "首都师范大学附属玉泉学校（小学部）",
        "phone": 88851095,
        "address": "北京市海淀区闵庄南路17号",
        "typeName": "小学",
        "id": 330
    },
    {
        "type": 1,
        "name": "北京航空航天大学实验学校",
        "phone": 82328079,
        "address": "北京市海淀区学院路37号",
        "typeName": "小学",
        "id": 331
    },
    {
        "type": 1,
        "name": "北京市八一学校（中学部）",
        "phone": 58839132,
        "address": "北京市海淀区苏州街29号",
        "typeName": "小学",
        "id": 332
    },
    {
        "type": 1,
        "name": "首都师范大学附属育新学校",
        "phone": 82951952,
        "address": "北京市昌平区黄平路新康园小区4号",
        "typeName": "小学",
        "id": 333
    },
    {
        "type": 1,
        "name": "清华大学附属中学永丰学校",
        "phone": 56922311,
        "address": "北京市海淀区北清路永丰嘉园西侧百旺杏林湾西北角",
        "typeName": "小学",
        "id": 334
    },
    {
        "type": 1,
        "name": "北京理工大学附属中学",
        "phone": 53205787,
        "address": "北京市海淀区车道沟东路1号",
        "typeName": "小学",
        "id": 335
    },
    {
        "type": 1,
        "name": "北京市育英学校",
        "phone": 68272370,
        "address": "北京市海淀区万寿路西街11号",
        "typeName": "小学",
        "id": 336
    },
    {
        "type": 1,
        "name": "北京实验学校（海淀）",
        "phone": "68363366-610",
        "address": "北京市海淀区阜成路甲3号",
        "typeName": "小学",
        "id": 337
    },
    {
        "type": 1,
        "name": "北京中法实验学校（北京市温泉第二中学）",
        "phone": 62482574,
        "address": "北京市海淀区温泉镇环山村2号院",
        "typeName": "小学",
        "id": 338
    },
    {
        "type": 1,
        "name": "北京实验学校（海淀）（小学部）",
        "phone": 68314535,
        "address": "北京市海淀区甘家口阜成门北一街甲185号",
        "typeName": "小学",
        "id": 339
    },
    {
        "type": 1,
        "name": "北京理工大学附属中学（小学部）",
        "phone": 53275640,
        "address": "北京市海淀区车道沟路1号",
        "typeName": "小学",
        "id": 340
    },
    {
        "type": 1,
        "name": "北京市育英学校（小学部）",
        "phone": 68282370,
        "address": "海淀区万寿路西街11号",
        "typeName": "小学",
        "id": 341
    },
    {
        "type": 1,
        "name": "北京市八一学校（小学部）",
        "phone": 58839008,
        "address": "北京市海淀区彩和坊19号",
        "typeName": "小学",
        "id": 342
    },
    {
        "type": 1,
        "name": "清华大学附属中学永丰学校（小学部）",
        "phone": 62473648,
        "address": "北京市海淀区永丰乡辛店村东（北清路与友谊路交汇处西北侧）",
        "typeName": "小学",
        "id": 343
    },
    {
        "type": 1,
        "name": "北京市海淀工读学校",
        "phone": 62400306,
        "address": "北京市海淀区温泉170号",
        "typeName": "小学",
        "id": 344
    },
    {
        "type": 1,
        "name": "北京市海淀区五一小学本校区",
        "phone": 88223366,
        "address": "北京市海淀区永定路127号",
        "typeName": "小学",
        "id": 345
    },
    {
        "type": 1,
        "name": "北京市海淀区五一小学北校区",
        "phone": 88223416,
        "address": "北京市海淀区永定路123号",
        "typeName": "小学",
        "id": 346
    },
    {
        "type": 1,
        "name": "北京市海淀区五一小学晋分校区",
        "phone": 58973944,
        "address": "北京市海淀区八角东街12号建西苑中里",
        "typeName": "小学",
        "id": 347
    },
    {
        "type": 1,
        "name": "首都师范大学附属小学主校区",
        "phone": 88438535,
        "address": "北京市海淀区通汇路9号",
        "typeName": "小学",
        "id": 348
    },
    {
        "type": 1,
        "name": "首都师范大学附属小学柳明校区",
        "phone": 88462596,
        "address": "北京市海淀区田村柳明家园",
        "typeName": "小学",
        "id": 349
    },
    {
        "type": 1,
        "name": "北京市海淀区图强第二小学",
        "phone": 68278524,
        "address": "北京市海淀区正大南路4号",
        "typeName": "小学",
        "id": 350
    },
    {
        "type": 1,
        "name": "北京市海淀区四王府小学",
        "phone": 62591333,
        "address": "北京市海淀区香山正白旗甲9号",
        "typeName": "小学",
        "id": 351
    },
    {
        "type": 1,
        "name": "北京市海淀区七一小学",
        "phone": 63935811,
        "address": "北京市海淀区莲花池西路1号",
        "typeName": "小学",
        "id": 352
    },
    {
        "type": 1,
        "name": "北京市海淀区羊坊店第四小学",
        "phone": 63984084,
        "address": "北京市海淀区羊坊店路114号",
        "typeName": "小学",
        "id": 353
    },
    {
        "type": 1,
        "name": "北京市海淀区香山小学",
        "phone": 82592671,
        "address": "北京市海淀区香山北正黄旗36号",
        "typeName": "小学",
        "id": 354
    },
    {
        "type": 1,
        "name": "北京市海淀区永泰小学",
        "phone": 62906074,
        "address": "北京市海淀区清河镇永泰小区东里49号",
        "typeName": "小学",
        "id": 355
    },
    {
        "type": 1,
        "name": "北京市海淀区永泰小学清缘里校区",
        "phone": 62903812,
        "address": "北京市海淀区清河龙岗路12号情缘里中区11号楼",
        "typeName": "小学",
        "id": 356
    },
    {
        "type": 1,
        "name": "北京市海淀区翠微小学本校区",
        "phone": 68177879,
        "address": "北京市海淀区翠微路22号",
        "typeName": "小学",
        "id": 357
    },
    {
        "type": 1,
        "name": "北京市海淀区翠微小学东校区",
        "phone": 68510268,
        "address": "北京市海淀区柳林馆南路18号",
        "typeName": "小学",
        "id": 358
    },
    {
        "type": 1,
        "name": "北京市海淀区翠微小学西校区",
        "phone": 68255011,
        "address": "北京市海淀区翠微中里22号",
        "typeName": "小学",
        "id": 359
    },
    {
        "type": 1,
        "name": "北京市海淀区翠微小学北校区",
        "phone": 60605701,
        "address": "北京市海淀区北部新区高科技园区翠微小学北校区",
        "typeName": "小学",
        "id": 360
    },
    {
        "type": 1,
        "name": "北京市海淀区翠微小学白家疃校区",
        "phone": 62457228,
        "address": "北京市海淀区温泉镇白家疃村667号",
        "typeName": "小学",
        "id": 361
    },
    {
        "type": 1,
        "name": "北京市海淀区中关村第二小学（中关村校区）",
        "phone": 52960800,
        "address": "北京市海淀区中关村北一条10号",
        "typeName": "小学",
        "id": 362
    },
    {
        "type": 1,
        "name": "北京市海淀区中关村第二小学（华清校区）",
        "phone": 52960850,
        "address": "北京市海淀区中关村东路16号",
        "typeName": "小学",
        "id": 363
    },
    {
        "type": 1,
        "name": "北京市海淀区中关村第二小学（百旺校区）",
        "phone": 52960900,
        "address": "北京市海淀区后厂村路66号",
        "typeName": "小学",
        "id": 364
    },
    {
        "type": 1,
        "name": "北京市海淀区双榆树第一小学",
        "phone": 82129851,
        "address": "北京市海淀区双榆树东里32号",
        "typeName": "小学",
        "id": 365
    },
    {
        "type": 1,
        "name": "北京市海淀区西颐小学",
        "phone": 62652616,
        "address": "海淀区北三环西路49号",
        "typeName": "小学",
        "id": 366
    },
    {
        "type": 1,
        "name": "中国农业大学附属小学",
        "phone": 62895367,
        "address": "北京市海淀区圆明园西路3号院内",
        "typeName": "小学",
        "id": 367
    },
    {
        "type": 1,
        "name": "北京市海淀区台头小学",
        "phone": 62459612,
        "address": "北京市海淀区聂各庄路13号",
        "typeName": "小学",
        "id": 368
    },
    {
        "type": 1,
        "name": "北京市海淀区台头小学红星校区",
        "phone": 62455948,
        "address": "北京市海淀区北安河路36号",
        "typeName": "小学",
        "id": 369
    },
    {
        "type": 1,
        "name": "北京市海淀区科技园小学西玉河校区",
        "phone": 62473004,
        "address": "北京市海淀区西北旺镇西玉河村",
        "typeName": "小学",
        "id": 370
    },
    {
        "type": 1,
        "name": "北京市海淀区科技园小学大牛坊校区",
        "phone": 62473486,
        "address": "北京市海淀区西北旺镇六里屯村",
        "typeName": "小学",
        "id": 371
    },
    {
        "type": 1,
        "name": "北京教育学院附属海淀实验小学",
        "phone": 68163066,
        "address": "北京市海淀区田村后街",
        "typeName": "小学",
        "id": 372
    },
    {
        "type": 1,
        "name": "北京市海淀区永丰中心小学",
        "phone": 62452742,
        "address": "北京市海淀区西北旺镇六里屯村",
        "typeName": "小学",
        "id": 373
    },
    {
        "type": 1,
        "name": "北京市海淀区永丰中心小学屯佃校区",
        "phone": 62475218,
        "address": "北京市海淀区西北旺镇屯佃村",
        "typeName": "小学",
        "id": 374
    },
    {
        "type": 1,
        "name": "北京市海淀区永丰中心小学宏丰校区",
        "phone": 62456623,
        "address": "北京市海淀区西北旺镇亮甲店村",
        "typeName": "小学",
        "id": 375
    },
    {
        "type": 1,
        "name": "北京市海淀区东北旺中心小学",
        "phone": 62899048,
        "address": "北京市海淀区马连洼竹园西街8号",
        "typeName": "小学",
        "id": 376
    },
    {
        "type": 1,
        "name": "北京市海淀区翠湖小学白水洼校区",
        "phone": 62471264,
        "address": "海淀区上庄镇白水洼村",
        "typeName": "小学",
        "id": 377
    },
    {
        "type": 1,
        "name": "北京市海淀区翠湖小学前章村校区",
        "phone": 62471464,
        "address": "海淀区上庄镇前章村",
        "typeName": "小学",
        "id": 378
    },
    {
        "type": 1,
        "name": "北京市海淀区教师进修学校附属实验小学西山校区",
        "phone": "010-62590462",
        "address": "北京市海淀区四季青镇黑塔村",
        "typeName": "小学",
        "id": 379
    },
    {
        "type": 1,
        "name": "北京市海淀区教师进修学校附属实验小学北辛庄校区",
        "phone": "010-62591268",
        "address": "北京市海淀区四季青镇北辛庄村",
        "typeName": "小学",
        "id": 380
    },
    {
        "type": 1,
        "name": "北京市海淀区教师进修学校附属实验小学西冉校区",
        "phone": "010-88852031",
        "address": "北京市海淀区四季青镇西冉村",
        "typeName": "小学",
        "id": 381
    },
    {
        "type": 1,
        "name": "北京市海淀区上庄中心小学（上庄校区）",
        "phone": "82472702-809",
        "address": "北京市海淀区上庄镇上庄村1号",
        "typeName": "小学",
        "id": 382
    },
    {
        "type": 1,
        "name": "北京市海淀区上庄中心小学（东马坊校区）",
        "phone": 62471422,
        "address": "北京市海淀区上庄镇东马坊20号",
        "typeName": "小学",
        "id": 383
    },
    {
        "type": 1,
        "name": "北京市海淀区西二旗小学",
        "phone": 62940447,
        "address": "北京市海淀区智学苑小区内西二旗小学",
        "typeName": "小学",
        "id": 384
    },
    {
        "type": 1,
        "name": "北京市海淀区太平路小学本校",
        "phone": 88625318,
        "address": "北京市海淀区采石路9号",
        "typeName": "小学",
        "id": 385
    },
    {
        "type": 1,
        "name": "北京市海淀区太平路小学分校",
        "phone": 88625318,
        "address": "海淀区复兴路46号院",
        "typeName": "小学",
        "id": 386
    },
    {
        "type": 1,
        "name": "北京市海淀区北安河中心小学（本校区）",
        "phone": 62456423,
        "address": "北京市海淀区苏家坨镇北安河村环谷园路2号",
        "typeName": "小学",
        "id": 387
    },
    {
        "type": 1,
        "name": "北京市海淀区北安河中心小学（周家巷校区）",
        "phone": 62455244,
        "address": "北京市海淀区苏家坨镇周家巷村军庄路1号",
        "typeName": "小学",
        "id": 388
    },
    {
        "type": 1,
        "name": "北京市海淀区清河第五小学",
        "phone": 62989485,
        "address": "北京市海淀区北京体育大学家属区内",
        "typeName": "小学",
        "id": 389
    },
    {
        "type": 1,
        "name": "北京市海淀区中关村第三小学万柳北校区",
        "phone": 59812198,
        "address": "北京市海淀区万柳中路9号",
        "typeName": "小学",
        "id": 390
    },
    {
        "type": 1,
        "name": "北京市海淀区中关村第三小学万柳南校区",
        "phone": 59812053,
        "address": "北京市海淀区万柳中路23号",
        "typeName": "小学",
        "id": 391
    },
    {
        "type": 1,
        "name": "北京市海淀区中关村第三小学红山校区",
        "phone": 62563118,
        "address": "北京市海淀区红山口甲3号国防大学院内中区507",
        "typeName": "小学",
        "id": 392
    },
    {
        "type": 1,
        "name": "北京市海淀区苏家坨中心小学西小营校区",
        "phone": 62454876,
        "address": "北京市海淀区苏家坨镇西小营村南环路2号",
        "typeName": "小学",
        "id": 393
    },
    {
        "type": 1,
        "name": "北京市海淀区苏家坨中心小学苏三四校区",
        "phone": 62454965,
        "address": "北京市海淀区苏家坨镇苏三四村南区146号",
        "typeName": "小学",
        "id": 394
    },
    {
        "type": 1,
        "name": "北京外国语大学附属小学万寿寺校区",
        "phone": 68419327,
        "address": "北京市海淀区万寿寺北里16号",
        "typeName": "小学",
        "id": 395
    },
    {
        "type": 1,
        "name": "北京外国语大学附属小学魏公村校区",
        "phone": 88572301,
        "address": "北京市海淀区魏公村小区23号楼",
        "typeName": "小学",
        "id": 396
    },
    {
        "type": 1,
        "name": "北京市海淀区花园村第二小学花园村校区",
        "phone": 68411302,
        "address": "北京市海淀区花园村4号",
        "typeName": "小学",
        "id": 397
    },
    {
        "type": 1,
        "name": "北京市海淀区花园村第二小学潘庄校区",
        "phone": 68460456,
        "address": "北京市海淀区潘庄4号",
        "typeName": "小学",
        "id": 398
    },
    {
        "type": 1,
        "name": "北京市海淀区万泉河小学",
        "phone": 82612639,
        "address": "北京市海淀区芙蓉里小区十号楼东侧",
        "typeName": "小学",
        "id": 399
    },
    {
        "type": 1,
        "name": "北京市海淀区前进小学",
        "phone": 62010936,
        "address": "北京市海淀区建安西路32号",
        "typeName": "小学",
        "id": 400
    },
    {
        "type": 1,
        "name": "北京市海淀区定慧里小学",
        "phone": 88125721,
        "address": "北京市海淀区定慧西里18号",
        "typeName": "小学",
        "id": 401
    },
    {
        "type": 1,
        "name": "北京市海淀区和平小学东埠头校区",
        "phone": 62458612,
        "address": "北京市海淀区温泉镇辛庄村",
        "typeName": "小学",
        "id": 402
    },
    {
        "type": 1,
        "name": "北京市海淀区和平小学辛庄校区",
        "phone": 62468612,
        "address": "北京市海淀区温泉镇辛庄村",
        "typeName": "小学",
        "id": 403
    },
    {
        "type": 1,
        "name": "北京市海淀区实验小学（花园村校区）",
        "phone": 68415029,
        "address": "北京市海淀区西三环北路107号",
        "typeName": "小学",
        "id": 404
    },
    {
        "type": 1,
        "name": "北京市海淀区实验小学（阜成路校区）",
        "phone": 68510860,
        "address": "北京市海淀区阜成路10号",
        "typeName": "小学",
        "id": 405
    },
    {
        "type": 1,
        "name": "北京市海淀区实验小学（苏州街校区）",
        "phone": 68472782,
        "address": "北京市海淀区厂洼街4号",
        "typeName": "小学",
        "id": 406
    },
    {
        "type": 1,
        "name": "北京市海淀区实验小学（北洼路校区）",
        "phone": 68419628,
        "address": "北京市海淀区车道沟南里36号",
        "typeName": "小学",
        "id": 407
    },
    {
        "type": 1,
        "name": "中国人民大学附属中学实验小学",
        "phone": 62550591,
        "address": "北京市海淀区知春里小区甲22号",
        "typeName": "小学",
        "id": 408
    },
    {
        "type": 1,
        "name": "北方交通大学附属小学南校区",
        "phone": "010-62255246",
        "address": "北京市海淀区北下关85号",
        "typeName": "小学",
        "id": 409
    },
    {
        "type": 1,
        "name": "北方交通大学附属小学北校区",
        "phone": "010-62242304",
        "address": "北京市海淀区上园村3号院",
        "typeName": "小学",
        "id": 410
    },
    {
        "type": 1,
        "name": "中关村一小中关村校区",
        "phone": 62564837,
        "address": "北京市海淀区中关村南二街5号",
        "typeName": "小学",
        "id": 411
    },
    {
        "type": 1,
        "name": "中关村一小党校校区",
        "phone": 62820129,
        "address": "北京市海淀区大有北里149号",
        "typeName": "小学",
        "id": 412
    },
    {
        "type": 1,
        "name": "中关村一小天秀校区",
        "phone": 62829925,
        "address": "北京市海淀区肖家河天秀小区4号",
        "typeName": "小学",
        "id": 413
    },
    {
        "type": 1,
        "name": "北京大学附属小学",
        "phone": 62760982,
        "address": "北京大学燕东园内北京大学附属小学",
        "typeName": "小学",
        "id": 414
    },
    {
        "type": 1,
        "name": "北京市海淀区羊坊店中心小学本校区",
        "phone": "63984382-8000",
        "address": "北京市海淀区北蜂窝66号",
        "typeName": "小学",
        "id": 415
    },
    {
        "type": 1,
        "name": "北京市海淀区羊坊店中心小学分校区",
        "phone": "63984382-8001",
        "address": "北京市海淀区北蜂窝62号",
        "typeName": "小学",
        "id": 416
    },
    {
        "type": 1,
        "name": "北京市海淀区第四实验小学",
        "phone": 59811692,
        "address": "北京市海淀区香山南路南河滩",
        "typeName": "小学",
        "id": 417
    },
    {
        "type": 1,
        "name": "清华大学附属小学",
        "phone": 62784656,
        "address": "北京市海淀区清华大学院内",
        "typeName": "小学",
        "id": 418
    },
    {
        "type": 1,
        "name": "中国人民大学附属小学亮甲店分校（东校区）",
        "phone": "88124028-8001",
        "address": "北京市海淀区八里庄街道亮甲店1号",
        "typeName": "小学",
        "id": 419
    },
    {
        "type": 1,
        "name": "中国人民大学附属小学亮甲店分校（西校区）",
        "phone": "88504063-801",
        "address": "北京市海淀区西四环北路137号院",
        "typeName": "小学",
        "id": 420
    },
    {
        "type": 1,
        "name": "北京市海淀区六一小学",
        "phone": 88123803,
        "address": "北京市海淀区恩济里小区",
        "typeName": "小学",
        "id": 421
    },
    {
        "type": 1,
        "name": "北京市海淀区玉泉小学",
        "phone": "010-68273296",
        "address": "北京市海淀区金沟河路35号",
        "typeName": "小学",
        "id": 422
    },
    {
        "type": 1,
        "name": "北京市海淀区玉泉小学三里校区",
        "phone": "010-68273296",
        "address": "北京市海淀区金沟河路35号",
        "typeName": "小学",
        "id": 423
    },
    {
        "type": 1,
        "name": "北京市海淀区玉泉小学五里校区",
        "phone": "010-88265193",
        "address": "北京市海淀区玉海园五里23号",
        "typeName": "小学",
        "id": 424
    },
    {
        "type": 1,
        "name": "北京市海淀区玉泉小学首师附一分校校区",
        "phone": "010-68186343",
        "address": "阜石路甲59号首师大附中一分校",
        "typeName": "小学",
        "id": 425
    },
    {
        "type": 1,
        "name": "北京市海淀区玉泉小学中科院校区",
        "phone": "无",
        "address": "玉泉路19号-乙",
        "typeName": "小学",
        "id": 426
    },
    {
        "type": 1,
        "name": "北京市海淀区羊坊店第五小学",
        "phone": 63984248,
        "address": "北京市海淀区北蜂窝路9号",
        "typeName": "小学",
        "id": 427
    },
    {
        "type": 1,
        "name": "北京市海淀区星火小学",
        "phone": 62264804,
        "address": "北京市海淀区新外大街志强园",
        "typeName": "小学",
        "id": 428
    },
    {
        "type": 1,
        "name": "中国农业科学院附属小学农科院校区",
        "phone": "010-62133225-8050",
        "address": "北京市海淀区中关村南大街12号",
        "typeName": "小学",
        "id": 429
    },
    {
        "type": 1,
        "name": "中国农业科学院附属小学学院南路校区",
        "phone": "010-62133225-8000",
        "address": "北京市海淀区皂君庙甲38号",
        "typeName": "小学",
        "id": 430
    },
    {
        "type": 1,
        "name": "中国农业科学院附属小学气象路校区",
        "phone": "010-62133225-8055",
        "address": "北京市海淀区西外太平庄13号",
        "typeName": "小学",
        "id": 431
    },
    {
        "type": 1,
        "name": "中国农业科学院附属小学政法路校区",
        "phone": "010-62133225-8081",
        "address": "北京市海淀区明光村23号",
        "typeName": "小学",
        "id": 432
    },
    {
        "type": 1,
        "name": "中国农业科学院附属小学明光路校区",
        "phone": "无",
        "address": "北京市海淀区明光村23号",
        "typeName": "小学",
        "id": 433
    },
    {
        "type": 1,
        "name": "北京市海淀区培英小学",
        "phone": 68237141,
        "address": "北京市海淀区太平路19号",
        "typeName": "小学",
        "id": 434
    },
    {
        "type": 1,
        "name": "首都师范大学实验小学北校区",
        "phone": 68356551,
        "address": "北京市海淀区二里沟中街4号",
        "typeName": "小学",
        "id": 435
    },
    {
        "type": 1,
        "name": "首都师范大学实验小学南校区",
        "phone": 68356551,
        "address": "北京市海淀区景王坟甲3号",
        "typeName": "小学",
        "id": 436
    },
    {
        "type": 1,
        "name": "八里庄小学",
        "phone": 68415045,
        "address": "北京市海淀区八里庄街7号",
        "typeName": "小学",
        "id": 437
    },
    {
        "type": 1,
        "name": "中国人民大学附属小学银燕分校",
        "phone": 88862761,
        "address": "北京市海淀区蓝靛厂火器营厢红旗48号",
        "typeName": "小学",
        "id": 438
    },
    {
        "type": 1,
        "name": "北京理工大学附属小学",
        "phone": 68918178,
        "address": "北京市海淀区南大街5号",
        "typeName": "小学",
        "id": 439
    },
    {
        "type": 1,
        "name": "北京市海淀区今典小学本校区",
        "phone": 62232316,
        "address": "海淀区红联北村83号",
        "typeName": "小学",
        "id": 440
    },
    {
        "type": 1,
        "name": "北京市海淀区今典小学文慧园校区",
        "phone": 62250371,
        "address": "海淀区索家坟小区42号",
        "typeName": "小学",
        "id": 441
    },
    {
        "type": 1,
        "name": "北京市海淀区今典小学志强园校区",
        "phone": 62232316,
        "address": "海淀区德胜门外甲5号太平湖西区",
        "typeName": "小学",
        "id": 442
    },
    {
        "type": 1,
        "name": "北京市海淀区今典小学明光村校区",
        "phone": 62232316,
        "address": "海淀区西土城路39号",
        "typeName": "小学",
        "id": 443
    },
    {
        "type": 1,
        "name": "北京市海淀区学院路小学",
        "phone": 62356667,
        "address": "北京市海淀区蓟门里小区",
        "typeName": "小学",
        "id": 444
    },
    {
        "type": 1,
        "name": "北京师范大学实验小学",
        "phone": 58802073,
        "address": "北京市海淀区新街口外大街19号",
        "typeName": "小学",
        "id": 445
    },
    {
        "type": 1,
        "name": "北京市海淀区双榆树中心小学",
        "phone": 62634457,
        "address": "北京市海淀区双榆树西里36号",
        "typeName": "小学",
        "id": 446
    },
    {
        "type": 1,
        "name": "北京石油学院附属小学本校区",
        "phone": 82381619,
        "address": "海淀区学院路20号院",
        "typeName": "小学",
        "id": 447
    },
    {
        "type": 1,
        "name": "北京石油学院附属小学东校区",
        "phone": 63211485,
        "address": "学院路二里庄小区24号",
        "typeName": "小学",
        "id": 448
    },
    {
        "type": 1,
        "name": "北京石油学院附属小学南校区",
        "phone": 62345990,
        "address": "学院路志新村小区25号",
        "typeName": "小学",
        "id": 449
    },
    {
        "type": 1,
        "name": "中国农业大学附属实验小学",
        "phone": 82375058,
        "address": "北京市海淀区学清路32号",
        "typeName": "小学",
        "id": 450
    },
    {
        "type": 1,
        "name": "北京市海淀区东升实验小学",
        "phone": 82170565,
        "address": "北京市海淀区月泉路八家嘉苑西",
        "typeName": "小学",
        "id": 451
    },
    {
        "type": 1,
        "name": "北京市海淀区第三实验小学南校区",
        "phone": 82320566,
        "address": "北京市海淀区成府路20号（地质大学院内）",
        "typeName": "小学",
        "id": 452
    },
    {
        "type": 1,
        "name": "北京市海淀区第三实验小学北校区",
        "phone": 62311383,
        "address": "北京市海淀区王庄路42号（地铁13号线五道口站东）",
        "typeName": "小学",
        "id": 453
    },
    {
        "type": 1,
        "name": "北京市海淀区清华东路小学",
        "phone": 62311604,
        "address": "北京市海淀区清华东路4号",
        "typeName": "小学",
        "id": 454
    },
    {
        "type": 1,
        "name": "北京林业大学附属小学",
        "phone": 62338974,
        "address": "北京市海淀区清华东路35号",
        "typeName": "小学",
        "id": 455
    },
    {
        "type": 1,
        "name": "北京科技大学附属小学",
        "phone": 62332661,
        "address": "北京市海淀区学院路30号",
        "typeName": "小学",
        "id": 456
    },
    {
        "type": 1,
        "name": "北京市海淀区中关村第四小学",
        "phone": "82116260-0515",
        "address": "北京市海淀区太阳园小区14号",
        "typeName": "小学",
        "id": 457
    },
    {
        "type": 1,
        "name": "北京市海淀区万泉小学",
        "phone": "62545652-8016",
        "address": "北京市海淀区万泉庄甲一号",
        "typeName": "小学",
        "id": 458
    },
    {
        "type": 1,
        "name": "北京市海淀区万泉小学曙光校区",
        "phone": "62545652-606",
        "address": "北京市海淀区曙光花园望河园13号",
        "typeName": "小学",
        "id": 459
    },
    {
        "type": 1,
        "name": "北京市海淀区六郎庄小学",
        "phone": 62649402,
        "address": "北京市海淀区六郎庄村慈佑街1号",
        "typeName": "小学",
        "id": 460
    },
    {
        "type": 1,
        "name": "中国人民大学附属小学",
        "phone": 88863978,
        "address": "北京市海淀区蓝靛厂路18号",
        "typeName": "小学",
        "id": 461
    },
    {
        "type": 1,
        "name": "北京市海淀区西苑小学",
        "phone": 62881433,
        "address": "北京市海淀区西苑操场甲1号",
        "typeName": "小学",
        "id": 462
    },
    {
        "type": 1,
        "name": "北京市海淀区培星小学南校区",
        "phone": 62881646,
        "address": "北京市海淀区厢红旗19号",
        "typeName": "小学",
        "id": 463
    },
    {
        "type": 1,
        "name": "北京市海淀区培星小学北校区",
        "phone": 62881646,
        "address": "北京市海淀区厢红旗董四墓村36号",
        "typeName": "小学",
        "id": 464
    },
    {
        "type": 1,
        "name": "北京市海淀区第二实验小学橡树校区",
        "phone": 62942192,
        "address": "清河镇西海淀区第二实验小学",
        "typeName": "小学",
        "id": 465
    },
    {
        "type": 1,
        "name": "北京市海淀区第二实验小学清宁校区",
        "phone": 62912689,
        "address": "海淀区安宁庄东路28号院",
        "typeName": "小学",
        "id": 466
    },
    {
        "type": 1,
        "name": "北京市海淀区第二实验小学当代校区",
        "phone": 82742551,
        "address": "海淀区安宁庄西路9号院",
        "typeName": "小学",
        "id": 467
    },
    {
        "type": 1,
        "name": "北京市海淀区第二实验小学汇缘校区",
        "phone": 62913533,
        "address": "海淀区清河朱房村",
        "typeName": "小学",
        "id": 468
    },
    {
        "type": 1,
        "name": "北京市海淀区育鹰小学-西校区",
        "phone": 82710040,
        "address": "北京市海淀区安宁庄路5号院-5号楼",
        "typeName": "小学",
        "id": 469
    },
    {
        "type": 1,
        "name": "北京市海淀区育鹰小学-东校区",
        "phone": 82900272,
        "address": "北京市海淀区西三旗花园二里",
        "typeName": "小学",
        "id": 470
    },
    {
        "type": 1,
        "name": "北京市海淀区民族小学南校区",
        "phone": "010-62019594",
        "address": "海淀区德胜门外后黑寺1号",
        "typeName": "小学",
        "id": 471
    },
    {
        "type": 1,
        "name": "北京市海淀区民族小学北校区",
        "phone": "010-62360329",
        "address": "海淀区花园北路26号",
        "typeName": "小学",
        "id": 472
    },
    {
        "type": 1,
        "name": "北京医科大学附属小学花园路校区",
        "phone": 62005621,
        "address": "北京市海淀区花园路甲三号",
        "typeName": "小学",
        "id": 473
    },
    {
        "type": 1,
        "name": "北京医科大学附属小学牡丹园校区",
        "phone": 62362856,
        "address": "北京市海淀区牡丹园东里4号楼对面",
        "typeName": "小学",
        "id": 474
    },
    {
        "type": 1,
        "name": "北京市海淀区九一小学（西校区）",
        "phone": 62017376,
        "address": "北京市海淀区塔院小区朗秋园甲8号",
        "typeName": "小学",
        "id": 475
    },
    {
        "type": 1,
        "name": "北京市海淀区九一小学（东校区）",
        "phone": 62017376,
        "address": "北京市海淀区花园路1号",
        "typeName": "小学",
        "id": 476
    },
    {
        "type": 1,
        "name": "北京市海淀区清河第四小学",
        "phone": "010-82951502",
        "address": "北京市海淀区西三旗建材城翡丽铂庭二区6号楼",
        "typeName": "小学",
        "id": 477
    },
    {
        "type": 1,
        "name": "北京市海淀区枫丹实验小学",
        "phone": 82935198,
        "address": "北京市海淀区西三旗建材城中里1号",
        "typeName": "小学",
        "id": 478
    },
    {
        "type": 1,
        "name": "北大附小肖家河分校",
        "phone": 62977317,
        "address": "北京市海淀区肖家河王庄1号",
        "typeName": "小学",
        "id": 479
    },
    {
        "type": 1,
        "name": "北大附小肖家河分校-树村部",
        "phone": 62977317,
        "address": "上地街道树村路",
        "typeName": "小学",
        "id": 480
    },
    {
        "type": 1,
        "name": "北京市海淀区上地实验小学",
        "phone": 82781993,
        "address": "北京市海淀区上地东里五区内",
        "typeName": "小学",
        "id": 481
    },
    {
        "type": 1,
        "name": "北京市海淀区上地实验小学上庄校区",
        "phone": 82469399,
        "address": "北京市海淀区沙阳路06号",
        "typeName": "小学",
        "id": 482
    },
    {
        "type": 1,
        "name": "北京市海淀区红英小学韩家川校区",
        "phone": 62457917,
        "address": "北京市海淀区西北旺镇韩家川村",
        "typeName": "小学",
        "id": 483
    },
    {
        "type": 1,
        "name": "北京市海淀区红英小学冷泉校区",
        "phone": 62486366,
        "address": "北京市海淀区西北旺镇冷泉村",
        "typeName": "小学",
        "id": 484
    },
    {
        "type": 1,
        "name": "北京市海淀区红英小学唐家岭校区",
        "phone": 89029026,
        "address": "北京市海淀区西北旺镇唐家岭新城",
        "typeName": "小学",
        "id": 485
    },
    {
        "type": 1,
        "name": "北京市海淀区红英小学六里屯校区",
        "phone": 53826506,
        "address": "北京市海淀区西北旺镇六里屯小区",
        "typeName": "小学",
        "id": 486
    },
    {
        "type": 1,
        "name": "北京石油学院附属第二实验小学",
        "phone": 62904591,
        "address": "北京市海淀区清河镇宝盛东路32号",
        "typeName": "小学",
        "id": 487
    },
    {
        "type": 1,
        "name": "北京石油学院附属实验小学",
        "phone": 82738703,
        "address": "北京市海淀区清河宝盛里观景园22号",
        "typeName": "小学",
        "id": 488
    },
    {
        "type": 1,
        "name": "北京市海淀北部新区实验学校学校小学部校区",
        "phone": 62455944,
        "address": "北京市海淀区苏家坨镇前沙涧村",
        "typeName": "小学",
        "id": 489
    },
    {
        "type": 1,
        "name": "北京市十一学校一分校（小学部）",
        "phone": 68156809,
        "address": "北京市海淀区万寿路街道翠微路19号",
        "typeName": "小学",
        "id": 490
    },
    {
        "type": 1,
        "name": "北京市第二十中学附属实验学校（小学部）",
        "phone": 82982704,
        "address": "北京市海淀区西三旗街道永泰庄路",
        "typeName": "小学",
        "id": 491
    },
    {
        "type": 1,
        "name": "北京市育英学校西翠路校区",
        "phone": 68278890,
        "address": "海淀区万寿路丙一号",
        "typeName": "小学",
        "id": 492
    },
    {
        "type": 1,
        "name": "首都师范大学附属育新学校（小学部）",
        "phone": 82951952,
        "address": "北京市海淀区西三旗育新花园小区34号",
        "typeName": "小学",
        "id": 493
    },
    {
        "type": 1,
        "name": "北京市海淀区清华附小清河分校",
        "phone": 82711946,
        "address": "北京市海淀区清河一街73号",
        "typeName": "小学",
        "id": 494
    },
    {
        "type": 1,
        "name": "清华大学附属小学清河分校",
        "phone": 62912073,
        "address": "北京市海淀区清河四街1号",
        "typeName": "小学",
        "id": 495
    },
    {
        "type": 1,
        "name": "北京教育科学研究院丰台学校",
        "phone": 59248366,
        "address": "丰台区西红门南二街201号院1号楼",
        "typeName": "小学",
        "id": 496
    },
    {
        "type": 1,
        "name": "北京市丰台区长辛店学校",
        "phone": 83876512,
        "address": "北京市丰台区朱家坟一里44号",
        "typeName": "小学",
        "id": 497
    },
    {
        "type": 1,
        "name": "北京市第十二中学南站校区",
        "phone": 67213111,
        "address": "丰台区洋桥北里5号",
        "typeName": "小学",
        "id": 498
    },
    {
        "type": 1,
        "name": "北京教育学院丰台分院附属学校",
        "phone": 83612776,
        "address": "北京市丰台区丰桥路6号院",
        "typeName": "小学",
        "id": 499
    },
    {
        "type": 1,
        "name": "北京四中璞瑅学校",
        "phone": "010-87656636",
        "address": "北京市丰台区方庄紫芳园六区7号",
        "typeName": "小学",
        "id": 500
    },
    {
        "type": 1,
        "name": "北京市西罗园学校",
        "phone": 87293306,
        "address": "北京市丰台区西罗园二区21号楼",
        "typeName": "小学",
        "id": 501
    },
    {
        "type": 1,
        "name": "北京市丰台区和义学校",
        "phone": 67983090,
        "address": "北京市丰台区南苑北里三区12号",
        "typeName": "小学",
        "id": 502
    },
    {
        "type": 1,
        "name": "北京教育学院附属丰台实验学校",
        "phone": "010-68278799",
        "address": "北京市丰台区大成南里四区24号",
        "typeName": "小学",
        "id": 503
    },
    {
        "type": 1,
        "name": "中央民族大学附属中学丰台实验学校",
        "phone": 83314725,
        "address": "北京市丰台区王佐镇西王佐村25号",
        "typeName": "小学",
        "id": 504
    },
    {
        "type": 1,
        "name": "清华大学附属中学丰台学校（中学部）",
        "phone": 63475285,
        "address": "北京市丰台区西客站南路19号",
        "typeName": "小学",
        "id": 505
    },
    {
        "type": 1,
        "name": "清华大学附属中学丰台学校（小学部）",
        "phone": 63374687,
        "address": "丰台区太平桥村550号",
        "typeName": "小学",
        "id": 506
    },
    {
        "type": 1,
        "name": "中国教育科学研究院丰台实验学校",
        "phone": 63833945,
        "address": "北京市丰台区程庄路90号",
        "typeName": "小学",
        "id": 507
    },
    {
        "type": 1,
        "name": "中国教育科学研究院丰台实验学校小学一部",
        "phone": 83655531,
        "address": "丰台区程庄南里7号",
        "typeName": "小学",
        "id": 508
    },
    {
        "type": 1,
        "name": "中国教育科学研究院丰台实验学校小学二部",
        "phone": 83651264,
        "address": "丰台区五里店南里30号",
        "typeName": "小学",
        "id": 509
    },
    {
        "type": 1,
        "name": "中国人民大学附属中学丰台学校",
        "phone": 62511402,
        "address": "北京市丰台区王佐镇民族苑路9号",
        "typeName": "小学",
        "id": 510
    },
    {
        "type": 1,
        "name": "北京市大成学校",
        "phone": "68671815-8806",
        "address": "北京市青塔小区C区蔚园24号",
        "typeName": "小学",
        "id": 511
    },
    {
        "type": 1,
        "name": "北京市海淀区实验小学丰台分校（东校区）",
        "phone": 63261968,
        "address": "丰台区莲花池南里15号",
        "typeName": "小学",
        "id": 512
    },
    {
        "type": 1,
        "name": "北京市海淀区实验小学丰台分校（西校区）",
        "phone": 63261968,
        "address": "西三环莲宝中路",
        "typeName": "小学",
        "id": 513
    },
    {
        "type": 1,
        "name": "北京市丰台区东高地第二小学",
        "phone": 67991704,
        "address": "丰台区万源东里75栋",
        "typeName": "小学",
        "id": 514
    },
    {
        "type": 1,
        "name": "北京市丰台区东铁匠营第二小学",
        "phone": 67621601,
        "address": "北京市丰台区永外北铁营259号",
        "typeName": "小学",
        "id": 515
    },
    {
        "type": 1,
        "name": "北京市丰台区分钟寺小学校",
        "phone": 87690600,
        "address": "北京市丰台区分钟寺村450号",
        "typeName": "小学",
        "id": 516
    },
    {
        "type": 1,
        "name": "北京市丰台区师范学校附属小学",
        "phone": 63822914,
        "address": "北京市丰台区文体路30号",
        "typeName": "小学",
        "id": 517
    },
    {
        "type": 1,
        "name": "丰台区师范学校附属小学城南嘉园校区",
        "phone": 87587838,
        "address": "丰台区城南嘉园益明园7号楼",
        "typeName": "小学",
        "id": 518
    },
    {
        "type": 1,
        "name": "北京市丰台区丰台第二中学附属实验小学",
        "phone": "010-83738485",
        "address": "北京市丰台区小屯西路兴景路300号",
        "typeName": "小学",
        "id": 519
    },
    {
        "type": 1,
        "name": "北京市丰台区丰台第六小学",
        "phone": 63823479,
        "address": "北京市丰台区丰台镇七里庄138号",
        "typeName": "小学",
        "id": 520
    },
    {
        "type": 1,
        "name": "北京市丰台区东铁匠营第一小学",
        "phone": 67612363,
        "address": "北京市丰台区东铁匠营胡村1号",
        "typeName": "小学",
        "id": 521
    },
    {
        "type": 1,
        "name": "北京市丰台区东铁匠营第一小学分校",
        "phone": 67280892,
        "address": "北京市丰台区石榴园南里17号",
        "typeName": "小学",
        "id": 522
    },
    {
        "type": 1,
        "name": "北京市丰台区东罗园小学",
        "phone": 67221314,
        "address": "北京市丰台区马公庄5号楼",
        "typeName": "小学",
        "id": 523
    },
    {
        "type": 1,
        "name": "北京市丰台区长安新城小学",
        "phone": 51225910,
        "address": "丰台区大成南里1区11号楼",
        "typeName": "小学",
        "id": 524
    },
    {
        "type": 1,
        "name": "北京十二中附属实验小学",
        "phone": 68606370,
        "address": "丰台区丰仪路3号院",
        "typeName": "小学",
        "id": 525
    },
    {
        "type": 1,
        "name": "北京市丰台区槐房小学",
        "phone": 67916420,
        "address": "北京市丰台区南苑乡槐房村335号",
        "typeName": "小学",
        "id": 526
    },
    {
        "type": 1,
        "name": "北京市丰台区芳城园小学",
        "phone": 67648616,
        "address": "北京市丰台区方庄芳城园二区1号",
        "typeName": "小学",
        "id": 527
    },
    {
        "type": 1,
        "name": "北京市丰台区芳群园第一小学",
        "phone": 67634832,
        "address": "北京市丰台区芳群园一区14#",
        "typeName": "小学",
        "id": 528
    },
    {
        "type": 1,
        "name": "北京市丰台区蒲黄榆第一小学",
        "phone": 67656312,
        "address": "北京市丰台区蒲黄榆西里三号楼",
        "typeName": "小学",
        "id": 529
    },
    {
        "type": 1,
        "name": "首都经济贸易大学附属小学",
        "phone": 63736204,
        "address": "北京市丰台区南大元290号",
        "typeName": "小学",
        "id": 530
    },
    {
        "type": 1,
        "name": "北京市丰台区丰台第一小学（本部）",
        "phone": 63890152,
        "address": "北京市丰台区东安街1号",
        "typeName": "小学",
        "id": 531
    },
    {
        "type": 1,
        "name": "北京市丰台区丰台第一小学（丰益分校）",
        "phone": 83662509,
        "address": "北京市丰台区丰管路3号院-1楼",
        "typeName": "小学",
        "id": 532
    },
    {
        "type": 1,
        "name": "北京市丰台区丰台第一小学（远洋分校）",
        "phone": 58031277,
        "address": "北京市丰台区马家堡东路108号院路北",
        "typeName": "小学",
        "id": 533
    },
    {
        "type": 1,
        "name": "北京市丰台区人民村小学",
        "phone": 83600516,
        "address": "北京市丰台区人民村路58号",
        "typeName": "小学",
        "id": 534
    },
    {
        "type": 1,
        "name": "北京市丰台区南苑镇第一小学",
        "phone": 67991392,
        "address": "北京市丰台区南苑公所胡同22号",
        "typeName": "小学",
        "id": 535
    },
    {
        "type": 1,
        "name": "北京市丰台区四合庄小学",
        "phone": 83621316,
        "address": "北京市丰台区花乡桥南育仁里小区2号院内",
        "typeName": "小学",
        "id": 536
    },
    {
        "type": 1,
        "name": "北京教育科学研究院丰台实验小学",
        "phone": 87662327,
        "address": "北京市丰台区顺八条8号院二区三号楼",
        "typeName": "小学",
        "id": 537
    },
    {
        "type": 1,
        "name": "北京市丰台区纪家庙小学",
        "phone": 63359123,
        "address": "北京市丰台区于家胡同59号（三环内）",
        "typeName": "小学",
        "id": 538
    },
    {
        "type": 1,
        "name": "北京市丰台区西罗园第五小学",
        "phone": 87293569,
        "address": "北京市丰台区西罗园三区16号楼",
        "typeName": "小学",
        "id": 539
    },
    {
        "type": 1,
        "name": "北京市丰台区西马金润小学",
        "phone": 87597043,
        "address": "北京市丰台区西马场南里2区25号",
        "typeName": "小学",
        "id": 540
    },
    {
        "type": 1,
        "name": "北京铁路分局北京铁路职工子弟第十一小学",
        "phone": 63736807,
        "address": "北京市丰台区葆台村140号",
        "typeName": "小学",
        "id": 541
    },
    {
        "type": 1,
        "name": "北京市丰台区长辛店第一小学",
        "phone": 83876144,
        "address": "北京市丰台区长辛店大街145号",
        "typeName": "小学",
        "id": 542
    },
    {
        "type": 1,
        "name": "北京市丰台区长辛店第一小学一分校区",
        "phone": 83876245,
        "address": "丰台区长辛店大街262号",
        "typeName": "小学",
        "id": 543
    },
    {
        "type": 1,
        "name": "北京市丰台区长辛店第七小学",
        "phone": 83876327,
        "address": "北京市丰台区陈庄大街4号",
        "typeName": "小学",
        "id": 544
    },
    {
        "type": 1,
        "name": "北京市丰台区丰台第一小学长辛店分校",
        "phone": 83879138,
        "address": "北京市丰台区张郭庄南路3号",
        "typeName": "小学",
        "id": 545
    },
    {
        "type": 1,
        "name": "北京市丰台区丰台第五小学本校区",
        "phone": 63823080,
        "address": "丰台区西四环南路78号",
        "typeName": "小学",
        "id": 546
    },
    {
        "type": 1,
        "name": "北京市丰台区丰台第五小学京铁校区",
        "phone": "51800266—8002",
        "address": "丰台区西四环中路京铁家园1区8号楼",
        "typeName": "小学",
        "id": 547
    },
    {
        "type": 1,
        "name": "北京市丰台区丰台第五小学鸿业校区",
        "phone": 83203571,
        "address": "丰台区鸿业兴园二期4号楼",
        "typeName": "小学",
        "id": 548
    },
    {
        "type": 1,
        "name": "北京市丰台区丰台第五小学科丰校区",
        "phone": 63723474,
        "address": "丰台科技园恒富街1号",
        "typeName": "小学",
        "id": 549
    },
    {
        "type": 1,
        "name": "北京市丰台区丰台第五小学银地校区",
        "phone": 83720370,
        "address": "北京市丰台区黄土岗银地家园17号楼",
        "typeName": "小学",
        "id": 550
    },
    {
        "type": 1,
        "name": "北京市丰台区丰台第八小学",
        "phone": 63720715,
        "address": "北京市丰台区丰台桥南韩庄子二里8号楼",
        "typeName": "小学",
        "id": 551
    },
    {
        "type": 1,
        "name": "北京市丰台区五爱屯小学",
        "phone": 67991284,
        "address": "北京市丰台区西宏苑小区6号楼",
        "typeName": "小学",
        "id": 552
    },
    {
        "type": 1,
        "name": "北京大学附属小学丰台分校",
        "phone": 83315793,
        "address": "北京市丰台区王佐镇新村78号",
        "typeName": "小学",
        "id": 553
    },
    {
        "type": 1,
        "name": "北京市丰台区卢沟桥第二小学校",
        "phone": 83893491,
        "address": "北京市丰台区卢沟桥南里32号",
        "typeName": "小学",
        "id": 554
    },
    {
        "type": 1,
        "name": "北京市丰台区大红门第一小学（西校区）",
        "phone": 87266050,
        "address": "北京市丰台区大红门西路4号",
        "typeName": "小学",
        "id": 555
    },
    {
        "type": 1,
        "name": "北京市丰台区大红门第一小学（东校区）",
        "phone": 67247294,
        "address": "北京市丰台区大红门西前街24号",
        "typeName": "小学",
        "id": 556
    },
    {
        "type": 1,
        "name": "北京市丰台区太平桥第二小学",
        "phone": 63263132,
        "address": "北京市丰台区太平桥8号",
        "typeName": "小学",
        "id": 557
    },
    {
        "type": 1,
        "name": "北京市丰台区时光小学",
        "phone": 67245135,
        "address": "北京市丰台区石榴园北里20号",
        "typeName": "小学",
        "id": 558
    },
    {
        "type": 1,
        "name": "北京市丰台区苏家坡小学",
        "phone": "010-67212473",
        "address": "北京市丰台区大红门路15号",
        "typeName": "小学",
        "id": 559
    },
    {
        "type": 1,
        "name": "北京市丰台区角门小学",
        "phone": 67537268,
        "address": "北京市丰台区角门东里甲30号",
        "typeName": "小学",
        "id": 560
    },
    {
        "type": 1,
        "name": "北京市丰台区长辛店中心小学",
        "phone": 83800552,
        "address": "丰台区槐树岭五号",
        "typeName": "小学",
        "id": 561
    },
    {
        "type": 1,
        "name": "北京朝阳芳草地国际学校丽泽分校",
        "phone": 63256917,
        "address": "北京市丰台区丽泽路一号院四号楼",
        "typeName": "小学",
        "id": 562
    },
    {
        "type": 1,
        "name": "北京市丰台区丰台第七小学校",
        "phone": 63821456,
        "address": "北京市丰台区东大街东里",
        "typeName": "小学",
        "id": 563
    },
    {
        "type": 1,
        "name": "北京市丰台区丰台第五小学万柳分校",
        "phone": 63302794,
        "address": "北京市丰台区万柳园小区10号楼",
        "typeName": "小学",
        "id": 564
    },
    {
        "type": 1,
        "name": "北京市丰台区玉林小学",
        "phone": 63058202,
        "address": "北京市丰台区右安门外玉林里18号",
        "typeName": "小学",
        "id": 565
    },
    {
        "type": 1,
        "name": "北京市丰台区大红门第二小学",
        "phone": 67221506,
        "address": "北京市丰台区大红门东后街157号",
        "typeName": "小学",
        "id": 566
    },
    {
        "type": 1,
        "name": "北京市丰台区小井小学",
        "phone": 63814959,
        "address": "丰台区小井村338号",
        "typeName": "小学",
        "id": 567
    },
    {
        "type": 1,
        "name": "北京市丰台区成寿寺小学",
        "phone": 67607225,
        "address": "北京市丰台区成安路16号",
        "typeName": "小学",
        "id": 568
    },
    {
        "type": 1,
        "name": "北京市丰台区扶轮小学",
        "phone": 83308461,
        "address": "北京市丰台区长辛店崔村一里8号",
        "typeName": "小学",
        "id": 569
    },
    {
        "type": 1,
        "name": "东高地第一小学",
        "phone": "010-67991703",
        "address": "北京市丰台区东高地斜街1号",
        "typeName": "小学",
        "id": 570
    },
    {
        "type": 1,
        "name": "北京市丰台区西罗园小学",
        "phone": 67213657,
        "address": "北京市丰台区海户西里12号",
        "typeName": "小学",
        "id": 571
    },
    {
        "type": 1,
        "name": "北京市丰台区黄土岗小学",
        "phone": "010-63726855",
        "address": "北京市丰台区后黄土岗3号",
        "typeName": "小学",
        "id": 572
    },
    {
        "type": 1,
        "name": "北京市丰台区万泉寺小学（2018年学校撤销）",
        "phone": 63336916,
        "address": "北京市丰台区万泉寺270号",
        "typeName": "小学",
        "id": 573
    },
    {
        "type": 1,
        "name": "北京市丰台区东高地第三小学",
        "phone": 68759890,
        "address": "丰台区东高地梅源小区内",
        "typeName": "小学",
        "id": 574
    },
    {
        "type": 1,
        "name": "北京市丰台区东高地第三小学西校区",
        "phone": 67065495,
        "address": "丰台区警备东路6号",
        "typeName": "小学",
        "id": 575
    },
    {
        "type": 1,
        "name": "北京市丰台区东高地第四小学",
        "phone": 67991613,
        "address": "北京市丰台区东高地万源南路2号",
        "typeName": "小学",
        "id": 576
    },
    {
        "type": 1,
        "name": "北京市丰台区丰体时代小学",
        "phone": 63821729,
        "address": "北京市丰台区丰体南路一号院10号楼",
        "typeName": "小学",
        "id": 577
    },
    {
        "type": 1,
        "name": "北京市第十八中学附属实验小学",
        "phone": 67629257,
        "address": "北京市丰台区庄芳星园二区九号楼",
        "typeName": "小学",
        "id": 578
    },
    {
        "type": 1,
        "name": "北京教育科学研究院丰台第二实验小学",
        "phone": 67621920,
        "address": "北京市丰台区石榴庄南里2号",
        "typeName": "小学",
        "id": 579
    },
    {
        "type": 1,
        "name": "北京市丰台区新发地小学",
        "phone": 83721271,
        "address": "北京市丰台区天伦锦城12号楼",
        "typeName": "小学",
        "id": 580
    },
    {
        "type": 1,
        "name": "北京舞蹈学院附中丰台实验小学",
        "phone": 83401920,
        "address": "北京市丰台区右安门外翠林小区三里4号楼",
        "typeName": "小学",
        "id": 581
    },
    {
        "type": 1,
        "name": "北京市丰台区芳星园第二小学",
        "phone": 67638304,
        "address": "北京市丰台区芳星园三区21#",
        "typeName": "小学",
        "id": 582
    },
    {
        "type": 1,
        "name": "北京市丰台区西罗园第六小学",
        "phone": 67232530,
        "address": "北京市丰台区西罗园南里9号楼",
        "typeName": "小学",
        "id": 583
    },
    {
        "type": 1,
        "name": "北京市赵登禹学校（南校区）",
        "phone": 6757177067582740,
        "address": "北京市丰台区马家堡嘉园二里小区43号",
        "typeName": "小学",
        "id": 584
    },
    {
        "type": 1,
        "name": "北京市赵登禹学校（东校区）",
        "phone": 6752870000000000,
        "address": "北京市丰台区马家堡西里52号",
        "typeName": "小学",
        "id": 585
    },
    {
        "type": 1,
        "name": "首都医科大学附属小学",
        "phone": "010-63538602",
        "address": "北京市丰台区右安门外东二街51号",
        "typeName": "小学",
        "id": 586
    },
    {
        "type": 1,
        "name": "首都师范大学附属云岗小学北校区",
        "phone": 68741436,
        "address": "北京市丰台区云岗北区西里7号",
        "typeName": "小学",
        "id": 587
    },
    {
        "type": 1,
        "name": "首都师范大学附属云岗小学南校区",
        "phone": 83316381,
        "address": "北京市丰台区云岗南区西里23号",
        "typeName": "小学",
        "id": 588
    },
    {
        "type": 1,
        "name": "北京小学丰台万年花城分校",
        "phone": 58370903,
        "address": "北京市丰台区万年花城万芳园二区4号楼",
        "typeName": "小学",
        "id": 589
    },
    {
        "type": 1,
        "name": "北京市丰台区卢沟桥第一小学",
        "phone": "010-83891403",
        "address": "北京市丰台区卢沟桥城内街155号",
        "typeName": "小学",
        "id": 590
    },
    {
        "type": 1,
        "name": "北京市丰台区看丹小学",
        "phone": 63713286,
        "address": "北京市丰台区建新路36号",
        "typeName": "小学",
        "id": 591
    },
    {
        "type": 1,
        "name": "北京市丰台区芳古园小学",
        "phone": 67629801,
        "address": "北京市丰台区方庄芳古园一区19号",
        "typeName": "小学",
        "id": 592
    },
    {
        "type": 1,
        "name": "北京市丰台区芳古园小学（东校区）",
        "phone": 67623520,
        "address": "北京市丰台区方庄芳古园二区8号",
        "typeName": "小学",
        "id": 593
    },
    {
        "type": 1,
        "name": "北京市丰台区芳古园小学（西校区）",
        "phone": 67613813,
        "address": "北京市丰台区蒲安里15号",
        "typeName": "小学",
        "id": 594
    },
    {
        "type": 1,
        "name": "北京市丰台区阳春小学",
        "phone": 83709138,
        "address": "北京市丰台区花乡羊坊村111号阳春小学",
        "typeName": "小学",
        "id": 595
    },
    {
        "type": 1,
        "name": "北京市丰台区靛厂小学",
        "phone": 63823941,
        "address": "北京市丰台区靛厂村甲300号",
        "typeName": "小学",
        "id": 596
    },
    {
        "type": 1,
        "name": "北京市丰台区草桥小学",
        "phone": 67529075,
        "address": "丰台区草桥欣园四区",
        "typeName": "小学",
        "id": 597
    },
    {
        "type": 1,
        "name": "北京教育科学研究院附属石景山实验学校南校区",
        "phone": 68888118,
        "address": "北京市石景山区古城小街18号",
        "typeName": "小学",
        "id": 598
    },
    {
        "type": 1,
        "name": "北京教育科学研究院附属石景山实验学校北校区",
        "phone": 68888118,
        "address": "北京市石景山区古城北路62号",
        "typeName": "小学",
        "id": 599
    },
    {
        "type": 1,
        "name": "北京市蓝天第一学校",
        "phone": 88742739,
        "address": "北京市石景山区苹果园三区",
        "typeName": "小学",
        "id": 600
    },
    {
        "type": 1,
        "name": "北京市京源学校莲石湖分校",
        "phone": 52866684,
        "address": "北京市石景山区燕堤中街26号院1号及16号院1号",
        "typeName": "小学",
        "id": 601
    },
    {
        "type": 1,
        "name": "北京市石景山区黄庄学校",
        "phone": 88681619,
        "address": "北京市石景山区黄庄村43号",
        "typeName": "小学",
        "id": 602
    },
    {
        "type": 1,
        "name": "北京市石景山区华奥学校",
        "phone": 68664165,
        "address": "北京市石景山区永乐东小区",
        "typeName": "小学",
        "id": 603
    },
    {
        "type": 1,
        "name": "北京市石景山区台京学校",
        "phone": 686663821,
        "address": "北京市石景山区衙门口村西南后街",
        "typeName": "小学",
        "id": 604
    },
    {
        "type": 1,
        "name": "北京市石景山区中杉学校",
        "phone": 88173625,
        "address": "石景山区石门路342号",
        "typeName": "小学",
        "id": 605
    },
    {
        "type": 1,
        "name": "北京景山学校远洋分校",
        "phone": 88690802,
        "address": "北京市石景山区鲁谷东街22号",
        "typeName": "小学",
        "id": 606
    },
    {
        "type": 1,
        "name": "人大附中石景山学校",
        "phone": 83193000,
        "address": "北京市石景山区五里坨街道隆恩寺街秀府路",
        "typeName": "小学",
        "id": 607
    },
    {
        "type": 1,
        "name": "北京市京源学校",
        "phone": 68644122,
        "address": "北京市石景山区京原路10号",
        "typeName": "小学",
        "id": 608
    },
    {
        "type": 1,
        "name": "首钢矿业公司职工子弟学校",
        "phone": "0315-7710094",
        "address": "河北省迁安市首钢滨河村",
        "typeName": "小学",
        "id": 609
    },
    {
        "type": 1,
        "name": "北京市石景山区向阳小学",
        "phone": 68687838,
        "address": "北京市石景山区衙门口大横街",
        "typeName": "小学",
        "id": 610
    },
    {
        "type": 1,
        "name": "北京市石景山区师范学校附属小学",
        "phone": 68653826,
        "address": "北京市石景山区鲁谷路66号",
        "typeName": "小学",
        "id": 611
    },
    {
        "type": 1,
        "name": "北京市石景山区第二实验小学",
        "phone": 88970161,
        "address": "北京市石景山区老山西街21号",
        "typeName": "小学",
        "id": 612
    },
    {
        "type": 1,
        "name": "北京市石景山区银河小学",
        "phone": 68624930,
        "address": "北京市石景山区六合园甲24号",
        "typeName": "小学",
        "id": 613
    },
    {
        "type": 1,
        "name": "北京市石景山区实验小学分校",
        "phone": 88233583,
        "address": "北京市石景山区玉泉西街何家坟北",
        "typeName": "小学",
        "id": 614
    },
    {
        "type": 1,
        "name": "北京市石景山区古城第二小学",
        "phone": 68872963,
        "address": "北京市石景山区古城南路",
        "typeName": "小学",
        "id": 615
    },
    {
        "type": 1,
        "name": "北京市石景山区古城第二小学低年级部",
        "phone": 68832985,
        "address": "北京市石景山区地铁古城家园西门",
        "typeName": "小学",
        "id": 616
    },
    {
        "type": 1,
        "name": "北京市石景山区古城第二小学分校",
        "phone": 88926911,
        "address": "北京市石景山区古城南里18号",
        "typeName": "小学",
        "id": 617
    },
    {
        "type": 1,
        "name": "北京市石景山外语实验小学",
        "phone": 88996420,
        "address": "北京市石景山区首钢黄南苑小区内",
        "typeName": "小学",
        "id": 618
    },
    {
        "type": 1,
        "name": "北京市石景山区实验小学",
        "phone": 68862278,
        "address": "北京市石景山区八角北里39号院",
        "typeName": "小学",
        "id": 619
    },
    {
        "type": 1,
        "name": "北京市石景山区北辛安小学",
        "phone": 68872398,
        "address": "北京市石景山区北辛安南岔13号",
        "typeName": "小学",
        "id": 620
    },
    {
        "type": 1,
        "name": "北京市石景山区水泥厂小学",
        "phone": 88958865,
        "address": "北京市石景山区京原路68号",
        "typeName": "小学",
        "id": 621
    },
    {
        "type": 1,
        "name": "北京市石景山区苹果园第二小学",
        "phone": 68872198,
        "address": "北京市石景山区苹果园一区甲十号",
        "typeName": "小学",
        "id": 622
    },
    {
        "type": 1,
        "name": "北京市石景山区西黄村小学",
        "phone": 88932653,
        "address": "北京市石景山区八大处路102号",
        "typeName": "小学",
        "id": 623
    },
    {
        "type": 1,
        "name": "北京大学附属小学石景山学校",
        "phone": 88963389,
        "address": "北京市石景山区八大处路乙2号",
        "typeName": "小学",
        "id": 624
    },
    {
        "type": 1,
        "name": "北京市石景山区海特花园小学",
        "phone": 88798124,
        "address": "石景山区海特花园内(近苹果园地铁站)",
        "typeName": "小学",
        "id": 625
    },
    {
        "type": 1,
        "name": "北京市石景山区先锋小学",
        "phone": 88714644,
        "address": "北京市石景山区绍家坡先锋小学",
        "typeName": "小学",
        "id": 626
    },
    {
        "type": 1,
        "name": "北京市石景山区金顶街第二小学",
        "phone": 88717111,
        "address": "北京市石景山区金顶北路22号院",
        "typeName": "小学",
        "id": 627
    },
    {
        "type": 1,
        "name": "北京市石景山区金顶街第四小学",
        "phone": 88722510,
        "address": "北京市石景山区金顶北路",
        "typeName": "小学",
        "id": 628
    },
    {
        "type": 1,
        "name": "北京市石景山区广宁村小学",
        "phone": 88993222,
        "address": "北京市石景山区广宁村新立街151号",
        "typeName": "小学",
        "id": 629
    },
    {
        "type": 1,
        "name": "北京市石景山区麻峪小学",
        "phone": 88991876,
        "address": "北京市石景山区麻峪南街甲51号",
        "typeName": "小学",
        "id": 630
    },
    {
        "type": 1,
        "name": "北京市石景山区电厂路小学",
        "phone": 88953963,
        "address": "北京市石景山区高井路18号",
        "typeName": "小学",
        "id": 631
    },
    {
        "type": 1,
        "name": "北京市石景山区红旗小学",
        "phone": 88902251,
        "address": "北京市石景山区高井甲32号",
        "typeName": "小学",
        "id": 632
    },
    {
        "type": 1,
        "name": "北京市石景山区炮厂小学",
        "phone": 88950092,
        "address": "北京市石景山区7312工厂内",
        "typeName": "小学",
        "id": 633
    },
    {
        "type": 1,
        "name": "北京市石景山外语实验小学分校",
        "phone": 88901160,
        "address": "北京市石景山区香山南路168号",
        "typeName": "小学",
        "id": 634
    },
    {
        "type": 1,
        "name": "北京市石景山区五里坨小学",
        "phone": 88904267,
        "address": "北京市石景山区五里坨车站路7号",
        "typeName": "小学",
        "id": 635
    },
    {
        "type": 1,
        "name": "北京市石景山区爱乐实验小学",
        "phone": 68656411,
        "address": "北京市石景山区鲁谷街道聚兴园居委会",
        "typeName": "小学",
        "id": 636
    },
    {
        "type": 1,
        "name": "北京市石景山区爱乐实验小学高年级部",
        "phone": 68656411,
        "address": "北京市石景山区鲁谷南路19号附近",
        "typeName": "小学",
        "id": 637
    },
    {
        "type": 1,
        "name": "北京市石景山区京原小学",
        "phone": 68860464,
        "address": "北京市石景山区鲁谷路66号",
        "typeName": "小学",
        "id": 638
    },
    {
        "type": 1,
        "name": "北京市石景山区石景山小学",
        "phone": 88992808,
        "address": "北京市石景山区模式口西里甲32号",
        "typeName": "小学",
        "id": 639
    },
    {
        "type": 1,
        "name": "北方工业大学附属小学南校区",
        "phone": 68838180,
        "address": "北京市石景山区八角北路52号",
        "typeName": "小学",
        "id": 640
    },
    {
        "type": 1,
        "name": "北方工业大学附属小学北校区",
        "phone": 68838180,
        "address": "北京市石景山区八角北路46附近",
        "typeName": "小学",
        "id": 641
    },
    {
        "type": 1,
        "name": "北京市石景山区树仁小学",
        "phone": 52633318,
        "address": "北京市石景山区衙门口东街西五环旧货市场北侧",
        "typeName": "小学",
        "id": 642
    },
    {
        "type": 1,
        "name": "北京市门头沟区妙峰山民族学校",
        "phone": 61880043,
        "address": "北京市门头沟区妙峰山镇陇驾庄村",
        "typeName": "小学",
        "id": 643
    },
    {
        "type": 1,
        "name": "北京市三家店铁路中学",
        "phone": 61819104,
        "address": "北京市门头沟区城子街道办事处华新建社区",
        "typeName": "小学",
        "id": 644
    },
    {
        "type": 1,
        "name": "北京市门头沟区大峪第一小学",
        "phone": 69842047,
        "address": "北京市门头沟区永定镇上园北路9号",
        "typeName": "小学",
        "id": 645
    },
    {
        "type": 1,
        "name": "北京市门头沟区城子小学",
        "phone": 69842157,
        "address": "北京市门头沟区城子大街10号",
        "typeName": "小学",
        "id": 646
    },
    {
        "type": 1,
        "name": "北京市门头沟区大台中心小学",
        "phone": "61870328-8013",
        "address": "北京市门头沟区大台路7号",
        "typeName": "小学",
        "id": 647
    },
    {
        "type": 1,
        "name": "北京市门头沟区灰地小学",
        "phone": "61870328-8013",
        "address": "北京市门头沟区大台路14号",
        "typeName": "小学",
        "id": 648
    },
    {
        "type": 1,
        "name": "北京市门头沟区唐家坟小学",
        "phone": "61870328-8013",
        "address": "北京市门头沟区大台路16号",
        "typeName": "小学",
        "id": 649
    },
    {
        "type": 1,
        "name": "北京市门头沟区军庄中心小学",
        "phone": "60811384-8003",
        "address": "北京市门头沟区军庄镇军庄村",
        "typeName": "小学",
        "id": 650
    },
    {
        "type": 1,
        "name": "北京市门头沟区三家店小学",
        "phone": 61811804,
        "address": "北京市门头沟区三家店中街8号",
        "typeName": "小学",
        "id": 651
    },
    {
        "type": 1,
        "name": "北京市门头沟区清水中心小学",
        "phone": 60855374,
        "address": "北京市门头沟区清水镇上清水村",
        "typeName": "小学",
        "id": 652
    },
    {
        "type": 1,
        "name": "北京市门头沟区斋堂中心小学",
        "phone": 69816657,
        "address": "北京市门头沟区斋堂镇西斋堂村",
        "typeName": "小学",
        "id": 653
    },
    {
        "type": 1,
        "name": "北京市门头沟区龙泉雾小学",
        "phone": 61899950,
        "address": "北京市门头沟区龙泉雾村曹家台6号",
        "typeName": "小学",
        "id": 654
    },
    {
        "type": 1,
        "name": "北京市门头沟区付家台中心小学",
        "phone": "61839774-8208",
        "address": "北京市门头沟区雁翅镇付家台村",
        "typeName": "小学",
        "id": 655
    },
    {
        "type": 1,
        "name": "北京市门头沟区军响中心小学",
        "phone": "61817014-815",
        "address": "北京市门头沟区斋堂镇军响村",
        "typeName": "小学",
        "id": 656
    },
    {
        "type": 1,
        "name": "北京市门头沟区黑山小学",
        "phone": "69842367-8002",
        "address": "北京市门头沟区黑山北小街3号",
        "typeName": "小学",
        "id": 657
    },
    {
        "type": 1,
        "name": "北京市门头沟区王平村中心小学",
        "phone": "61859474-808",
        "address": "北京市门头沟区王平镇河北教学楼",
        "typeName": "小学",
        "id": 658
    },
    {
        "type": 1,
        "name": "北京市门头沟区大峪第二小学",
        "phone": 69820716,
        "address": "北京市门头沟区新桥路18号",
        "typeName": "小学",
        "id": 659
    },
    {
        "type": 1,
        "name": "北京市门头沟区育园小学",
        "phone": 69843527,
        "address": "北京市门头沟区新桥南大街21号",
        "typeName": "小学",
        "id": 660
    },
    {
        "type": 1,
        "name": "北京市门头沟区京师实验小学",
        "phone": 61890553,
        "address": "北京市门头沟区大峪街道办事处坡头西街社区",
        "typeName": "小学",
        "id": 661
    },
    {
        "type": 1,
        "name": "北京市门头沟区龙泉小学",
        "phone": 69842057,
        "address": "门头沟区石泉砖厂B9地块耿王路西口",
        "typeName": "小学",
        "id": 662
    },
    {
        "type": 1,
        "name": "北京市门头沟区东辛房小学",
        "phone": 69842007,
        "address": "北京市门头沟区东辛房门头沟路130号",
        "typeName": "小学",
        "id": 663
    },
    {
        "type": 1,
        "name": "北京市门头沟区潭柘寺中心小学",
        "phone": 60863217,
        "address": "北京市门头沟区潭柘寺镇鲁家滩村",
        "typeName": "小学",
        "id": 664
    },
    {
        "type": 1,
        "name": "北京市门头沟区潭柘寺中心南辛房小学",
        "phone": 60862357,
        "address": "北京市门头沟区潭柘寺镇南辛房村",
        "typeName": "小学",
        "id": 665
    },
    {
        "type": 1,
        "name": "北京第二实验小学永定分校",
        "phone": "010-69802847",
        "address": "北京市门头沟区永定镇冯村",
        "typeName": "小学",
        "id": 666
    },
    {
        "type": 1,
        "name": "北京第二实验小学永定分校冯村小学",
        "phone": "010-69802847",
        "address": "北京市门头沟区石龙西路",
        "typeName": "小学",
        "id": 667
    },
    {
        "type": 1,
        "name": "北京市门头沟区琉璃渠小学",
        "phone": 61800103,
        "address": "北京市门头沟区琉璃渠后街155号",
        "typeName": "小学",
        "id": 668
    },
    {
        "type": 1,
        "name": "中国人民大学附属小学京西分校",
        "phone": 69808816,
        "address": "北京市门头沟区石门营石园北路",
        "typeName": "小学",
        "id": 669
    },
    {
        "type": 1,
        "name": "北京市第八中学京西附属小学",
        "phone": "无",
        "address": "北京市门头沟区永定镇东辛称C地块",
        "typeName": "小学",
        "id": 670
    },
    {
        "type": 1,
        "name": "北京市房山区北潞园学校",
        "phone": 89392062,
        "address": "北京市房山区良乡北潞园北潞春E5楼",
        "typeName": "小学",
        "id": 671
    },
    {
        "type": 1,
        "name": "北京市房山区昊天学校",
        "phone": 69387621,
        "address": "北京市房山区拱辰街道办事处玉竹园社区居委会",
        "typeName": "小学",
        "id": 672
    },
    {
        "type": 1,
        "name": "北京市房山区大宁学校",
        "phone": "81352008-8001",
        "address": "北京市房山区长阳镇大宁山庄小区",
        "typeName": "小学",
        "id": 673
    },
    {
        "type": 1,
        "name": "北京市房山区四〇一学校",
        "phone": 69358428,
        "address": "北京市房山区新镇东平街",
        "typeName": "小学",
        "id": 674
    },
    {
        "type": 1,
        "name": "北京十二中朗悦学校",
        "phone": 80360732,
        "address": "北京市房山区长阳镇阜盛东街62号院",
        "typeName": "小学",
        "id": 675
    },
    {
        "type": 1,
        "name": "北京市房山区房山长育中心小学",
        "phone": 69319614,
        "address": "北京市房山区西大街33号",
        "typeName": "小学",
        "id": 676
    },
    {
        "type": 1,
        "name": "北京市房山区房山街道饶乐府完全小学",
        "phone": 89326813,
        "address": "北京市房山区房山街道饶乐府村",
        "typeName": "小学",
        "id": 677
    },
    {
        "type": 1,
        "name": "北京市房山区城关镇顾册完全小学",
        "phone": 69329838,
        "address": "北京市房山区城关镇顾册村",
        "typeName": "小学",
        "id": 678
    },
    {
        "type": 1,
        "name": "北京市房山区城关街道田各庄完全小学",
        "phone": 81301667,
        "address": "北京市房山区城关街道田各庄村",
        "typeName": "小学",
        "id": 679
    },
    {
        "type": 1,
        "name": "北京市房山区城关镇羊头岗完全小学",
        "phone": 81307346,
        "address": "北京市房山区城关街道办事处羊头岗村委会",
        "typeName": "小学",
        "id": 680
    },
    {
        "type": 1,
        "name": "北京市房山区城关镇马各庄完全小学",
        "phone": 89327904,
        "address": "北京市房山区城关镇马各庄村",
        "typeName": "小学",
        "id": 681
    },
    {
        "type": 1,
        "name": "北京市房山区良乡镇西石羊完全小学",
        "phone": 60331606,
        "address": "北京市房山区良乡镇西石羊村",
        "typeName": "小学",
        "id": 682
    },
    {
        "type": 1,
        "name": "北京市房山区良乡镇邢家坞完全小学",
        "phone": 60332654,
        "address": "北京市房山区良乡镇邢家坞村",
        "typeName": "小学",
        "id": 683
    },
    {
        "type": 1,
        "name": "北京市房山区周口店地区周口店中心小学",
        "phone": 69305628,
        "address": "北京市房山区周口店大街54号",
        "typeName": "小学",
        "id": 684
    },
    {
        "type": 1,
        "name": "北京市房山区周口店地区瓦井完全小学",
        "phone": 69309317,
        "address": "北京市房山区周口店镇瓦井村",
        "typeName": "小学",
        "id": 685
    },
    {
        "type": 1,
        "name": "北京市房山区周口店地区娄子水完全小学",
        "phone": 69308312,
        "address": "北京市房山区周口店地区娄子水完全小学",
        "typeName": "小学",
        "id": 686
    },
    {
        "type": 1,
        "name": "北京市房山区周口店地区黄山店小学",
        "phone": 60364717,
        "address": "北京市房山区周口店地区黄山店小学",
        "typeName": "小学",
        "id": 687
    },
    {
        "type": 1,
        "name": "北京市房山区周口店中心校长沟峪小学",
        "phone": 69307069,
        "address": "北京市房山区周口店地区办事处长沟峪社区居委会",
        "typeName": "小学",
        "id": 688
    },
    {
        "type": 1,
        "name": "北京市房山区琉璃河镇琉璃河中心小学",
        "phone": 89381244,
        "address": "北京市房山区琉璃河镇二街村",
        "typeName": "小学",
        "id": 689
    },
    {
        "type": 1,
        "name": "北京市房山区琉璃河镇立教完全小学",
        "phone": 61391824,
        "address": "北京市房山区琉璃河镇立教村",
        "typeName": "小学",
        "id": 690
    },
    {
        "type": 1,
        "name": "北京市房山区琉璃河镇兴礼完全小学",
        "phone": 51139611,
        "address": "北京市房山区琉璃河镇兴礼村",
        "typeName": "小学",
        "id": 691
    },
    {
        "type": 1,
        "name": "北京市房山区琉璃河镇祖村完全小学",
        "phone": 80301743,
        "address": "北京市房山区琉璃河镇祖村",
        "typeName": "小学",
        "id": 692
    },
    {
        "type": 1,
        "name": "北京市房山区琉璃河镇三街完全小学",
        "phone": 51137630,
        "address": "北京市房山区琉璃河镇三街村委会",
        "typeName": "小学",
        "id": 693
    },
    {
        "type": 1,
        "name": "北京市房山区琉璃河镇西南召中心小学",
        "phone": 80398194,
        "address": "北京市房山区琉璃河镇西南召村",
        "typeName": "小学",
        "id": 694
    },
    {
        "type": 1,
        "name": "北京市房山区琉璃河镇常舍完全小学",
        "phone": 80390560,
        "address": "北京市房山区琉璃河镇常舍村",
        "typeName": "小学",
        "id": 695
    },
    {
        "type": 1,
        "name": "北京市房山区琉璃河镇路村完全小学",
        "phone": 80397440,
        "address": "北京市房山区琉璃河镇路村",
        "typeName": "小学",
        "id": 696
    },
    {
        "type": 1,
        "name": "北京市房山区琉璃河镇务滋完全小学",
        "phone": 80397124,
        "address": "北京市房山区琉璃河镇务滋村",
        "typeName": "小学",
        "id": 697
    },
    {
        "type": 1,
        "name": "北京市房山区琉璃河镇窑上村中心小学",
        "phone": 80321189,
        "address": "北京市房山区琉璃河镇窑上村委会",
        "typeName": "小学",
        "id": 698
    },
    {
        "type": 1,
        "name": "北京市房山区良乡镇良乡中心小学",
        "phone": 69369846,
        "address": "北京市房山区西潞街道苏庄三里",
        "typeName": "小学",
        "id": 699
    },
    {
        "type": 1,
        "name": "北京市房山区良乡镇固村完全小学",
        "phone": 89359730,
        "address": "北京市房山区西潞街道办事处固村村委会",
        "typeName": "小学",
        "id": 700
    },
    {
        "type": 1,
        "name": "北京市房山区良乡镇太平庄小学",
        "phone": 61356549,
        "address": "北京市房山区西潞街道太平庄村",
        "typeName": "小学",
        "id": 701
    },
    {
        "type": 1,
        "name": "北京市房山区阎村镇阎村中心小学",
        "phone": 89318516,
        "address": "北京市房山区阎村镇南梨园村委会",
        "typeName": "小学",
        "id": 702
    },
    {
        "type": 1,
        "name": "北京市房山区阎村镇大董村完全小学",
        "phone": 89318314,
        "address": "北京市房山区阎村镇吴庄村",
        "typeName": "小学",
        "id": 703
    },
    {
        "type": 1,
        "name": "北京市房山区阎村镇后沿村完全小学",
        "phone": 89319502,
        "address": "北京市房山区阎村镇后沿村村委会",
        "typeName": "小学",
        "id": 704
    },
    {
        "type": 1,
        "name": "北京市房山区阎村镇后十三里完全小学",
        "phone": 80331204,
        "address": "北京市房山区阎村镇后十三里村",
        "typeName": "小学",
        "id": 705
    },
    {
        "type": 1,
        "name": "北京市房山区阎村镇北坊完全小学",
        "phone": 60386906,
        "address": "北京市房山区阎村镇北坊村",
        "typeName": "小学",
        "id": 706
    },
    {
        "type": 1,
        "name": "北京市房山区窦店镇窦店中心小学",
        "phone": 69391062,
        "address": "北京市房山区窦店镇窦店村委会",
        "typeName": "小学",
        "id": 707
    },
    {
        "type": 1,
        "name": "北京市房山区窦店镇芦村完全小学",
        "phone": 69393445,
        "address": "北京市房山区窦店镇芦村（村委会）",
        "typeName": "小学",
        "id": 708
    },
    {
        "type": 1,
        "name": "北京市房山区窦店镇望楚完全小学",
        "phone": 60394039,
        "address": "北京市房山区窦店镇望楚村",
        "typeName": "小学",
        "id": 709
    },
    {
        "type": 1,
        "name": "北京市房山区窦店镇交道中心小学",
        "phone": 80318549,
        "address": "北京市房山区窦店镇一街村委会",
        "typeName": "小学",
        "id": 710
    },
    {
        "type": 1,
        "name": "北京市房山区窦店镇兴隆庄完全小学",
        "phone": 80317446,
        "address": "北京市房山区窦店镇兴隆庄完全小学",
        "typeName": "小学",
        "id": 711
    },
    {
        "type": 1,
        "name": "北京市房山区石楼镇夏村完全小学",
        "phone": 69324924,
        "address": "北京市房山区石楼镇夏村村委会",
        "typeName": "小学",
        "id": 712
    },
    {
        "type": 1,
        "name": "北京市房山区石楼镇大次洛完全小学",
        "phone": 89302704,
        "address": "北京市房山区石楼镇大次洛村委会",
        "typeName": "小学",
        "id": 713
    },
    {
        "type": 1,
        "name": "北京市房山区长阳镇长阳中心小学",
        "phone": 51135399,
        "address": "北京市房山区长阳镇碧桂园小区内",
        "typeName": "小学",
        "id": 714
    },
    {
        "type": 1,
        "name": "北京市房山区长阳镇西营完全小学",
        "phone": 80366323,
        "address": "北京市房山区长阳镇西营村",
        "typeName": "小学",
        "id": 715
    },
    {
        "type": 1,
        "name": "北京市房山区长阳镇葫芦垡中心小学",
        "phone": 60352107,
        "address": "北京市房山区长阳镇葫芦垡村",
        "typeName": "小学",
        "id": 716
    },
    {
        "type": 1,
        "name": "北京市房山区长阳镇阎仙垡完全小学",
        "phone": 60352107,
        "address": "北京市房山区长阳镇阎仙垡村",
        "typeName": "小学",
        "id": 717
    },
    {
        "type": 1,
        "name": "北京市房山区长阳镇公议庄完全小学",
        "phone": 60352107,
        "address": "北京市房山区长阳镇公议庄村",
        "typeName": "小学",
        "id": 718
    },
    {
        "type": 1,
        "name": "北京市房山区河北镇河北小学",
        "phone": 60377430,
        "address": "北京市房山区河北镇河南村",
        "typeName": "小学",
        "id": 719
    },
    {
        "type": 1,
        "name": "北京市房山区河北镇磁家务小学",
        "phone": 60312736,
        "address": "北京市房山区河北镇磁家务村",
        "typeName": "小学",
        "id": 720
    },
    {
        "type": 1,
        "name": "北京市房山区长沟镇长沟中心小学",
        "phone": "010-61361673",
        "address": "北京市房山区长沟镇西长沟村",
        "typeName": "小学",
        "id": 721
    },
    {
        "type": 1,
        "name": "北京市房山区长沟镇龙泉河完全小学",
        "phone": "010-61361673",
        "address": "北京市房山区长沟镇南甘池村",
        "typeName": "小学",
        "id": 722
    },
    {
        "type": 1,
        "name": "北京市房山区长沟镇北正完全小学",
        "phone": "010-61361673",
        "address": "北京市房山区长沟镇北正村",
        "typeName": "小学",
        "id": 723
    },
    {
        "type": 1,
        "name": "北京市房山区大石窝镇南尚乐中心小学",
        "phone": 61323364,
        "address": "北京市房山区大石窝镇石窝村委会",
        "typeName": "小学",
        "id": 724
    },
    {
        "type": 1,
        "name": "北京市房山区大石窝镇后石门村完全小学",
        "phone": 61383285,
        "address": "北京市房山区大石窝镇后石门村委会",
        "typeName": "小学",
        "id": 725
    },
    {
        "type": 1,
        "name": "北京市房山区大石窝镇下滩明德小学",
        "phone": 61322808,
        "address": "北京市房山区大石窝镇下滩村",
        "typeName": "小学",
        "id": 726
    },
    {
        "type": 1,
        "name": "北京市房山区张坊镇张坊中心小学",
        "phone": 61338591,
        "address": "北京市房山区张坊镇张坊村中区61号",
        "typeName": "小学",
        "id": 727
    },
    {
        "type": 1,
        "name": "北京市房山区张坊镇南白岱完全小学",
        "phone": 61339943,
        "address": "北京市房山区张坊镇南白岱村",
        "typeName": "小学",
        "id": 728
    },
    {
        "type": 1,
        "name": "北京市房山区张坊镇瓦沟完全小学",
        "phone": 61336030,
        "address": "北京市房山区张坊镇瓦沟村",
        "typeName": "小学",
        "id": 729
    },
    {
        "type": 1,
        "name": "北京市房山区张坊镇西白岱完全小学",
        "phone": 61339224,
        "address": "北京市房山区张坊镇西白岱村",
        "typeName": "小学",
        "id": 730
    },
    {
        "type": 1,
        "name": "北京市房山区十渡中心小学",
        "phone": 61340543,
        "address": "北京市房山区十渡镇十渡村",
        "typeName": "小学",
        "id": 731
    },
    {
        "type": 1,
        "name": "北京市房山区青龙湖镇坨里中心小学",
        "phone": 80379412,
        "address": "北京市房山区青龙湖镇坨里村",
        "typeName": "小学",
        "id": 732
    },
    {
        "type": 1,
        "name": "北京市房山区青龙湖镇上万完全小学",
        "phone": 80370016,
        "address": "北京市房山区青龙湖镇上万村委会",
        "typeName": "小学",
        "id": 733
    },
    {
        "type": 1,
        "name": "北京市房山区青龙湖镇沙窝完全小学",
        "phone": 80379484,
        "address": "北京市房山区青龙湖镇沙窝村",
        "typeName": "小学",
        "id": 734
    },
    {
        "type": 1,
        "name": "北京市房山区青龙湖镇北车营完全小学",
        "phone": 60383045,
        "address": "北京市房山区青龙湖镇北车营村",
        "typeName": "小学",
        "id": 735
    },
    {
        "type": 1,
        "name": "北京市房山区青龙湖镇南四位完全小学",
        "phone": 60301247,
        "address": "北京市房山区青龙湖镇南四位村",
        "typeName": "小学",
        "id": 736
    },
    {
        "type": 1,
        "name": "北京市房山区青龙湖镇大苑村完全小学",
        "phone": 60329594,
        "address": "北京市房山区青龙湖镇大苑村村委会",
        "typeName": "小学",
        "id": 737
    },
    {
        "type": 1,
        "name": "北京市房山区韩村河镇韩村河中心小学",
        "phone": 80380170,
        "address": "北京市房山区韩村河镇韩村河村",
        "typeName": "小学",
        "id": 738
    },
    {
        "type": 1,
        "name": "北京市房山区韩村河镇赵各庄完全小学",
        "phone": 80380630,
        "address": "北京市房山区韩村河镇赵各庄村",
        "typeName": "小学",
        "id": 739
    },
    {
        "type": 1,
        "name": "北京市房山区韩村河镇曹章完全小学",
        "phone": 80380867,
        "address": "北京市房山区韩村河镇曹章村",
        "typeName": "小学",
        "id": 740
    },
    {
        "type": 1,
        "name": "北京市房山区韩村河镇五侯中心小学",
        "phone": 61317057,
        "address": "北京市房山区韩村河镇五侯村",
        "typeName": "小学",
        "id": 741
    },
    {
        "type": 1,
        "name": "北京市房山区韩村河镇天开完全小学",
        "phone": 61341456,
        "address": "北京市房山区韩村河镇天开村",
        "typeName": "小学",
        "id": 742
    },
    {
        "type": 1,
        "name": "北京市房山区韩村河镇宝健希望小学",
        "phone": 61314056,
        "address": "北京市房山区韩村河镇孤山口村",
        "typeName": "小学",
        "id": 743
    },
    {
        "type": 1,
        "name": "北京市房山区霞云岭乡霞云岭中心小学",
        "phone": 60367111,
        "address": "北京市房山区霞云岭乡霞云岭村",
        "typeName": "小学",
        "id": 744
    },
    {
        "type": 1,
        "name": "北京市房山区南窖乡南窖中心小学",
        "phone": 60375248,
        "address": "北京市房山区南窖乡南窖中心校",
        "typeName": "小学",
        "id": 745
    },
    {
        "type": 1,
        "name": "北京市房山区佛子庄乡中心小学",
        "phone": 60360683,
        "address": "北京市房山区佛子庄乡东班各庄村",
        "typeName": "小学",
        "id": 746
    },
    {
        "type": 1,
        "name": "北京市房山区大安山乡大安山学校",
        "phone": 60373655,
        "address": "北京市房山区大安山乡大安山村",
        "typeName": "小学",
        "id": 747
    },
    {
        "type": 1,
        "name": "北京市房山区大安山乡大安山第二完全小学",
        "phone": 60373655,
        "address": "北京市房山区大安山乡元港村",
        "typeName": "小学",
        "id": 748
    },
    {
        "type": 1,
        "name": "北京市房山区史家营乡史家营中心小学",
        "phone": 60336331,
        "address": "北京市房山区史家营乡柳林水村",
        "typeName": "小学",
        "id": 749
    },
    {
        "type": 1,
        "name": "北京市房山区蒲洼乡蒲洼中心小学",
        "phone": 61371781,
        "address": "北京市房山区蒲洼乡蒲洼村",
        "typeName": "小学",
        "id": 750
    },
    {
        "type": 1,
        "name": "北京市房山区城关小学",
        "phone": 69314881,
        "address": "北京市房山区城关街道办事处南关村委会",
        "typeName": "小学",
        "id": 751
    },
    {
        "type": 1,
        "name": "北京市房山区城关第二小学",
        "phone": 89321401,
        "address": "北京市房山区城关街道办事处南街村委会",
        "typeName": "小学",
        "id": 752
    },
    {
        "type": 1,
        "name": "北京市房山区城关第三小学",
        "phone": 81308220,
        "address": "北京市房山区永安西里25号",
        "typeName": "小学",
        "id": 753
    },
    {
        "type": 1,
        "name": "北京市房山区良乡小学",
        "phone": 69351626,
        "address": "北京市房山区良乡中路12号",
        "typeName": "小学",
        "id": 754
    },
    {
        "type": 1,
        "name": "北京市房山区良乡第二小学",
        "phone": 69352015,
        "address": "北京市房山区良乡北秀街27号",
        "typeName": "小学",
        "id": 755
    },
    {
        "type": 1,
        "name": "北京市房山区良乡第三小学",
        "phone": 89355434,
        "address": "北京市房山区西潞街道办事处西潞园社区居委会",
        "typeName": "小学",
        "id": 756
    },
    {
        "type": 1,
        "name": "首都师范大学附属房山小学",
        "phone": 89365747,
        "address": "北京市房山区良乡镇建华路10号",
        "typeName": "小学",
        "id": 757
    },
    {
        "type": 1,
        "name": "北京市房山区琉璃河水泥厂学校",
        "phone": 51105590,
        "address": "北京市房山区琉璃河车站前街十号院",
        "typeName": "小学",
        "id": 758
    },
    {
        "type": 1,
        "name": "北京市房山区良乡镇官道中心小学",
        "phone": 603000000,
        "address": "北京市房山区良乡镇黑古台村",
        "typeName": "小学",
        "id": 759
    },
    {
        "type": 1,
        "name": "北京市房山区周口店地区新街完全小学",
        "phone": 61399572,
        "address": "北京市房山区周口店镇新街村",
        "typeName": "小学",
        "id": 760
    },
    {
        "type": 1,
        "name": "北京市房山区长阳第一小学（怡和校区）",
        "phone": 60356890,
        "address": "北京市房山区长阳怡和北路6号",
        "typeName": "小学",
        "id": 761
    },
    {
        "type": 1,
        "name": "北京市房山区长阳第一小学（祥云校区）",
        "phone": 60356890,
        "address": "北京市房山区祥云街10号院10号楼",
        "typeName": "小学",
        "id": 762
    },
    {
        "type": 1,
        "name": "北京市房山区城关第四小学",
        "phone": 89324303,
        "address": "北京市房山区城关街道矿机北潞临9号",
        "typeName": "小学",
        "id": 763
    },
    {
        "type": 1,
        "name": "北京市房山区阎村镇绿城完全小学",
        "phone": 89318516,
        "address": "北京市房山区阎村镇小紫草坞村委会",
        "typeName": "小学",
        "id": 764
    },
    {
        "type": 1,
        "name": "黄城根小学房山分校",
        "phone": "010-80337601",
        "address": "北京市房山区长阳镇长政北街11号院",
        "typeName": "小学",
        "id": 765
    },
    {
        "type": 1,
        "name": "北京市房山区石楼镇石楼中心小学",
        "phone": 89309624,
        "address": "北京市房山区石楼镇石楼村委会",
        "typeName": "小学",
        "id": 766
    },
    {
        "type": 1,
        "name": "北京市房山区石楼镇吉羊完全小学",
        "phone": 89309719,
        "address": "北京市房山区石楼镇吉羊村委会",
        "typeName": "小学",
        "id": 767
    },
    {
        "type": 1,
        "name": "北京市房山区青龙湖镇中心小学",
        "phone": 60329613,
        "address": "北京市房山区青龙湖镇豆各庄村委会",
        "typeName": "小学",
        "id": 768
    },
    {
        "type": 1,
        "name": "北京市房山区良乡第四小学",
        "phone": 60337866,
        "address": "北京市房山区拱辰街道瑞雪春堂小区二里20号",
        "typeName": "小学",
        "id": 769
    },
    {
        "type": 1,
        "name": "北京市房山区窦店镇窦店第二小学",
        "phone": 69399199,
        "address": "北京市房山区窦店镇于庄村委会",
        "typeName": "小学",
        "id": 770
    },
    {
        "type": 1,
        "name": "北京市房山区良乡行宫园学校",
        "phone": 89351495,
        "address": "北京市房山区拱辰街道办事处行宫园社区居委会",
        "typeName": "小学",
        "id": 771
    },
    {
        "type": 1,
        "name": "北京市燕山东风小学",
        "phone": 69344862,
        "address": "北京市房山区燕山东风街16号",
        "typeName": "小学",
        "id": 772
    },
    {
        "type": 1,
        "name": "北京市房山区燕山羊耳峪小学",
        "phone": 69336750,
        "address": "北京市房山区燕山羊耳峪东里1号",
        "typeName": "小学",
        "id": 773
    },
    {
        "type": 1,
        "name": "北京市燕山向阳小学",
        "phone": 81334210,
        "address": "北京市房山区燕山迎风街道五巷3号",
        "typeName": "小学",
        "id": 774
    },
    {
        "type": 1,
        "name": "北京市燕山前进第二小学",
        "phone": 69345031,
        "address": "北京市房山区燕山迎风杏花西里40号",
        "typeName": "小学",
        "id": 775
    },
    {
        "type": 1,
        "name": "北京市房山区燕山星城小学",
        "phone": 69347554,
        "address": "北京市房山区燕山星城健德一里41号",
        "typeName": "小学",
        "id": 776
    },
    {
        "type": 1,
        "name": "北京市通州区马驹桥学校",
        "phone": "010-60504922",
        "address": "北京市通州区马驹桥镇新海西路88号",
        "typeName": "小学",
        "id": 777
    },
    {
        "type": 1,
        "name": "北京市通州区梨园学校",
        "phone": "010-60523022",
        "address": "北京市通州区梨园镇云景东路399号",
        "typeName": "小学",
        "id": 778
    },
    {
        "type": 1,
        "name": "北京市通州区潞河中学附属学校",
        "phone": "010-81511961",
        "address": "北京市通州区梨园镇半壁店大街23号（怡然世家小区）对面",
        "typeName": "小学",
        "id": 779
    },
    {
        "type": 1,
        "name": "北京市通州区牛堡屯学校",
        "phone": "010-69581391",
        "address": "北京市通州区张家湾镇中街村462号",
        "typeName": "小学",
        "id": 780
    },
    {
        "type": 1,
        "name": "北京市通州区陆辛庄学校",
        "phone": "010-69581109",
        "address": "北京市通州区张家湾镇陆辛庄村573号",
        "typeName": "小学",
        "id": 781
    },
    {
        "type": 1,
        "name": "北京市通州区教师研修中心实验学校",
        "phone": "010-60562767",
        "address": "北京市通州区东关顾家坡1号",
        "typeName": "小学",
        "id": 782
    },
    {
        "type": 1,
        "name": "北京市育才学校通州分校",
        "phone": "010-81576606",
        "address": "北京市通州区梨园镇群芳中二街3号",
        "typeName": "小学",
        "id": 783
    },
    {
        "type": 1,
        "name": "北京市通州区立华学校",
        "phone": 89586122,
        "address": "北京市通州区潞城镇大豆各庄村",
        "typeName": "小学",
        "id": 784
    },
    {
        "type": 1,
        "name": "北京市通州区培智学校",
        "phone": 81524430,
        "address": "北京市通州区运河西大街6号",
        "typeName": "小学",
        "id": 785
    },
    {
        "type": 1,
        "name": "北京市通州区台湖学校",
        "phone": 61536208,
        "address": "北京市通州区台湖镇台湖村台湖学校",
        "typeName": "小学",
        "id": 786
    },
    {
        "type": 1,
        "name": "北京市通州区第四中学",
        "phone": "010-69522231",
        "address": "北京市通州区新华西街45号",
        "typeName": "小学",
        "id": 787
    },
    {
        "type": 1,
        "name": "北京市通州区第二中学小学部",
        "phone": "010-69544963",
        "address": "北京市通州区玉带河西街28号",
        "typeName": "小学",
        "id": 788
    },
    {
        "type": 1,
        "name": "北京教育科学研究院通州区第一实验小学",
        "phone": "010-81526570",
        "address": "北京市通州区梨园镇刘老村西",
        "typeName": "小学",
        "id": 789
    },
    {
        "type": 1,
        "name": "北京市通州区玉桥小学",
        "phone": "010-81587322",
        "address": "北京市通州区玉桥东路1号",
        "typeName": "小学",
        "id": 790
    },
    {
        "type": 1,
        "name": "北京市通州区民族小学",
        "phone": "010-69553754",
        "address": "北京市通州区回民胡同47号",
        "typeName": "小学",
        "id": 791
    },
    {
        "type": 1,
        "name": "北京市通州区东方小学",
        "phone": "010-69542593",
        "address": "通州区西顺城街甲32号",
        "typeName": "小学",
        "id": 792
    },
    {
        "type": 1,
        "name": "北京小学通州分校",
        "phone": "010-80853103",
        "address": "通州区芙蓉园313号",
        "typeName": "小学",
        "id": 793
    },
    {
        "type": 1,
        "name": "北京市通州区永乐店镇中心小学",
        "phone": "69568681-801",
        "address": "北京市通州区永乐店镇永三村北",
        "typeName": "小学",
        "id": 794
    },
    {
        "type": 1,
        "name": "北京市通州区永乐店镇小务小学",
        "phone": "010-80551547",
        "address": "北京市通州区永乐店镇小务村410号",
        "typeName": "小学",
        "id": 795
    },
    {
        "type": 1,
        "name": "北京市通州区德仁务小学",
        "phone": 69569364,
        "address": "北京市通州区永乐店镇德仁务村",
        "typeName": "小学",
        "id": 796
    },
    {
        "type": 1,
        "name": "北京市通州区永乐店镇柴厂屯小学",
        "phone": "010-80511545",
        "address": "北京市通州区永乐店镇柴厂屯村682号",
        "typeName": "小学",
        "id": 797
    },
    {
        "type": 1,
        "name": "北京市通州区北苑小学",
        "phone": "010-69542948",
        "address": "北京市通州区北苑南路22号",
        "typeName": "小学",
        "id": 798
    },
    {
        "type": 1,
        "name": "北京市通州区漷县镇中心小学",
        "phone": "010-80586050",
        "address": "北京市通州区漷县镇漷兴一街临15号",
        "typeName": "小学",
        "id": 799
    },
    {
        "type": 1,
        "name": "北京市通州区东定安小学",
        "phone": "010-80566385",
        "address": "北京市通州区漷县镇东定安村",
        "typeName": "小学",
        "id": 800
    },
    {
        "type": 1,
        "name": "北京市通州区漷县镇草厂小学",
        "phone": "010-69569035",
        "address": "北京市通州区漷县镇草厂村",
        "typeName": "小学",
        "id": 801
    },
    {
        "type": 1,
        "name": "北京市通州区漷县镇马头小学",
        "phone": "010-80599811",
        "address": "北京市通州区漷县镇马堤村东口",
        "typeName": "小学",
        "id": 802
    },
    {
        "type": 1,
        "name": "北京市通州区漷县镇靛庄小学",
        "phone": "010-80586567",
        "address": "北京市通州区漷县镇靛庄村",
        "typeName": "小学",
        "id": 803
    },
    {
        "type": 1,
        "name": "北京市通州区漷县镇觅子店小学",
        "phone": "010-80566040",
        "address": "北京市通州区漷县镇尚武集村",
        "typeName": "小学",
        "id": 804
    },
    {
        "type": 1,
        "name": "北京市通州区梨园镇大稿新村小学",
        "phone": 81566724,
        "address": "通州区梨园镇大稿新村小区内",
        "typeName": "小学",
        "id": 805
    },
    {
        "type": 1,
        "name": "北京市通州区梨园镇中心小学",
        "phone": "010-81536330",
        "address": "北京市通州区群芳中一街",
        "typeName": "小学",
        "id": 806
    },
    {
        "type": 1,
        "name": "北京市通州区西集镇大灰店小学",
        "phone": "010-61576408",
        "address": "北京市通州区西集镇大灰店村西",
        "typeName": "小学",
        "id": 807
    },
    {
        "type": 1,
        "name": "北京市通州区西集镇杜柳棵小学",
        "phone": "010-61558108",
        "address": "北京市通州区西集镇杜柳棵村",
        "typeName": "小学",
        "id": 808
    },
    {
        "type": 1,
        "name": "北京市通州区芙蓉小学",
        "phone": "010-89535016",
        "address": "北京市通州区通胡大街70号",
        "typeName": "小学",
        "id": 809
    },
    {
        "type": 1,
        "name": "北京市通州区西集镇郎府小学",
        "phone": "010-61558074",
        "address": "北京市通州区西集镇郎东村",
        "typeName": "小学",
        "id": 810
    },
    {
        "type": 1,
        "name": "北京市通州区西集镇沙古堆小学",
        "phone": "010-61558162",
        "address": "北京市通州区西集镇沙古堆村",
        "typeName": "小学",
        "id": 811
    },
    {
        "type": 1,
        "name": "北京第二实验小学通州分校",
        "phone": 56542838,
        "address": "北京市通州区砖厂北里39号",
        "typeName": "小学",
        "id": 812
    },
    {
        "type": 1,
        "name": "北京市史家小学通州分校",
        "phone": "010-81590956",
        "address": "北京市通州区玉桥东小区A1区",
        "typeName": "小学",
        "id": 813
    },
    {
        "type": 1,
        "name": "北京市通州区潞城镇中心小学",
        "phone": "010-80854802",
        "address": "北京市通州区潞城镇七级村南",
        "typeName": "小学",
        "id": 814
    },
    {
        "type": 1,
        "name": "北京市通州区潞城镇胡各庄小学",
        "phone": "010-89581581",
        "address": "北京市通州区潞城镇胡各庄村",
        "typeName": "小学",
        "id": 815
    },
    {
        "type": 1,
        "name": "北京市通州区潞城镇卜落垡小学",
        "phone": "010-61521714",
        "address": "北京市通州区潞城镇卜落垡村东南侧",
        "typeName": "小学",
        "id": 816
    },
    {
        "type": 1,
        "name": "北京市通州区潞城镇大豆各庄小学",
        "phone": "010-61521334",
        "address": "北京市通州区潞城镇大豆各庄小学",
        "typeName": "小学",
        "id": 817
    },
    {
        "type": 1,
        "name": "北京市通州区潞城镇大东各庄小学",
        "phone": "010-61521136",
        "address": "北京市通州区潞城镇大东各庄村",
        "typeName": "小学",
        "id": 818
    },
    {
        "type": 1,
        "name": "北京市通州区南关小学",
        "phone": "010-80886253",
        "address": "北京市通州区东营后街9号",
        "typeName": "小学",
        "id": 819
    },
    {
        "type": 1,
        "name": "北京市通州区宋庄镇中心小学",
        "phone": "010-69597892",
        "address": "北京市通州区宋庄镇中心小学",
        "typeName": "小学",
        "id": 820
    },
    {
        "type": 1,
        "name": "北京市通州区宋庄镇徐辛庄小学",
        "phone": "010-89563232",
        "address": "北京市通州区宋庄镇徐辛庄村",
        "typeName": "小学",
        "id": 821
    },
    {
        "type": 1,
        "name": "北京市通州区宋庄镇翟里小学",
        "phone": "010-89573246",
        "address": "北京市通州区宋庄镇翟里小学",
        "typeName": "小学",
        "id": 822
    },
    {
        "type": 1,
        "name": "北京市通州区宋庄镇葛渠小学",
        "phone": "010-89513390",
        "address": "北京市通州区宋庄镇葛渠村",
        "typeName": "小学",
        "id": 823
    },
    {
        "type": 1,
        "name": "北京市通州区宋庄镇富豪小学",
        "phone": "010-89551130",
        "address": "北京市通州区宋庄镇富豪村",
        "typeName": "小学",
        "id": 824
    },
    {
        "type": 1,
        "name": "北京市通州区宋庄镇北寺庄小学",
        "phone": "010-89571779",
        "address": "北京市通州区宋庄镇北寺村",
        "typeName": "小学",
        "id": 825
    },
    {
        "type": 1,
        "name": "北京市通州区宋庄镇师姑庄小学",
        "phone": "010-89513799",
        "address": "北京市通州区宋庄镇师姑庄村",
        "typeName": "小学",
        "id": 826
    },
    {
        "type": 1,
        "name": "北京市通州区西集镇中心小学",
        "phone": "010-61576249",
        "address": "北京市通州区西集镇政府街25号",
        "typeName": "小学",
        "id": 827
    },
    {
        "type": 1,
        "name": "北京市通州区西集镇肖林小学",
        "phone": "010-61576144",
        "address": "北京市通州区西集镇肖林村南",
        "typeName": "小学",
        "id": 828
    },
    {
        "type": 1,
        "name": "北京市通州区西集镇新东仪小学",
        "phone": "010-61576064",
        "address": "北京市通州区西集镇黄东仪村南",
        "typeName": "小学",
        "id": 829
    },
    {
        "type": 1,
        "name": "北京市通州区永顺镇发电厂小学",
        "phone": "010-61563183",
        "address": "北京市通州区永顺镇小圣庙村",
        "typeName": "小学",
        "id": 830
    },
    {
        "type": 1,
        "name": "北京市通州区永顺镇范庄小学",
        "phone": "010-69553172",
        "address": "北京市通州区范庄村（通州区西潞苑小区北侧）",
        "typeName": "小学",
        "id": 831
    },
    {
        "type": 1,
        "name": "北京市通州区永顺镇龙旺庄小学",
        "phone": "010-89519604",
        "address": "北京市通州区永顺镇潞邑西路龙旺庄学校",
        "typeName": "小学",
        "id": 832
    },
    {
        "type": 1,
        "name": "北京市通州区永顺镇乔庄小学",
        "phone": "010-81585845",
        "address": "北京市通州区运乔嘉园",
        "typeName": "小学",
        "id": 833
    },
    {
        "type": 1,
        "name": "北京市通州区永顺镇西马庄小学",
        "phone": "010-60513380",
        "address": "北京市通州区永顺镇西马庄村",
        "typeName": "小学",
        "id": 834
    },
    {
        "type": 1,
        "name": "北京市通州区永顺镇中心小学",
        "phone": "010-81564281",
        "address": "北京市通州区永顺镇杨庄村",
        "typeName": "小学",
        "id": 835
    },
    {
        "type": 1,
        "name": "北京市通州区运河小学",
        "phone": "010-81513227",
        "address": "北京市通州区运河西大街246号",
        "typeName": "小学",
        "id": 836
    },
    {
        "type": 1,
        "name": "北京市通州区张家湾镇中心小学",
        "phone": "010-69572781",
        "address": "北京市通州区张家湾镇开发区广聚街12号",
        "typeName": "小学",
        "id": 837
    },
    {
        "type": 1,
        "name": "北京市通州区张家湾镇上店小学",
        "phone": "010-61509861",
        "address": "北京市通州区张家湾镇上店村423号",
        "typeName": "小学",
        "id": 838
    },
    {
        "type": 1,
        "name": "北京市通州区张家湾镇枣林庄民族小学",
        "phone": "010-69573469",
        "address": "北京市通州区张家湾镇枣林庄村",
        "typeName": "小学",
        "id": 839
    },
    {
        "type": 1,
        "name": "北京市通州区张家湾镇民族小学",
        "phone": "010-61506950",
        "address": "北京市通州区张家湾镇",
        "typeName": "小学",
        "id": 840
    },
    {
        "type": 1,
        "name": "北京市通州区张家湾镇张湾村民族小学",
        "phone": "010-61561781",
        "address": "北京市通州区张家湾张湾村",
        "typeName": "小学",
        "id": 841
    },
    {
        "type": 1,
        "name": "北京市通州区贡院小学",
        "phone": 69520882,
        "address": "北京市通州区新华大街西海子西路贡院小学",
        "typeName": "小学",
        "id": 842
    },
    {
        "type": 1,
        "name": "北京市通州区后南仓小学",
        "phone": "010-69554153",
        "address": "北京市通州区新仓路53号",
        "typeName": "小学",
        "id": 843
    },
    {
        "type": 1,
        "name": "北京市通州区临河里小学",
        "phone": 81594962,
        "address": "北京市通州区临河里58号",
        "typeName": "小学",
        "id": 844
    },
    {
        "type": 1,
        "name": "北京市通州区于家务乡中心小学",
        "phone": 80531942,
        "address": "北京市通州区于家务乡于家务村",
        "typeName": "小学",
        "id": 845
    },
    {
        "type": 1,
        "name": "北京市通州区于家务乡渠头小学",
        "phone": 80521541,
        "address": "北京市通州区于家务乡渠头村",
        "typeName": "小学",
        "id": 846
    },
    {
        "type": 1,
        "name": "北京市通州区于家务乡西垡小学",
        "phone": 80521422,
        "address": "北京市通州区于家务乡西垡村",
        "typeName": "小学",
        "id": 847
    },
    {
        "type": 1,
        "name": "北京市通州区漷县镇侯黄庄小学",
        "phone": 80563268,
        "address": "北京市通州区漷县镇侯黄庄村",
        "typeName": "小学",
        "id": 848
    },
    {
        "type": 1,
        "name": "北京市通州区台湖镇中心小学",
        "phone": 69507134,
        "address": "北京市通州区台湖镇次渠大街",
        "typeName": "小学",
        "id": 849
    },
    {
        "type": 1,
        "name": "北京市通州区次渠家园小学",
        "phone": 81507073,
        "address": "北京市通州区次渠北里131号",
        "typeName": "小学",
        "id": 850
    },
    {
        "type": 1,
        "name": "北京市通州区张家湾镇张辛庄小学",
        "phone": "010-61507651",
        "address": "通州区张家湾镇张辛庄村",
        "typeName": "小学",
        "id": 851
    },
    {
        "type": 1,
        "name": "北京市通州区焦王庄小学",
        "phone": 89597737,
        "address": "通州区永顺镇焦王庄村陈列馆路466号",
        "typeName": "小学",
        "id": 852
    },
    {
        "type": 1,
        "name": "北京市通州区马驹桥镇中心小学",
        "phone": 60500123,
        "address": "北京市通州区马驹桥镇兴华大街9号",
        "typeName": "小学",
        "id": 853
    },
    {
        "type": 1,
        "name": "北京市通州区马驹桥镇大杜社小学",
        "phone": 61586608,
        "address": "北京市通州区马驹桥镇大杜社村",
        "typeName": "小学",
        "id": 854
    },
    {
        "type": 1,
        "name": "北京市通州区马驹桥镇金桥小学",
        "phone": 61530882,
        "address": "北京市通州区马驹桥景晟北二街",
        "typeName": "小学",
        "id": 855
    },
    {
        "type": 1,
        "name": "北京市通州区马驹桥镇小张湾小学",
        "phone": 60500604,
        "address": "北京市通州区马驹桥镇小张湾村",
        "typeName": "小学",
        "id": 856
    },
    {
        "type": 1,
        "name": "北京市通州区马驹桥镇实验小学",
        "phone": 80829550,
        "address": "北京市通州区二街村15号",
        "typeName": "小学",
        "id": 857
    },
    {
        "type": 1,
        "name": "北京市通州区永顺小学",
        "phone": "010-69544474",
        "address": "通州区永顺南街75号",
        "typeName": "小学",
        "id": 858
    },
    {
        "type": 1,
        "name": "北京市通州区中山街小学",
        "phone": 69544116,
        "address": "北京市通州区新华西街16号",
        "typeName": "小学",
        "id": 859
    },
    {
        "type": 1,
        "name": "北京市通州区官园小学",
        "phone": 69544034,
        "address": "北京市通州区新仓路29号",
        "typeName": "小学",
        "id": 860
    },
    {
        "type": 1,
        "name": "北京新城职业学校(主校区）",
        "phone": 61537151,
        "address": "北京市通州区台湖镇台湖村862号",
        "typeName": "小学",
        "id": 861
    },
    {
        "type": 1,
        "name": "北京新城职业学校（宋庄校区）",
        "phone": 89571329,
        "address": "北京市通州区宋庄镇北寺庄村155号",
        "typeName": "小学",
        "id": 862
    },
    {
        "type": 1,
        "name": "北京市顺义区高丽营学校",
        "phone": 69455654,
        "address": "北京市顺义区高丽营镇四村村委会",
        "typeName": "小学",
        "id": 863
    },
    {
        "type": 1,
        "name": "北京市顺义区海德京华双语学校",
        "phone": "101-80416103",
        "address": "北京市顺义区后沙峪安富街9号",
        "typeName": "小学",
        "id": 864
    },
    {
        "type": 1,
        "name": "北京市鼎石学校",
        "phone": 80496008,
        "address": "北京市顺义区安富街11号",
        "typeName": "小学",
        "id": 865
    },
    {
        "type": 1,
        "name": "北京市海嘉双语学校",
        "phone": "010-80410390",
        "address": "北京市顺义区后沙峪裕民大街1号9-12#",
        "typeName": "小学",
        "id": 866
    },
    {
        "type": 1,
        "name": "北京市牛栏山一中实验学校",
        "phone": "010-81480932",
        "address": "北京市顺义区顺安路99号",
        "typeName": "小学",
        "id": 867
    },
    {
        "type": 1,
        "name": "北京市顺义区青苗学校",
        "phone": 64560618,
        "address": "北京市顺义区天竺镇丽苑街15号",
        "typeName": "小学",
        "id": 868
    },
    {
        "type": 1,
        "name": "北京市新英才学校",
        "phone": "010-80467116",
        "address": "北京市顺义区后沙峪镇安华街九号",
        "typeName": "小学",
        "id": 869
    },
    {
        "type": 1,
        "name": "北京市新府学外国语学校",
        "phone": 89420199,
        "address": "北京市顺义区京顺路99号",
        "typeName": "小学",
        "id": 870
    },
    {
        "type": 1,
        "name": "北京市顺义区板桥中心小学校",
        "phone": 60442174,
        "address": "北京市顺义区赵全营镇板桥村牛板路板桥段1号",
        "typeName": "小学",
        "id": 871
    },
    {
        "type": 1,
        "name": "北京市顺义区北石槽中心小学校",
        "phone": 60422512,
        "address": "北京市顺义区北石槽镇府前街11号",
        "typeName": "小学",
        "id": 872
    },
    {
        "type": 1,
        "name": "北京市顺义区北务中心小学校",
        "phone": 61421934,
        "address": "北京市顺义区北务镇商业街17号",
        "typeName": "小学",
        "id": 873
    },
    {
        "type": 1,
        "name": "北京市顺义区北小营中心小学校",
        "phone": 60483734,
        "address": "北京市顺义区北小营镇北小营村平安路47号",
        "typeName": "小学",
        "id": 874
    },
    {
        "type": 1,
        "name": "北京市顺义区博华外国语学校",
        "phone": 69403302,
        "address": "北京市顺义区双丰街道白各庄村",
        "typeName": "小学",
        "id": 875
    },
    {
        "type": 1,
        "name": "北京市顺义区仓上小学",
        "phone": 81498534,
        "address": "北京市顺义区仁和顺平路仓上小区仓上小学",
        "typeName": "小学",
        "id": 876
    },
    {
        "type": 1,
        "name": "北京市顺义区仇家店中心小学校",
        "phone": 60483729,
        "address": "北京市顺义区北小营镇仇家店村环村北路25号",
        "typeName": "小学",
        "id": 877
    },
    {
        "type": 1,
        "name": "北京市顺义区大孙各庄中心小学校",
        "phone": 61432073,
        "address": "北京市顺义区大孙各庄镇府前东街6号",
        "typeName": "小学",
        "id": 878
    },
    {
        "type": 1,
        "name": "北京市顺义区第一中学附属小学",
        "phone": 60496230,
        "address": "北京市顺义区澜西园二区1号",
        "typeName": "小学",
        "id": 879
    },
    {
        "type": 1,
        "name": "北京市顺义区东风小学",
        "phone": 69445326,
        "address": "北京市顺义区光明南街拥军路9号",
        "typeName": "小学",
        "id": 880
    },
    {
        "type": 1,
        "name": "北京市顺义区港馨小学",
        "phone": 89449879,
        "address": "北京市顺义区港馨家园小区（东区）",
        "typeName": "小学",
        "id": 881
    },
    {
        "type": 1,
        "name": "北京市顺义区高丽营第二小学",
        "phone": "69491856-8021",
        "address": "北京市顺义区高丽营镇张喜庄——拓新区13号",
        "typeName": "小学",
        "id": 882
    },
    {
        "type": 1,
        "name": "北京市顺义区光明小学",
        "phone": 69422329,
        "address": "顺义区东安路1号",
        "typeName": "小学",
        "id": 883
    },
    {
        "type": 1,
        "name": "北京市顺义区河南村中心小学校",
        "phone": 89492187,
        "address": "北京市顺义区仁和地区河南村幸福路3号",
        "typeName": "小学",
        "id": 884
    },
    {
        "type": 1,
        "name": "北京市顺义区后沙峪中心小学校",
        "phone": 80416782,
        "address": "北京市顺义区后沙峪镇安富街21号",
        "typeName": "小学",
        "id": 885
    },
    {
        "type": 1,
        "name": "北京市顺义区建新小学",
        "phone": 69433973,
        "address": "北京市顺义区建新南区38号楼",
        "typeName": "小学",
        "id": 886
    },
    {
        "type": 1,
        "name": "北京市顺义区空港小学",
        "phone": 80477519,
        "address": "北京市顺义区空港B区三山新新家园南侧",
        "typeName": "小学",
        "id": 887
    },
    {
        "type": 1,
        "name": "北京市顺义区李各庄学校",
        "phone": 60492697,
        "address": "北京市顺义区木林镇李各庄村育才路2号",
        "typeName": "小学",
        "id": 888
    },
    {
        "type": 1,
        "name": "北京市顺义区李桥中心小学校",
        "phone": 1081478145,
        "address": "北京市顺义区李桥镇馨港庄园三区38号楼",
        "typeName": "小学",
        "id": 889
    },
    {
        "type": 1,
        "name": "北京市顺义区李遂中心小学校",
        "phone": 89481710,
        "address": "北京市顺义区李遂镇南孙路李遂段17号",
        "typeName": "小学",
        "id": 890
    },
    {
        "type": 1,
        "name": "北京市顺义区马坡第二小学",
        "phone": 69407358,
        "address": "北京市顺义区马卷村西",
        "typeName": "小学",
        "id": 891
    },
    {
        "type": 1,
        "name": "北京市顺义区马坡中心小学校",
        "phone": 69401651,
        "address": "北京市顺义区马坡镇政府西侧",
        "typeName": "小学",
        "id": 892
    },
    {
        "type": 1,
        "name": "北京市顺义区明德小学",
        "phone": 60448505,
        "address": "北京市顺义区木林镇马坊村中心街5号",
        "typeName": "小学",
        "id": 893
    },
    {
        "type": 1,
        "name": "北京市顺义区木林中心小学校",
        "phone": 60456039,
        "address": "顺义区木林镇木林村东",
        "typeName": "小学",
        "id": 894
    },
    {
        "type": 1,
        "name": "北京市顺义区南彩第二小学",
        "phone": 89477267,
        "address": "北京市顺义区顺平路俸伯段4号",
        "typeName": "小学",
        "id": 895
    },
    {
        "type": 1,
        "name": "北京市顺义区南彩实验学校",
        "phone": 60418001,
        "address": "北京市顺义区南彩镇柳行村",
        "typeName": "小学",
        "id": 896
    },
    {
        "type": 1,
        "name": "北京市顺义区南法信中心小学校",
        "phone": 69473552,
        "address": "北京市顺义区顺三路三号",
        "typeName": "小学",
        "id": 897
    },
    {
        "type": 1,
        "name": "北京市顺义区牛栏山第二小学",
        "phone": 61427791,
        "address": "北京市顺义区牛栏山镇下坡屯家园三区甲10号",
        "typeName": "小学",
        "id": 898
    },
    {
        "type": 1,
        "name": "北京市顺义区牛栏山第三小学",
        "phone": 60428973,
        "address": "北京市顺义区牛栏山镇龙湖香醍漫步庄园三区16号楼",
        "typeName": "小学",
        "id": 899
    },
    {
        "type": 1,
        "name": "北京市顺义区牛栏山第一小学",
        "phone": 69411083,
        "address": "北京市顺义区牛栏山镇牛富路2号",
        "typeName": "小学",
        "id": 900
    },
    {
        "type": 1,
        "name": "北京市顺义区仁和中心小学校",
        "phone": "69447725-8008",
        "address": "北京市顺义区顺西路17号",
        "typeName": "小学",
        "id": 901
    },
    {
        "type": 1,
        "name": "北京市顺义区沙岭学校",
        "phone": 61443818,
        "address": "北京市顺义区顺平路沙岭段青年路5号",
        "typeName": "小学",
        "id": 902
    },
    {
        "type": 1,
        "name": "北京市顺义区石园小学",
        "phone": 69425729,
        "address": "北京市顺义区石园北区石园小学",
        "typeName": "小学",
        "id": 903
    },
    {
        "type": 1,
        "name": "北京市顺义区双兴小学",
        "phone": 81493907,
        "address": "北京市顺义区光明北街22号",
        "typeName": "小学",
        "id": 904
    },
    {
        "type": 1,
        "name": "北京市顺义区天竺第二小学",
        "phone": "010-84166244",
        "address": "北京市顺义区天竺翠竹新村31号楼",
        "typeName": "小学",
        "id": 905
    },
    {
        "type": 1,
        "name": "北京市顺义区天竺第一小学",
        "phone": 80467236,
        "address": "北京市顺义区天竺镇府右街7号",
        "typeName": "小学",
        "id": 906
    },
    {
        "type": 1,
        "name": "北京市顺义区西辛小学",
        "phone": "69447725-8008",
        "address": "北京市顺义区西辛南区（顺西路12号）",
        "typeName": "小学",
        "id": 907
    },
    {
        "type": 1,
        "name": "北京市顺义区小店中心小学校",
        "phone": 61412824,
        "address": "北京市顺义区杨镇辛庄子村小学路4号",
        "typeName": "小学",
        "id": 908
    },
    {
        "type": 1,
        "name": "北京市顺义区沿河中心小学校",
        "phone": 69489636,
        "address": "北京市顺义区李桥镇任李路沿河段115#",
        "typeName": "小学",
        "id": 909
    },
    {
        "type": 1,
        "name": "北京市顺义区杨镇中心小学校",
        "phone": 61451244,
        "address": "北京市顺义区杨镇环镇东路12号",
        "typeName": "小学",
        "id": 910
    },
    {
        "type": 1,
        "name": "北京市顺义区裕达隆小学",
        "phone": "010-80489121",
        "address": "北京市顺义区空港工业A区天柱西路28号",
        "typeName": "小学",
        "id": 911
    },
    {
        "type": 1,
        "name": "北京市顺义区裕龙小学（现代学院校址）",
        "phone": 60414976,
        "address": "北京市顺义区裕龙二街南侧",
        "typeName": "小学",
        "id": 912
    },
    {
        "type": 1,
        "name": "北京市顺义区裕龙小学（裕龙校址）",
        "phone": 69468268,
        "address": "北京市顺义区拥军路1号",
        "typeName": "小学",
        "id": 913
    },
    {
        "type": 1,
        "name": "北京市顺义区张镇中心小学校",
        "phone": 61480604,
        "address": "北京市顺义区张孙路张镇段2号",
        "typeName": "小学",
        "id": 914
    },
    {
        "type": 1,
        "name": "北京市顺义区赵全营中心小学校",
        "phone": 60431160,
        "address": "北京市顺义区赵全营镇赵全营村西南(顺义区牛板路赵全营段92号）",
        "typeName": "小学",
        "id": 915
    },
    {
        "type": 1,
        "name": "首都师范大学附属顺义实验小学",
        "phone": 106944310,
        "address": "北京市顺义区顺兴街11号院21号楼",
        "typeName": "小学",
        "id": 916
    },
    {
        "type": 1,
        "name": "北京市顺义区空港第二小学",
        "phone": 80460160,
        "address": "北京市顺义区空港地区天裕昕园西区",
        "typeName": "小学",
        "id": 917
    },
    {
        "type": 1,
        "name": "北京市顺义区龙湾屯中心小学校",
        "phone": 60461289,
        "address": "北京市顺义区龙湾屯镇府南路8号",
        "typeName": "小学",
        "id": 918
    },
    {
        "type": 1,
        "name": "北京市顺义区南彩第一小学",
        "phone": 89469285,
        "address": "北京市顺义区南彩镇南彩村东",
        "typeName": "小学",
        "id": 919
    },
    {
        "type": 1,
        "name": "北京教育学院附属大兴实验小学",
        "phone": 89256664,
        "address": "北京市大兴区罗奇营路23号院",
        "typeName": "小学",
        "id": 920
    },
    {
        "type": 1,
        "name": "北京市大兴区兴华中学仰山校区",
        "phone": 60208802,
        "address": "大兴区黄村镇金星西路",
        "typeName": "小学",
        "id": 921
    },
    {
        "type": 1,
        "name": "北京景山学校大兴实验学校",
        "phone": 80253660,
        "address": "北京市大兴区双高路1号",
        "typeName": "小学",
        "id": 922
    },
    {
        "type": 1,
        "name": "北京师范大学大兴附属中学（东校区）",
        "phone": 89269526,
        "address": "大兴区黄村镇清泰路3号",
        "typeName": "小学",
        "id": 923
    },
    {
        "type": 1,
        "name": "国家教育行政学院附属实验学校",
        "phone": 61219675,
        "address": "北京市大兴区黄村镇三合路一号",
        "typeName": "小学",
        "id": 924
    },
    {
        "type": 1,
        "name": "首都师范大学附属中学大兴南校区",
        "phone": 89256538,
        "address": "北京市大兴区北臧村镇永旺西路与百利大街交叉口",
        "typeName": "小学",
        "id": 925
    },
    {
        "type": 1,
        "name": "北京市大兴区兴海学校",
        "phone": "010-80053103",
        "address": "北京市大兴区西红门地区办事处西红门三村村委会",
        "typeName": "小学",
        "id": 926
    },
    {
        "type": 1,
        "name": "北京市大兴区金海学校",
        "phone": 61288948,
        "address": "北京市大兴区西红门镇金星庄团河路",
        "typeName": "小学",
        "id": 927
    },
    {
        "type": 1,
        "name": "北京市大兴区永华实验学校",
        "phone": 86393700,
        "address": "北京市大兴区黄村镇林校路街道义和庄东里社区",
        "typeName": "小学",
        "id": 928
    },
    {
        "type": 1,
        "name": "北京市第二中学亦庄学校（南校区）",
        "phone": 67876993,
        "address": "北京经济技术开发区天宝北街甲2号",
        "typeName": "小学",
        "id": 929
    },
    {
        "type": 1,
        "name": "首都师范大学附属中学大兴北校区",
        "phone": 60209833,
        "address": "北京市大兴区黄村镇兴和街10号",
        "typeName": "小学",
        "id": 930
    },
    {
        "type": 1,
        "name": "北京教育学院附属大兴实验小学融汇校区",
        "phone": 61259827,
        "address": "北京市大兴区华佗路1号院",
        "typeName": "小学",
        "id": 931
    },
    {
        "type": 1,
        "name": "北京市大兴区第一小学",
        "phone": "010－69246005",
        "address": "大兴区兴华大街三段三号",
        "typeName": "小学",
        "id": 932
    },
    {
        "type": 1,
        "name": "北京市大兴区旧宫镇第二中心小学",
        "phone": 67976476,
        "address": "大兴区旧宫镇德义街1号",
        "typeName": "小学",
        "id": 933
    },
    {
        "type": 1,
        "name": "北京市大兴区旧宫镇第二中心小学芳源里校区",
        "phone": 67948257,
        "address": "大兴区旧宫镇芳源里小区11号",
        "typeName": "小学",
        "id": 934
    },
    {
        "type": 1,
        "name": "北京市大兴区黄村镇第一中心小学",
        "phone": 69242237,
        "address": "北京市大兴区黄村镇金华寺东路3号",
        "typeName": "小学",
        "id": 935
    },
    {
        "type": 1,
        "name": "北京市大兴区黄村镇第一中心小学福苑校区",
        "phone": 60213066,
        "address": "北京市大兴区工业开发区科苑路8号",
        "typeName": "小学",
        "id": 936
    },
    {
        "type": 1,
        "name": "北京市大兴区黄村镇第一中心小学天堂河完小",
        "phone": 60278740,
        "address": "北京市大兴区黄村镇天利路3号",
        "typeName": "小学",
        "id": 937
    },
    {
        "type": 1,
        "name": "北京市大兴区黄村镇狼各庄民族小学",
        "phone": 60275725,
        "address": "北京市大兴区黄村镇狼各庄西村",
        "typeName": "小学",
        "id": 938
    },
    {
        "type": 1,
        "name": "北京市大兴区黄村镇观音寺完全小学",
        "phone": 69231279,
        "address": "大兴区黄村镇观音寺街8号",
        "typeName": "小学",
        "id": 939
    },
    {
        "type": 1,
        "name": "北京市大兴区第八小学",
        "phone": "69252212-8001",
        "address": "北京市大兴区红楼西巷11号",
        "typeName": "小学",
        "id": 940
    },
    {
        "type": 1,
        "name": "北京市大兴区采育镇第三中心小学",
        "phone": 80202845,
        "address": "北京市大兴区采育镇凤河营村",
        "typeName": "小学",
        "id": 941
    },
    {
        "type": 1,
        "name": "北京市大兴区亦庄镇第二中心小学",
        "phone": 67883319,
        "address": "北京市大兴区亦庄镇博兴八路东侧",
        "typeName": "小学",
        "id": 942
    },
    {
        "type": 1,
        "name": "北京市大兴区瀛海镇第一中心小学",
        "phone": 69278013,
        "address": "北京市大兴区瀛海地区办事处西一村村委会",
        "typeName": "小学",
        "id": 943
    },
    {
        "type": 1,
        "name": "北京小学翡翠城分校",
        "phone": 58370702,
        "address": "北京市大兴区兴盛街146号",
        "typeName": "小学",
        "id": 944
    },
    {
        "type": 1,
        "name": "北京小学翡翠城分校南校区",
        "phone": 58396411,
        "address": "北京市大兴区黄村镇成庄南巷10号",
        "typeName": "小学",
        "id": 945
    },
    {
        "type": 1,
        "name": "北京市大兴区榆垡镇第二中心小学",
        "phone": 89215647,
        "address": "北京市大兴区榆垡镇南张华村",
        "typeName": "小学",
        "id": 946
    },
    {
        "type": 1,
        "name": "北京市大兴区榆垡镇第二中心小学曹各庄完小",
        "phone": 89261513,
        "address": "北京市大兴区榆垡镇曹各庄村",
        "typeName": "小学",
        "id": 947
    },
    {
        "type": 1,
        "name": "北京市大兴区榆垡镇第二中心小学小店完小",
        "phone": 89261183,
        "address": "北京市大兴区榆垡镇小店村",
        "typeName": "小学",
        "id": 948
    },
    {
        "type": 1,
        "name": "北京市大兴区第七中学小学部",
        "phone": 61239436,
        "address": "北京市大兴区黄村镇西芦城村",
        "typeName": "小学",
        "id": 949
    },
    {
        "type": 1,
        "name": "北京印刷学院附属小学",
        "phone": 69238190,
        "address": "北京市大兴区黄村镇康庄东巷1号",
        "typeName": "小学",
        "id": 950
    },
    {
        "type": 1,
        "name": "北京市育才学校大兴分校",
        "phone": 50929138,
        "address": "北京市大兴区纪百户街9号",
        "typeName": "小学",
        "id": 951
    },
    {
        "type": 1,
        "name": "北京市大兴区庞各庄镇第一中心小学",
        "phone": 89282155,
        "address": "大兴区庞各庄镇庞各庄中学A区",
        "typeName": "小学",
        "id": 952
    },
    {
        "type": 1,
        "name": "北京市大兴区庞各庄镇薛营回民小学",
        "phone": "010-89287749",
        "address": "北京市大兴区庞各庄镇薛营村",
        "typeName": "小学",
        "id": 953
    },
    {
        "type": 1,
        "name": "北京市大兴区庞各庄镇北顿垡完小",
        "phone": 89288203,
        "address": "北京市大兴区庞各庄镇北顿垡村",
        "typeName": "小学",
        "id": 954
    },
    {
        "type": 1,
        "name": "北京市大兴区庞各庄镇庆国完全小学",
        "phone": "010-89288143",
        "address": "北京市大兴区庞各庄镇宋各庄村东",
        "typeName": "小学",
        "id": 955
    },
    {
        "type": 1,
        "name": "北京市大兴区庞各庄镇第一中心小学南顿垡小学",
        "phone": 1089228143,
        "address": "北京市大兴区庞各庄镇南顿垡小学",
        "typeName": "小学",
        "id": 956
    },
    {
        "type": 1,
        "name": "北京市大兴区团河小学",
        "phone": 61299583,
        "address": "大兴区团河苑小区",
        "typeName": "小学",
        "id": 957
    },
    {
        "type": 1,
        "name": "北京市大兴区长子营镇第二中心小学",
        "phone": 80219499,
        "address": "北京市大兴区朱庄村",
        "typeName": "小学",
        "id": 958
    },
    {
        "type": 1,
        "name": "北京市大兴区榆垡镇第一中心小学",
        "phone": 89213453,
        "address": "北京市大兴区榆垡镇榆祥路1号",
        "typeName": "小学",
        "id": 959
    },
    {
        "type": 1,
        "name": "北京市大兴区第三小学",
        "phone": 69242111,
        "address": "北京市大兴区兴丰大街三段76号",
        "typeName": "小学",
        "id": 960
    },
    {
        "type": 1,
        "name": "北京市大兴区滨河小学",
        "phone": 69234313,
        "address": "北京市大兴区滨河西里",
        "typeName": "小学",
        "id": 961
    },
    {
        "type": 1,
        "name": "北京市大兴区长子营镇第一中心小学",
        "phone": 80265140,
        "address": "北京市大兴区长子营镇沁水营村北",
        "typeName": "小学",
        "id": 962
    },
    {
        "type": 1,
        "name": "北京市大兴区黄村镇第二中心小学",
        "phone": 61222208,
        "address": "北京市大兴区黄村镇狼垡长丰园一区",
        "typeName": "小学",
        "id": 963
    },
    {
        "type": 1,
        "name": "北京市大兴区枣园小学",
        "phone": 81290298,
        "address": "北京市大兴区黄村镇枣园小区临甲29号楼",
        "typeName": "小学",
        "id": 964
    },
    {
        "type": 1,
        "name": "北京市大兴区亦庄镇第一中心小学",
        "phone": 67882243,
        "address": "北京市大兴区亦庄镇贵园北路6号",
        "typeName": "小学",
        "id": 965
    },
    {
        "type": 1,
        "name": "北京八中大兴分校（小学部）",
        "phone": 87396468,
        "address": "北京市大兴区亦庄镇渠羊路临18号",
        "typeName": "小学",
        "id": 966
    },
    {
        "type": 1,
        "name": "北京市大兴区北臧村镇中心小学",
        "phone": 60276134,
        "address": "北京市大兴区北臧村镇诸营村北",
        "typeName": "小学",
        "id": 967
    },
    {
        "type": 1,
        "name": "北京市大兴区北臧村镇皮各庄小学",
        "phone": 60276151,
        "address": "北京市大兴区北臧村镇皮各庄村",
        "typeName": "小学",
        "id": 968
    },
    {
        "type": 1,
        "name": "北京市大兴区北臧村镇马村小学",
        "phone": 61259546,
        "address": "北京市大兴区北臧村镇马村村东",
        "typeName": "小学",
        "id": 969
    },
    {
        "type": 1,
        "name": "北京市大兴区庞各庄镇第二中心小学",
        "phone": 89251248,
        "address": "北京市大兴区庞各庄镇定福庄村",
        "typeName": "小学",
        "id": 970
    },
    {
        "type": 1,
        "name": "北京市大兴区庞各庄镇第二中心小学张公垡完小",
        "phone": 89251074,
        "address": "北京市大兴区庞各庄镇张公垡村",
        "typeName": "小学",
        "id": 971
    },
    {
        "type": 1,
        "name": "北京市大兴区庞各庄镇第二中心小学赵村完小",
        "phone": 89259800,
        "address": "北京市大兴区庞各庄镇赵村村",
        "typeName": "小学",
        "id": 972
    },
    {
        "type": 1,
        "name": "北京市大兴区庞各庄镇第二中心小学梁家务完小",
        "phone": 89251084,
        "address": "北京市大兴区庞各庄镇梁家务村",
        "typeName": "小学",
        "id": 973
    },
    {
        "type": 1,
        "name": "北京市大兴区庞各庄镇第二中心小学留民庄完小",
        "phone": 89255544,
        "address": "北京市大兴区庞各庄镇留民庄村",
        "typeName": "小学",
        "id": 974
    },
    {
        "type": 1,
        "name": "北京市大兴区采育镇第二中心小学",
        "phone": "010—80209734",
        "address": "北京市大兴区采育镇大皮营一寸",
        "typeName": "小学",
        "id": 975
    },
    {
        "type": 1,
        "name": "北京市大兴区第九小学",
        "phone": 69241877,
        "address": "北京市大兴区黄村镇清源西里小区",
        "typeName": "小学",
        "id": 976
    },
    {
        "type": 1,
        "name": "北京市大兴区第五小学",
        "phone": 69243090,
        "address": "兴丰大街商场南巷--北京市大兴区第五小学南校区",
        "typeName": "小学",
        "id": 977
    },
    {
        "type": 1,
        "name": "北京市大兴区第五小学（北校区）",
        "phone": 69243090,
        "address": "兴华北路（二段）78号院158号--北京市大兴区第五小学北校区",
        "typeName": "小学",
        "id": 978
    },
    {
        "type": 1,
        "name": "大兴区黄村镇第三中心小学",
        "phone": 61267247,
        "address": "北京市大兴区黄村镇孙村",
        "typeName": "小学",
        "id": 979
    },
    {
        "type": 1,
        "name": "北京教育科学研究院大兴实验小学",
        "phone": 81296379,
        "address": "北京市大兴区黄村镇兴泰街5号17楼",
        "typeName": "小学",
        "id": 980
    },
    {
        "type": 1,
        "name": "北京市大兴区安定镇中心小学",
        "phone": 80236196,
        "address": "北京市大兴区定康路6号安定法庭",
        "typeName": "小学",
        "id": 981
    },
    {
        "type": 1,
        "name": "北京市大兴区安定镇西芦各庄完全小学",
        "phone": 89231042,
        "address": "北京市大兴区安定镇西芦各庄村南",
        "typeName": "小学",
        "id": 982
    },
    {
        "type": 1,
        "name": "北京市大兴区安定镇通州马坊完全小学",
        "phone": 80239592,
        "address": "北京市大兴区安定镇通州马坊村",
        "typeName": "小学",
        "id": 983
    },
    {
        "type": 1,
        "name": "北京市大兴区安定镇后安定完全小学",
        "phone": 80232194,
        "address": "北京市大兴区安定镇后安定村",
        "typeName": "小学",
        "id": 984
    },
    {
        "type": 1,
        "name": "北京市大兴区安定镇东白塔民族小学",
        "phone": 80231764,
        "address": "北京市大兴区安定镇东白塔村南街10号",
        "typeName": "小学",
        "id": 985
    },
    {
        "type": 1,
        "name": "北京亦庄实验小学",
        "phone": 53582380,
        "address": "北京市亦庄经济技术开发区四合路1号",
        "typeName": "小学",
        "id": 986
    },
    {
        "type": 1,
        "name": "北京教育科学研究院旧宫实验小学",
        "phone": "50927173-8118",
        "address": "北京市大兴区旧宫镇旧忠东街2号院",
        "typeName": "小学",
        "id": 987
    },
    {
        "type": 1,
        "name": "北京市大兴区魏善庄镇第一中心小学",
        "phone": 89201757,
        "address": "北京市大兴区魏善庄镇魏善庄村西",
        "typeName": "小学",
        "id": 988
    },
    {
        "type": 1,
        "name": "大兴区魏善庄镇第一中心小学西芦垡完小",
        "phone": 89201441,
        "address": "北京市大兴区魏善庄镇后査路东行500米",
        "typeName": "小学",
        "id": 989
    },
    {
        "type": 1,
        "name": "北京市大兴区魏善庄镇王各庄完全小学",
        "phone": 89243255,
        "address": "北京市大兴区魏善庄镇王各庄村东200米",
        "typeName": "小学",
        "id": 990
    },
    {
        "type": 1,
        "name": "大兴区魏善庄镇后大营完全小学",
        "phone": "010-89204153",
        "address": "北京市大兴区魏善庄镇后大营村",
        "typeName": "小学",
        "id": 991
    },
    {
        "type": 1,
        "name": "北京市大兴区青云店镇第一中心小学",
        "phone": 80281021,
        "address": "北京市大兴区青云店镇二村",
        "typeName": "小学",
        "id": 992
    },
    {
        "type": 1,
        "name": "北京市大兴区青云店镇第一中心小学小回城完全小学",
        "phone": 80221397,
        "address": "北京市大兴区青云店镇东回城村",
        "typeName": "小学",
        "id": 993
    },
    {
        "type": 1,
        "name": "北京市大兴区青云店镇第一中心小学低年级部",
        "phone": 80284600,
        "address": "北京市大兴区青云店镇四村",
        "typeName": "小学",
        "id": 994
    },
    {
        "type": 1,
        "name": "北京市大兴第七小学",
        "phone": 69242373,
        "address": "北京市大兴区兴华大街富华巷9号",
        "typeName": "小学",
        "id": 995
    },
    {
        "type": 1,
        "name": "北京市大兴区采育镇第一中心小学",
        "phone": 80271213,
        "address": "采育镇西二村",
        "typeName": "小学",
        "id": 996
    },
    {
        "type": 1,
        "name": "北京市大兴区采育镇辛店小学",
        "phone": 80272634,
        "address": "采育镇南辛店二村",
        "typeName": "小学",
        "id": 997
    },
    {
        "type": 1,
        "name": "北京市大兴区青云店镇第二中心小学",
        "phone": 80213240,
        "address": "北京市大兴区青云店镇垡上村西",
        "typeName": "小学",
        "id": 998
    },
    {
        "type": 1,
        "name": "青云店镇第二中心小学南大红门完全小学",
        "phone": 80217697,
        "address": "北京市大兴区青云店镇南大红门村西",
        "typeName": "小学",
        "id": 999
    },
    {
        "type": 1,
        "name": "北京师范大学大兴附属小学",
        "phone": "60239337-8015",
        "address": "大兴区黄村镇香园路香海园小区",
        "typeName": "小学",
        "id": 1000
    },
    {
        "type": 1,
        "name": "北京小学大兴分校",
        "phone": 69201329,
        "address": "北京市大兴区黄村镇兴业路北京小学大兴分校（清城）",
        "typeName": "小学",
        "id": 1001
    },
    {
        "type": 1,
        "name": "北京市大兴区第六小学",
        "phone": 69243993,
        "address": "北京市大兴区兴丰街道富强东里小区42号",
        "typeName": "小学",
        "id": 1002
    },
    {
        "type": 1,
        "name": "北京市大兴区礼贤镇第一中心小学",
        "phone": 89271810,
        "address": "北京市大兴区礼贤镇招贤路1号",
        "typeName": "小学",
        "id": 1003
    },
    {
        "type": 1,
        "name": "北京市大兴区礼贤镇第一中心小学荆家务完小",
        "phone": 89272313,
        "address": "北京市大兴区礼贤镇荆家务村荆福路1号",
        "typeName": "小学",
        "id": 1004
    },
    {
        "type": 1,
        "name": "北京市大兴区礼贤镇第一中心小学龙头完小",
        "phone": 89233552,
        "address": "北京市大兴区礼贤镇龙头村南街",
        "typeName": "小学",
        "id": 1005
    },
    {
        "type": 1,
        "name": "北京市大兴区礼贤镇第一中心小学孙营完小",
        "phone": 89273032,
        "address": "北京市大兴区礼贤镇孙营村北",
        "typeName": "小学",
        "id": 1006
    },
    {
        "type": 1,
        "name": "北京市大兴区礼贤镇第一中心小学西里河完小",
        "phone": 89272351,
        "address": "北京市大兴区礼贤镇西里河村村北",
        "typeName": "小学",
        "id": 1007
    },
    {
        "type": 1,
        "name": "北京第二实验小学大兴实验学校",
        "phone": 60248993,
        "address": "北京市大兴区西红门镇长盛路5号",
        "typeName": "小学",
        "id": 1008
    },
    {
        "type": 1,
        "name": "北京市大兴区魏善庄镇第二中心小学",
        "phone": 89231346,
        "address": "北京市大兴区魏善庄镇政府向西1500米路北",
        "typeName": "小学",
        "id": 1009
    },
    {
        "type": 1,
        "name": "北京市大兴区魏善庄镇第二中心小学东研垡完小",
        "phone": 89231704,
        "address": "北京市大兴区魏善庄镇政府向东1000米路北",
        "typeName": "小学",
        "id": 1010
    },
    {
        "type": 1,
        "name": "北京市大兴区礼贤镇第二中心小学",
        "phone": 89221774,
        "address": "北京市大兴区礼贤镇辛英路4号",
        "typeName": "小学",
        "id": 1011
    },
    {
        "type": 1,
        "name": "北京市大兴区礼贤镇杨各庄完全小学",
        "phone": 89221147,
        "address": "北京市大兴区礼贤镇前杨街大礼路47号",
        "typeName": "小学",
        "id": 1012
    },
    {
        "type": 1,
        "name": "北京市大兴区旧宫镇第一中心小学",
        "phone": 87913381,
        "address": "北京市大兴区旧宫镇庑殿路南临1号",
        "typeName": "小学",
        "id": 1013
    },
    {
        "type": 1,
        "name": "北京市大兴区第二小学",
        "phone": 69294248,
        "address": "北京市大兴区黄村镇林校路街道办事处永华北里社区居委会",
        "typeName": "小学",
        "id": 1014
    },
    {
        "type": 1,
        "name": "北京小学大兴分校亦庄学校",
        "phone": 57794995,
        "address": "北京市大兴区亦庄镇亦庄新城三海子三号路西侧",
        "typeName": "小学",
        "id": 1015
    },
    {
        "type": 1,
        "name": "清华大学附属小学昌平学校",
        "phone": "010-64128252",
        "address": "北京市昌平区东小口镇陈家营中路一号",
        "typeName": "小学",
        "id": 1016
    },
    {
        "type": 1,
        "name": "燕丹学校",
        "phone": 61757143,
        "address": "北七家镇燕丹村一号",
        "typeName": "小学",
        "id": 1017
    },
    {
        "type": 1,
        "name": "北京市昌平区第四中学",
        "phone": 69741024,
        "address": "北京市昌平区城北街道永安路5号",
        "typeName": "小学",
        "id": 1018
    },
    {
        "type": 1,
        "name": "北京市昌平区第四中学小学部",
        "phone": 60719985,
        "address": "北京市昌平区城南街道南郝庄村南",
        "typeName": "小学",
        "id": 1019
    },
    {
        "type": 1,
        "name": "北京市昌平区百善学校",
        "phone": 61738685,
        "address": "北京市昌平区百善镇孟祖村",
        "typeName": "小学",
        "id": 1020
    },
    {
        "type": 1,
        "name": "亭自庄学校",
        "phone": 60756280,
        "address": "马池口镇北小营村临804号",
        "typeName": "小学",
        "id": 1021
    },
    {
        "type": 1,
        "name": "北京市昌平区下庄学校",
        "phone": 89726143,
        "address": "北京市昌平区延寿镇下庄村",
        "typeName": "小学",
        "id": 1022
    },
    {
        "type": 1,
        "name": "北京市昌平区黑山寨学校",
        "phone": 89727147,
        "address": "北京市昌平区延寿镇黑山寨村委会西",
        "typeName": "小学",
        "id": 1023
    },
    {
        "type": 1,
        "name": "兴寿学校",
        "phone": 61726488,
        "address": "兴寿镇兴寿村",
        "typeName": "小学",
        "id": 1024
    },
    {
        "type": 1,
        "name": "特殊儿童教育学校",
        "phone": 89778378,
        "address": "昌平区流村镇瓦窑村北台",
        "typeName": "小学",
        "id": 1025
    },
    {
        "type": 1,
        "name": "北京市昌平区桃洼学校",
        "phone": 69778465,
        "address": "北京市昌平区南口镇前桃洼村123号",
        "typeName": "小学",
        "id": 1026
    },
    {
        "type": 1,
        "name": "亭自庄学校中学校区",
        "phone": 60756280,
        "address": "马池口镇北小营村临804号",
        "typeName": "小学",
        "id": 1027
    },
    {
        "type": 1,
        "name": "亭自庄学校中心校区",
        "phone": 60756280,
        "address": "马池口镇北小营村临805号",
        "typeName": "小学",
        "id": 1028
    },
    {
        "type": 1,
        "name": "亭自庄学校土楼校区",
        "phone": 60756280,
        "address": "马池口镇土楼村",
        "typeName": "小学",
        "id": 1029
    },
    {
        "type": 1,
        "name": "首都师范大学附属回龙观育新学校",
        "phone": 60720259,
        "address": "北京市昌平区回龙观镇文化东路龙锦苑三区",
        "typeName": "小学",
        "id": 1030
    },
    {
        "type": 1,
        "name": "中国政法大学附属学校",
        "phone": 69742223,
        "address": "昌平区水库路20号",
        "typeName": "小学",
        "id": 1031
    },
    {
        "type": 1,
        "name": "北京师范大学昌平附属学校",
        "phone": "010-80722286",
        "address": "北京市昌平沙河高教园北三街5号",
        "typeName": "小学",
        "id": 1032
    },
    {
        "type": 1,
        "name": "流村中心小学",
        "phone": 89771170,
        "address": "北京市昌平区流村镇北流村",
        "typeName": "小学",
        "id": 1033
    },
    {
        "type": 1,
        "name": "流村中心古将小学",
        "phone": 89771534,
        "address": "北京市昌平区流村镇古将村",
        "typeName": "小学",
        "id": 1034
    },
    {
        "type": 1,
        "name": "流村中心南流小学",
        "phone": 89771504,
        "address": "北京市昌平区流村镇南流村",
        "typeName": "小学",
        "id": 1035
    },
    {
        "type": 1,
        "name": "流村中心新建村小学",
        "phone": 89774435,
        "address": "北京市昌平区流村镇新建村",
        "typeName": "小学",
        "id": 1036
    },
    {
        "type": 1,
        "name": "流村中心西峰山小学",
        "phone": 89771646,
        "address": "北京市昌平区流村镇西峰山村",
        "typeName": "小学",
        "id": 1037
    },
    {
        "type": 1,
        "name": "北京育翔小学回龙观学校",
        "phone": 62951810,
        "address": "昌平区回龙观镇龙域南街5号",
        "typeName": "小学",
        "id": 1038
    },
    {
        "type": 1,
        "name": "平西府中心白庙小学",
        "phone": 81785308,
        "address": "北京市昌平区北七家镇白庙村东街平西府中心白庙小学",
        "typeName": "小学",
        "id": 1039
    },
    {
        "type": 1,
        "name": "平西府中心郑各庄小学",
        "phone": 81781899,
        "address": "北京市昌平区北七家镇宏福苑小区郑各庄小学",
        "typeName": "小学",
        "id": 1040
    },
    {
        "type": 1,
        "name": "平西府中心东沙各庄小学",
        "phone": 89756082,
        "address": "北京市昌平区北七家镇东沙各庄村东沙各庄小学",
        "typeName": "小学",
        "id": 1041
    },
    {
        "type": 1,
        "name": "平西府中心东三旗小学",
        "phone": 81755396,
        "address": "北京市昌平区北七家镇东三旗村东三旗小学",
        "typeName": "小学",
        "id": 1042
    },
    {
        "type": 1,
        "name": "北京市昌平区城南中心旧县小学",
        "phone": 89745509,
        "address": "北京市昌平区旧县村旧县小学",
        "typeName": "小学",
        "id": 1043
    },
    {
        "type": 1,
        "name": "北京市昌平区东小口中心小学",
        "phone": 82915237,
        "address": "北京市昌平区东小口镇中滩村189号",
        "typeName": "小学",
        "id": 1044
    },
    {
        "type": 1,
        "name": "北京市昌平区十三陵中心小学",
        "phone": 89705349,
        "address": "北京市昌平区十三陵镇涧头村西",
        "typeName": "小学",
        "id": 1045
    },
    {
        "type": 1,
        "name": "北京市昌平区十三陵中心泰陵园小学",
        "phone": 89763878,
        "address": "北京市昌平区十三陵泰陵园村",
        "typeName": "小学",
        "id": 1046
    },
    {
        "type": 1,
        "name": "北京市昌平第二实验小学（东校区）",
        "phone": 81739181,
        "address": "北京市昌平区回龙观镇龙腾苑二区30号",
        "typeName": "小学",
        "id": 1047
    },
    {
        "type": 1,
        "name": "北京市昌平第二实验小学（西校区）",
        "phone": 80775951,
        "address": "北京市昌平区回龙观镇龙泽苑东区34号",
        "typeName": "小学",
        "id": 1048
    },
    {
        "type": 1,
        "name": "天通苑小学",
        "phone": 61752994,
        "address": "北京市昌平区天通苑东一区40号",
        "typeName": "小学",
        "id": 1049
    },
    {
        "type": 1,
        "name": "北京市昌平区北七家中心小学",
        "phone": "81761240-8015",
        "address": "北京市昌平区北七家镇八仙庄村",
        "typeName": "小学",
        "id": 1050
    },
    {
        "type": 1,
        "name": "北京市昌平第三实验小学",
        "phone": 61718488,
        "address": "北京市昌平区小汤山镇土沟新村小区36号",
        "typeName": "小学",
        "id": 1051
    },
    {
        "type": 1,
        "name": "北京市昌平区城关小学",
        "phone": 89782255,
        "address": "北京市昌平区西环里32号",
        "typeName": "小学",
        "id": 1052
    },
    {
        "type": 1,
        "name": "城南中心邓庄小学",
        "phone": 89731540,
        "address": "昌平区城南街道邓庄村",
        "typeName": "小学",
        "id": 1053
    },
    {
        "type": 1,
        "name": "城南中心小学",
        "phone": 89713048,
        "address": "昌平区郝庄家园",
        "typeName": "小学",
        "id": 1054
    },
    {
        "type": 1,
        "name": "北京市昌平区高崖口中心小学",
        "phone": "010-89778046",
        "address": "北京市昌平区流村镇瓦窑村",
        "typeName": "小学",
        "id": 1055
    },
    {
        "type": 1,
        "name": "北京农学院附属小学",
        "phone": 62711359,
        "address": "北京市昌平区回龙观文化居住区文博路2号",
        "typeName": "小学",
        "id": 1056
    },
    {
        "type": 1,
        "name": "北京市昌平区回龙观中心小学",
        "phone": 80776706,
        "address": "北京市昌平区回龙观镇二拨子新村",
        "typeName": "小学",
        "id": 1057
    },
    {
        "type": 1,
        "name": "北京市昌平区回龙观中心回龙观小学",
        "phone": 62713036,
        "address": "北京市昌平区回龙观镇南店新村",
        "typeName": "小学",
        "id": 1058
    },
    {
        "type": 1,
        "name": "北京市昌平区南口职业学校",
        "phone": 69771570,
        "address": "北京市昌平区南口镇南农家园小区内",
        "typeName": "小学",
        "id": 1059
    },
    {
        "type": 1,
        "name": "清华大学附属中学昌平学校悦府校区小学部",
        "phone": "无",
        "address": "北京市昌平区龙锦一街37号院",
        "typeName": "小学",
        "id": 1060
    },
    {
        "type": 1,
        "name": "北京市昌平区崔村中心大辛峰小学",
        "phone": 60721987,
        "address": "北京市昌平区崔村镇大辛峰村",
        "typeName": "小学",
        "id": 1061
    },
    {
        "type": 1,
        "name": "北京市昌平区崔村中心香堂小学",
        "phone": 60722051,
        "address": "北京市昌平区崔村镇香堂村",
        "typeName": "小学",
        "id": 1062
    },
    {
        "type": 1,
        "name": "北京市昌平区崔村中心小学",
        "phone": 60721418,
        "address": "北京市昌平区崔村镇西崔村",
        "typeName": "小学",
        "id": 1063
    },
    {
        "type": 1,
        "name": "昌平区二毛学校",
        "phone": 69742442,
        "address": "北京市昌平区城北街道五街宽街胡同",
        "typeName": "小学",
        "id": 1064
    },
    {
        "type": 1,
        "name": "北京市昌平区小汤山中心小学",
        "phone": 61781352,
        "address": "北京市昌平区小汤山镇市场街",
        "typeName": "小学",
        "id": 1065
    },
    {
        "type": 1,
        "name": "北京市昌平区小汤山中心马坊小学",
        "phone": 61784731,
        "address": "北京市昌平区小汤山镇马坊村",
        "typeName": "小学",
        "id": 1066
    },
    {
        "type": 1,
        "name": "北京市昌平区南口镇小学",
        "phone": "010-69771078",
        "address": "北京市昌平区南口镇兴隆街33号",
        "typeName": "小学",
        "id": 1067
    },
    {
        "type": 1,
        "name": "城北中心小学",
        "phone": 69746126,
        "address": "北京市昌平区城北街道六街瑞光胡同10号",
        "typeName": "小学",
        "id": 1068
    },
    {
        "type": 1,
        "name": "城北中心三街小学",
        "phone": 69746126,
        "address": "北京市昌平区城北街道三街财神庙胡同5号",
        "typeName": "小学",
        "id": 1069
    },
    {
        "type": 1,
        "name": "城北中心东关小学",
        "phone": 69746126,
        "address": "北京市昌平区城北街道东关育才胡同10号",
        "typeName": "小学",
        "id": 1070
    },
    {
        "type": 1,
        "name": "城北中心西关小学",
        "phone": 69746126,
        "address": "北京市昌平区城北街道西关槐树巷",
        "typeName": "小学",
        "id": 1071
    },
    {
        "type": 1,
        "name": "北京市昌平区巩华学校",
        "phone": 1080710799,
        "address": "北京市昌平区沙河镇顺沙路路松街",
        "typeName": "小学",
        "id": 1072
    },
    {
        "type": 1,
        "name": "北京市昌平区南口铁道北小学",
        "phone": 69772863,
        "address": "北京市昌平区南口镇道北东区平房290号",
        "typeName": "小学",
        "id": 1073
    },
    {
        "type": 1,
        "name": "阳坊中心小学",
        "phone": 69762985,
        "address": "北京市昌平区阳坊镇北横街1号",
        "typeName": "小学",
        "id": 1074
    },
    {
        "type": 1,
        "name": "阳坊中心后白小学",
        "phone": 69767614,
        "address": "阳坊中心后白虎涧村",
        "typeName": "小学",
        "id": 1075
    },
    {
        "type": 1,
        "name": "阳坊中心四家庄小学",
        "phone": 69768464,
        "address": "北京市昌平区阳坊镇四家庄",
        "typeName": "小学",
        "id": 1076
    },
    {
        "type": 1,
        "name": "华北电力大学附属小学",
        "phone": 81713452,
        "address": "北京市昌平区回龙观风雅园三区13号",
        "typeName": "小学",
        "id": 1077
    },
    {
        "type": 1,
        "name": "北京市昌平区霍营中心小学",
        "phone": 56875501,
        "address": "北京市昌平区回龙观龙跃街10号院4号",
        "typeName": "小学",
        "id": 1078
    },
    {
        "type": 1,
        "name": "北京市昌平区霍营中心半截塔小学",
        "phone": 81773306,
        "address": "北京市昌平区东小口地区办事处半截塔村委会",
        "typeName": "小学",
        "id": 1079
    },
    {
        "type": 1,
        "name": "北京市昌平区老峪沟中心小学",
        "phone": 80192091,
        "address": "北京市昌平区流村镇老峪沟中心小学",
        "typeName": "小学",
        "id": 1080
    },
    {
        "type": 1,
        "name": "北京市昌平区沙河中心小学",
        "phone": 80762246,
        "address": "北京市昌平区沙河镇西沙屯村中轴南街12号",
        "typeName": "小学",
        "id": 1081
    },
    {
        "type": 1,
        "name": "北京市昌平区沙河中心于辛庄小学",
        "phone": 52560239,
        "address": "北京市昌平区沙河镇于辛庄村",
        "typeName": "小学",
        "id": 1082
    },
    {
        "type": 1,
        "name": "北京市昌平区史各庄中心小学",
        "phone": 69739820,
        "address": "北京市昌平区回龙观镇东半壁店266号",
        "typeName": "小学",
        "id": 1083
    },
    {
        "type": 1,
        "name": "北京市昌平区史各庄中心定福皇庄回民小学",
        "phone": 69734831,
        "address": "北京市昌平区回龙观镇定福皇庄村500号",
        "typeName": "小学",
        "id": 1084
    },
    {
        "type": 1,
        "name": "北京绿谷小香玉艺术学校",
        "phone": 69956001,
        "address": "北京市平谷顺兴路2号",
        "typeName": "小学",
        "id": 1085
    },
    {
        "type": 1,
        "name": "北京第二外国语学院平谷附属学校",
        "phone": 69909434,
        "address": "北京市平谷区东高村镇西高村平三路与东崔路交叉口北50米",
        "typeName": "小学",
        "id": 1086
    },
    {
        "type": 1,
        "name": "北京市平谷区特教中心",
        "phone": 69962152,
        "address": "北京市平谷区兴谷开发区平翔路18号",
        "typeName": "小学",
        "id": 1087
    },
    {
        "type": 1,
        "name": "北京市平谷区第一小学",
        "phone": 89999626,
        "address": "北京市平谷区建设西街3号",
        "typeName": "小学",
        "id": 1088
    },
    {
        "type": 1,
        "name": "北京市平谷区第二小学",
        "phone": 69928290,
        "address": "北京市平谷区园田街8号",
        "typeName": "小学",
        "id": 1089
    },
    {
        "type": 1,
        "name": "北京市平谷区第三小学",
        "phone": 69961350,
        "address": "北京市平谷区建设南小区1号",
        "typeName": "小学",
        "id": 1090
    },
    {
        "type": 1,
        "name": "北京实验学校附属小学",
        "phone": 69962203,
        "address": "北京市平谷区平谷镇新平东路保安街路北57号",
        "typeName": "小学",
        "id": 1091
    },
    {
        "type": 1,
        "name": "北京市平谷区第五小学",
        "phone": 69933027,
        "address": "北京市平谷区海关西园7号",
        "typeName": "小学",
        "id": 1092
    },
    {
        "type": 1,
        "name": "北京市平谷区第六小学",
        "phone": 89968961,
        "address": "北京市平谷区平谷镇金乡西小区48号",
        "typeName": "小学",
        "id": 1093
    },
    {
        "type": 1,
        "name": "北京市平谷区第七小学",
        "phone": 69913521,
        "address": "北京市平谷区平谷镇西鹿角村",
        "typeName": "小学",
        "id": 1094
    },
    {
        "type": 1,
        "name": "北京市平谷区第八小学",
        "phone": 69956392,
        "address": "北京市平谷区兴谷开发区平谷北街和谷丰东路交叉口西200米",
        "typeName": "小学",
        "id": 1095
    },
    {
        "type": 1,
        "name": "北京第二实验小学平谷分校",
        "phone": 89990964,
        "address": "北京市平谷区王辛庄镇小辛寨村东大街2号",
        "typeName": "小学",
        "id": 1096
    },
    {
        "type": 1,
        "name": "北京第一师范学校附属小学平谷分校",
        "phone": 60953608,
        "address": "北京市平谷区体育中心西路6号院",
        "typeName": "小学",
        "id": 1097
    },
    {
        "type": 1,
        "name": "北京市平谷区上营小学",
        "phone": 69919838,
        "address": "北京市平谷区王辛庄镇北上营西路2号",
        "typeName": "小学",
        "id": 1098
    },
    {
        "type": 1,
        "name": "北京市平谷区峪口镇中心小学",
        "phone": 61906296,
        "address": "北京市平谷区峪口镇育才路46号",
        "typeName": "小学",
        "id": 1099
    },
    {
        "type": 1,
        "name": "北京市平谷区峪口镇东凡各庄小学",
        "phone": 61960940,
        "address": "北京市平谷区峪口镇东樊各庄校园路29号",
        "typeName": "小学",
        "id": 1100
    },
    {
        "type": 1,
        "name": "北京市平谷区峪口镇中桥小学",
        "phone": 61906296,
        "address": "北京市平谷去峪口镇兴桥路6号",
        "typeName": "小学",
        "id": 1101
    },
    {
        "type": 1,
        "name": "北京市平谷区北杨桥中心小学",
        "phone": 61911484,
        "address": "北京市平谷区峪口镇北杨家桥村中路42号",
        "typeName": "小学",
        "id": 1102
    },
    {
        "type": 1,
        "name": "北京市平谷区大官庄小学",
        "phone": 61983483,
        "address": "北京市平谷区峪口镇大官庄村大杨路与小官庄路交叉口西150米",
        "typeName": "小学",
        "id": 1103
    },
    {
        "type": 1,
        "name": "北京市平谷区放光中心小学",
        "phone": 89956604,
        "address": "北京市平谷区王辛庄镇放光东路3号",
        "typeName": "小学",
        "id": 1104
    },
    {
        "type": 1,
        "name": "北京市平谷区北辛庄小学",
        "phone": 89956604,
        "address": "北京市平谷区王辛庄镇北辛庄村东路35号",
        "typeName": "小学",
        "id": 1105
    },
    {
        "type": 1,
        "name": "北京市平谷区南独乐河小学",
        "phone": 60926118,
        "address": "北京市平谷区南独乐河镇南独乐河村中心校西路3号",
        "typeName": "小学",
        "id": 1106
    },
    {
        "type": 1,
        "name": "北京市平谷区峨嵋山小学",
        "phone": 60961934,
        "address": "北京市平谷区峨嵋山上营67号",
        "typeName": "小学",
        "id": 1107
    },
    {
        "type": 1,
        "name": "北京市平谷区望马台小学",
        "phone": 60929788,
        "address": "北京市平谷区南独乐河镇望马台村东路231号",
        "typeName": "小学",
        "id": 1108
    },
    {
        "type": 1,
        "name": "北京市平谷区夏各庄小学",
        "phone": 60913524,
        "address": "北京市平谷区夏各庄镇夏各庄村北街3号",
        "typeName": "小学",
        "id": 1109
    },
    {
        "type": 1,
        "name": "北京市平谷区安固小学",
        "phone": 60913837,
        "address": "北京市平谷区夏各庄镇安固村后街127号",
        "typeName": "小学",
        "id": 1110
    },
    {
        "type": 1,
        "name": "北京市平谷区大兴庄镇三府庄小学",
        "phone": 89931514,
        "address": "北京市平谷区大兴庄镇三府庄村顺福街5号",
        "typeName": "小学",
        "id": 1111
    },
    {
        "type": 1,
        "name": "北京市平谷区大兴庄镇周村小学",
        "phone": 60953608,
        "address": "北京市平谷区大兴庄镇周村东路59号",
        "typeName": "小学",
        "id": 1112
    },
    {
        "type": 1,
        "name": "北京市平谷区大华山中心小学",
        "phone": 61947964,
        "address": "北京市平谷区大华山镇大华山村大街122号",
        "typeName": "小学",
        "id": 1113
    },
    {
        "type": 1,
        "name": "北京市平谷区后北宫小学",
        "phone": 61947964,
        "address": "北京市平谷区大华山镇后北宫西路115号",
        "typeName": "小学",
        "id": 1114
    },
    {
        "type": 1,
        "name": "北京市平谷区山东庄小学",
        "phone": 60937150,
        "address": "北京市平谷区山东庄镇山东庄村西大街173号",
        "typeName": "小学",
        "id": 1115
    },
    {
        "type": 1,
        "name": "北京市平谷区西沥津高级小学",
        "phone": 60937150,
        "address": "北京市平谷区山东庄镇东大街1号",
        "typeName": "小学",
        "id": 1116
    },
    {
        "type": 1,
        "name": "北京市平谷区马昌营中心小学",
        "phone": 61982905,
        "address": "北京市平谷区马昌营镇云打路与马天路交叉口北50米",
        "typeName": "小学",
        "id": 1117
    },
    {
        "type": 1,
        "name": "北京市平谷区王各庄小学",
        "phone": 60909361,
        "address": "北京市平谷区马昌营镇王各庄村53号",
        "typeName": "小学",
        "id": 1118
    },
    {
        "type": 1,
        "name": "北京市平谷区东双营小学",
        "phone": 61919068,
        "address": "北京市平谷区马昌营镇东东双营小学路与东双营环路交叉口南200米路东",
        "typeName": "小学",
        "id": 1119
    },
    {
        "type": 1,
        "name": "北京市平谷区门楼中心小学",
        "phone": 60951719,
        "address": "北京市平谷区东高村镇北张岱中路94号",
        "typeName": "小学",
        "id": 1120
    },
    {
        "type": 1,
        "name": "北京市平谷区东交民巷小学马坊分校",
        "phone": 60991330,
        "address": "北京市平谷区马坊镇新农小街和金平北路交叉口",
        "typeName": "小学",
        "id": 1121
    },
    {
        "type": 1,
        "name": "北京市平谷区马坊中心小学",
        "phone": 60996210,
        "address": "北京市平谷区马坊镇菜园前街17号",
        "typeName": "小学",
        "id": 1122
    },
    {
        "type": 1,
        "name": "北京市平谷区金海湖学校",
        "phone": 69992848,
        "address": "北京市平谷区黄松峪乡黄松峪村东街343号",
        "typeName": "小学",
        "id": 1123
    },
    {
        "type": 1,
        "name": "北京市平谷区靠山集中心小学",
        "phone": 89975307,
        "address": "北京市平谷区金海湖镇靠山集大街47号",
        "typeName": "小学",
        "id": 1124
    },
    {
        "type": 1,
        "name": "北京市平谷区刘家店中心小学",
        "phone": 61971032,
        "address": "北京市平谷区刘家店镇刘家店西路56号",
        "typeName": "小学",
        "id": 1125
    },
    {
        "type": 1,
        "name": "北京市平谷区镇罗营中心小学",
        "phone": 61969060,
        "address": "北京市平谷区镇罗营镇上营东街163号",
        "typeName": "小学",
        "id": 1126
    },
    {
        "type": 1,
        "name": "北京市平谷区黄松峪中心小学",
        "phone": 60971660,
        "address": "北京市平谷区黄松峪乡黄松峪村东街275号",
        "typeName": "小学",
        "id": 1127
    },
    {
        "type": 1,
        "name": "北京市平谷区熊儿寨中心小学",
        "phone": 61961865,
        "address": "北京市平谷区熊儿寨乡熊儿寨村熊儿寨东路36号",
        "typeName": "小学",
        "id": 1128
    },
    {
        "type": 1,
        "name": "北京市平谷区大旺务小学",
        "phone": 69909434,
        "address": "北京市平谷区东高村镇大旺务前街220号",
        "typeName": "小学",
        "id": 1129
    },
    {
        "type": 1,
        "name": "北京市平谷区南山小学",
        "phone": 60926118,
        "address": "北京市平谷区南独乐河镇南山村东南路黑峪桥北30米",
        "typeName": "小学",
        "id": 1130
    },
    {
        "type": 1,
        "name": "北京市平谷区关山小学",
        "phone": 61969060,
        "address": "北京市平谷区镇罗营镇关上村平关路与东寺峪路交叉口东200米",
        "typeName": "小学",
        "id": 1131
    },
    {
        "type": 1,
        "name": "北京市平谷区东辛撞小学",
        "phone": 61947964,
        "address": "北京市平谷区大华山镇东辛撞村东辛撞路1号",
        "typeName": "小学",
        "id": 1132
    },
    {
        "type": 1,
        "name": "北京市怀柔区雁栖学校",
        "phone": 61642988,
        "address": "北京市怀柔区雁栖镇范各庄村南",
        "typeName": "小学",
        "id": 1133
    },
    {
        "type": 1,
        "name": "北京市怀柔区茶坞铁路小学",
        "phone": 61649878,
        "address": "北京市怀柔区桥梓镇茶坞铁路小区22号",
        "typeName": "小学",
        "id": 1134
    },
    {
        "type": 1,
        "name": "北京市怀柔区怀北学校",
        "phone": 60687189,
        "address": "北京市怀柔区怀北镇怀北庄村248号",
        "typeName": "小学",
        "id": 1135
    },
    {
        "type": 1,
        "name": "北京市怀柔区庙城学校",
        "phone": 60694257,
        "address": "北京市怀柔区庙城镇庙城村南",
        "typeName": "小学",
        "id": 1136
    },
    {
        "type": 1,
        "name": "北京市怀柔区庙城镇两河小学",
        "phone": 89609924,
        "address": "北京市怀柔区庙城镇刘两河村东",
        "typeName": "小学",
        "id": 1137
    },
    {
        "type": 1,
        "name": "北京市怀柔区庙城镇桃山小学",
        "phone": 89611001,
        "address": "北京市怀柔区庙城镇桃山村",
        "typeName": "小学",
        "id": 1138
    },
    {
        "type": 1,
        "name": "北京市怀柔区第一小学",
        "phone": 69644189,
        "address": "北京市怀柔区迎宾路13号",
        "typeName": "小学",
        "id": 1139
    },
    {
        "type": 1,
        "name": "北京市怀柔区第二小学",
        "phone": 69687936,
        "address": "北京市怀柔区湖光小区14号",
        "typeName": "小学",
        "id": 1140
    },
    {
        "type": 1,
        "name": "北京市怀柔区第三小学",
        "phone": 69623762,
        "address": "北京市怀柔区府前街11号",
        "typeName": "小学",
        "id": 1141
    },
    {
        "type": 1,
        "name": "北京市怀柔区实验小学",
        "phone": 89681753,
        "address": "北京市怀柔区小中富乐一区196号",
        "typeName": "小学",
        "id": 1142
    },
    {
        "type": 1,
        "name": "北京市怀柔区第六小学",
        "phone": 60686907,
        "address": "北京市怀柔区开放路119号",
        "typeName": "小学",
        "id": 1143
    },
    {
        "type": 1,
        "name": "北京第二实验小学怀柔分校",
        "phone": 61668015,
        "address": "北京市怀柔区雁栖开发区雁栖大街7号",
        "typeName": "小学",
        "id": 1144
    },
    {
        "type": 1,
        "name": "北京市怀柔区怀柔镇中心小学",
        "phone": 69697413,
        "address": "北京市怀柔区南华园三区2号",
        "typeName": "小学",
        "id": 1145
    },
    {
        "type": 1,
        "name": "北京市怀柔区怀柔镇张各长小学",
        "phone": 69627530,
        "address": "北京市怀柔区怀耿路2号",
        "typeName": "小学",
        "id": 1146
    },
    {
        "type": 1,
        "name": "北京市怀柔区怀柔镇郭家坞小学",
        "phone": 89619594,
        "address": "北京市怀柔区怀柔镇郭家坞村西",
        "typeName": "小学",
        "id": 1147
    },
    {
        "type": 1,
        "name": "北京市怀柔区北房镇中心小学",
        "phone": 61684091,
        "address": "北京市怀柔区北房镇幸福西街26号",
        "typeName": "小学",
        "id": 1148
    },
    {
        "type": 1,
        "name": "北京市怀柔区北房镇宰相庄小学",
        "phone": 61668055,
        "address": "北京市怀柔区中高路",
        "typeName": "小学",
        "id": 1149
    },
    {
        "type": 1,
        "name": "北京市怀柔区杨宋镇中心小学",
        "phone": 61679817,
        "address": "北京市怀柔区杨宋镇仙台村北",
        "typeName": "小学",
        "id": 1150
    },
    {
        "type": 1,
        "name": "北京市怀柔区桥梓镇中心小学",
        "phone": 69675029,
        "address": "北京市怀柔区桥梓镇后桥梓村西",
        "typeName": "小学",
        "id": 1151
    },
    {
        "type": 1,
        "name": "北京市怀柔区桥梓镇北宅小学",
        "phone": 60671644,
        "address": "北京市怀柔区桥梓镇北宅村北",
        "typeName": "小学",
        "id": 1152
    },
    {
        "type": 1,
        "name": "北京市怀柔区渤海镇中心小学",
        "phone": 61649896,
        "address": "北京市怀柔区渤海镇渤海所村东",
        "typeName": "小学",
        "id": 1153
    },
    {
        "type": 1,
        "name": "北京市怀柔区九渡河镇中心小学",
        "phone": 61691305,
        "address": "北京市怀柔区九渡河镇黄坎村西",
        "typeName": "小学",
        "id": 1154
    },
    {
        "type": 1,
        "name": "北京市怀柔区九渡河镇九渡河小学",
        "phone": 89606935,
        "address": "北京市怀柔区九渡河镇九渡河村东",
        "typeName": "小学",
        "id": 1155
    },
    {
        "type": 1,
        "name": "北京市怀柔区九渡河镇黄花城小学",
        "phone": 61652163,
        "address": "北京市怀柔区九渡河镇西台村北",
        "typeName": "小学",
        "id": 1156
    },
    {
        "type": 1,
        "name": "北京市怀柔区琉璃庙镇中心小学",
        "phone": 61618246,
        "address": "北京市怀柔区琉璃庙镇琉璃庙村49号",
        "typeName": "小学",
        "id": 1157
    },
    {
        "type": 1,
        "name": "北京市怀柔区汤河口镇中心小学",
        "phone": 89671860,
        "address": "北京市怀柔区汤河口镇汤河口大街13号",
        "typeName": "小学",
        "id": 1158
    },
    {
        "type": 1,
        "name": "北京市怀柔区宝山镇中心小学",
        "phone": 60625634,
        "address": "北京市怀柔区宝山镇宝山寺村",
        "typeName": "小学",
        "id": 1159
    },
    {
        "type": 1,
        "name": "北京市怀柔区长哨营满族乡中心小学",
        "phone": 60621261,
        "address": "北京市怀柔区长哨营满族乡长哨营村西",
        "typeName": "小学",
        "id": 1160
    },
    {
        "type": 1,
        "name": "北京市怀柔区喇叭沟门满族乡中心小学",
        "phone": 69653221,
        "address": "北京市怀柔区喇叭沟门满族乡喇叭沟门村西",
        "typeName": "小学",
        "id": 1161
    },
    {
        "type": 1,
        "name": "北京市密云区高岭学校",
        "phone": 81081048,
        "address": "北京市密云区高岭镇高岭村",
        "typeName": "小学",
        "id": 1162
    },
    {
        "type": 1,
        "name": "北京市密云区大城子学校",
        "phone": 61072398,
        "address": "北京市密云区大城子镇大城子村",
        "typeName": "小学",
        "id": 1163
    },
    {
        "type": 1,
        "name": "北京市密云区特殊教育学校",
        "phone": 53319327,
        "address": "北京市密云区十里堡镇燕落寨村",
        "typeName": "小学",
        "id": 1164
    },
    {
        "type": 1,
        "name": "北京市密云区第二小学",
        "phone": 69044364,
        "address": "北京市密云区二街通城胡同4号",
        "typeName": "小学",
        "id": 1165
    },
    {
        "type": 1,
        "name": "北京市密云区第四小学",
        "phone": 69044908,
        "address": "北京市密云区鼓楼街道办事处宾阳里社区",
        "typeName": "小学",
        "id": 1166
    },
    {
        "type": 1,
        "name": "北京市密云区第六小学",
        "phone": 89095585,
        "address": "北京市密云区兴云路28号",
        "typeName": "小学",
        "id": 1167
    },
    {
        "type": 1,
        "name": "北京市密云区第一小学",
        "phone": 69042263,
        "address": "北京市密云区育才路东侧2号",
        "typeName": "小学",
        "id": 1168
    },
    {
        "type": 1,
        "name": "北京市密云区第三小学",
        "phone": 69045357,
        "address": "北京市密云区鼓楼街道康复路3号",
        "typeName": "小学",
        "id": 1169
    },
    {
        "type": 1,
        "name": "北京市密云区果园小学",
        "phone": 69043401,
        "address": "北京市密云区果园街道办事处果园新里社区",
        "typeName": "小学",
        "id": 1170
    },
    {
        "type": 1,
        "name": "北京市密云区季庄小学",
        "phone": 69097819,
        "address": "北京市密云区果园西里16号",
        "typeName": "小学",
        "id": 1171
    },
    {
        "type": 1,
        "name": "北京市密云区南菜园小学",
        "phone": 69051861,
        "address": "北京市密云区檀州家园东区",
        "typeName": "小学",
        "id": 1172
    },
    {
        "type": 1,
        "name": "北京市朝阳区实验小学密云学校",
        "phone": 61099926,
        "address": "北京市密云区密云镇大唐庄社区",
        "typeName": "小学",
        "id": 1173
    },
    {
        "type": 1,
        "name": "北京市密云区溪翁庄镇中心小学",
        "phone": "69012485-8006",
        "address": "北京市密云区溪翁庄镇溪翁庄村",
        "typeName": "小学",
        "id": 1174
    },
    {
        "type": 1,
        "name": "北京市密云区溪翁庄镇东智小学",
        "phone": 89031010,
        "address": "北京市密云区溪翁庄镇东智东村",
        "typeName": "小学",
        "id": 1175
    },
    {
        "type": 1,
        "name": "北京市密云区西田各庄镇中心小学",
        "phone": "61009732-808",
        "address": "北京市密云区西田各庄镇西田各庄村",
        "typeName": "小学",
        "id": 1176
    },
    {
        "type": 1,
        "name": "北京市密云区西田各庄镇卸甲山小学",
        "phone": "61009732-808",
        "address": "北京市密云区西田各庄镇卸甲山村",
        "typeName": "小学",
        "id": 1177
    },
    {
        "type": 1,
        "name": "北京市密云区西田各庄镇太子务小学",
        "phone": "61009732-808",
        "address": "北京市密云区西田各庄镇太子务村",
        "typeName": "小学",
        "id": 1178
    },
    {
        "type": 1,
        "name": "北京市密云区西田各庄镇疃里小学",
        "phone": 61009629,
        "address": "北京市密云区西田各庄镇疃里村",
        "typeName": "小学",
        "id": 1179
    },
    {
        "type": 1,
        "name": "北京市密云区十里堡镇中心小学",
        "phone": 89021074,
        "address": "北京市密云区十里堡镇庄禾屯村",
        "typeName": "小学",
        "id": 1180
    },
    {
        "type": 1,
        "name": "北京市密云区十里堡镇水杨红小学",
        "phone": 61027886,
        "address": "北京市密云区十里堡镇红光村",
        "typeName": "小学",
        "id": 1181
    },
    {
        "type": 1,
        "name": "北京市密云区河南寨镇中心小学",
        "phone": "61086592－8327",
        "address": "北京市密云区河南寨镇河南寨村",
        "typeName": "小学",
        "id": 1182
    },
    {
        "type": 1,
        "name": "北京市密云区河南寨镇台上小学",
        "phone": 61086097,
        "address": "北京市密云区河南寨镇下屯村",
        "typeName": "小学",
        "id": 1183
    },
    {
        "type": 1,
        "name": "北京市密云区巨各庄镇中心小学",
        "phone": 61032045,
        "address": "北京市密云区巨各庄镇水峪村",
        "typeName": "小学",
        "id": 1184
    },
    {
        "type": 1,
        "name": "北京市密云区巨各庄镇东白岩小学",
        "phone": 61064004,
        "address": "北京市密云区巨各庄镇东白岩村",
        "typeName": "小学",
        "id": 1185
    },
    {
        "type": 1,
        "name": "北京市密云区穆家峪镇中心小学",
        "phone": 61051942,
        "address": "北京市密云区穆家峪镇羊山村",
        "typeName": "小学",
        "id": 1186
    },
    {
        "type": 1,
        "name": "北京市密云区穆家峪镇后栗园小学",
        "phone": 89011795,
        "address": "北京市密云区穆家峪镇后栗园村",
        "typeName": "小学",
        "id": 1187
    },
    {
        "type": 1,
        "name": "北京市密云区穆家峪镇新农村小学",
        "phone": 89012904,
        "address": "北京市密云区穆家峪镇新农村村",
        "typeName": "小学",
        "id": 1188
    },
    {
        "type": 1,
        "name": "北京市密云区太师屯镇中心小学",
        "phone": 69033497,
        "address": "北京市密云区太师屯镇永安路中心街3号",
        "typeName": "小学",
        "id": 1189
    },
    {
        "type": 1,
        "name": "北京市密云区太师屯镇桑园小学",
        "phone": 81061034,
        "address": "北京市密云区太师屯镇桑园村",
        "typeName": "小学",
        "id": 1190
    },
    {
        "type": 1,
        "name": "北京市密云区太师屯镇东庄禾小学",
        "phone": 69034344,
        "address": "北京市密云区太师屯镇东庄禾村",
        "typeName": "小学",
        "id": 1191
    },
    {
        "type": 1,
        "name": "北京市密云区高岭镇上甸子小学",
        "phone": 81081659,
        "address": "北京市密云区高岭镇上甸子村",
        "typeName": "小学",
        "id": 1192
    },
    {
        "type": 1,
        "name": "北京市密云区不老屯镇中心小学",
        "phone": 81091544,
        "address": "北京市密云区不老屯镇不老屯村",
        "typeName": "小学",
        "id": 1193
    },
    {
        "type": 1,
        "name": "北京市密云区冯家峪镇中心小学",
        "phone": 81060148,
        "address": "北京市密云区冯家峪镇冯家峪村",
        "typeName": "小学",
        "id": 1194
    },
    {
        "type": 1,
        "name": "北京市密云区东邵渠镇中心小学",
        "phone": 61061524,
        "address": "北京市密云区东邵渠镇东邵渠村",
        "typeName": "小学",
        "id": 1195
    },
    {
        "type": 1,
        "name": "北京市密云区北庄镇中心小学",
        "phone": 81001381,
        "address": "北京市密云区东安街47号",
        "typeName": "小学",
        "id": 1196
    },
    {
        "type": 1,
        "name": "北京市密云区新城子镇中心小学",
        "phone": 81028105,
        "address": "北京市密云区新城子镇新城子村",
        "typeName": "小学",
        "id": 1197
    },
    {
        "type": 1,
        "name": "北京市密云区石城镇中心小学",
        "phone": "61025357-8022",
        "address": "北京市密云区石城镇南石城村",
        "typeName": "小学",
        "id": 1198
    },
    {
        "type": 1,
        "name": "北京市密云区第五小学(民族小学)",
        "phone": 69050883,
        "address": "北京市密云区南更大街143号",
        "typeName": "小学",
        "id": 1199
    },
    {
        "type": 1,
        "name": "北京市密云区河南寨镇提辖庄小学",
        "phone": 89091713,
        "address": "北京市密云区河南寨镇提辖庄村",
        "typeName": "小学",
        "id": 1200
    },
    {
        "type": 1,
        "name": "北京市密云区古北口镇中心小学",
        "phone": 81051400,
        "address": "北京市密云区古北口镇河西村",
        "typeName": "小学",
        "id": 1201
    },
    {
        "type": 1,
        "name": "北京市密云区檀营满族蒙古族乡中心小学",
        "phone": 69091833,
        "address": "北京市密云区檀营满族蒙古族乡村南",
        "typeName": "小学",
        "id": 1202
    },
    {
        "type": 1,
        "name": "北京市密云区第七小学",
        "phone": 61091090,
        "address": "北京市密云区阳光街南檀营街东",
        "typeName": "小学",
        "id": 1203
    },
    {
        "type": 1,
        "name": "北京市密云区职业学校",
        "phone": 69057468,
        "address": "北京市密云区果园西路2号",
        "typeName": "小学",
        "id": 1204
    },
    {
        "type": 1,
        "name": "北京市延庆区十一学校",
        "phone": 69184272,
        "address": "北京市延庆区延庆镇三清观２９号",
        "typeName": "小学",
        "id": 1205
    },
    {
        "type": 1,
        "name": "北京市延庆区香营学校",
        "phone": 60161845,
        "address": "北京市延庆区香营乡香营村西",
        "typeName": "小学",
        "id": 1206
    },
    {
        "type": 1,
        "name": "北京市延庆区永宁学校",
        "phone": 60171305,
        "address": "北京市延庆区永宁镇东门外",
        "typeName": "小学",
        "id": 1207
    },
    {
        "type": 1,
        "name": "北京市延庆区张山营学校",
        "phone": 69113386,
        "address": "北京市延庆区张山营镇下芦凤营村",
        "typeName": "小学",
        "id": 1208
    },
    {
        "type": 1,
        "name": "北京市延庆区特殊教育中心",
        "phone": 81183889,
        "address": "北京市延庆区张山营镇中羊坊村西",
        "typeName": "小学",
        "id": 1209
    },
    {
        "type": 1,
        "name": "北京市延庆区西屯中心小学",
        "phone": 61111890,
        "address": "北京市延庆区延庆镇新白庙村北",
        "typeName": "小学",
        "id": 1210
    },
    {
        "type": 1,
        "name": "北京市延庆 区莲花池小学",
        "phone": 61111890,
        "address": "北京市延庆区延庆镇莲花池村",
        "typeName": "小学",
        "id": 1211
    },
    {
        "type": 1,
        "name": "北京市延庆 区司家营小学",
        "phone": 81192149,
        "address": "北京市延庆区延庆镇司家营村",
        "typeName": "小学",
        "id": 1212
    },
    {
        "type": 1,
        "name": "北京市延庆区八里庄中心小学",
        "phone": 61121811,
        "address": "北京市延庆区延庆镇八里庄村南",
        "typeName": "小学",
        "id": 1213
    },
    {
        "type": 1,
        "name": "北京市延庆区广积屯完小",
        "phone": 51051227,
        "address": "北京市延庆区延庆镇广积屯村",
        "typeName": "小学",
        "id": 1214
    },
    {
        "type": 1,
        "name": "北京市延庆区榆林堡小学",
        "phone": 61161316,
        "address": "北京市延庆区康庄镇榆林堡村",
        "typeName": "小学",
        "id": 1215
    },
    {
        "type": 1,
        "name": "延庆区小丰营中心小学",
        "phone": 81187470,
        "address": "北京市延庆区康庄镇小丰营村",
        "typeName": "小学",
        "id": 1216
    },
    {
        "type": 1,
        "name": "北京市延庆区太平庄中心小学",
        "phone": 69130174,
        "address": "北京市延庆区康庄镇马营村东",
        "typeName": "小学",
        "id": 1217
    },
    {
        "type": 1,
        "name": "北京市延庆区八达岭中心小学",
        "phone": 69129491,
        "address": "北京市延庆区八达岭镇西拨子村北",
        "typeName": "小学",
        "id": 1218
    },
    {
        "type": 1,
        "name": "北京市延庆区旧县中心小学",
        "phone": 61151965,
        "address": "北京市延庆区旧县镇旧县村",
        "typeName": "小学",
        "id": 1219
    },
    {
        "type": 1,
        "name": "北京市延庆区大柏老中心小学",
        "phone": 61151980,
        "address": "北京市延庆区旧县镇大柏老村西",
        "typeName": "小学",
        "id": 1220
    },
    {
        "type": 1,
        "name": "北京市延庆区姚家营中心小学",
        "phone": 69110567,
        "address": "北京市延庆区张山营鎮姚家营村",
        "typeName": "小学",
        "id": 1221
    },
    {
        "type": 1,
        "name": "北京市延庆区四海中心小学",
        "phone": 60187283,
        "address": "北京市延庆区四海镇四海村北街东一区73号",
        "typeName": "小学",
        "id": 1222
    },
    {
        "type": 1,
        "name": "北京市延庆区千家店学校",
        "phone": 60188403,
        "address": "北京市延庆区千家店镇后沟村33号",
        "typeName": "小学",
        "id": 1223
    },
    {
        "type": 1,
        "name": "北京市延庆区沈家营中心小学",
        "phone": "61132021-8202",
        "address": "北京市延庆区沈家营镇沈家营村南",
        "typeName": "小学",
        "id": 1224
    },
    {
        "type": 1,
        "name": "北京市延庆区大榆树中心小学",
        "phone": 61182347,
        "address": "延庆区大榆树镇大榆树村",
        "typeName": "小学",
        "id": 1225
    },
    {
        "type": 1,
        "name": "北京市延庆区下屯中心小学",
        "phone": 61119517,
        "address": "北京市延庆区大榆树镇下屯村西北",
        "typeName": "小学",
        "id": 1226
    },
    {
        "type": 1,
        "name": "北京市延庆区井家庄中心小学",
        "phone": 61191704,
        "address": "北京市延庆区井庄镇井庄村",
        "typeName": "小学",
        "id": 1227
    },
    {
        "type": 1,
        "name": "北京市延庆区西二道河中心小学",
        "phone": 61180360,
        "address": "北京市延庆区井庄镇西二道河村南",
        "typeName": "小学",
        "id": 1228
    },
    {
        "type": 1,
        "name": "北京市延庆区大庄科中心小学",
        "phone": 60189814,
        "address": "北京市延庆区大庄科乡大庄科村南",
        "typeName": "小学",
        "id": 1229
    },
    {
        "type": 1,
        "name": "北京市延庆区刘斌堡中心小学",
        "phone": 60181013,
        "address": "北京市延庆区刘斌堡乡刘斌堡村东",
        "typeName": "小学",
        "id": 1230
    },
    {
        "type": 1,
        "name": "北京市延庆区珍珠泉中心小学",
        "phone": 60186413,
        "address": "北京市延庆区珍珠泉乡珍珠泉村东",
        "typeName": "小学",
        "id": 1231
    },
    {
        "type": 1,
        "name": "北京市延庆区第一小学",
        "phone": 69103216,
        "address": "北京市延庆区延庆镇杨家胡同42号",
        "typeName": "小学",
        "id": 1232
    },
    {
        "type": 1,
        "name": "北京市延庆区康庄中心小学",
        "phone": 61161316,
        "address": "北京市延庆区康庄镇一街村",
        "typeName": "小学",
        "id": 1233
    },
    {
        "type": 1,
        "name": "北京市延庆区第二小学",
        "phone": 69173839,
        "address": "北京市延庆区延庆镇高塔街62号",
        "typeName": "小学",
        "id": 1234
    },
    {
        "type": 1,
        "name": "北京市延庆区第三小学",
        "phone": 69103150,
        "address": "北京市延庆区延庆镇东外大街66号",
        "typeName": "小学",
        "id": 1235
    },
    {
        "type": 1,
        "name": "北京市延庆区第四小学",
        "phone": 81198749,
        "address": "北京市延庆区延庆镇南菜园二区",
        "typeName": "小学",
        "id": 1236
    },
    {
        "type": 1,
        "name": "北京市延庆区靳家堡中心小学",
        "phone": 69190712,
        "address": "北京市延庆区张山营镇靳家堡村西南",
        "typeName": "小学",
        "id": 1237
    },
    {
        "type": 2,
        "name": "北京市前门外国语学校",
        "phone": 67023169,
        "address": "北京市东城区前门东大街甲14号",
        "typeName": "中学",
        "id": 1238
    },
    {
        "type": 2,
        "name": "北京市第十一中学分校",
        "phone": 67024119,
        "address": "东城区天坛南里西区14号",
        "typeName": "中学",
        "id": 1239
    },
    {
        "type": 2,
        "name": "北京市第二中学分校",
        "phone": 65268716,
        "address": "北京市东城区朝阳门内南竹杆胡同81号",
        "typeName": "中学",
        "id": 1240
    },
    {
        "type": 2,
        "name": "北京市第十一中学实验学校",
        "phone": 67271674,
        "address": "北京市东城区西革新里114号",
        "typeName": "中学",
        "id": 1241
    },
    {
        "type": 2,
        "name": "北京市第五中学分校（地安门校区）",
        "phone": 64039676,
        "address": "北京市东城区地安门东大街127号",
        "typeName": "中学",
        "id": 1242
    },
    {
        "type": 2,
        "name": "北京市国子监中学",
        "phone": 64039676,
        "address": "北京市东城区国子监街26号",
        "typeName": "中学",
        "id": 1243
    },
    {
        "type": 2,
        "name": "北京市第五中学分校(鼓楼校区)",
        "phone": 64039650,
        "address": "北京市东城区鼓楼东大街127号",
        "typeName": "中学",
        "id": 1244
    },
    {
        "type": 2,
        "name": "北京市第五中学",
        "phone": 64068564,
        "address": "北京市东城区细管胡同13号",
        "typeName": "中学",
        "id": 1245
    },
    {
        "type": 2,
        "name": "北京市第二中学",
        "phone": 65255945,
        "address": "北京市东城区内务部街15号",
        "typeName": "中学",
        "id": 1246
    },
    {
        "type": 2,
        "name": "北京市广渠门中学南校区",
        "phone": 67112448,
        "address": "东城区夕照寺中街19号",
        "typeName": "中学",
        "id": 1247
    },
    {
        "type": 2,
        "name": "北京汇文实验中学（125中学）",
        "phone": 65243696,
        "address": "北京市东城区崇文门内大街后沟胡同乙2号",
        "typeName": "中学",
        "id": 1248
    },
    {
        "type": 2,
        "name": "北京市第五十中学",
        "phone": 67173905,
        "address": "东城区夕照寺街13号",
        "typeName": "中学",
        "id": 1249
    },
    {
        "type": 2,
        "name": "北京汇文中学",
        "phone": 67117375,
        "address": "北京市东城区培新街6号",
        "typeName": "中学",
        "id": 1250
    },
    {
        "type": 2,
        "name": "北京市第二十二中学交道口校区",
        "phone": 64042225,
        "address": "北京市东城区交道口东大街77号",
        "typeName": "中学",
        "id": 1251
    },
    {
        "type": 2,
        "name": "北京市第二十二中学安定门校区",
        "phone": 64042225,
        "address": "北京市东城区交道口北二条43号",
        "typeName": "中学",
        "id": 1252
    },
    {
        "type": 2,
        "name": "北京市第一六五中学",
        "phone": 64043902,
        "address": "北京市东城区育群胡同45号",
        "typeName": "中学",
        "id": 1253
    },
    {
        "type": 2,
        "name": "北京市第二十五中学",
        "phone": 65257525,
        "address": "东城区灯市口大街55号",
        "typeName": "中学",
        "id": 1254
    },
    {
        "type": 2,
        "name": "中央工艺美术学院附属中学（北京市国际美术学校）",
        "phone": 64674127,
        "address": "北京市东城区东直门外胡家园小区23号",
        "typeName": "中学",
        "id": 1255
    },
    {
        "type": 2,
        "name": "北京市第一四二中学（北京宏志中学）",
        "phone": 64274985,
        "address": "北京市东城和平里中街43号",
        "typeName": "中学",
        "id": 1256
    },
    {
        "type": 2,
        "name": "北京市第一六六中学（灯市口校区）",
        "phone": 65253226,
        "address": "东城区同福夹道3号",
        "typeName": "中学",
        "id": 1257
    },
    {
        "type": 2,
        "name": "北京市第一七一中学西校区",
        "phone": "010-84122418",
        "address": "东城区安定门外青年湖南街23号",
        "typeName": "中学",
        "id": 1258
    },
    {
        "type": 2,
        "name": "北京市第一七一中学东校区",
        "phone": "010-64212702",
        "address": "东城区和平里北街8号",
        "typeName": "中学",
        "id": 1259
    },
    {
        "type": 2,
        "name": "北京市第九十六中学",
        "phone": 67014822,
        "address": "北京市东城区崇文门西小街3号",
        "typeName": "中学",
        "id": 1260
    },
    {
        "type": 2,
        "name": "北京市第九十六中学南校区",
        "phone": 67014822,
        "address": "北京市东城区新世界家园甲5号",
        "typeName": "中学",
        "id": 1261
    },
    {
        "type": 2,
        "name": "北京市第十一中学",
        "phone": 67025095,
        "address": "北京市东城区金鱼池西区1号",
        "typeName": "中学",
        "id": 1262
    },
    {
        "type": 2,
        "name": "北京市第五十五中学",
        "phone": 64164252,
        "address": "北京市东城区新中街12号",
        "typeName": "中学",
        "id": 1263
    },
    {
        "type": 2,
        "name": "北京市第五十中学分校",
        "phone": 67263338,
        "address": "北京市东城区永外安乐林路14号",
        "typeName": "中学",
        "id": 1264
    },
    {
        "type": 2,
        "name": "北京市第二十四中学",
        "phone": "010-65254402",
        "address": "北京市东城区外交部街31号",
        "typeName": "中学",
        "id": 1265
    },
    {
        "type": 2,
        "name": "北京市第二十七中学",
        "phone": 65255586,
        "address": "北京市东华门大街智德前巷11号",
        "typeName": "中学",
        "id": 1266
    },
    {
        "type": 2,
        "name": "北京市崇文门中学",
        "phone": 67182515,
        "address": "东城区东花市北里西区5号",
        "typeName": "中学",
        "id": 1267
    },
    {
        "type": 2,
        "name": "北京市第五十四中学",
        "phone": 84221682,
        "address": "北京市东城区和平里六区九号",
        "typeName": "中学",
        "id": 1268
    },
    {
        "type": 2,
        "name": "北京市龙潭中学",
        "phone": 67148924,
        "address": "北京市东城区板厂南里3号",
        "typeName": "中学",
        "id": 1269
    },
    {
        "type": 2,
        "name": "北京市广渠门中学",
        "phone": 67126226,
        "address": "东城区白桥大街甲一号",
        "typeName": "中学",
        "id": 1270
    },
    {
        "type": 2,
        "name": "北京市第六十五中学",
        "phone": 65251745,
        "address": "北京市东城区北河沿大街115号",
        "typeName": "中学",
        "id": 1271
    },
    {
        "type": 2,
        "name": "北京市第一六六中学(东四六条校区)",
        "phone": 64042204,
        "address": "东城区东四六条甲44号",
        "typeName": "中学",
        "id": 1272
    },
    {
        "type": 2,
        "name": "北京市第五十四中学分校",
        "phone": 64217992,
        "address": "北京市东城区和平里中街甲37号",
        "typeName": "中学",
        "id": 1273
    },
    {
        "type": 2,
        "name": "北京市第二十一中学",
        "phone": 64043394,
        "address": "北京市东城区交道口北三条57号",
        "typeName": "中学",
        "id": 1274
    },
    {
        "type": 2,
        "name": "北京市第八中学（东里校区）",
        "phone": 59733788,
        "address": "北京市西城区西便门东大街乙2号",
        "typeName": "中学",
        "id": 1275
    },
    {
        "type": 2,
        "name": "北京市第八中学（木樨地校区）",
        "phone": 59733951,
        "address": "北京市西城区复兴门外大街乙20号",
        "typeName": "中学",
        "id": 1276
    },
    {
        "type": 2,
        "name": "北京市第十五中学初中部",
        "phone": 63520237,
        "address": "西城区盆儿胡同58号",
        "typeName": "中学",
        "id": 1277
    },
    {
        "type": 2,
        "name": "北京市第三十五中（初中部）",
        "phone": 68052350,
        "address": "北京市西城区月坛北街丙一号院",
        "typeName": "中学",
        "id": 1278
    },
    {
        "type": 2,
        "name": "北京市育才学校龙泉校区",
        "phone": "52829888- 3102",
        "address": "西城区龙泉胡同11号",
        "typeName": "中学",
        "id": 1279
    },
    {
        "type": 2,
        "name": "三帆中学裕中校区",
        "phone": 62036242,
        "address": "北京市西城区裕中西里21号",
        "typeName": "中学",
        "id": 1280
    },
    {
        "type": 2,
        "name": "北京市三帆中学",
        "phone": 62043088,
        "address": "北京市西城区新风街7号",
        "typeName": "中学",
        "id": 1281
    },
    {
        "type": 2,
        "name": "北京市西城区育华中学",
        "phone": 58824172,
        "address": "北京市昌平区沙河镇七里渠南村531号育华中学",
        "typeName": "中学",
        "id": 1282
    },
    {
        "type": 2,
        "name": "北京市第八中学（本部）",
        "phone": 59733532,
        "address": "北京市西城区学院小街2号",
        "typeName": "中学",
        "id": 1283
    },
    {
        "type": 2,
        "name": "北京市第八中学（西里校区）",
        "phone": 56548500,
        "address": "北京市西城区西便门西里18号",
        "typeName": "中学",
        "id": 1284
    },
    {
        "type": 2,
        "name": "北京市第八中学（白云观校区）",
        "phone": 59733877,
        "address": "北京市西城区白云观街北里12号",
        "typeName": "中学",
        "id": 1285
    },
    {
        "type": 2,
        "name": "北京市第三十五中(高中部)",
        "phone": 66022895,
        "address": "赵登禹路8号",
        "typeName": "中学",
        "id": 1286
    },
    {
        "type": 2,
        "name": "北京市第三十五中（国际部）",
        "phone": 63886500,
        "address": "北京市大兴区采育镇育镇街1号",
        "typeName": "中学",
        "id": 1287
    },
    {
        "type": 2,
        "name": "北京西城职业学校（西便门校区）",
        "phone": 83150497,
        "address": "北京市西城区西便门内大街69号",
        "typeName": "中学",
        "id": 1288
    },
    {
        "type": 2,
        "name": "北京市实美职业学校",
        "phone": 68347677,
        "address": "北京市西城区百万庄大街19号",
        "typeName": "中学",
        "id": 1289
    },
    {
        "type": 2,
        "name": "北京市实验职业学校",
        "phone": 63461004,
        "address": "北京市西城区菜园街13号",
        "typeName": "中学",
        "id": 1290
    },
    {
        "type": 2,
        "name": "北京市实验职业学校（东校区）",
        "phone": 63461004,
        "address": "北京市西城区菜园街26号",
        "typeName": "中学",
        "id": 1291
    },
    {
        "type": 2,
        "name": "北京市外事学校",
        "phone": 66164731,
        "address": "北京市西城区西直门内永祥胡同3号",
        "typeName": "中学",
        "id": 1292
    },
    {
        "type": 2,
        "name": "北京师范大学第二附属中学（国际部）",
        "phone": 82738787,
        "address": "北京市海淀区宝盛里观澳园18",
        "typeName": "中学",
        "id": 1293
    },
    {
        "type": 2,
        "name": "北京市实美职业学校（百万庄校区）",
        "phone": 68347677,
        "address": "北京市西城区百万庄大街19号",
        "typeName": "中学",
        "id": 1294
    },
    {
        "type": 2,
        "name": "北京市实美职业学校（安德路校区）",
        "phone": 62057958,
        "address": "北京市西城区安德路大井胡同6号",
        "typeName": "中学",
        "id": 1295
    },
    {
        "type": 2,
        "name": "北京市第四中学",
        "phone": 66175061,
        "address": "北京市西城区西黄城根北街甲2号",
        "typeName": "中学",
        "id": 1296
    },
    {
        "type": 2,
        "name": "北京市第四中学东校区",
        "phone": 66539648,
        "address": "北京市地安门西大街教场胡同4号",
        "typeName": "中学",
        "id": 1297
    },
    {
        "type": 2,
        "name": "北京市第四中学广外校区",
        "phone": 63540870,
        "address": "北京市广安门外红居街20号",
        "typeName": "中学",
        "id": 1298
    },
    {
        "type": 2,
        "name": "北京市第四中学复兴门国际校区",
        "phone": 66539982,
        "address": "北京市复兴门内大街西铁匠胡同宗帽头条2号",
        "typeName": "中学",
        "id": 1299
    },
    {
        "type": 2,
        "name": "北京市第七中学",
        "phone": 62076509,
        "address": "北京市西城区安德路69号",
        "typeName": "中学",
        "id": 1300
    },
    {
        "type": 2,
        "name": "北京市第八中学（百万庄校区）",
        "phone": 56548606,
        "address": "北京市西城区百万庄南街14号",
        "typeName": "中学",
        "id": 1301
    },
    {
        "type": 2,
        "name": "北京市第十三中学",
        "phone": 56876807,
        "address": "北京西城区柳荫街27号",
        "typeName": "中学",
        "id": 1302
    },
    {
        "type": 2,
        "name": "北京市第三十一中学",
        "phone": 66011125,
        "address": "北京市西城区西绒线胡同33号",
        "typeName": "中学",
        "id": 1303
    },
    {
        "type": 2,
        "name": "北京市第三十九中学",
        "phone": 66175807,
        "address": "西城区西黄城根北街6号",
        "typeName": "中学",
        "id": 1304
    },
    {
        "type": 2,
        "name": "北京市第四十一中学",
        "phone": 66160807,
        "address": "西四北二条58号",
        "typeName": "中学",
        "id": 1305
    },
    {
        "type": 2,
        "name": "北京市第四十三中学东址",
        "phone": 63030329,
        "address": "北京市西城区后孙公园胡同37号",
        "typeName": "中学",
        "id": 1306
    },
    {
        "type": 2,
        "name": "北京市第四十三中学西址",
        "phone": 63030329,
        "address": "北京市西城区后孙公园胡同37号",
        "typeName": "中学",
        "id": 1307
    },
    {
        "type": 2,
        "name": "北京市第五十六中学",
        "phone": 68313995,
        "address": "北京市西城区文兴街3号",
        "typeName": "中学",
        "id": 1308
    },
    {
        "type": 2,
        "name": "北京市第六十六中学（北址）",
        "phone": 83546966,
        "address": "西城区枣林前街111号",
        "typeName": "中学",
        "id": 1309
    },
    {
        "type": 2,
        "name": "北京市第六十六中学（南址）",
        "phone": 63586615,
        "address": "白广路33号",
        "typeName": "中学",
        "id": 1310
    },
    {
        "type": 2,
        "name": "北京市第一五九中学",
        "phone": 66033672,
        "address": "西城区金融街街道王府仓胡同23号",
        "typeName": "中学",
        "id": 1311
    },
    {
        "type": 2,
        "name": "北京市第一六一中学（北校区）",
        "phone": 66016899,
        "address": "北京市西城区北长街113号",
        "typeName": "中学",
        "id": 1312
    },
    {
        "type": 2,
        "name": "北京市第一六一中学（中校区）",
        "phone": 66054717,
        "address": "北京市西城区大宴乐胡同11号",
        "typeName": "中学",
        "id": 1313
    },
    {
        "type": 2,
        "name": "北京市第一六一中学（南校区）",
        "phone": 66067845,
        "address": "北京市西城区前门西大街43号",
        "typeName": "中学",
        "id": 1314
    },
    {
        "type": 2,
        "name": "北京市第一六一中学分校",
        "phone": 68522558,
        "address": "北京市西城区月坛北街18号",
        "typeName": "中学",
        "id": 1315
    },
    {
        "type": 2,
        "name": "北京市第三中学",
        "phone": 66183974,
        "address": "北京市西城区富国街3号",
        "typeName": "中学",
        "id": 1316
    },
    {
        "type": 2,
        "name": "北京市北纬路中学",
        "phone": 63031256,
        "address": "北京市西城区北纬路46号",
        "typeName": "中学",
        "id": 1317
    },
    {
        "type": 2,
        "name": "北京师范大学第二附属中学西城实验学校",
        "phone": 62053478,
        "address": "北京市西城区安德路116号",
        "typeName": "中学",
        "id": 1318
    },
    {
        "type": 2,
        "name": "北京师范大学实验二龙路中学",
        "phone": 66020463,
        "address": "北京市西城区大木仓胡同39号",
        "typeName": "中学",
        "id": 1319
    },
    {
        "type": 2,
        "name": "北京市回民学校",
        "phone": 63046958,
        "address": "西城区广安门内大街225号",
        "typeName": "中学",
        "id": 1320
    },
    {
        "type": 2,
        "name": "北京教育学院附属中学",
        "phone": 62252385,
        "address": "新街口四条48号",
        "typeName": "中学",
        "id": 1321
    },
    {
        "type": 2,
        "name": "北京市鲁迅中学",
        "phone": 66035483,
        "address": "北京市西城区新文化街45号",
        "typeName": "中学",
        "id": 1322
    },
    {
        "type": 2,
        "name": "北京师范大学附属中学",
        "phone": 63030007,
        "address": "北京市西城区南新华街18号",
        "typeName": "中学",
        "id": 1323
    },
    {
        "type": 2,
        "name": "北京师范大学附属中学分校",
        "phone": 63534619,
        "address": "北京市西城区太平街西巷四号",
        "typeName": "中学",
        "id": 1324
    },
    {
        "type": 2,
        "name": "北京师范大学实验华夏女子中学",
        "phone": 63261984,
        "address": "北京市西城区红莲中里12号",
        "typeName": "中学",
        "id": 1325
    },
    {
        "type": 2,
        "name": "北京师范大学附属实验中学",
        "phone": 66526188,
        "address": "北京市西城区二龙路14号",
        "typeName": "中学",
        "id": 1326
    },
    {
        "type": 2,
        "name": "北京市铁路第二中学",
        "phone": 68014724,
        "address": "西城区月坛西街5号",
        "typeName": "中学",
        "id": 1327
    },
    {
        "type": 2,
        "name": "北京市铁路第二中学南址",
        "phone": 68014724,
        "address": "西城区三里河东路9号",
        "typeName": "中学",
        "id": 1328
    },
    {
        "type": 2,
        "name": "北京市宣武外国语实验学校",
        "phone": 63522858,
        "address": "北京市西城区广安门外莲花河胡同四号",
        "typeName": "中学",
        "id": 1329
    },
    {
        "type": 2,
        "name": "北京市月坛中学",
        "phone": 68045321,
        "address": "北京市西城区南礼士路二条1号",
        "typeName": "中学",
        "id": 1330
    },
    {
        "type": 2,
        "name": "北京师范大学第二附属中学",
        "phone": 62042244,
        "address": "北京市西城区新街口外大街12号",
        "typeName": "中学",
        "id": 1331
    },
    {
        "type": 2,
        "name": "北京市劲松第一中学",
        "phone": 67764061,
        "address": "朝阳区劲松一区125楼",
        "typeName": "中学",
        "id": 1332
    },
    {
        "type": 2,
        "name": "北京市楼梓庄中学",
        "phone": 84319147,
        "address": "北京市朝阳区金盏乡楼梓庄村北后街二号",
        "typeName": "中学",
        "id": 1333
    },
    {
        "type": 2,
        "name": "北京工业大学附属中学双桥分校",
        "phone": 85365502,
        "address": "朝阳区管庄乡双桥中路",
        "typeName": "中学",
        "id": 1334
    },
    {
        "type": 2,
        "name": "北京市第八十中学实验学校温榆河分校",
        "phone": "010-64385341",
        "address": "北京市朝阳区崔各庄乡崔各庄村北",
        "typeName": "中学",
        "id": 1335
    },
    {
        "type": 2,
        "name": "北京市黑庄户中学",
        "phone": 85385005,
        "address": "北京市朝阳区黑庄户政府东侧",
        "typeName": "中学",
        "id": 1336
    },
    {
        "type": 2,
        "name": "北京市日坛中学初中部",
        "phone": 65679323,
        "address": "朝阳区建外永安西里10号",
        "typeName": "中学",
        "id": 1337
    },
    {
        "type": 2,
        "name": "北京市朝阳区教育研究中心附属学校",
        "phone": "010-64372045",
        "address": "北京市朝阳区酒仙桥南路甲七号",
        "typeName": "中学",
        "id": 1338
    },
    {
        "type": 2,
        "name": "北京市朝阳区教育研究中心附属学校（分部）",
        "phone": "010-64372045",
        "address": "北京市朝阳区酒仙桥十街坊三号",
        "typeName": "中学",
        "id": 1339
    },
    {
        "type": 2,
        "name": "北京市三里屯一中（幸福村校区）",
        "phone": 64167721,
        "address": "朝阳区幸福村路41号",
        "typeName": "中学",
        "id": 1340
    },
    {
        "type": 2,
        "name": "北京市第七十一中学",
        "phone": 67643226,
        "address": "北京市朝阳区小红门龙爪树834号",
        "typeName": "中学",
        "id": 1341
    },
    {
        "type": 2,
        "name": "北京市第十六中学",
        "phone": 1051883248,
        "address": "北京市朝阳区东坝乡红松园甲3号",
        "typeName": "中学",
        "id": 1342
    },
    {
        "type": 2,
        "name": "北京市陈经纶中学本部初中",
        "phone": 85618241,
        "address": "北京市朝阳区东大桥路甲15号",
        "typeName": "中学",
        "id": 1343
    },
    {
        "type": 2,
        "name": "北京市十八里店中学",
        "phone": 67472284,
        "address": "北京市朝阳区十八里店乡周庄甲一号",
        "typeName": "中学",
        "id": 1344
    },
    {
        "type": 2,
        "name": "北京工业大学实验学校南校区",
        "phone": 67779121,
        "address": "北京市朝阳区华威西里48楼",
        "typeName": "中学",
        "id": 1345
    },
    {
        "type": 2,
        "name": "北京工业大学实验学校北校区",
        "phone": 67783070,
        "address": "北京市朝阳区劲松四区406楼",
        "typeName": "中学",
        "id": 1346
    },
    {
        "type": 2,
        "name": "北京市高家园中学",
        "phone": 64345274,
        "address": "北京市朝阳区高家园小区210号",
        "typeName": "中学",
        "id": 1347
    },
    {
        "type": 2,
        "name": "中国传媒大学附属中学",
        "phone": 65761528,
        "address": "北京市朝阳区大黄庄西里一号",
        "typeName": "中学",
        "id": 1348
    },
    {
        "type": 2,
        "name": "北京市呼家楼中学",
        "phone": "010-65081105",
        "address": "北京市朝阳区呼家楼西里南街2号",
        "typeName": "中学",
        "id": 1349
    },
    {
        "type": 2,
        "name": "北京市电气工程学校（将台路校区）",
        "phone": 64370704,
        "address": "北京市朝阳区将台路4号",
        "typeName": "中学",
        "id": 1350
    },
    {
        "type": 2,
        "name": "北京市电气工程学校（甘露园校区）",
        "phone": 85744127,
        "address": "北京市朝阳区甘露园南里60号",
        "typeName": "中学",
        "id": 1351
    },
    {
        "type": 2,
        "name": "北京市电气工程学校（管庄校区）",
        "phone": 52114035,
        "address": "朝阳区八里桥塔营东甲1号",
        "typeName": "中学",
        "id": 1352
    },
    {
        "type": 2,
        "name": "北京市求实职业学校安贞里校区",
        "phone": 64414012,
        "address": "北京市朝阳区安贞里三区11号楼",
        "typeName": "中学",
        "id": 1353
    },
    {
        "type": 2,
        "name": "北京市求实职业学校望京校区",
        "phone": 64391603,
        "address": "北京市朝阳区望京北路20号",
        "typeName": "中学",
        "id": 1354
    },
    {
        "type": 2,
        "name": "北京市求实职业学校亚运村校区",
        "phone": 64919722,
        "address": "北京市朝阳区安慧里三区21楼",
        "typeName": "中学",
        "id": 1355
    },
    {
        "type": 2,
        "name": "北京市求实职业学校+团结湖校区一部",
        "phone": 85972408,
        "address": "北京市朝阳区团结湖北三条10号楼",
        "typeName": "中学",
        "id": 1356
    },
    {
        "type": 2,
        "name": "北京市求实职业学校+团结湖校区二部",
        "phone": 65828051,
        "address": "北京市区朝阳区团结湖北二条5号楼",
        "typeName": "中学",
        "id": 1357
    },
    {
        "type": 2,
        "name": "北京市求实职业学校+机场校区",
        "phone": 64591882,
        "address": "北京市朝阳区首都机场南路东里21号楼",
        "typeName": "中学",
        "id": 1358
    },
    {
        "type": 2,
        "name": "北京市陈经纶中学本部高中",
        "phone": 85622206,
        "address": "北京市朝阳区朝外大街38号",
        "typeName": "中学",
        "id": 1359
    },
    {
        "type": 2,
        "name": "北京市日坛中学高中部",
        "phone": "010-65072260",
        "address": "朝阳区建外光华西里4号",
        "typeName": "中学",
        "id": 1360
    },
    {
        "type": 2,
        "name": "北京市三里屯一中（三里屯校区）",
        "phone": 64162264,
        "address": "北京市朝阳区北三里屯5号",
        "typeName": "中学",
        "id": 1361
    },
    {
        "type": 2,
        "name": "北京市第十七中学高碑店校区",
        "phone": "85758343-8601",
        "address": "高碑店西店村甲一号",
        "typeName": "中学",
        "id": 1362
    },
    {
        "type": 2,
        "name": "北京市劲松职业高中常营校区",
        "phone": 57913826,
        "address": "常营住欣家园102号院",
        "typeName": "中学",
        "id": 1363
    },
    {
        "type": 2,
        "name": "北京市劲松职业高中劲松校区",
        "phone": 67710311,
        "address": "劲松八区811楼",
        "typeName": "中学",
        "id": 1364
    },
    {
        "type": 2,
        "name": "北京市劲松职业高中双龙校区",
        "phone": 87315534,
        "address": "双龙南里双龙小区135号",
        "typeName": "中学",
        "id": 1365
    },
    {
        "type": 2,
        "name": "北京市劲松职业高中综合高中部",
        "phone": 64676361,
        "address": "新源里街14号楼",
        "typeName": "中学",
        "id": 1366
    },
    {
        "type": 2,
        "name": "北京市第八十中学",
        "phone": 58047000,
        "address": "北京市朝阳区望京北路甲16号",
        "typeName": "中学",
        "id": 1367
    },
    {
        "type": 2,
        "name": "北京市垂杨柳中学",
        "phone": 1067345732,
        "address": "北京市朝阳区十里河弘善家园201楼（周转办学）",
        "typeName": "中学",
        "id": 1368
    },
    {
        "type": 2,
        "name": "对外经济贸易大学附属中学（北京市第九十四中学）",
        "phone": 64790087,
        "address": "北京市朝阳区南湖中园315号",
        "typeName": "中学",
        "id": 1369
    },
    {
        "type": 2,
        "name": "东北师范大学附属中学朝阳学校",
        "phone": "010-52060100",
        "address": "北京市朝阳区金盏金融服务园区1112-14",
        "typeName": "中学",
        "id": 1370
    },
    {
        "type": 2,
        "name": "北京市华侨城黄冈中学（华侨城校区）",
        "phone": 67385479,
        "address": "朝阳区金蝉欢乐园1号院甲一号",
        "typeName": "中学",
        "id": 1371
    },
    {
        "type": 2,
        "name": "北京市华侨城黄冈中学（翠成校区）",
        "phone": 67361203,
        "address": "朝阳区翠成馨园317楼",
        "typeName": "中学",
        "id": 1372
    },
    {
        "type": 2,
        "name": "北京市华侨城黄冈中学（垡头校区）",
        "phone": 67385479,
        "address": "朝阳区垡头西里3区-2号",
        "typeName": "中学",
        "id": 1373
    },
    {
        "type": 2,
        "name": "北京市团结湖第三中学（高中部）",
        "phone": 85981827,
        "address": "北京市团结湖中路8号",
        "typeName": "中学",
        "id": 1374
    },
    {
        "type": 2,
        "name": "北京市十一学校龙樾实验中学",
        "phone": 88279206,
        "address": "北京市海淀区小营C3地块",
        "typeName": "中学",
        "id": 1375
    },
    {
        "type": 2,
        "name": "北航实验学校分校",
        "phone": 62026980,
        "address": "北京市海淀区蓟门里小区北甲11号",
        "typeName": "中学",
        "id": 1376
    },
    {
        "type": 2,
        "name": "首都师范大学附属中学第一分校",
        "phone": 56840621,
        "address": "北京市海淀区阜石路甲59号",
        "typeName": "中学",
        "id": 1377
    },
    {
        "type": 2,
        "name": "清华大学附属中学上地学校",
        "phone": 62648300,
        "address": "北京市海淀区中关村北大街2号",
        "typeName": "中学",
        "id": 1378
    },
    {
        "type": 2,
        "name": "北京市清河中学",
        "phone": 62927409,
        "address": "北京市海淀区清河镇一街83号",
        "typeName": "中学",
        "id": 1379
    },
    {
        "type": 2,
        "name": "北京市上庄中学",
        "phone": 82474609,
        "address": "北京市海淀区上庄镇43号",
        "typeName": "中学",
        "id": 1380
    },
    {
        "type": 2,
        "name": "北京市上庄第二中学",
        "phone": 62471523,
        "address": "北京市海淀区上庄镇东小营2号",
        "typeName": "中学",
        "id": 1381
    },
    {
        "type": 2,
        "name": "中国人民大学附属中学翠微学校初中校区",
        "phone": 68279667,
        "address": "北京市海淀区复兴路二十一号",
        "typeName": "中学",
        "id": 1382
    },
    {
        "type": 2,
        "name": "北京市第一零一中学初中部",
        "phone": 82115548,
        "address": "海淀双榆树东里35号",
        "typeName": "中学",
        "id": 1383
    },
    {
        "type": 2,
        "name": "北京市第一零一中学温泉校区",
        "phone": 62488399,
        "address": "海淀区温泉镇叠风路",
        "typeName": "中学",
        "id": 1384
    },
    {
        "type": 2,
        "name": "北京市上地实验学校",
        "phone": 62975206,
        "address": "北京市海淀区上地二街3号",
        "typeName": "中学",
        "id": 1385
    },
    {
        "type": 2,
        "name": "北京市第二十中学（新都分校）",
        "phone": 82913980,
        "address": "海淀区西三旗新都路3号",
        "typeName": "中学",
        "id": 1386
    },
    {
        "type": 2,
        "name": "北京市海淀实验中学东校区",
        "phone": 68482185,
        "address": "北京市海淀区阜成路白堆子131号",
        "typeName": "中学",
        "id": 1387
    },
    {
        "type": 2,
        "name": "北京市健翔学校",
        "phone": 62035801,
        "address": "北京市海淀区花园北路32号",
        "typeName": "中学",
        "id": 1388
    },
    {
        "type": 2,
        "name": "中央民族大学附属中学",
        "phone": 68932088,
        "address": "北京市海淀区法华寺甲5号",
        "typeName": "中学",
        "id": 1389
    },
    {
        "type": 2,
        "name": "中国人民大学附属中学翠微学校高中校区",
        "phone": 68279667,
        "address": "北京市海淀区翠微路一号",
        "typeName": "中学",
        "id": 1390
    },
    {
        "type": 2,
        "name": "北京市海淀实验中学西校区",
        "phone": 68709126,
        "address": "北京市海淀区紫竹院路96号",
        "typeName": "中学",
        "id": 1391
    },
    {
        "type": 2,
        "name": "北京市信息管理学校中关村校区",
        "phone": 62990493,
        "address": "北京市海淀区彩和坊甲10号",
        "typeName": "中学",
        "id": 1392
    },
    {
        "type": 2,
        "name": "中国人民大学附属中学",
        "phone": 62511859,
        "address": "北京市海淀区中关村大街37号",
        "typeName": "中学",
        "id": 1393
    },
    {
        "type": 2,
        "name": "中国人民大学附属中学第二分校",
        "phone": "88457078-274",
        "address": "北京市海淀区蓝靛厂路25号",
        "typeName": "中学",
        "id": 1394
    },
    {
        "type": 2,
        "name": "人大附中西山学校",
        "phone": 62833690,
        "address": "北京市海淀区马连洼南路9号",
        "typeName": "中学",
        "id": 1395
    },
    {
        "type": 2,
        "name": "北京市第一O一中学",
        "phone": 51633179,
        "address": "北京市海淀区颐和园路11号",
        "typeName": "中学",
        "id": 1396
    },
    {
        "type": 2,
        "name": "北京市十一学校",
        "phone": 88628801,
        "address": "北京市海淀区玉泉路66号",
        "typeName": "中学",
        "id": 1397
    },
    {
        "type": 2,
        "name": "北京十一实验中学",
        "phone": 68156905,
        "address": "北京市海淀区太平路8号",
        "typeName": "中学",
        "id": 1398
    },
    {
        "type": 2,
        "name": "北方交通大学附属中学北校区",
        "phone": 62118576,
        "address": "北京市海淀区皂君庙12号",
        "typeName": "中学",
        "id": 1399
    },
    {
        "type": 2,
        "name": "北京交通大学附属中学南校区",
        "phone": 62230714,
        "address": "北京市海淀区上园村3号",
        "typeName": "中学",
        "id": 1400
    },
    {
        "type": 2,
        "name": "北方交通大学附属中学东校区",
        "phone": 62188138,
        "address": "海淀区马甸西路2号",
        "typeName": "中学",
        "id": 1401
    },
    {
        "type": 2,
        "name": "北方交通大学附属中学第二分校",
        "phone": 62173476,
        "address": "北京市海淀区西外高梁桥斜街3号",
        "typeName": "中学",
        "id": 1402
    },
    {
        "type": 2,
        "name": "北方交通大学附属中学分校",
        "phone": 62233056,
        "address": "北京市海淀区学院路一号",
        "typeName": "中学",
        "id": 1403
    },
    {
        "type": 2,
        "name": "北京市八一学校附属玉泉中学",
        "phone": 62876377,
        "address": "北京市海淀区厢红旗东门外甲3号",
        "typeName": "中学",
        "id": 1404
    },
    {
        "type": 2,
        "name": "北京市中关村中学",
        "phone": 62619892,
        "address": "北京市海淀区科学院南路甲14号",
        "typeName": "中学",
        "id": 1405
    },
    {
        "type": 2,
        "name": "北京市中关村中学双榆树校区",
        "phone": 62550710,
        "address": "北京市海淀区双榆树北里25号",
        "typeName": "中学",
        "id": 1406
    },
    {
        "type": 2,
        "name": "北京市中关村中学清华园校区",
        "phone": 62616700,
        "address": "北京市海淀区清华大学南门",
        "typeName": "中学",
        "id": 1407
    },
    {
        "type": 2,
        "name": "北京市中关村中学知春分校",
        "phone": 82125874,
        "address": "北京市海淀区知春东里12号",
        "typeName": "中学",
        "id": 1408
    },
    {
        "type": 2,
        "name": "北京市第二十中学",
        "phone": 62917568,
        "address": "北京市海淀区清河小营西路11号",
        "typeName": "中学",
        "id": 1409
    },
    {
        "type": 2,
        "name": "首都师范大学附属中学",
        "phone": 68902963,
        "address": "北京市海淀区北洼路33号",
        "typeName": "中学",
        "id": 1410
    },
    {
        "type": 2,
        "name": "首都师范大学第二附属中学（花园村校区）",
        "phone": 68713821,
        "address": "北京市海淀区增光路50号",
        "typeName": "中学",
        "id": 1411
    },
    {
        "type": 2,
        "name": "首都师范大学第二附属中学（曙光校区）",
        "phone": 88440883,
        "address": "北京市海淀区彰化路8号",
        "typeName": "中学",
        "id": 1412
    },
    {
        "type": 2,
        "name": "清华大学附属中学",
        "phone": 62784695,
        "address": "北京市海淀区中关村北大街清华大学北侧",
        "typeName": "中学",
        "id": 1413
    },
    {
        "type": 2,
        "name": "北京理工大学附属中学（南校区）",
        "phone": "010-88123782",
        "address": "北京市海淀区定慧西里17号",
        "typeName": "中学",
        "id": 1414
    },
    {
        "type": 2,
        "name": "北京大学附属中学",
        "phone": 62579602,
        "address": "北京市海淀区大泥湾路甲82号",
        "typeName": "中学",
        "id": 1415
    },
    {
        "type": 2,
        "name": "北京医学院附属中学",
        "phone": 62017364,
        "address": "北京市海淀区花园北路40号",
        "typeName": "中学",
        "id": 1416
    },
    {
        "type": 2,
        "name": "北京市第十九中学东校区",
        "phone": "010-82518546",
        "address": "北京市海淀区万泉河路83号",
        "typeName": "中学",
        "id": 1417
    },
    {
        "type": 2,
        "name": "北京市第十九中学阳春光华校区",
        "phone": "010-82518546",
        "address": "北京市海淀区知泉路",
        "typeName": "中学",
        "id": 1418
    },
    {
        "type": 2,
        "name": "北京市海淀区教师进修学校附属实验学校北校区",
        "phone": 88509615,
        "address": "北京市海淀区远大路34号",
        "typeName": "中学",
        "id": 1419
    },
    {
        "type": 2,
        "name": "北京市海淀区教师进修学校附属实验学校南校区",
        "phone": 88132698,
        "address": "北京市海淀区八里庄北里20号",
        "typeName": "中学",
        "id": 1420
    },
    {
        "type": 2,
        "name": "北京市第五十七中学",
        "phone": 63269351,
        "address": "北京市海淀区北蜂窝中路6号",
        "typeName": "中学",
        "id": 1421
    },
    {
        "type": 2,
        "name": "北京市育英中学",
        "phone": 68287770,
        "address": "翠微东里12号",
        "typeName": "中学",
        "id": 1422
    },
    {
        "type": 2,
        "name": "中国农业大学附属中学",
        "phone": 62896811,
        "address": "北京市海淀区圆明园西路三号",
        "typeName": "中学",
        "id": 1423
    },
    {
        "type": 2,
        "name": "北京科技大学附属中学",
        "phone": 82371799,
        "address": "北京市海淀区志新路36号",
        "typeName": "中学",
        "id": 1424
    },
    {
        "type": 2,
        "name": "北京石油学院附属中学",
        "phone": 82377672,
        "address": "北京市海淀区学院路20号",
        "typeName": "中学",
        "id": 1425
    },
    {
        "type": 2,
        "name": "北京师范大学第三附属中学",
        "phone": 62058676,
        "address": "北京市海淀区北三环中路甲36号",
        "typeName": "中学",
        "id": 1426
    },
    {
        "type": 2,
        "name": "北京市玉渊潭中学",
        "phone": 63983619,
        "address": "北京市海淀区羊坊店路皇亭子",
        "typeName": "中学",
        "id": 1427
    },
    {
        "type": 2,
        "name": "中国矿业大学（北京）附属中学",
        "phone": 62319913,
        "address": "北京市海淀区学院路丁11号",
        "typeName": "中学",
        "id": 1428
    },
    {
        "type": 2,
        "name": "中国地质大学附属中学",
        "phone": 82310924,
        "address": "北京市海淀区成府路20号",
        "typeName": "中学",
        "id": 1429
    },
    {
        "type": 2,
        "name": "北大附中香山学校",
        "phone": 82597165,
        "address": "北京市海淀区香山南辛村一号",
        "typeName": "中学",
        "id": 1430
    },
    {
        "type": 2,
        "name": "北京外国语大学附属中学",
        "phone": 68412071,
        "address": "北京市海淀区万寿寺北里14号",
        "typeName": "中学",
        "id": 1431
    },
    {
        "type": 2,
        "name": "北京市第四十七中学",
        "phone": 62450147,
        "address": "北京市海淀区苏家坨镇北安河村环谷园路8号",
        "typeName": "中学",
        "id": 1432
    },
    {
        "type": 2,
        "name": "北京市丰台第八中学（北大地校区）",
        "phone": 63841055,
        "address": "北京市丰台区丰台北大地西区一里16号",
        "typeName": "中学",
        "id": 1433
    },
    {
        "type": 2,
        "name": "北京市丰台第八中学（中海校区）",
        "phone": 63841056,
        "address": "北京市丰台区丰葆东路37号",
        "typeName": "中学",
        "id": 1434
    },
    {
        "type": 2,
        "name": "北京市第十八中学左安门分校",
        "phone": 87644502,
        "address": "北京市丰台区四方景园五区9号楼",
        "typeName": "中学",
        "id": 1435
    },
    {
        "type": 2,
        "name": "北京市芳星园中学",
        "phone": 67684478,
        "address": "北京市丰台区方庄芳城园三区10号",
        "typeName": "中学",
        "id": 1436
    },
    {
        "type": 2,
        "name": "北京十二中科丰校区",
        "phone": 63819681,
        "address": "丰台区西四环南路93号",
        "typeName": "中学",
        "id": 1437
    },
    {
        "type": 2,
        "name": "北京市首都师范大学附属丽泽中学南校区",
        "phone": 63853427,
        "address": "北京市丰台区新村四里3号",
        "typeName": "中学",
        "id": 1438
    },
    {
        "type": 2,
        "name": "北京市丰台区卢沟桥中学",
        "phone": 83219580,
        "address": "北京市丰台区晓月苑小区垂虹街2号",
        "typeName": "中学",
        "id": 1439
    },
    {
        "type": 2,
        "name": "北京市丰台区看丹中学",
        "phone": 63737583,
        "address": "北京市丰台区建新路19号",
        "typeName": "中学",
        "id": 1440
    },
    {
        "type": 2,
        "name": "北京市丰台区东铁匠营第二中学",
        "phone": 67251236,
        "address": "北京市丰台区石榴园北里4号",
        "typeName": "中学",
        "id": 1441
    },
    {
        "type": 2,
        "name": "北京市丰台区南苑中学",
        "phone": 67997576,
        "address": "北京市丰台区南苑西路1号",
        "typeName": "中学",
        "id": 1442
    },
    {
        "type": 2,
        "name": "北京市丰台区工读学校",
        "phone": 83876188,
        "address": "北京市丰台区长辛店西峰寺60号",
        "typeName": "中学",
        "id": 1443
    },
    {
        "type": 2,
        "name": "北京市丰台区丰台第二中学初中部",
        "phone": 68547155,
        "address": "丰台区小屯西路93号",
        "typeName": "中学",
        "id": 1444
    },
    {
        "type": 2,
        "name": "北京市佟麟阁中学",
        "phone": 67214327,
        "address": "北京市丰台区大红门路60号",
        "typeName": "中学",
        "id": 1445
    },
    {
        "type": 2,
        "name": "北京市右安门外国语学校",
        "phone": 63291397,
        "address": "北京市丰台区右安门外玉林东里三区19号",
        "typeName": "中学",
        "id": 1446
    },
    {
        "type": 2,
        "name": "北京市第十八中学西马金润校区",
        "phone": 87596496,
        "address": "丰台区西马场南里1区22号",
        "typeName": "中学",
        "id": 1447
    },
    {
        "type": 2,
        "name": "北京教育学院附属丰台实验学校分校",
        "phone": 63812202,
        "address": "北京市西四环中路103号",
        "typeName": "中学",
        "id": 1448
    },
    {
        "type": 2,
        "name": "北京市赵登禹学校（本部）",
        "phone": 6757612567573220,
        "address": "北京市丰台区马家堡嘉园一里17号",
        "typeName": "中学",
        "id": 1449
    },
    {
        "type": 2,
        "name": "北京市丰台区黄土岗中学",
        "phone": 83721356,
        "address": "丰台区南四环西路16号",
        "typeName": "中学",
        "id": 1450
    },
    {
        "type": 2,
        "name": "北京市丰台区槐树岭学校",
        "phone": 83842497,
        "address": "丰台区长辛店大灰厂东路19号",
        "typeName": "中学",
        "id": 1451
    },
    {
        "type": 2,
        "name": "北京市第十中学",
        "phone": 83881559,
        "address": "北京市丰台区长辛店南关东里1号",
        "typeName": "中学",
        "id": 1452
    },
    {
        "type": 2,
        "name": "北京市第十中学东校区",
        "phone": 63864232,
        "address": "北京市丰台区莲花池西路",
        "typeName": "中学",
        "id": 1453
    },
    {
        "type": 2,
        "name": "北京市航天中学",
        "phone": 67991659,
        "address": "北京市万源北路3号",
        "typeName": "中学",
        "id": 1454
    },
    {
        "type": 2,
        "name": "北京市第十二中学",
        "phone": 83666020,
        "address": "丰台区益泽路15号",
        "typeName": "中学",
        "id": 1455
    },
    {
        "type": 2,
        "name": "北京市首都师范大学附属丽泽中学",
        "phone": 63825292,
        "address": "北京市丰台区西四环南路62号",
        "typeName": "中学",
        "id": 1456
    },
    {
        "type": 2,
        "name": "北京市丰台区长辛店第一中学",
        "phone": 83876126,
        "address": "北京市丰台区长辛店桥西扶轮胡同45号",
        "typeName": "中学",
        "id": 1457
    },
    {
        "type": 2,
        "name": "首都经济贸易大学附属中学",
        "phone": 63780686,
        "address": "北京市丰台区丰台桥南新华街2号",
        "typeName": "中学",
        "id": 1458
    },
    {
        "type": 2,
        "name": "首都师范大学附属云岗中学",
        "phone": 68740542,
        "address": "北京市丰台区云岗北区东里1号",
        "typeName": "中学",
        "id": 1459
    },
    {
        "type": 2,
        "name": "北京师范大学第四附属中学（高中）",
        "phone": 83401226,
        "address": "丰台区右安门外翠林小区2里22号",
        "typeName": "中学",
        "id": 1460
    },
    {
        "type": 2,
        "name": "北京师范大学第四附属中学（初中）",
        "phone": 83401226,
        "address": "丰台区右安门外开阳里一街甲1号",
        "typeName": "中学",
        "id": 1461
    },
    {
        "type": 2,
        "name": "北京市丰台区丰台第二中学",
        "phone": 63800782,
        "address": "丰台区东安街头条3号",
        "typeName": "中学",
        "id": 1462
    },
    {
        "type": 2,
        "name": "北京市丰台区东铁匠营第一中学",
        "phone": 67621765,
        "address": "北京市丰台区刘家窑东里7号",
        "typeName": "中学",
        "id": 1463
    },
    {
        "type": 2,
        "name": "北京市第十八中学",
        "phone": 67696709,
        "address": "丰台区方庄芳星园二区11号",
        "typeName": "中学",
        "id": 1464
    },
    {
        "type": 2,
        "name": "北京市石景山区石景山中学",
        "phone": 88293411,
        "address": "北京市石景山区模式口西里甲31号院",
        "typeName": "中学",
        "id": 1465
    },
    {
        "type": 2,
        "name": "北京市石景山区实验中学分校",
        "phone": 68847301,
        "address": "北京市石景山区老山西里甲2号",
        "typeName": "中学",
        "id": 1466
    },
    {
        "type": 2,
        "name": "北京市第九中学分校",
        "phone": 88751337,
        "address": "北京市石景山区金顶北路8号",
        "typeName": "中学",
        "id": 1467
    },
    {
        "type": 2,
        "name": "北京市石景山区实验中学",
        "phone": 68847301,
        "address": "北京市石景山区八角路40号",
        "typeName": "中学",
        "id": 1468
    },
    {
        "type": 2,
        "name": "北京市石景山区实验中学东校区",
        "phone": 68847301,
        "address": "北京市石景山区老山西里2号北",
        "typeName": "中学",
        "id": 1469
    },
    {
        "type": 2,
        "name": "北方工业大学附属学校中学部",
        "phone": 88926569,
        "address": "北京市石景山区八角北路53号",
        "typeName": "中学",
        "id": 1470
    },
    {
        "type": 2,
        "name": "北京市高井中学",
        "phone": 88953819,
        "address": "北京市石景山区高井路26号",
        "typeName": "中学",
        "id": 1471
    },
    {
        "type": 2,
        "name": "首都师范大学附属苹果园中学分校",
        "phone": 88794698,
        "address": "北京市石景山区苹果园南路25号",
        "typeName": "中学",
        "id": 1472
    },
    {
        "type": 2,
        "name": "北京市同文中学",
        "phone": 68653297,
        "address": "北京市石景山区永乐小区甲8号",
        "typeName": "中学",
        "id": 1473
    },
    {
        "type": 2,
        "name": "北京佳汇中学",
        "phone": 88296005,
        "address": "北京市石景山区模式口南里",
        "typeName": "中学",
        "id": 1474
    },
    {
        "type": 2,
        "name": "首都师范大学附属苹果园中学",
        "phone": 88932576,
        "address": "北京市石景山区苹果园南路25号",
        "typeName": "中学",
        "id": 1475
    },
    {
        "type": 2,
        "name": "北京市第九中学",
        "phone": 88731886,
        "address": "北京市石景山区模式口大街16号",
        "typeName": "中学",
        "id": 1476
    },
    {
        "type": 2,
        "name": "北京师范大学附属中学京西分校",
        "phone": 83193000,
        "address": "北京市石景山区五里坨街道隆恩寺街秀府路",
        "typeName": "中学",
        "id": 1477
    },
    {
        "type": 2,
        "name": "北京市古城中学（东）",
        "phone": 68871582,
        "address": "北京市石景山区古城南路6号",
        "typeName": "中学",
        "id": 1478
    },
    {
        "type": 2,
        "name": "北京市古城中学（西）",
        "phone": 68871582,
        "address": "北京市石景山区八角南路甲16号",
        "typeName": "中学",
        "id": 1479
    },
    {
        "type": 2,
        "name": "北京大学附属中学石景山学校",
        "phone": 88962352,
        "address": "北京市石景山区八大处路8号",
        "typeName": "中学",
        "id": 1480
    },
    {
        "type": 2,
        "name": "北京市礼文中学",
        "phone": 88977433,
        "address": "北京市石景山区老山东里甲19号",
        "typeName": "中学",
        "id": 1481
    },
    {
        "type": 2,
        "name": "北京市门头沟区新桥路中学",
        "phone": 69842617,
        "address": "北京市门头沟区新桥大街64号",
        "typeName": "中学",
        "id": 1482
    },
    {
        "type": 2,
        "name": "北京市大峪中学分校",
        "phone": 69862030,
        "address": "北京市门头沟区石门营环岛紫金北路",
        "typeName": "中学",
        "id": 1483
    },
    {
        "type": 2,
        "name": "北京市门头沟京师实验中学",
        "phone": 69842158,
        "address": "北京市门头沟区门头沟路130号",
        "typeName": "中学",
        "id": 1484
    },
    {
        "type": 2,
        "name": "北京市王平中学",
        "phone": 61859455,
        "address": "北京市门头沟区王平镇西石古岩村",
        "typeName": "中学",
        "id": 1485
    },
    {
        "type": 2,
        "name": "北京市门头沟区潭柘寺中学",
        "phone": 60862395,
        "address": "北京市门头沟区潭柘寺镇南辛房村北街119号",
        "typeName": "中学",
        "id": 1486
    },
    {
        "type": 2,
        "name": "北京市门头沟区军庄中学",
        "phone": 60811869,
        "address": "北京市门头沟区军庄镇",
        "typeName": "中学",
        "id": 1487
    },
    {
        "type": 2,
        "name": "北京市门头沟区斋堂中学",
        "phone": 69816694,
        "address": "北京市门头沟区斋堂镇斋堂大街7号",
        "typeName": "中学",
        "id": 1488
    },
    {
        "type": 2,
        "name": "北京市门头沟区清水中学",
        "phone": 60855374,
        "address": "北京市门头沟区清水镇上清水村",
        "typeName": "中学",
        "id": 1489
    },
    {
        "type": 2,
        "name": "北京市门头沟区大峪中学西校区",
        "phone": 69842502,
        "address": "北京市门头沟区大峪南路1号",
        "typeName": "中学",
        "id": 1490
    },
    {
        "type": 2,
        "name": "北京市育园中学",
        "phone": "010-69852174",
        "address": "北京市门头沟区新桥南大街甲21号",
        "typeName": "中学",
        "id": 1491
    },
    {
        "type": 2,
        "name": "北京市大峪中学",
        "phone": 61864110,
        "address": "北京市门头沟区滨河德露苑18号",
        "typeName": "中学",
        "id": 1492
    },
    {
        "type": 2,
        "name": "首都师范大学附属中学永定分校",
        "phone": 69801362,
        "address": "北京市门头沟区石龙北路32号",
        "typeName": "中学",
        "id": 1493
    },
    {
        "type": 2,
        "name": "北京市第八中学京西校区",
        "phone": 52990928,
        "address": "北京市门头沟区永定镇东辛称地块",
        "typeName": "中学",
        "id": 1494
    },
    {
        "type": 2,
        "name": "北京市房山区房山第三中学",
        "phone": 69312717,
        "address": "北京市房山区城关街道办事处饶乐府村",
        "typeName": "中学",
        "id": 1495
    },
    {
        "type": 2,
        "name": "北京市房山区良乡第三中学",
        "phone": 69382124,
        "address": "北京市房山区良乡镇苏庄大街11号",
        "typeName": "中学",
        "id": 1496
    },
    {
        "type": 2,
        "name": "北京市房山区良乡第四中学",
        "phone": 61356573,
        "address": "北京市房山区西潞街道太平庄村",
        "typeName": "中学",
        "id": 1497
    },
    {
        "type": 2,
        "name": "北京市房山区北洛中学",
        "phone": 89384275,
        "address": "北京市房山区琉璃河镇北洛村西",
        "typeName": "中学",
        "id": 1498
    },
    {
        "type": 2,
        "name": "北京市房山区南召中学",
        "phone": 80399691,
        "address": "北京市房山区琉璃河镇北白村北",
        "typeName": "中学",
        "id": 1499
    },
    {
        "type": 2,
        "name": "北京市房山区南梨园中学",
        "phone": 89318519,
        "address": "北京市房山区阎村镇南梨园村",
        "typeName": "中学",
        "id": 1500
    },
    {
        "type": 2,
        "name": "北京市房山区晨曦中学",
        "phone": 61308599,
        "address": "北京市房山区阎村镇吴庄村南",
        "typeName": "中学",
        "id": 1501
    },
    {
        "type": 2,
        "name": "北京市房山区窦店中学",
        "phone": 69392390,
        "address": "北京市房山区窦店镇窦店村",
        "typeName": "中学",
        "id": 1502
    },
    {
        "type": 2,
        "name": "北京市房山区石楼中学",
        "phone": 89300655,
        "address": "北京市房山区石楼镇石楼村南",
        "typeName": "中学",
        "id": 1503
    },
    {
        "type": 2,
        "name": "北京市房山区夏村中学",
        "phone": 69324314,
        "address": "北京市房山区石楼镇夏村",
        "typeName": "中学",
        "id": 1504
    },
    {
        "type": 2,
        "name": "北京市房山区葫芦垡中学",
        "phone": 60352106,
        "address": "北京市房山区长阳镇葫芦垡村委会",
        "typeName": "中学",
        "id": 1505
    },
    {
        "type": 2,
        "name": "北京市房山区长阳中学",
        "phone": 81312227,
        "address": "北京市房山区长阳镇北广阳城村",
        "typeName": "中学",
        "id": 1506
    },
    {
        "type": 2,
        "name": "北京市房山区石窝中学",
        "phone": 61323639,
        "address": "北京市房山区大石窝镇石窝村",
        "typeName": "中学",
        "id": 1507
    },
    {
        "type": 2,
        "name": "北京市房山区张坊中学",
        "phone": 61339840,
        "address": "北京市房山区张坊镇史各庄村",
        "typeName": "中学",
        "id": 1508
    },
    {
        "type": 2,
        "name": "北京市房山区青龙湖中学",
        "phone": 60322015,
        "address": "北京市房山区青龙湖镇豆各庄村下三区68号",
        "typeName": "中学",
        "id": 1509
    },
    {
        "type": 2,
        "name": "北京市房山区岳各庄中学",
        "phone": 61311757,
        "address": "北京市房山区韩村河镇岳各庄村岳各庄大街甲2号",
        "typeName": "中学",
        "id": 1510
    },
    {
        "type": 2,
        "name": "北京市房山区房山第二中学",
        "phone": 69312990,
        "address": "北京市房山城关街道沿河路5号",
        "typeName": "中学",
        "id": 1511
    },
    {
        "type": 2,
        "name": "北京市房山第四中学",
        "phone": 69327194,
        "address": "北京市房山区城关街道育新街1号",
        "typeName": "中学",
        "id": 1512
    },
    {
        "type": 2,
        "name": "北京市房山区良乡第二中学",
        "phone": 69351418,
        "address": "北京市房山区良乡城隍庙街106号",
        "typeName": "中学",
        "id": 1513
    },
    {
        "type": 2,
        "name": "北京市房山区良乡第五中学",
        "phone": 89318131,
        "address": "北京市房山区阎村镇大董村村北",
        "typeName": "中学",
        "id": 1514
    },
    {
        "type": 2,
        "name": "北京市房山区房山第五中学",
        "phone": 69308009,
        "address": "北京市房山区周口店镇娄子水村南",
        "typeName": "中学",
        "id": 1515
    },
    {
        "type": 2,
        "name": "北京市房山区良乡第六中学",
        "phone": 60331081,
        "address": "北京市房山区良乡镇官道村南大街一号",
        "typeName": "中学",
        "id": 1516
    },
    {
        "type": 2,
        "name": "北京市房山区韩村河中学",
        "phone": 80389243,
        "address": "北京市房山区韩村河圣园路甲一号",
        "typeName": "中学",
        "id": 1517
    },
    {
        "type": 2,
        "name": "北京市房山区南尚乐中学",
        "phone": 61385871,
        "address": "北京市房山区大石窝镇南尚乐村",
        "typeName": "中学",
        "id": 1518
    },
    {
        "type": 2,
        "name": "北京市房山区坨里中学（北京教育学院房山实验学校初中部）",
        "phone": 80379516,
        "address": "北京市房山区青龙湖镇坨里大街15号",
        "typeName": "中学",
        "id": 1519
    },
    {
        "type": 2,
        "name": "北京市燕山向阳中学",
        "phone": 69344853,
        "address": "北京市房山区燕山迎风北庄南里1号区",
        "typeName": "中学",
        "id": 1520
    },
    {
        "type": 2,
        "name": "北京市燕山前进中学",
        "phone": 80341529,
        "address": "北京市房山区燕山杏花西里46号",
        "typeName": "中学",
        "id": 1521
    },
    {
        "type": 2,
        "name": "北京市燕山星城中学",
        "phone": 81330598,
        "address": "北京市房山区燕化星城健德三里34号",
        "typeName": "中学",
        "id": 1522
    },
    {
        "type": 2,
        "name": "北京市房山区坨里中学（北京教育学院房山实验学校高中部）",
        "phone": 80379516,
        "address": "北京市房山区新镇东平街",
        "typeName": "中学",
        "id": 1523
    },
    {
        "type": 2,
        "name": "北京师范大学良乡附属中学",
        "phone": 69363082,
        "address": "北京市房山区良乡苏庄东街8号",
        "typeName": "中学",
        "id": 1524
    },
    {
        "type": 2,
        "name": "北京市房山区实验中学",
        "phone": 89349823,
        "address": "北京市房山区城关街道兴东大街267号",
        "typeName": "中学",
        "id": 1525
    },
    {
        "type": 2,
        "name": "北京市房山区房山中学",
        "phone": 69314284,
        "address": "北京市房山区城关东大街13号",
        "typeName": "中学",
        "id": 1526
    },
    {
        "type": 2,
        "name": "北京市房山区良乡中学",
        "phone": 69352605,
        "address": "北京市房山区拱辰街道南关东路5号",
        "typeName": "中学",
        "id": 1527
    },
    {
        "type": 2,
        "name": "北京师范大学燕化附属中学",
        "phone": 69343431,
        "address": "北京市房山区燕山迎风南路2号",
        "typeName": "中学",
        "id": 1528
    },
    {
        "type": 2,
        "name": "北京市房山区周口店中学",
        "phone": 69301511,
        "address": "北京市房山区周口店大街51号",
        "typeName": "中学",
        "id": 1529
    },
    {
        "type": 2,
        "name": "北京市房山区琉璃河中学",
        "phone": 61396897,
        "address": "北京市房山区琉璃河地区办事处琉璃河石桥北洄城村中区75号",
        "typeName": "中学",
        "id": 1530
    },
    {
        "type": 2,
        "name": "北京市房山区交道中学",
        "phone": 80319974,
        "address": "北京市房山区窦店镇交道二街三区66号",
        "typeName": "中学",
        "id": 1531
    },
    {
        "type": 2,
        "name": "北京市房山区长沟中学",
        "phone": 61366177,
        "address": "北京市房山区长沟镇长沟大街3号",
        "typeName": "中学",
        "id": 1532
    },
    {
        "type": 2,
        "name": "首都师范大学附属房山中学",
        "phone": 69364095,
        "address": "北京市房山区昊天大街75号",
        "typeName": "中学",
        "id": 1533
    },
    {
        "type": 2,
        "name": "北京四中房山分校",
        "phone": 80367561,
        "address": "北京市房山区长阳镇怡和路10号",
        "typeName": "中学",
        "id": 1534
    },
    {
        "type": 2,
        "name": "北京市顺义区北务中学",
        "phone": "010-61421946",
        "address": "北京市顺义区北务镇商业街15号",
        "typeName": "中学",
        "id": 1535
    },
    {
        "type": 2,
        "name": "北京市顺义区第八中学",
        "phone": 69429480,
        "address": "北京市顺义区光明北街18号",
        "typeName": "中学",
        "id": 1536
    },
    {
        "type": 2,
        "name": "北京市顺义区第三中学",
        "phone": 69422509,
        "address": "北京市顺义区府前东街27号",
        "typeName": "中学",
        "id": 1537
    },
    {
        "type": 2,
        "name": "北京市顺义区第十三中学",
        "phone": 60499118,
        "address": "北小营镇府西路1号",
        "typeName": "中学",
        "id": 1538
    },
    {
        "type": 2,
        "name": "北京市顺义区第十五中学",
        "phone": 57056628,
        "address": "北京市顺义区马坡地区泰和宜园第一社区临3号",
        "typeName": "中学",
        "id": 1539
    },
    {
        "type": 2,
        "name": "北京市顺义区第十一中学",
        "phone": 89477257,
        "address": "北京市顺义区顺平路俸伯段2号",
        "typeName": "中学",
        "id": 1540
    },
    {
        "type": 2,
        "name": "北京市顺义区第四学校",
        "phone": 61409188,
        "address": "北京市顺义区光明南街2号",
        "typeName": "中学",
        "id": 1541
    },
    {
        "type": 2,
        "name": "北京市顺义区第五中学",
        "phone": 89441490,
        "address": "北京市顺义区石园西区",
        "typeName": "中学",
        "id": 1542
    },
    {
        "type": 2,
        "name": "北京市顺义区李桥中学",
        "phone": 81473876,
        "address": "北京市顺义区顺通路李桥段30号",
        "typeName": "中学",
        "id": 1543
    },
    {
        "type": 2,
        "name": "北京市顺义区李遂中学",
        "phone": 1089481601,
        "address": "北京市顺义区李遂镇南孙路沟北段38号",
        "typeName": "中学",
        "id": 1544
    },
    {
        "type": 2,
        "name": "北京市顺义区南法信中学",
        "phone": 69472787,
        "address": "北京市顺义区南法信镇西海洪村",
        "typeName": "中学",
        "id": 1545
    },
    {
        "type": 2,
        "name": "北京市顺义区牛山第二中学",
        "phone": 69411107,
        "address": "北京市顺义区牛栏山镇府前街26号",
        "typeName": "中学",
        "id": 1546
    },
    {
        "type": 2,
        "name": "北京市顺义区仁和中学",
        "phone": 89493698,
        "address": "北京市顺义区站前东街6号",
        "typeName": "中学",
        "id": 1547
    },
    {
        "type": 2,
        "name": "北京市顺义区天竺中学",
        "phone": 80467213,
        "address": "北京市顺义区天竺镇府前一街29号",
        "typeName": "中学",
        "id": 1548
    },
    {
        "type": 2,
        "name": "北京市顺义区沿河中学",
        "phone": 69480315,
        "address": "北京市顺义区平沿路北河段137号",
        "typeName": "中学",
        "id": 1549
    },
    {
        "type": 2,
        "name": "北京市顺义区杨镇第二中学",
        "phone": "010-61451155",
        "address": "北京市顺义区杨镇三街西",
        "typeName": "中学",
        "id": 1550
    },
    {
        "type": 2,
        "name": "北京市顺义区赵全营中学",
        "phone": 60431128,
        "address": "北京市顺义区牛板路赵全营段129",
        "typeName": "中学",
        "id": 1551
    },
    {
        "type": 2,
        "name": "北京市顺义区第十二中学",
        "phone": 89469285,
        "address": "北京市顺义区南彩镇南彩村东",
        "typeName": "中学",
        "id": 1552
    },
    {
        "type": 2,
        "name": "北京市顺义牛栏山第一中学",
        "phone": "010-69411142",
        "address": "北京市顺义区牛栏山镇育才大街1号",
        "typeName": "中学",
        "id": 1553
    },
    {
        "type": 2,
        "name": "北京市顺义区第九中学",
        "phone": "010-89498802",
        "address": "北京市顺义区仁和镇河南村北",
        "typeName": "中学",
        "id": 1554
    },
    {
        "type": 2,
        "name": "北京市顺义区第一中学",
        "phone": 69444448,
        "address": "北京市顺义区双河大街15号",
        "typeName": "中学",
        "id": 1555
    },
    {
        "type": 2,
        "name": "北京市顺义区杨镇第一中学",
        "phone": "010－61451055",
        "address": "北京市顺义区杨镇木燕路下坡村段",
        "typeName": "中学",
        "id": 1556
    },
    {
        "type": 2,
        "name": "北京市第四中学顺义分校（北京市顺义区第十中学）",
        "phone": 80416138,
        "address": "北京市顺义区后沙峪镇双峪街45号",
        "typeName": "中学",
        "id": 1557
    },
    {
        "type": 2,
        "name": "北京市顺义区第二中学",
        "phone": "010-69421643",
        "address": "北京市顺义区西二环北路旺泉街道前进花园南侧",
        "typeName": "中学",
        "id": 1558
    },
    {
        "type": 2,
        "name": "北京市第十四中学大兴安定分校",
        "phone": 80235031,
        "address": "北京市大兴区安定镇兴安大街1号",
        "typeName": "中学",
        "id": 1559
    },
    {
        "type": 2,
        "name": "大兴区狼垡中学",
        "phone": 61222224,
        "address": "大兴区黄村镇狼垡一村",
        "typeName": "中学",
        "id": 1560
    },
    {
        "type": 2,
        "name": "北京市大兴区第七中学（东校区）",
        "phone": 89226207,
        "address": "北京市大兴区兴丰大街2段215号",
        "typeName": "中学",
        "id": 1561
    },
    {
        "type": 2,
        "name": "北京市大兴区第七中学（西校区）",
        "phone": 89226315,
        "address": "北京市大兴区西芦城村",
        "typeName": "中学",
        "id": 1562
    },
    {
        "type": 2,
        "name": "北京市大兴区定福庄中学",
        "phone": 89251348,
        "address": "北京市大兴区庞各庄镇定福庄村",
        "typeName": "中学",
        "id": 1563
    },
    {
        "type": 2,
        "name": "北京市大兴区凤河营中学",
        "phone": 80202915,
        "address": "北京市大兴区采育镇凤河营村",
        "typeName": "中学",
        "id": 1564
    },
    {
        "type": 2,
        "name": "北京市大兴区青云店中学",
        "phone": 80281019,
        "address": "北京市大兴区青云店镇青采路2号",
        "typeName": "中学",
        "id": 1565
    },
    {
        "type": 2,
        "name": "北京市大兴区第一中学北校区",
        "phone": "010-61219188",
        "address": "北京市大兴区黄村镇成庄南巷8号",
        "typeName": "中学",
        "id": 1566
    },
    {
        "type": 2,
        "name": "北京市大兴区郭家务中学",
        "phone": 89261346,
        "address": "北京市大兴区榆垡镇今荣街",
        "typeName": "中学",
        "id": 1567
    },
    {
        "type": 2,
        "name": "北京市第二中学亦庄学校（北校区）",
        "phone": 67882070,
        "address": "北京市大兴区亦庄镇广德中巷1号",
        "typeName": "中学",
        "id": 1568
    },
    {
        "type": 2,
        "name": "北京市大兴区第六中学",
        "phone": 60279249,
        "address": "北京市大兴区黄村镇京开路东甲54号",
        "typeName": "中学",
        "id": 1569
    },
    {
        "type": 2,
        "name": "北京市大兴区礼贤民族中学",
        "phone": 89270978,
        "address": "北京市大兴区礼贤镇青礼路2号",
        "typeName": "中学",
        "id": 1570
    },
    {
        "type": 2,
        "name": "北京市大兴区红星中学",
        "phone": "010-69272838",
        "address": "北京市大兴区灜海镇开发区内",
        "typeName": "中学",
        "id": 1571
    },
    {
        "type": 2,
        "name": "北京市大兴区庞各庄中学",
        "phone": 89281315,
        "address": "北京市大兴区庞各庄镇西义堂村北",
        "typeName": "中学",
        "id": 1572
    },
    {
        "type": 2,
        "name": "北京市大兴区太和中学",
        "phone": 69286016,
        "address": "大兴区瀛海镇工业开发区临2号",
        "typeName": "中学",
        "id": 1573
    },
    {
        "type": 2,
        "name": "北京市大兴区垡上中学",
        "phone": 80211947,
        "address": "北京市大兴青云店镇垡上村西",
        "typeName": "中学",
        "id": 1574
    },
    {
        "type": 2,
        "name": "北京市大兴区大辛庄中学",
        "phone": 89223595,
        "address": "北京市大兴区礼贤镇中心村大辛庄街5号",
        "typeName": "中学",
        "id": 1575
    },
    {
        "type": 2,
        "name": "北京市大兴区长子营中学",
        "phone": 80265347,
        "address": "北京市大兴区长子营镇沁水营村北",
        "typeName": "中学",
        "id": 1576
    },
    {
        "type": 2,
        "name": "北京市第八中学亦庄分校",
        "phone": 59753909,
        "address": "北京市大兴区亦庄镇三羊居住区北京八中亦庄分校",
        "typeName": "中学",
        "id": 1577
    },
    {
        "type": 2,
        "name": "北京市大兴区孙村中学",
        "phone": 1061268436,
        "address": "北京市大兴区黄村镇孙村村北",
        "typeName": "中学",
        "id": 1578
    },
    {
        "type": 2,
        "name": "北京大兴区第四中学",
        "phone": 69242259,
        "address": "北京市大兴区兴华中路54号",
        "typeName": "中学",
        "id": 1579
    },
    {
        "type": 2,
        "name": "北京市大兴区德茂中学",
        "phone": 67994560,
        "address": "北京市大兴区旧宫镇德茂庄德裕街14号",
        "typeName": "中学",
        "id": 1580
    },
    {
        "type": 2,
        "name": "北京市大兴区兴华中学",
        "phone": 69252909,
        "address": "大兴区兴华大街二段14号",
        "typeName": "中学",
        "id": 1581
    },
    {
        "type": 2,
        "name": "北京建筑大学附属中学（新校区）",
        "phone": 61205758,
        "address": "北京市大兴区黄村镇东西芦村南",
        "typeName": "中学",
        "id": 1582
    },
    {
        "type": 2,
        "name": "首都师范大学大兴附属中学",
        "phone": 61242429,
        "address": "北京市大兴区黄村镇海东路4号",
        "typeName": "中学",
        "id": 1583
    },
    {
        "type": 2,
        "name": "北京建筑大学附属中学",
        "phone": 69246724,
        "address": "北京市大兴区黄村镇红楼西巷20号",
        "typeName": "中学",
        "id": 1584
    },
    {
        "type": 2,
        "name": "北京市大兴区旧宫中学",
        "phone": 87961591,
        "address": "北京市大兴区旧宫镇旧宫东路61号",
        "typeName": "中学",
        "id": 1585
    },
    {
        "type": 2,
        "name": "北京师范大学大兴附属中学",
        "phone": 69252066,
        "address": "北京市大兴区黄村镇滨河街3号院",
        "typeName": "中学",
        "id": 1586
    },
    {
        "type": 2,
        "name": "北京市大兴区第一中学",
        "phone": "010-69243585",
        "address": "北京市大兴区黄村镇兴政街6号",
        "typeName": "中学",
        "id": 1587
    },
    {
        "type": 2,
        "name": "北京市大兴区第三中学",
        "phone": 69252449,
        "address": "北京市大兴区黄村镇三中巷7号",
        "typeName": "中学",
        "id": 1588
    },
    {
        "type": 2,
        "name": "北京亦庄实验中学",
        "phone": 87551512,
        "address": "北京经济技术开发区四合路12号",
        "typeName": "中学",
        "id": 1589
    },
    {
        "type": 2,
        "name": "北京市大兴区采育中学",
        "phone": 80271973,
        "address": "北京市大兴区采育镇福苑路7号",
        "typeName": "中学",
        "id": 1590
    },
    {
        "type": 2,
        "name": "北京市第八中学大兴分校",
        "phone": "010-69235154",
        "address": "北京市大兴区黄村镇康庄路40号",
        "typeName": "中学",
        "id": 1591
    },
    {
        "type": 2,
        "name": "北京市大兴区魏善庄中学",
        "phone": 89201948,
        "address": "北京市大兴区魏善庄镇东大街路北",
        "typeName": "中学",
        "id": 1592
    },
    {
        "type": 2,
        "name": "北京市大兴区榆垡中学",
        "phone": 89217848,
        "address": "大兴区榆垡镇今荣街",
        "typeName": "中学",
        "id": 1593
    },
    {
        "type": 2,
        "name": "华北电力大学附属中学",
        "phone": 81711358,
        "address": "北京市昌平区回龙观镇三合庄园西侧",
        "typeName": "中学",
        "id": 1594
    },
    {
        "type": 2,
        "name": "北京市昌平区大东流中学",
        "phone": 61711480,
        "address": "北京市昌平区小汤山镇大东流村109号",
        "typeName": "中学",
        "id": 1595
    },
    {
        "type": 2,
        "name": "北京市昌平区平西府中学",
        "phone": 81784789,
        "address": "北京市昌平区北七家镇平西府村北",
        "typeName": "中学",
        "id": 1596
    },
    {
        "type": 2,
        "name": "中滩中学",
        "phone": 84812036,
        "address": "北京市昌平区东小口镇中滩村292号",
        "typeName": "中学",
        "id": 1597
    },
    {
        "type": 2,
        "name": "北京市昌平区南口铁道北中学",
        "phone": 69771028,
        "address": "北京市昌平区南口镇道北南厂西区593号",
        "typeName": "中学",
        "id": 1598
    },
    {
        "type": 2,
        "name": "中国石油大学附属中学",
        "phone": 69741018,
        "address": "北京市昌平区富康路22号",
        "typeName": "中学",
        "id": 1599
    },
    {
        "type": 2,
        "name": "北京市昌平区上苑中学",
        "phone": "010-61707263",
        "address": "北京市兴寿镇东新城村",
        "typeName": "中学",
        "id": 1600
    },
    {
        "type": 2,
        "name": "北京市昌平区第一中学（天通苑校区）",
        "phone": 80788856,
        "address": "北京市昌平区天通苑北3区64号",
        "typeName": "中学",
        "id": 1601
    },
    {
        "type": 2,
        "name": "北京市昌平区崔村中学",
        "phone": 60721378,
        "address": "北京市昌平区崔村镇西崔村",
        "typeName": "中学",
        "id": 1602
    },
    {
        "type": 2,
        "name": "北京市昌平区流村中学",
        "phone": 89771769,
        "address": "北京市昌平区流村镇北流村",
        "typeName": "中学",
        "id": 1603
    },
    {
        "type": 2,
        "name": "北京市昌平区南邵中学",
        "phone": 60732165,
        "address": "北京市昌平区南邵地铁站东",
        "typeName": "中学",
        "id": 1604
    },
    {
        "type": 2,
        "name": "北京市昌平职业学校",
        "phone": 69732142,
        "address": "北京市昌平区沙河镇松兰堡村",
        "typeName": "中学",
        "id": 1605
    },
    {
        "type": 2,
        "name": "北京市昌平职业学校南口校区",
        "phone": 89793500,
        "address": "北京市昌平区南口镇南农家园南600米",
        "typeName": "中学",
        "id": 1606
    },
    {
        "type": 2,
        "name": "北京市第十五中学南口学校",
        "phone": 69771231,
        "address": "北京市昌平区南口镇陈庄村西",
        "typeName": "中学",
        "id": 1607
    },
    {
        "type": 2,
        "name": "首都师范大学附属中学昌平学校",
        "phone": 81762071,
        "address": "北京市昌平区北七家镇政府东侧",
        "typeName": "中学",
        "id": 1608
    },
    {
        "type": 2,
        "name": "北京市昌平区第二中学",
        "phone": 89740847,
        "address": "北京市昌平区城北街道政府街5号",
        "typeName": "中学",
        "id": 1609
    },
    {
        "type": 2,
        "name": "北京市昌平区第一中学",
        "phone": 69742029,
        "address": "北京市昌平区东关路16号",
        "typeName": "中学",
        "id": 1610
    },
    {
        "type": 2,
        "name": "北京师范大学第二附属中学未来科技城学校",
        "phone": "010-81750120",
        "address": "北京市昌平区北七家镇未来科学城南区",
        "typeName": "中学",
        "id": 1611
    },
    {
        "type": 2,
        "name": "北京实验学校附属中学",
        "phone": 69961750,
        "address": "北京市平谷区平谷镇南门街2号",
        "typeName": "中学",
        "id": 1612
    },
    {
        "type": 2,
        "name": "北京市平谷区第三中学",
        "phone": 69961891,
        "address": "北京市平谷区文化南街5号",
        "typeName": "中学",
        "id": 1613
    },
    {
        "type": 2,
        "name": "北京市平谷区第四中学",
        "phone": 89980573,
        "address": "北京市平谷区平谷镇赵各庄南街2号",
        "typeName": "中学",
        "id": 1614
    },
    {
        "type": 2,
        "name": "北京市平谷区第七中学",
        "phone": 89990775,
        "address": "北京市平谷区王辛庄镇校园路14号",
        "typeName": "中学",
        "id": 1615
    },
    {
        "type": 2,
        "name": "北京市平谷区刘家河中学",
        "phone": 60961747,
        "address": "北京市平谷区南独乐河镇刘家河西二路13号",
        "typeName": "中学",
        "id": 1616
    },
    {
        "type": 2,
        "name": "北京市平谷区夏各庄联办中学",
        "phone": 60913164,
        "address": "北京市平谷区夏各庄镇夏各庄后街3号",
        "typeName": "中学",
        "id": 1617
    },
    {
        "type": 2,
        "name": "北京市平谷区大华山中学",
        "phone": 52741304,
        "address": "北京市平谷区大华山镇大华山街263号",
        "typeName": "中学",
        "id": 1618
    },
    {
        "type": 2,
        "name": "北京市平谷区山东庄中学",
        "phone": 60937194,
        "address": "北京市平谷区山东庄镇北屯村职高街55号",
        "typeName": "中学",
        "id": 1619
    },
    {
        "type": 2,
        "name": "北京市平谷区峪口中学",
        "phone": 61906037,
        "address": "北京市平谷区峪口镇峪口西大街50号",
        "typeName": "中学",
        "id": 1620
    },
    {
        "type": 2,
        "name": "北京市平谷区门楼庄中学",
        "phone": 89923263,
        "address": "北京市平谷区东高村镇门楼庄村门侯路3号",
        "typeName": "中学",
        "id": 1621
    },
    {
        "type": 2,
        "name": "北京市平谷区马昌营中学",
        "phone": 61981679,
        "address": "北京市平谷区马昌營镇马昌營村古槐东三街甲2",
        "typeName": "中学",
        "id": 1622
    },
    {
        "type": 2,
        "name": "北京市平谷区黄松峪中学",
        "phone": 60971702,
        "address": "北京市平谷区黄松峪乡黄松峪村东街343号",
        "typeName": "中学",
        "id": 1623
    },
    {
        "type": 2,
        "name": "北京市平谷中学",
        "phone": 69961329,
        "address": "北京市平谷区平谷镇府前街6号",
        "typeName": "中学",
        "id": 1624
    },
    {
        "type": 2,
        "name": "北京市平谷区第五中学",
        "phone": 69952681,
        "address": "北京市平谷区平谷镇北二环路园丁小区1号",
        "typeName": "中学",
        "id": 1625
    },
    {
        "type": 2,
        "name": "北京实验学校",
        "phone": 69962844,
        "address": "北京市平谷区建设街204号",
        "typeName": "中学",
        "id": 1626
    },
    {
        "type": 2,
        "name": "北京师范大学附属中学平谷第一分校",
        "phone": 89998031,
        "address": "北京市平谷区马坊镇金河北街101号院",
        "typeName": "中学",
        "id": 1627
    },
    {
        "type": 2,
        "name": "北京市平谷区职业学校",
        "phone": 69909471,
        "address": "北京市平谷区峪口镇峪口西大街50号",
        "typeName": "中学",
        "id": 1628
    },
    {
        "type": 2,
        "name": "北京市怀柔区第三中学",
        "phone": 69623543,
        "address": "北京市怀柔区迎宾北路36号",
        "typeName": "中学",
        "id": 1629
    },
    {
        "type": 2,
        "name": "北京市怀柔区第四中学",
        "phone": 69643620,
        "address": "北京市怀柔区湖光小区34号",
        "typeName": "中学",
        "id": 1630
    },
    {
        "type": 2,
        "name": "北京市怀柔区第五中学",
        "phone": 51593554,
        "address": "北京市怀柔区东关二区1号",
        "typeName": "中学",
        "id": 1631
    },
    {
        "type": 2,
        "name": "北京市怀柔区体育运动学校",
        "phone": 69695553,
        "address": "北京市怀柔区青春路31号",
        "typeName": "中学",
        "id": 1632
    },
    {
        "type": 2,
        "name": "北京市怀柔区张各长中学",
        "phone": 69693665,
        "address": "北京市怀柔区怀柔镇张各长村",
        "typeName": "中学",
        "id": 1633
    },
    {
        "type": 2,
        "name": "北京市怀柔区北房中学",
        "phone": 61681501,
        "address": "北京市怀柔区北房镇驸马庄村西341号",
        "typeName": "中学",
        "id": 1634
    },
    {
        "type": 2,
        "name": "北京市怀柔区杨宋中学",
        "phone": 61678417,
        "address": "北京市怀柔区杨宋镇杨宋庄村南",
        "typeName": "中学",
        "id": 1635
    },
    {
        "type": 2,
        "name": "北京市怀柔区桥梓中学",
        "phone": 69675811,
        "address": "北京市怀柔区桥梓镇后桥梓村西",
        "typeName": "中学",
        "id": 1636
    },
    {
        "type": 2,
        "name": "北京市怀柔区汤河口中学",
        "phone": 89671037,
        "address": "北京市怀柔区汤河口镇汤河口大街3号",
        "typeName": "中学",
        "id": 1637
    },
    {
        "type": 2,
        "name": "北京市怀柔区渤海中学",
        "phone": 61631447,
        "address": "北京市怀柔区渤海镇沙峪村",
        "typeName": "中学",
        "id": 1638
    },
    {
        "type": 2,
        "name": "北京市怀柔区九渡河中学",
        "phone": 89606220,
        "address": "北京市怀柔区九渡河镇九渡河村东",
        "typeName": "中学",
        "id": 1639
    },
    {
        "type": 2,
        "name": "北京市怀柔区宝山中学",
        "phone": 89671037,
        "address": "北京市怀柔区宝山镇宝山寺村北",
        "typeName": "中学",
        "id": 1640
    },
    {
        "type": 2,
        "name": "北京市怀柔区长哨营满族中学",
        "phone": 89671037,
        "address": "北京市怀柔区长哨营满族乡长哨营村西",
        "typeName": "中学",
        "id": 1641
    },
    {
        "type": 2,
        "name": "北京市怀柔区喇叭沟门满族中学",
        "phone": 89671037,
        "address": "北京市怀柔区喇叭沟门满族乡喇叭沟门村西",
        "typeName": "中学",
        "id": 1642
    },
    {
        "type": 2,
        "name": "北京市怀柔区第一中学",
        "phone": 69643291,
        "address": "北京市怀柔区府前西街3号",
        "typeName": "中学",
        "id": 1643
    },
    {
        "type": 2,
        "name": "北京市怀柔区第二中学",
        "phone": 69622236,
        "address": "北京市怀柔区湖光南街6号",
        "typeName": "中学",
        "id": 1644
    },
    {
        "type": 2,
        "name": "首都师范大学附属红螺寺中学",
        "phone": 89688321,
        "address": "北京市怀柔区小中富乐一区190号",
        "typeName": "中学",
        "id": 1645
    },
    {
        "type": 2,
        "name": "北京市第一〇一中学怀柔分校",
        "phone": 61661700,
        "address": "北京市怀柔区雁栖经济开发区乐园大街31号北侧",
        "typeName": "中学",
        "id": 1646
    },
    {
        "type": 2,
        "name": "北京市密云区第三中学",
        "phone": 69041394,
        "address": "北京市密云区城关四街育才路3号",
        "typeName": "中学",
        "id": 1647
    },
    {
        "type": 2,
        "name": "北方交通大学附属中学密云分校",
        "phone": 69048688,
        "address": "北京市密云区鼓楼街道办事处檀城西区社区",
        "typeName": "中学",
        "id": 1648
    },
    {
        "type": 2,
        "name": "北京市密云区第五中学",
        "phone": 69071007,
        "address": "北京市密云区鼓楼街道办事处宾阳北里社区",
        "typeName": "中学",
        "id": 1649
    },
    {
        "type": 2,
        "name": "北京市密云区第六中学",
        "phone": 89096299,
        "address": "密云区兴云路23号",
        "typeName": "中学",
        "id": 1650
    },
    {
        "type": 2,
        "name": "北京市密云水库中学",
        "phone": 69012409,
        "address": "北京市密云区溪翁庄镇溪翁庄村东",
        "typeName": "中学",
        "id": 1651
    },
    {
        "type": 2,
        "name": "北京市密云区西田各庄中学",
        "phone": 61019604,
        "address": "北京市密云区西田各庄镇西田各庄村",
        "typeName": "中学",
        "id": 1652
    },
    {
        "type": 2,
        "name": "北京市密云区十里堡中学",
        "phone": 89022112,
        "address": "北京市密云区十里堡镇十里堡村",
        "typeName": "中学",
        "id": 1653
    },
    {
        "type": 2,
        "name": "北京市密云区河南寨中学",
        "phone": 61088688,
        "address": "北京市密云区河南寨镇芦古庄村",
        "typeName": "中学",
        "id": 1654
    },
    {
        "type": 2,
        "name": "北京市密云区新农村中学",
        "phone": 89012228,
        "address": "北京市密云区穆家峪镇新农村",
        "typeName": "中学",
        "id": 1655
    },
    {
        "type": 2,
        "name": "北京市密云区穆家峪中学",
        "phone": 61052413,
        "address": "北京市密云区穆家峪镇羊山村",
        "typeName": "中学",
        "id": 1656
    },
    {
        "type": 2,
        "name": "北京市密云区不老屯中学",
        "phone": 81092806,
        "address": "北京市密云区不老屯镇不老屯村",
        "typeName": "中学",
        "id": 1657
    },
    {
        "type": 2,
        "name": "北京市密云区古北口中学",
        "phone": 81051355,
        "address": "北京市密云区古北口镇古北口村",
        "typeName": "中学",
        "id": 1658
    },
    {
        "type": 2,
        "name": "北京市密云区东邵渠中学",
        "phone": 61062485,
        "address": "北京市密云区东邵渠镇东邵渠村",
        "typeName": "中学",
        "id": 1659
    },
    {
        "type": 2,
        "name": "北京市密云区北庄中学",
        "phone": 81001727,
        "address": "北京市密云区北庄镇北庄村",
        "typeName": "中学",
        "id": 1660
    },
    {
        "type": 2,
        "name": "北京市密云区新城子中学",
        "phone": 81022446,
        "address": "北京市密云区新城子镇新城子村",
        "typeName": "中学",
        "id": 1661
    },
    {
        "type": 2,
        "name": "北京市育英学校密云分校",
        "phone": 69084352,
        "address": "北京市密云区密云镇小唐庄社区",
        "typeName": "中学",
        "id": 1662
    },
    {
        "type": 2,
        "name": "北京市密云区第二中学",
        "phone": 69043327,
        "address": "密云区康复路5号",
        "typeName": "中学",
        "id": 1663
    },
    {
        "type": 2,
        "name": "首都师范大学附属密云中学",
        "phone": 89097210,
        "address": "北京市密云区果园街道兴云路27号",
        "typeName": "中学",
        "id": 1664
    },
    {
        "type": 2,
        "name": "北京师范大学密云实验中学",
        "phone": 69068601,
        "address": "密云区白檀村南",
        "typeName": "中学",
        "id": 1665
    },
    {
        "type": 2,
        "name": "北京市密云区巨各庄中学",
        "phone": 61036860,
        "address": "北京市密云区巨各庄镇前焦家坞村",
        "typeName": "中学",
        "id": 1666
    },
    {
        "type": 2,
        "name": "北京市密云区太师庄中学",
        "phone": 69032716,
        "address": "北京市密云区太师屯镇太师庄村",
        "typeName": "中学",
        "id": 1667
    },
    {
        "type": 2,
        "name": "北京市延庆区康庄中学",
        "phone": 61164849,
        "address": "北京市延庆区康庄镇榆林堡村东",
        "typeName": "中学",
        "id": 1668
    },
    {
        "type": 2,
        "name": "北京市延庆区体育运动学校",
        "phone": 69101175,
        "address": "北京市延庆区延庆镇赵庄村",
        "typeName": "中学",
        "id": 1669
    },
    {
        "type": 2,
        "name": "北京市延庆区八达岭中学",
        "phone": 69120429,
        "address": "北京市延庆区八达岭镇西拨子村北",
        "typeName": "中学",
        "id": 1670
    },
    {
        "type": 2,
        "name": "北京市延庆区旧县中学",
        "phone": 61153400,
        "address": "北京市延庆区旧县镇旧县村西",
        "typeName": "中学",
        "id": 1671
    },
    {
        "type": 2,
        "name": "北京市延庆区沈家营中学",
        "phone": 61131284,
        "address": "北京市延庆区沈家营镇冯庄村北",
        "typeName": "中学",
        "id": 1672
    },
    {
        "type": 2,
        "name": "北京市延庆区大榆树中学",
        "phone": 61183693,
        "address": "北京市延庆区大榆树镇大榆树村",
        "typeName": "中学",
        "id": 1673
    },
    {
        "type": 2,
        "name": "北京市延庆区下屯中学",
        "phone": "61110495-8000",
        "address": "北京市延庆区大榆树镇下屯村北",
        "typeName": "中学",
        "id": 1674
    },
    {
        "type": 2,
        "name": "北京市延庆区井庄中学",
        "phone": 61191948,
        "address": "北京市延庆区井庄镇艾官营村南",
        "typeName": "中学",
        "id": 1675
    },
    {
        "type": 2,
        "name": "北京市延庆区刘斌堡中学",
        "phone": 60181629,
        "address": "北京市延庆区刘斌堡乡刘斌堡村东",
        "typeName": "中学",
        "id": 1676
    },
    {
        "type": 2,
        "name": "北京市延庆区第四中学",
        "phone": 69180892,
        "address": "北京市延庆区香苑街110号",
        "typeName": "中学",
        "id": 1677
    },
    {
        "type": 2,
        "name": "北京市延庆区第八中学",
        "phone": "69175356-8040",
        "address": "北京市延庆区延庆镇广积屯村甲5号",
        "typeName": "中学",
        "id": 1678
    },
    {
        "type": 2,
        "name": "北京市延庆区第一中学",
        "phone": 69182699,
        "address": "北京市延庆区高塔路5号",
        "typeName": "中学",
        "id": 1679
    },
    {
        "type": 2,
        "name": "北京市延庆区第五中学",
        "phone": 81198943,
        "address": "北京市延庆区湖南东路10号",
        "typeName": "中学",
        "id": 1680
    },
    {
        "type": 2,
        "name": "北京市延庆区第一职业学校",
        "phone": 69185797,
        "address": "北京市延庆区湖南东路8号",
        "typeName": "中学",
        "id": 1681
    },
    {
        "type": 2,
        "name": "北京市延庆区第三中学",
        "phone": 69171191,
        "address": "北京市延庆区香苑街106号",
        "typeName": "中学",
        "id": 1682
    },
    {
        "type": 2,
        "name": "北京市延庆区第二中学",
        "phone": 81196606,
        "address": "北京市延庆区延庆镇菜园南街19号",
        "typeName": "中学",
        "id": 1683
    },
    {
        "type": 2,
        "name": "北京市通州区漷县中学",
        "phone": "010-80585485",
        "address": "北京市通州区漷县镇漷兴一街",
        "typeName": "中学",
        "id": 1684
    },
    {
        "type": 2,
        "name": "北京市通州区第六中学",
        "phone": "010-69543268",
        "address": "北京市通州区通州镇西顺城街32号",
        "typeName": "中学",
        "id": 1685
    },
    {
        "type": 2,
        "name": "北京市通州区柴厂屯中学",
        "phone": 80511402,
        "address": "北京市通州区永乐店镇柴厂屯村681号",
        "typeName": "中学",
        "id": 1686
    },
    {
        "type": 2,
        "name": "北京市通州区玉桥中学",
        "phone": "010-81588780",
        "address": "北京市通州区梨园北街23号",
        "typeName": "中学",
        "id": 1687
    },
    {
        "type": 2,
        "name": "北京市通州区郎府中学",
        "phone": "010-61558070",
        "address": "北京市通州区西集镇郎东村366号",
        "typeName": "中学",
        "id": 1688
    },
    {
        "type": 2,
        "name": "北京市通州区大杜社中学",
        "phone": "010-61582577",
        "address": "北京市通州区马驹桥镇大杜社村北",
        "typeName": "中学",
        "id": 1689
    },
    {
        "type": 2,
        "name": "北京市通州区北关中学",
        "phone": "010-69527871",
        "address": "北京市通州区永顺南街190号",
        "typeName": "中学",
        "id": 1690
    },
    {
        "type": 2,
        "name": "北京市通州区龙旺庄中学",
        "phone": "010-89594808",
        "address": "北京市通州区潞邑西路100号",
        "typeName": "中学",
        "id": 1691
    },
    {
        "type": 2,
        "name": "北京市通州区西集中学",
        "phone": "010-61576277",
        "address": "北京市通州区西集镇国防路25号",
        "typeName": "中学",
        "id": 1692
    },
    {
        "type": 2,
        "name": "北京市通州区甘棠中学",
        "phone": 61529328,
        "address": "北京市通州区潞城镇武兴路临33号",
        "typeName": "中学",
        "id": 1693
    },
    {
        "type": 2,
        "name": "北京市通州区小务中学",
        "phone": 80551584,
        "address": "北京市通州区永乐店镇小务村555号",
        "typeName": "中学",
        "id": 1694
    },
    {
        "type": 2,
        "name": "北京市通州区觅子店中学",
        "phone": 80563161,
        "address": "北京市通州漷县镇觅子店村440号",
        "typeName": "中学",
        "id": 1695
    },
    {
        "type": 2,
        "name": "北京市通州区于家务中学",
        "phone": 80531147,
        "address": "北京市通州区于家务乡于家务村",
        "typeName": "中学",
        "id": 1696
    },
    {
        "type": 2,
        "name": "北京市通州区运河中学初中部",
        "phone": 81523528,
        "address": "北京市通州区运河西大街107号",
        "typeName": "中学",
        "id": 1697
    },
    {
        "type": 2,
        "name": "北京市通州区宋庄中学",
        "phone": "010-69595470",
        "address": "北京市通州区宋庄镇政府路18号",
        "typeName": "中学",
        "id": 1698
    },
    {
        "type": 2,
        "name": "北京市通州区次渠中学",
        "phone": "010-69502312",
        "address": "北京市通州区台湖镇次渠中学",
        "typeName": "中学",
        "id": 1699
    },
    {
        "type": 2,
        "name": "北京市通州区运河中学高中部",
        "phone": 60520108,
        "address": "北京市通州区大方居西侧",
        "typeName": "中学",
        "id": 1700
    },
    {
        "type": 2,
        "name": "北京市现代音乐学校",
        "phone": "010-81511148",
        "address": "北京市通州区云景南大街68号",
        "typeName": "中学",
        "id": 1701
    },
    {
        "type": 2,
        "name": "北京市通州区新华学校",
        "phone": 1058458335,
        "address": "北京市通州区新华大街77号",
        "typeName": "中学",
        "id": 1702
    },
    {
        "type": 2,
        "name": "北京市通州区第二中学",
        "phone": "010-69544963",
        "address": "北京市通州区玉带河西街28号",
        "typeName": "中学",
        "id": 1703
    },
    {
        "type": 2,
        "name": "北京市通州区永乐店中学",
        "phone": "010-69568463",
        "address": "北京市通州区永乐店镇永三村1号",
        "typeName": "中学",
        "id": 1704
    },
    {
        "type": 2,
        "name": "首都师范大学附属中学（通州校区）",
        "phone": "010-69559659",
        "address": "北京市通州区中山大街50号",
        "typeName": "中学",
        "id": 1705
    },
    {
        "type": 2,
        "name": "北京市通州区张家湾中学",
        "phone": "010-69571821",
        "address": "通州区张家湾镇工业开发区广源路西街8号",
        "typeName": "中学",
        "id": 1706
    },
    {
        "type": 2,
        "name": "北京理工大学附属中学通州校区",
        "phone": "010-60573788",
        "address": "北京市通州区宋庄镇徐辛庄村北",
        "typeName": "中学",
        "id": 1707
    },
    {
        "type": 2,
        "name": "人大附中通州校区",
        "phone": "010-89537370",
        "address": "北京市通州区潞城镇胡各庄村南1号",
        "typeName": "中学",
        "id": 1708
    },
    {
        "type": 2,
        "name": "北京市第二中学通州校区",
        "phone": 89521723,
        "address": "北京市通州区潞城镇三元村东",
        "typeName": "中学",
        "id": 1709
    },
    {
        "type": 2,
        "name": "北京市通州区潞河中学",
        "phone": 69546337,
        "address": "北京市通州区新华南路135号",
        "typeName": "中学",
        "id": 1710
    },
    {
        "type": 2,
        "name": "北京潞河国际教育学园",
        "phone": 69553535,
        "address": "北京市通州区玉带河西街10号",
        "typeName": "中学",
        "id": 1711
    },
    {
        "type": 2,
        "name": "北京市第十五中学高中部",
        "phone": 63521515,
        "address": "西城区育新街2号",
        "typeName": "中学",
        "id": 1712
    },
    {
        "type": 2,
        "name": "北京市第二中学朝阳学校详情>>",
        "phone": "010-85818227",
        "address": "朝阳区姚家园西里6号",
        "typeName": "中学",
        "id": 1713
    }
]
// 简单学校列表
export const schoolShortList = [
    "学校名称",
    "北京市文汇中学",
    "北京市东城区文汇小学",
    "北京市第一七一中学附属青年湖小学",
    "北京市第五中学分校附属方家胡同小学",
    "北京市和平北路学校（北京市东城体育学校）",
    "北京市第一一五中学",
    "北京市第一六六中学附属校尉胡同小学",
    "北京市第一零九中学",
    "北京市广渠门中学附属花市小学",
    "北京市东直门中学附属雍和宫小学",
    "北京景山学校",
    "北京市东直门中学",
    "北京景山学校(灯市口校区)",
    "北京市东城区特殊教育学校",
    "北京市第一中学",
    "东城区培智中心学校",
    "北京市东城区景泰小学",
    "东四七条小学",
    "北京市东城区新鲜胡同小学",
    "北京市东城区史家小学分校",
    "北京市东城区天坛东里小学",
    "北京市东城区板厂小学",
    "北京市东城区西中街小学",
    "北京市东城区天坛南里小学",
    "北京市东城区宝华里小学",
    "北京市东城区新开路东总布小学",
    "北京市东城区史家实验学校（北京市东城区曙光小学）",
    "北京光明小学（幸福校区）",
    "北京光明小学（广渠校区）",
    "北京市东城区和平里第三小学",
    "北京市东城区黑芝麻胡同小学优质教育资源带黑芝麻校区",
    "北京市东城区黑芝麻胡同小学优质教育资源带圆恩寺校区",
    "北京市东城区黑芝麻胡同小学优质教育资源带织染局校区",
    "北京市东城区和平里第一小学",
    "北京市东城区和平里第九小学",
    "北京市东城区西总布小学",
    "北京市东城区回民小学",
    "北京市东城区回民实验小学",
    "北京市东城区定安里小学",
    "府学胡同小学",
    "府学胡同小学香饵校区",
    "府学胡同小学低年级部",
    "府学胡同小学美术馆后街校区",
    "府学胡同小学什锦花园校区",
    "东四十四条小学",
    "北京市东城区师范学校附属小学",
    "北京市东城区和平里第二小学",
    "北京市东城区和平里第四小学",
    "北京市东城区灯市口小学",
    "中央工艺美院附中艺美小学",
    "北京市东城区培新小学",
    "北京市东城区培新小学（红桥校区）",
    "北京市东城区培新小学（永生校区）",
    "北京市东城区灯市口小学优质资源带北池子校区",
    "北京市东城区灯市口小学优质资源带东高房校区",
    "北京市东城区体育馆路小学",
    "北京市东城区前门小学",
    "北京市东城区新怡小学",
    "北京市东城区金台书院小学",
    "北京市东城区安外三条小学",
    "北京市汇文第一小学",
    "北京市崇文小学",
    "东城区新景小学",
    "分司厅小学",
    "分司厅小学(北锣校区）",
    "分司厅小学(一年级部）",
    "北京市东城区东四九条小学",
    "北京第一师范学校附属小学",
    "北京市东城区革新里小学",
    "北京市东城区精忠街小学",
    "北京市东城区史家胡同小学（高年级部）",
    "北京市东城区史家胡同小学（一年级部）",
    "北京市东城区史家胡同小学（二年级部）",
    "北京市东城区地坛小学",
    "北京市东城区东交民巷小学（西校区）",
    "北京市东城区北新桥小学",
    "北京市东城区东交民巷小学(东校区)",
    "北京第一师范学校附属小学（高年级部）",
    "北京光明小学（本校区）",
    "北京市育才学校",
    "北京师范大学亚太实验学校",
    "北京市育才学校太平街校区",
    "北京市第八中学附属小学",
    "北京小学天宁寺分校（东址）",
    "北京小学天宁寺分校（西址）",
    "北京小学红山分校",
    "北京小学广安门内分校",
    "西城区厂桥小学（北址）",
    "西城区厂桥小学（南址）",
    "北京市西城区椿树馆小学",
    "北京市西城区登莱小学",
    "北京第二实验小学白云路分校",
    "北京第二实验小学广外分校",
    "北京第二实验小学涭水河分校（原址）",
    "北京第二实验小学涭水河分校（现临时周转地址）",
    "西城区阜成门外第一小学",
    "西城区复兴门外第一小学低年级部",
    "西城区复兴门外第一小学高年级部",
    "北京市西城区红莲小学",
    "西城区宏庙小学",
    "西城区华嘉小学（西校区）",
    "西城区华嘉小学（东校区）",
    "西城区黄城根小学（本址）",
    "西城区黄城根小学（后广平校区）",
    "西城区黄城根小学（官园校区）",
    "西城区黄城根小学（育幼校区）",
    "西城区进步小学",
    "北京雷锋小学",
    "北京雷锋小学（东街校区）",
    "西城区力学小学",
    "北京市西城区柳荫街小学（煤厂胡同址）",
    "北京市西城区柳荫街小学(大翔凤址）",
    "北京第一实验小学前门分校",
    "北京市三帆中学附属小学",
    "西城区三里河第三小学北址",
    "西城区三里河第三小学南址",
    "西城区三义里小学（三义里5号址）",
    "西城区三义里小学（三义里4号址）",
    "西城区什刹海小学本校",
    "西城区什刹海小学分校",
    "北京市第十五中学附属小学",
    "北京第二实验小学（新文化街校区）",
    "北京第二实验小学（王府校区）",
    "西城师范附小",
    "北京第二实验小学（长安校区）",
    "北京第二实验小学（德胜校区）",
    "北京市西城区顺城街第一小学",
    "西城区炭儿胡同小学",
    "西城区陶然亭小学",
    "西城区五路通小学",
    "西城区五路通小学教育学院校区",
    "西城区师范学校附属小学（六铺炕校区）",
    "北京市西城区师范学校附属小学（展览路校区）",
    "北京师范大学京师附小本校",
    "北京师范大学京师附小一分校",
    "北京师范大学京师附小二分校",
    "北京师范大学京师附小三分校",
    "北京市西城区北礼士路第一小学",
    "西城区香厂路小学本校",
    "西城区香厂路小学分校",
    "西城区新世纪实验小学",
    "北京市宣武师范学校附属第一小学（本校）",
    "北京市宣武师范学校附属第一小学（右安校区）",
    "北京市宣武师范学校附属第一小学（里仁校区）",
    "北京市宣武回民小学低年级部",
    "北京市宣武回民小学高年级部",
    "北京市西城区鸦儿胡同小学（北校区）",
    "北京市西城区鸦儿胡同小学（南校区）",
    "北京第二实验小学玉桃园分校",
    "北京市西城区育民小学（北校址）",
    "北京市西城区育民小学（南校址）",
    "西城区育翔小学马甸总校区",
    "西城区育翔小学阳光校区",
    "西城区育翔小学德胜里校区",
    "展览路第一小学",
    "展览路第一小学分校",
    "西城区志成小学（南址）",
    "西城区志成小学（西址）",
    "西城区中古友谊小学（三里河校区）",
    "西城区中古友谊小学（南礼士路校区）",
    "西城区自忠小学（本校）",
    "西城区自忠小学（分校）",
    "西城区实验小学",
    "西城区西单小学",
    "西城区北长街小学",
    "西城区奋斗小学（西址）",
    "西城区奋斗小学（东址）",
    "西城区奋斗小学（北址）",
    "西城区白纸坊小学",
    "西城区西什库小学",
    "西城区青年湖小学",
    "北京第一实验小学（和平门校区）",
    "北京第一实验小学（虎坊桥校区)",
    "北京第一实验小学（广外校区)",
    "北京市陈经纶中学崇实分校",
    "北京市朝阳区安华学校",
    "北京市陈经纶中学新教育实验分校",
    "北京市朝阳外国语学校北苑分校",
    "北京师范大学三帆中学朝阳学校",
    "北京教育学院朝阳分院附属学校",
    "北京市朝阳区芳草地国际学校富力分校",
    "首都师范大学附属实验学校",
    "首都师范大学附属实验学校花西校区",
    "首都师范大学附属实验学校西园校区",
    "北京市陈经纶中学帝景分校",
    "北京市中央商务区实验学校中学部",
    "北京市中央商务区实验学校小学部",
    "北京市日坛中学东润分校",
    "北京市日坛中学实验学校晨光校区",
    "北京市樱花园实验学校",
    "北京市三里屯一中（百子园校区）",
    "北京市陈经纶中学劲松分校",
    "北京市日坛中学实验学校西校区",
    "北京市陈经纶中学劲松分校",
    "北京市润丰学校",
    "中国教育科学研究院朝阳实验学校",
    "北京市忠德学校",
    "北京市朝阳区安民学校黎各庄校区",
    "北京市陈经纶中学分校东湖湾校区",
    "北京市陈经纶中学分校南湖东园校区",
    "北京市陈经纶中学嘉铭分校",
    "陈经纶中学嘉铭分校欧陆校区",
    "北京景山学校朝阳学校",
    "北京工业大学附属中学首城校区",
    "中央美术学院附属实验学校",
    "北京市朝阳区爱迪外国语学校",
    "北京中学",
    "北京第二外国语学院附属中学",
    "北京市陈经纶中学（保利分校）",
    "北京第二外国语学院附属中学本部（管庄校区）",
    "北京第二外国语学院附属中学（杨闸校区）",
    "北京第二外国语学院附属中学（小学部八里桥校区）",
    "北京第二外国语学院附属中学（小学部瑞祥民族校区）",
    "中央美术学院附属实验学校",
    "北京市和平街第一中学",
    "北京市和平街第一中学莲葩园校区",
    "北京市和平街第一中学朝来校区",
    "北京市东方德才学校",
    "北京市私立新亚中学",
    "清华大学附属中学朝阳学校",
    "北京市朝阳区垂杨柳中心小学劲松分校",
    "北京市朝阳区酒仙桥中心小学",
    "北京市朝阳区楼梓庄小学",
    "北京市朝阳区孛罗营小学",
    "北京市朝阳区将台洼小学",
    "北京市朝阳区平房小学",
    "北京市朝阳区北皋中心小学",
    "北京市朝阳区呼家楼中心小学万科青青分校",
    "中国传媒大学附属小学",
    "北京师范大学奥林匹克花园实验小学",
    "北京市朝阳区奶子房小学",
    "北京市朝阳区西直河小学",
    "北京市朝阳区黄胄艺术实验小学",
    "北京市朝阳劲松第四小学",
    "北京市朝阳区白家庄小学本部北校区",
    "北京市朝阳区白家庄小学本部南校区",
    "北京市朝阳区白家庄小学望京新城校区",
    "北京市朝阳区团结湖第二小学",
    "北京市朝阳区白家庄小学望京科技园校区",
    "北京市朝阳区将台路小学",
    "中国科学院附属实验学校",
    "北京市朝阳区双桥第二小学",
    "北京市朝阳区八里庄中心小学",
    "北京市朝阳区上辛堡小学",
    "北京市朝阳区八里庄中心小学北里校区",
    "北京市朝阳区实验小学罗马嘉园分校",
    "北京市朝阳区日坛小学四惠校区",
    "北京市朝阳区日坛小学东恒校区",
    "首都师范大学附属朝阳实验小学翠城南校区",
    "北京市朝阳区新升小学",
    "北京市朝阳区芳草地国际学校",
    "中国音乐学院附属北京实验学校安慧里校区低年级部",
    "中国音乐学院附属北京实验学校安慧里校区高年级部",
    "中国音乐学院附属北京实验学校北辰绿色家园分校",
    "北京市朝阳区芳草地国际学校双花园校区",
    "中国音乐学院附属北京实验学校慧忠北里校区",
    "北京市朝阳区芳草地国际学校万和城校区",
    "北京市朝阳区芳草地国际学校甘露园分校",
    "首都师范大学附属朝阳实验小学",
    "首都师范大学附属朝阳实验小学翠北校区",
    "北京市朝阳区垂杨柳中心小学（本部校区高部）",
    "北京市朝阳区垂杨柳中心小学（本部校区低部）",
    "北京市第八十中学枣营分校小学部",
    "北京第二外国语学院附属小学",
    "北京市朝阳区垂杨柳中心小学金都分校高部",
    "北京市朝阳区垂杨柳中心小学金都分校低部",
    "北京市朝阳区于家围小学",
    "北京市朝阳区实验小学新源里分校",
    "北京市朝阳区垂杨柳中心小学（杨柳校区高部）",
    "北京市朝阳区垂杨柳中心小学（杨柳校区中部）",
    "北京市朝阳区垂杨柳中心小学（杨柳校区低部）",
    "北京市朝阳区南磨房中心小学（高部）",
    "北京市朝阳区南磨房中心小学（低部）",
    "北京市朝阳师范学校附属小学和平街分校",
    "北京市朝阳区安民学校辛庄校区",
    "北京市朝阳区安民学校马泉营校区",
    "北京市朝阳区安民学校东八间校区",
    "北京市朝阳区安民学校西坝校区",
    "北京市朝阳区安民学校姚家园校区",
    "北京市朝阳区安民学校单店校区",
    "北京市朝阳区花家地实验小学",
    "北京市朝阳区安民学校定福庄校区",
    "北京市朝阳区安民学校小武基校区",
    "北京市朝阳区第二实验小学管庄校区低部",
    "北京市朝阳区花家地实验小学方舟校区",
    "北京市朝阳区呼家楼中心小学本部",
    "北京市朝阳区花家地实验小学西里校区",
    "北京市陈经纶中学分校",
    "北京市朝阳区第二实验小学双桥校区高部",
    "北京市朝阳区花家地实验小学朝来校区",
    "北京市朝阳区呼家楼中心小学东校区",
    "北京市朝阳区第二实验小学双桥校区低部",
    "北京市朝阳区芳草地国际学校民族校区",
    "北京市朝阳区酒仙桥第二小学",
    "北京市朝阳区呼家楼中心小学西校区",
    "北京师范大学朝阳附属学校",
    "北京市朝阳区第二实验小学远洋一方校区",
    "北京市朝阳区第二实验小学北辰福第校区",
    "北京市朝阳区高家园小学",
    "北京市朝阳师范学校附属小学太阳宫校区",
    "北京市朝阳区呼家楼中心小学南校区",
    "北京市朝阳区半壁店小学",
    "北京第二外国语学院附属小学定福分校",
    "北京市朝阳区定辛庄小学",
    "北京市朝阳区康乐园小学",
    "北京市朝阳区王四营中心小学",
    "北京市朝阳区王四营中心小学官庄校区",
    "北京市朝阳区兴隆小学",
    "北京明远教育书院实验小学中园校区",
    "北京明远教育书院实验小学知语校区",
    "北京明远教育书院实验小学望花路校区",
    "北京明远教育书院实验小学东园校区",
    "北京明远教育书院实验小学青年城校区",
    "北京市朝阳区石佛营小学",
    "北京市朝阳区团结湖小学丽水嘉园校区",
    "北京市朝阳区牌坊小学南校区",
    "北京市朝阳区牌坊小学",
    "北京市十八里店小学南校",
    "北京第二实验小学朝阳学校",
    "北京市朝阳区三里屯小学",
    "北京市十一学校一分校",
    "北京市第二十中学附属实验学校",
    "首都师范大学附属玉泉学校",
    "北京市海淀北部新区实验学校",
    "北京市育鸿学校",
    "北京市健翔学校人大校区",
    "北京航空航天大学实验学校小学部",
    "北京市育鸿学校（小学部）",
    "首都师范大学附属玉泉学校（小学部）",
    "北京航空航天大学实验学校",
    "北京市八一学校（中学部）",
    "首都师范大学附属育新学校",
    "清华大学附属中学永丰学校",
    "北京理工大学附属中学",
    "北京市育英学校",
    "北京实验学校（海淀）",
    "北京中法实验学校（北京市温泉第二中学）",
    "北京实验学校（海淀）（小学部）",
    "北京理工大学附属中学（小学部）",
    "北京市育英学校（小学部）",
    "北京市八一学校（小学部）",
    "清华大学附属中学永丰学校（小学部）",
    "北京市海淀工读学校",
    "北京市海淀区五一小学本校区",
    "北京市海淀区五一小学北校区",
    "北京市海淀区五一小学晋分校区",
    "首都师范大学附属小学主校区",
    "首都师范大学附属小学柳明校区",
    "北京市海淀区图强第二小学",
    "北京市海淀区四王府小学",
    "北京市海淀区七一小学",
    "北京市海淀区羊坊店第四小学",
    "北京市海淀区香山小学",
    "北京市海淀区永泰小学",
    "北京市海淀区永泰小学清缘里校区",
    "北京市海淀区翠微小学本校区",
    "北京市海淀区翠微小学东校区",
    "北京市海淀区翠微小学西校区",
    "北京市海淀区翠微小学北校区",
    "北京市海淀区翠微小学白家疃校区",
    "北京市海淀区中关村第二小学（中关村校区）",
    "北京市海淀区中关村第二小学（华清校区）",
    "北京市海淀区中关村第二小学（百旺校区）",
    "北京市海淀区双榆树第一小学",
    "北京市海淀区西颐小学",
    "中国农业大学附属小学",
    "北京市海淀区台头小学",
    "北京市海淀区台头小学红星校区",
    "北京市海淀区科技园小学西玉河校区",
    "北京市海淀区科技园小学大牛坊校区",
    "北京教育学院附属海淀实验小学",
    "北京市海淀区永丰中心小学",
    "北京市海淀区永丰中心小学屯佃校区",
    "北京市海淀区永丰中心小学宏丰校区",
    "北京市海淀区东北旺中心小学",
    "北京市海淀区翠湖小学白水洼校区",
    "北京市海淀区翠湖小学前章村校区",
    "北京市海淀区教师进修学校附属实验小学西山校区",
    "北京市海淀区教师进修学校附属实验小学北辛庄校区",
    "北京市海淀区教师进修学校附属实验小学西冉校区",
    "北京市海淀区上庄中心小学（上庄校区）",
    "北京市海淀区上庄中心小学（东马坊校区）",
    "北京市海淀区西二旗小学",
    "北京市海淀区太平路小学本校",
    "北京市海淀区太平路小学分校",
    "北京市海淀区北安河中心小学（本校区）",
    "北京市海淀区北安河中心小学（周家巷校区）",
    "北京市海淀区清河第五小学",
    "北京市海淀区中关村第三小学万柳北校区",
    "北京市海淀区中关村第三小学万柳南校区",
    "北京市海淀区中关村第三小学红山校区",
    "北京市海淀区苏家坨中心小学西小营校区",
    "北京市海淀区苏家坨中心小学苏三四校区",
    "北京外国语大学附属小学万寿寺校区",
    "北京外国语大学附属小学魏公村校区",
    "北京市海淀区花园村第二小学花园村校区",
    "北京市海淀区花园村第二小学潘庄校区",
    "北京市海淀区万泉河小学",
    "北京市海淀区前进小学",
    "北京市海淀区定慧里小学",
    "北京市海淀区和平小学东埠头校区",
    "北京市海淀区和平小学辛庄校区",
    "北京市海淀区实验小学（花园村校区）",
    "北京市海淀区实验小学（阜成路校区）",
    "北京市海淀区实验小学（苏州街校区）",
    "北京市海淀区实验小学（北洼路校区）",
    "中国人民大学附属中学实验小学",
    "北方交通大学附属小学南校区",
    "北方交通大学附属小学北校区",
    "中关村一小中关村校区",
    "中关村一小党校校区",
    "中关村一小天秀校区",
    "北京大学附属小学",
    "北京市海淀区羊坊店中心小学本校区",
    "北京市海淀区羊坊店中心小学分校区",
    "北京市海淀区第四实验小学",
    "清华大学附属小学",
    "中国人民大学附属小学亮甲店分校（东校区）",
    "中国人民大学附属小学亮甲店分校（西校区）",
    "北京市海淀区六一小学",
    "北京市海淀区玉泉小学",
    "北京市海淀区玉泉小学三里校区",
    "北京市海淀区玉泉小学五里校区",
    "北京市海淀区玉泉小学首师附一分校校区",
    "北京市海淀区玉泉小学中科院校区",
    "北京市海淀区羊坊店第五小学",
    "北京市海淀区星火小学",
    "中国农业科学院附属小学农科院校区",
    "中国农业科学院附属小学学院南路校区",
    "中国农业科学院附属小学气象路校区",
    "中国农业科学院附属小学政法路校区",
    "中国农业科学院附属小学明光路校区",
    "北京市海淀区培英小学",
    "首都师范大学实验小学北校区",
    "首都师范大学实验小学南校区",
    "八里庄小学",
    "中国人民大学附属小学银燕分校",
    "北京理工大学附属小学",
    "北京市海淀区今典小学本校区",
    "北京市海淀区今典小学文慧园校区",
    "北京市海淀区今典小学志强园校区",
    "北京市海淀区今典小学明光村校区",
    "北京市海淀区学院路小学",
    "北京师范大学实验小学",
    "北京市海淀区双榆树中心小学",
    "北京石油学院附属小学本校区",
    "北京石油学院附属小学东校区",
    "北京石油学院附属小学南校区",
    "中国农业大学附属实验小学",
    "北京市海淀区东升实验小学",
    "北京市海淀区第三实验小学南校区",
    "北京市海淀区第三实验小学北校区",
    "北京市海淀区清华东路小学",
    "北京林业大学附属小学",
    "北京科技大学附属小学",
    "北京市海淀区中关村第四小学",
    "北京市海淀区万泉小学",
    "北京市海淀区万泉小学曙光校区",
    "北京市海淀区六郎庄小学",
    "中国人民大学附属小学",
    "北京市海淀区西苑小学",
    "北京市海淀区培星小学南校区",
    "北京市海淀区培星小学北校区",
    "北京市海淀区第二实验小学橡树校区",
    "北京市海淀区第二实验小学清宁校区",
    "北京市海淀区第二实验小学当代校区",
    "北京市海淀区第二实验小学汇缘校区",
    "北京市海淀区育鹰小学-西校区",
    "北京市海淀区育鹰小学-东校区",
    "北京市海淀区民族小学南校区",
    "北京市海淀区民族小学北校区",
    "北京医科大学附属小学花园路校区",
    "北京医科大学附属小学牡丹园校区",
    "北京市海淀区九一小学（西校区）",
    "北京市海淀区九一小学（东校区）",
    "北京市海淀区清河第四小学",
    "北京市海淀区枫丹实验小学",
    "北大附小肖家河分校",
    "北大附小肖家河分校-树村部",
    "北京市海淀区上地实验小学",
    "北京市海淀区上地实验小学上庄校区",
    "北京市海淀区红英小学韩家川校区",
    "北京市海淀区红英小学冷泉校区",
    "北京市海淀区红英小学唐家岭校区",
    "北京市海淀区红英小学六里屯校区",
    "北京石油学院附属第二实验小学",
    "北京石油学院附属实验小学",
    "北京市海淀北部新区实验学校学校小学部校区",
    "北京市十一学校一分校（小学部）",
    "北京市第二十中学附属实验学校（小学部）",
    "北京市育英学校西翠路校区",
    "首都师范大学附属育新学校（小学部）",
    "北京市海淀区清华附小清河分校",
    "清华大学附属小学清河分校",
    "北京教育科学研究院丰台学校",
    "北京市丰台区长辛店学校",
    "北京市第十二中学南站校区",
    "北京教育学院丰台分院附属学校",
    "北京四中璞瑅学校",
    "北京市西罗园学校",
    "北京市丰台区和义学校",
    "北京教育学院附属丰台实验学校",
    "中央民族大学附属中学丰台实验学校",
    "清华大学附属中学丰台学校（中学部）",
    "清华大学附属中学丰台学校（小学部）",
    "中国教育科学研究院丰台实验学校",
    "中国教育科学研究院丰台实验学校小学一部",
    "中国教育科学研究院丰台实验学校小学二部",
    "中国人民大学附属中学丰台学校",
    "北京市大成学校",
    "北京市海淀区实验小学丰台分校（东校区）",
    "北京市海淀区实验小学丰台分校（西校区）",
    "北京市丰台区东高地第二小学",
    "北京市丰台区东铁匠营第二小学",
    "北京市丰台区分钟寺小学校",
    "北京市丰台区师范学校附属小学",
    "丰台区师范学校附属小学城南嘉园校区",
    "北京市丰台区丰台第二中学附属实验小学",
    "北京市丰台区丰台第六小学",
    "北京市丰台区东铁匠营第一小学",
    "北京市丰台区东铁匠营第一小学分校",
    "北京市丰台区东罗园小学",
    "北京市丰台区长安新城小学",
    "北京十二中附属实验小学",
    "北京市丰台区槐房小学",
    "北京市丰台区芳城园小学",
    "北京市丰台区芳群园第一小学",
    "北京市丰台区蒲黄榆第一小学",
    "首都经济贸易大学附属小学",
    "北京市丰台区丰台第一小学（本部）",
    "北京市丰台区丰台第一小学（丰益分校）",
    "北京市丰台区丰台第一小学（远洋分校）",
    "北京市丰台区人民村小学",
    "北京市丰台区南苑镇第一小学",
    "北京市丰台区四合庄小学",
    "北京教育科学研究院丰台实验小学",
    "北京市丰台区纪家庙小学",
    "北京市丰台区西罗园第五小学",
    "北京市丰台区西马金润小学",
    "北京铁路分局北京铁路职工子弟第十一小学",
    "北京市丰台区长辛店第一小学",
    "北京市丰台区长辛店第一小学一分校区",
    "北京市丰台区长辛店第七小学",
    "北京市丰台区丰台第一小学长辛店分校",
    "北京市丰台区丰台第五小学本校区",
    "北京市丰台区丰台第五小学京铁校区",
    "北京市丰台区丰台第五小学鸿业校区",
    "北京市丰台区丰台第五小学科丰校区",
    "北京市丰台区丰台第五小学银地校区",
    "北京市丰台区丰台第八小学",
    "北京市丰台区五爱屯小学",
    "北京大学附属小学丰台分校",
    "北京市丰台区卢沟桥第二小学校",
    "北京市丰台区大红门第一小学（西校区）",
    "北京市丰台区大红门第一小学（东校区）",
    "北京市丰台区太平桥第二小学",
    "北京市丰台区时光小学",
    "北京市丰台区苏家坡小学",
    "北京市丰台区角门小学",
    "北京市丰台区长辛店中心小学",
    "北京朝阳芳草地国际学校丽泽分校",
    "北京市丰台区丰台第七小学校",
    "北京市丰台区丰台第五小学万柳分校",
    "北京市丰台区玉林小学",
    "北京市丰台区大红门第二小学",
    "北京市丰台区小井小学",
    "北京市丰台区成寿寺小学",
    "北京市丰台区扶轮小学",
    "东高地第一小学",
    "北京市丰台区西罗园小学",
    "北京市丰台区黄土岗小学",
    "北京市丰台区万泉寺小学（2018年学校撤销）",
    "北京市丰台区东高地第三小学",
    "北京市丰台区东高地第三小学西校区",
    "北京市丰台区东高地第四小学",
    "北京市丰台区丰体时代小学",
    "北京市第十八中学附属实验小学",
    "北京教育科学研究院丰台第二实验小学",
    "北京市丰台区新发地小学",
    "北京舞蹈学院附中丰台实验小学",
    "北京市丰台区芳星园第二小学",
    "北京市丰台区西罗园第六小学",
    "北京市赵登禹学校（南校区）",
    "北京市赵登禹学校（东校区）",
    "首都医科大学附属小学",
    "首都师范大学附属云岗小学北校区",
    "首都师范大学附属云岗小学南校区",
    "北京小学丰台万年花城分校",
    "北京市丰台区卢沟桥第一小学",
    "北京市丰台区看丹小学",
    "北京市丰台区芳古园小学",
    "北京市丰台区芳古园小学（东校区）",
    "北京市丰台区芳古园小学（西校区）",
    "北京市丰台区阳春小学",
    "北京市丰台区靛厂小学",
    "北京市丰台区草桥小学",
    "北京教育科学研究院附属石景山实验学校南校区",
    "北京教育科学研究院附属石景山实验学校北校区",
    "北京市蓝天第一学校",
    "北京市京源学校莲石湖分校",
    "北京市石景山区黄庄学校",
    "北京市石景山区华奥学校",
    "北京市石景山区台京学校",
    "北京市石景山区中杉学校",
    "北京景山学校远洋分校",
    "人大附中石景山学校",
    "北京市京源学校",
    "首钢矿业公司职工子弟学校",
    "北京市石景山区向阳小学",
    "北京市石景山区师范学校附属小学",
    "北京市石景山区第二实验小学",
    "北京市石景山区银河小学",
    "北京市石景山区实验小学分校",
    "北京市石景山区古城第二小学",
    "北京市石景山区古城第二小学低年级部",
    "北京市石景山区古城第二小学分校",
    "北京市石景山外语实验小学",
    "北京市石景山区实验小学",
    "北京市石景山区北辛安小学",
    "北京市石景山区水泥厂小学",
    "北京市石景山区苹果园第二小学",
    "北京市石景山区西黄村小学",
    "北京大学附属小学石景山学校",
    "北京市石景山区海特花园小学",
    "北京市石景山区先锋小学",
    "北京市石景山区金顶街第二小学",
    "北京市石景山区金顶街第四小学",
    "北京市石景山区广宁村小学",
    "北京市石景山区麻峪小学",
    "北京市石景山区电厂路小学",
    "北京市石景山区红旗小学",
    "北京市石景山区炮厂小学",
    "北京市石景山外语实验小学分校",
    "北京市石景山区五里坨小学",
    "北京市石景山区爱乐实验小学",
    "北京市石景山区爱乐实验小学高年级部",
    "北京市石景山区京原小学",
    "北京市石景山区石景山小学",
    "北方工业大学附属小学南校区",
    "北方工业大学附属小学北校区",
    "北京市石景山区树仁小学",
    "北京市门头沟区妙峰山民族学校",
    "北京市三家店铁路中学",
    "北京市门头沟区大峪第一小学",
    "北京市门头沟区城子小学",
    "北京市门头沟区大台中心小学",
    "北京市门头沟区灰地小学",
    "北京市门头沟区唐家坟小学",
    "北京市门头沟区军庄中心小学",
    "北京市门头沟区三家店小学",
    "北京市门头沟区清水中心小学",
    "北京市门头沟区斋堂中心小学",
    "北京市门头沟区龙泉雾小学",
    "北京市门头沟区付家台中心小学",
    "北京市门头沟区军响中心小学",
    "北京市门头沟区黑山小学",
    "北京市门头沟区王平村中心小学",
    "北京市门头沟区大峪第二小学",
    "北京市门头沟区育园小学",
    "北京市门头沟区京师实验小学",
    "北京市门头沟区龙泉小学",
    "北京市门头沟区东辛房小学",
    "北京市门头沟区潭柘寺中心小学",
    "北京市门头沟区潭柘寺中心南辛房小学",
    "北京第二实验小学永定分校",
    "北京第二实验小学永定分校冯村小学",
    "北京市门头沟区琉璃渠小学",
    "中国人民大学附属小学京西分校",
    "北京市第八中学京西附属小学",
    "北京市房山区北潞园学校",
    "北京市房山区昊天学校",
    "北京市房山区大宁学校",
    "北京市房山区四〇一学校",
    "北京十二中朗悦学校",
    "北京市房山区房山长育中心小学",
    "北京市房山区房山街道饶乐府完全小学",
    "北京市房山区城关镇顾册完全小学",
    "北京市房山区城关街道田各庄完全小学",
    "北京市房山区城关镇羊头岗完全小学",
    "北京市房山区城关镇马各庄完全小学",
    "北京市房山区良乡镇西石羊完全小学",
    "北京市房山区良乡镇邢家坞完全小学",
    "北京市房山区周口店地区周口店中心小学",
    "北京市房山区周口店地区瓦井完全小学",
    "北京市房山区周口店地区娄子水完全小学",
    "北京市房山区周口店地区黄山店小学",
    "北京市房山区周口店中心校长沟峪小学",
    "北京市房山区琉璃河镇琉璃河中心小学",
    "北京市房山区琉璃河镇立教完全小学",
    "北京市房山区琉璃河镇兴礼完全小学",
    "北京市房山区琉璃河镇祖村完全小学",
    "北京市房山区琉璃河镇三街完全小学",
    "北京市房山区琉璃河镇西南召中心小学",
    "北京市房山区琉璃河镇常舍完全小学",
    "北京市房山区琉璃河镇路村完全小学",
    "北京市房山区琉璃河镇务滋完全小学",
    "北京市房山区琉璃河镇窑上村中心小学",
    "北京市房山区良乡镇良乡中心小学",
    "北京市房山区良乡镇固村完全小学",
    "北京市房山区良乡镇太平庄小学",
    "北京市房山区阎村镇阎村中心小学",
    "北京市房山区阎村镇大董村完全小学",
    "北京市房山区阎村镇后沿村完全小学",
    "北京市房山区阎村镇后十三里完全小学",
    "北京市房山区阎村镇北坊完全小学",
    "北京市房山区窦店镇窦店中心小学",
    "北京市房山区窦店镇芦村完全小学",
    "北京市房山区窦店镇望楚完全小学",
    "北京市房山区窦店镇交道中心小学",
    "北京市房山区窦店镇兴隆庄完全小学",
    "北京市房山区石楼镇夏村完全小学",
    "北京市房山区石楼镇大次洛完全小学",
    "北京市房山区长阳镇长阳中心小学",
    "北京市房山区长阳镇西营完全小学",
    "北京市房山区长阳镇葫芦垡中心小学",
    "北京市房山区长阳镇阎仙垡完全小学",
    "北京市房山区长阳镇公议庄完全小学",
    "北京市房山区河北镇河北小学",
    "北京市房山区河北镇磁家务小学",
    "北京市房山区长沟镇长沟中心小学",
    "北京市房山区长沟镇龙泉河完全小学",
    "北京市房山区长沟镇北正完全小学",
    "北京市房山区大石窝镇南尚乐中心小学",
    "北京市房山区大石窝镇后石门村完全小学",
    "北京市房山区大石窝镇下滩明德小学",
    "北京市房山区张坊镇张坊中心小学",
    "北京市房山区张坊镇南白岱完全小学",
    "北京市房山区张坊镇瓦沟完全小学",
    "北京市房山区张坊镇西白岱完全小学",
    "北京市房山区十渡中心小学",
    "北京市房山区青龙湖镇坨里中心小学",
    "北京市房山区青龙湖镇上万完全小学",
    "北京市房山区青龙湖镇沙窝完全小学",
    "北京市房山区青龙湖镇北车营完全小学",
    "北京市房山区青龙湖镇南四位完全小学",
    "北京市房山区青龙湖镇大苑村完全小学",
    "北京市房山区韩村河镇韩村河中心小学",
    "北京市房山区韩村河镇赵各庄完全小学",
    "北京市房山区韩村河镇曹章完全小学",
    "北京市房山区韩村河镇五侯中心小学",
    "北京市房山区韩村河镇天开完全小学",
    "北京市房山区韩村河镇宝健希望小学",
    "北京市房山区霞云岭乡霞云岭中心小学",
    "北京市房山区南窖乡南窖中心小学",
    "北京市房山区佛子庄乡中心小学",
    "北京市房山区大安山乡大安山学校",
    "北京市房山区大安山乡大安山第二完全小学",
    "北京市房山区史家营乡史家营中心小学",
    "北京市房山区蒲洼乡蒲洼中心小学",
    "北京市房山区城关小学",
    "北京市房山区城关第二小学",
    "北京市房山区城关第三小学",
    "北京市房山区良乡小学",
    "北京市房山区良乡第二小学",
    "北京市房山区良乡第三小学",
    "首都师范大学附属房山小学",
    "北京市房山区琉璃河水泥厂学校",
    "北京市房山区良乡镇官道中心小学",
    "北京市房山区周口店地区新街完全小学",
    "北京市房山区长阳第一小学（怡和校区）",
    "北京市房山区长阳第一小学（祥云校区）",
    "北京市房山区城关第四小学",
    "北京市房山区阎村镇绿城完全小学",
    "黄城根小学房山分校",
    "北京市房山区石楼镇石楼中心小学",
    "北京市房山区石楼镇吉羊完全小学",
    "北京市房山区青龙湖镇中心小学",
    "北京市房山区良乡第四小学",
    "北京市房山区窦店镇窦店第二小学",
    "北京市房山区良乡行宫园学校",
    "北京市燕山东风小学",
    "北京市房山区燕山羊耳峪小学",
    "北京市燕山向阳小学",
    "北京市燕山前进第二小学",
    "北京市房山区燕山星城小学",
    "北京市通州区马驹桥学校",
    "北京市通州区梨园学校",
    "北京市通州区潞河中学附属学校",
    "北京市通州区牛堡屯学校",
    "北京市通州区陆辛庄学校",
    "北京市通州区教师研修中心实验学校",
    "北京市育才学校通州分校",
    "北京市通州区立华学校",
    "北京市通州区培智学校",
    "北京市通州区台湖学校",
    "北京市通州区第四中学",
    "北京市通州区第二中学小学部",
    "北京教育科学研究院通州区第一实验小学",
    "北京市通州区玉桥小学",
    "北京市通州区民族小学",
    "北京市通州区东方小学",
    "北京小学通州分校",
    "北京市通州区永乐店镇中心小学",
    "北京市通州区永乐店镇小务小学",
    "北京市通州区德仁务小学",
    "北京市通州区永乐店镇柴厂屯小学",
    "北京市通州区北苑小学",
    "北京市通州区漷县镇中心小学",
    "北京市通州区东定安小学",
    "北京市通州区漷县镇草厂小学",
    "北京市通州区漷县镇马头小学",
    "北京市通州区漷县镇靛庄小学",
    "北京市通州区漷县镇觅子店小学",
    "北京市通州区梨园镇大稿新村小学",
    "北京市通州区梨园镇中心小学",
    "北京市通州区西集镇大灰店小学",
    "北京市通州区西集镇杜柳棵小学",
    "北京市通州区芙蓉小学",
    "北京市通州区西集镇郎府小学",
    "北京市通州区西集镇沙古堆小学",
    "北京第二实验小学通州分校",
    "北京市史家小学通州分校",
    "北京市通州区潞城镇中心小学",
    "北京市通州区潞城镇胡各庄小学",
    "北京市通州区潞城镇卜落垡小学",
    "北京市通州区潞城镇大豆各庄小学",
    "北京市通州区潞城镇大东各庄小学",
    "北京市通州区南关小学",
    "北京市通州区宋庄镇中心小学",
    "北京市通州区宋庄镇徐辛庄小学",
    "北京市通州区宋庄镇翟里小学",
    "北京市通州区宋庄镇葛渠小学",
    "北京市通州区宋庄镇富豪小学",
    "北京市通州区宋庄镇北寺庄小学",
    "北京市通州区宋庄镇师姑庄小学",
    "北京市通州区西集镇中心小学",
    "北京市通州区西集镇肖林小学",
    "北京市通州区西集镇新东仪小学",
    "北京市通州区永顺镇发电厂小学",
    "北京市通州区永顺镇范庄小学",
    "北京市通州区永顺镇龙旺庄小学",
    "北京市通州区永顺镇乔庄小学",
    "北京市通州区永顺镇西马庄小学",
    "北京市通州区永顺镇中心小学",
    "北京市通州区运河小学",
    "北京市通州区张家湾镇中心小学",
    "北京市通州区张家湾镇上店小学",
    "北京市通州区张家湾镇枣林庄民族小学",
    "北京市通州区张家湾镇民族小学",
    "北京市通州区张家湾镇张湾村民族小学",
    "北京市通州区贡院小学",
    "北京市通州区后南仓小学",
    "北京市通州区临河里小学",
    "北京市通州区于家务乡中心小学",
    "北京市通州区于家务乡渠头小学",
    "北京市通州区于家务乡西垡小学",
    "北京市通州区漷县镇侯黄庄小学",
    "北京市通州区台湖镇中心小学",
    "北京市通州区次渠家园小学",
    "北京市通州区张家湾镇张辛庄小学",
    "北京市通州区焦王庄小学",
    "北京市通州区马驹桥镇中心小学",
    "北京市通州区马驹桥镇大杜社小学",
    "北京市通州区马驹桥镇金桥小学",
    "北京市通州区马驹桥镇小张湾小学",
    "北京市通州区马驹桥镇实验小学",
    "北京市通州区永顺小学",
    "北京市通州区中山街小学",
    "北京市通州区官园小学",
    "北京新城职业学校(主校区）",
    "北京新城职业学校（宋庄校区）",
    "北京市顺义区高丽营学校",
    "北京市顺义区海德京华双语学校",
    "北京市鼎石学校",
    "北京市海嘉双语学校",
    "北京市牛栏山一中实验学校",
    "北京市顺义区青苗学校",
    "北京市新英才学校",
    "北京市新府学外国语学校",
    "北京市顺义区板桥中心小学校",
    "北京市顺义区北石槽中心小学校",
    "北京市顺义区北务中心小学校",
    "北京市顺义区北小营中心小学校",
    "北京市顺义区博华外国语学校",
    "北京市顺义区仓上小学",
    "北京市顺义区仇家店中心小学校",
    "北京市顺义区大孙各庄中心小学校",
    "北京市顺义区第一中学附属小学",
    "北京市顺义区东风小学",
    "北京市顺义区港馨小学",
    "北京市顺义区高丽营第二小学",
    "北京市顺义区光明小学",
    "北京市顺义区河南村中心小学校",
    "北京市顺义区后沙峪中心小学校",
    "北京市顺义区建新小学",
    "北京市顺义区空港小学",
    "北京市顺义区李各庄学校",
    "北京市顺义区李桥中心小学校",
    "北京市顺义区李遂中心小学校",
    "北京市顺义区马坡第二小学",
    "北京市顺义区马坡中心小学校",
    "北京市顺义区明德小学",
    "北京市顺义区木林中心小学校",
    "北京市顺义区南彩第二小学",
    "北京市顺义区南彩实验学校",
    "北京市顺义区南法信中心小学校",
    "北京市顺义区牛栏山第二小学",
    "北京市顺义区牛栏山第三小学",
    "北京市顺义区牛栏山第一小学",
    "北京市顺义区仁和中心小学校",
    "北京市顺义区沙岭学校",
    "北京市顺义区石园小学",
    "北京市顺义区双兴小学",
    "北京市顺义区天竺第二小学",
    "北京市顺义区天竺第一小学",
    "北京市顺义区西辛小学",
    "北京市顺义区小店中心小学校",
    "北京市顺义区沿河中心小学校",
    "北京市顺义区杨镇中心小学校",
    "北京市顺义区裕达隆小学",
    "北京市顺义区裕龙小学（现代学院校址）",
    "北京市顺义区裕龙小学（裕龙校址）",
    "北京市顺义区张镇中心小学校",
    "北京市顺义区赵全营中心小学校",
    "首都师范大学附属顺义实验小学",
    "北京市顺义区空港第二小学",
    "北京市顺义区龙湾屯中心小学校",
    "北京市顺义区南彩第一小学",
    "北京教育学院附属大兴实验小学",
    "北京市大兴区兴华中学仰山校区",
    "北京景山学校大兴实验学校",
    "北京师范大学大兴附属中学（东校区）",
    "国家教育行政学院附属实验学校",
    "首都师范大学附属中学大兴南校区",
    "北京市大兴区兴海学校",
    "北京市大兴区金海学校",
    "北京市大兴区永华实验学校",
    "北京市第二中学亦庄学校（南校区）",
    "首都师范大学附属中学大兴北校区",
    "北京教育学院附属大兴实验小学融汇校区",
    "北京市大兴区第一小学",
    "北京市大兴区旧宫镇第二中心小学",
    "北京市大兴区旧宫镇第二中心小学芳源里校区",
    "北京市大兴区黄村镇第一中心小学",
    "北京市大兴区黄村镇第一中心小学福苑校区",
    "北京市大兴区黄村镇第一中心小学天堂河完小",
    "北京市大兴区黄村镇狼各庄民族小学",
    "北京市大兴区黄村镇观音寺完全小学",
    "北京市大兴区第八小学",
    "北京市大兴区采育镇第三中心小学",
    "北京市大兴区亦庄镇第二中心小学",
    "北京市大兴区瀛海镇第一中心小学",
    "北京小学翡翠城分校",
    "北京小学翡翠城分校南校区",
    "北京市大兴区榆垡镇第二中心小学",
    "北京市大兴区榆垡镇第二中心小学曹各庄完小",
    "北京市大兴区榆垡镇第二中心小学小店完小",
    "北京市大兴区第七中学小学部",
    "北京印刷学院附属小学",
    "北京市育才学校大兴分校",
    "北京市大兴区庞各庄镇第一中心小学",
    "北京市大兴区庞各庄镇薛营回民小学",
    "北京市大兴区庞各庄镇北顿垡完小",
    "北京市大兴区庞各庄镇庆国完全小学",
    "北京市大兴区庞各庄镇第一中心小学南顿垡小学",
    "北京市大兴区团河小学",
    "北京市大兴区长子营镇第二中心小学",
    "北京市大兴区榆垡镇第一中心小学",
    "北京市大兴区第三小学",
    "北京市大兴区滨河小学",
    "北京市大兴区长子营镇第一中心小学",
    "北京市大兴区黄村镇第二中心小学",
    "北京市大兴区枣园小学",
    "北京市大兴区亦庄镇第一中心小学",
    "北京八中大兴分校（小学部）",
    "北京市大兴区北臧村镇中心小学",
    "北京市大兴区北臧村镇皮各庄小学",
    "北京市大兴区北臧村镇马村小学",
    "北京市大兴区庞各庄镇第二中心小学",
    "北京市大兴区庞各庄镇第二中心小学张公垡完小",
    "北京市大兴区庞各庄镇第二中心小学赵村完小",
    "北京市大兴区庞各庄镇第二中心小学梁家务完小",
    "北京市大兴区庞各庄镇第二中心小学留民庄完小",
    "北京市大兴区采育镇第二中心小学",
    "北京市大兴区第九小学",
    "北京市大兴区第五小学",
    "北京市大兴区第五小学（北校区）",
    "大兴区黄村镇第三中心小学",
    "北京教育科学研究院大兴实验小学",
    "北京市大兴区安定镇中心小学",
    "北京市大兴区安定镇西芦各庄完全小学",
    "北京市大兴区安定镇通州马坊完全小学",
    "北京市大兴区安定镇后安定完全小学",
    "北京市大兴区安定镇东白塔民族小学",
    "北京亦庄实验小学",
    "北京教育科学研究院旧宫实验小学",
    "北京市大兴区魏善庄镇第一中心小学",
    "大兴区魏善庄镇第一中心小学西芦垡完小",
    "北京市大兴区魏善庄镇王各庄完全小学",
    "大兴区魏善庄镇后大营完全小学",
    "北京市大兴区青云店镇第一中心小学",
    "北京市大兴区青云店镇第一中心小学小回城完全小学",
    "北京市大兴区青云店镇第一中心小学低年级部",
    "北京市大兴第七小学",
    "北京市大兴区采育镇第一中心小学",
    "北京市大兴区采育镇辛店小学",
    "北京市大兴区青云店镇第二中心小学",
    "青云店镇第二中心小学南大红门完全小学",
    "北京师范大学大兴附属小学",
    "北京小学大兴分校",
    "北京市大兴区第六小学",
    "北京市大兴区礼贤镇第一中心小学",
    "北京市大兴区礼贤镇第一中心小学荆家务完小",
    "北京市大兴区礼贤镇第一中心小学龙头完小",
    "北京市大兴区礼贤镇第一中心小学孙营完小",
    "北京市大兴区礼贤镇第一中心小学西里河完小",
    "北京第二实验小学大兴实验学校",
    "北京市大兴区魏善庄镇第二中心小学",
    "北京市大兴区魏善庄镇第二中心小学东研垡完小",
    "北京市大兴区礼贤镇第二中心小学",
    "北京市大兴区礼贤镇杨各庄完全小学",
    "北京市大兴区旧宫镇第一中心小学",
    "北京市大兴区第二小学",
    "北京小学大兴分校亦庄学校",
    "清华大学附属小学昌平学校",
    "燕丹学校",
    "北京市昌平区第四中学",
    "北京市昌平区第四中学小学部",
    "北京市昌平区百善学校",
    "亭自庄学校",
    "北京市昌平区下庄学校",
    "北京市昌平区黑山寨学校",
    "兴寿学校",
    "特殊儿童教育学校",
    "北京市昌平区桃洼学校",
    "亭自庄学校中学校区",
    "亭自庄学校中心校区",
    "亭自庄学校土楼校区",
    "首都师范大学附属回龙观育新学校",
    "中国政法大学附属学校",
    "北京师范大学昌平附属学校",
    "流村中心小学",
    "流村中心古将小学",
    "流村中心南流小学",
    "流村中心新建村小学",
    "流村中心西峰山小学",
    "北京育翔小学回龙观学校",
    "平西府中心白庙小学",
    "平西府中心郑各庄小学",
    "平西府中心东沙各庄小学",
    "平西府中心东三旗小学",
    "北京市昌平区城南中心旧县小学",
    "北京市昌平区东小口中心小学",
    "北京市昌平区十三陵中心小学",
    "北京市昌平区十三陵中心泰陵园小学",
    "北京市昌平第二实验小学（东校区）",
    "北京市昌平第二实验小学（西校区）",
    "天通苑小学",
    "北京市昌平区北七家中心小学",
    "北京市昌平第三实验小学",
    "北京市昌平区城关小学",
    "城南中心邓庄小学",
    "城南中心小学",
    "北京市昌平区高崖口中心小学",
    "北京农学院附属小学",
    "北京市昌平区回龙观中心小学",
    "北京市昌平区回龙观中心回龙观小学",
    "北京市昌平区南口职业学校",
    "清华大学附属中学昌平学校悦府校区小学部",
    "北京市昌平区崔村中心大辛峰小学",
    "北京市昌平区崔村中心香堂小学",
    "北京市昌平区崔村中心小学",
    "昌平区二毛学校",
    "北京市昌平区小汤山中心小学",
    "北京市昌平区小汤山中心马坊小学",
    "北京市昌平区南口镇小学",
    "城北中心小学",
    "城北中心三街小学",
    "城北中心东关小学",
    "城北中心西关小学",
    "北京市昌平区巩华学校",
    "北京市昌平区南口铁道北小学",
    "阳坊中心小学",
    "阳坊中心后白小学",
    "阳坊中心四家庄小学",
    "华北电力大学附属小学",
    "北京市昌平区霍营中心小学",
    "北京市昌平区霍营中心半截塔小学",
    "北京市昌平区老峪沟中心小学",
    "北京市昌平区沙河中心小学",
    "北京市昌平区沙河中心于辛庄小学",
    "北京市昌平区史各庄中心小学",
    "北京市昌平区史各庄中心定福皇庄回民小学",
    "北京绿谷小香玉艺术学校",
    "北京第二外国语学院平谷附属学校",
    "北京市平谷区特教中心",
    "北京市平谷区第一小学",
    "北京市平谷区第二小学",
    "北京市平谷区第三小学",
    "北京实验学校附属小学",
    "北京市平谷区第五小学",
    "北京市平谷区第六小学",
    "北京市平谷区第七小学",
    "北京市平谷区第八小学",
    "北京第二实验小学平谷分校",
    "北京第一师范学校附属小学平谷分校",
    "北京市平谷区上营小学",
    "北京市平谷区峪口镇中心小学",
    "北京市平谷区峪口镇东凡各庄小学",
    "北京市平谷区峪口镇中桥小学",
    "北京市平谷区北杨桥中心小学",
    "北京市平谷区大官庄小学",
    "北京市平谷区放光中心小学",
    "北京市平谷区北辛庄小学",
    "北京市平谷区南独乐河小学",
    "北京市平谷区峨嵋山小学",
    "北京市平谷区望马台小学",
    "北京市平谷区夏各庄小学",
    "北京市平谷区安固小学",
    "北京市平谷区大兴庄镇三府庄小学",
    "北京市平谷区大兴庄镇周村小学",
    "北京市平谷区大华山中心小学",
    "北京市平谷区后北宫小学",
    "北京市平谷区山东庄小学",
    "北京市平谷区西沥津高级小学",
    "北京市平谷区马昌营中心小学",
    "北京市平谷区王各庄小学",
    "北京市平谷区东双营小学",
    "北京市平谷区门楼中心小学",
    "北京市平谷区东交民巷小学马坊分校",
    "北京市平谷区马坊中心小学",
    "北京市平谷区金海湖学校",
    "北京市平谷区靠山集中心小学",
    "北京市平谷区刘家店中心小学",
    "北京市平谷区镇罗营中心小学",
    "北京市平谷区黄松峪中心小学",
    "北京市平谷区熊儿寨中心小学",
    "北京市平谷区大旺务小学",
    "北京市平谷区南山小学",
    "北京市平谷区关山小学",
    "北京市平谷区东辛撞小学",
    "北京市怀柔区雁栖学校",
    "北京市怀柔区茶坞铁路小学",
    "北京市怀柔区怀北学校",
    "北京市怀柔区庙城学校",
    "北京市怀柔区庙城镇两河小学",
    "北京市怀柔区庙城镇桃山小学",
    "北京市怀柔区第一小学",
    "北京市怀柔区第二小学",
    "北京市怀柔区第三小学",
    "北京市怀柔区实验小学",
    "北京市怀柔区第六小学",
    "北京第二实验小学怀柔分校",
    "北京市怀柔区怀柔镇中心小学",
    "北京市怀柔区怀柔镇张各长小学",
    "北京市怀柔区怀柔镇郭家坞小学",
    "北京市怀柔区北房镇中心小学",
    "北京市怀柔区北房镇宰相庄小学",
    "北京市怀柔区杨宋镇中心小学",
    "北京市怀柔区桥梓镇中心小学",
    "北京市怀柔区桥梓镇北宅小学",
    "北京市怀柔区渤海镇中心小学",
    "北京市怀柔区九渡河镇中心小学",
    "北京市怀柔区九渡河镇九渡河小学",
    "北京市怀柔区九渡河镇黄花城小学",
    "北京市怀柔区琉璃庙镇中心小学",
    "北京市怀柔区汤河口镇中心小学",
    "北京市怀柔区宝山镇中心小学",
    "北京市怀柔区长哨营满族乡中心小学",
    "北京市怀柔区喇叭沟门满族乡中心小学",
    "北京市密云区高岭学校",
    "北京市密云区大城子学校",
    "北京市密云区特殊教育学校",
    "北京市密云区第二小学",
    "北京市密云区第四小学",
    "北京市密云区第六小学",
    "北京市密云区第一小学",
    "北京市密云区第三小学",
    "北京市密云区果园小学",
    "北京市密云区季庄小学",
    "北京市密云区南菜园小学",
    "北京市朝阳区实验小学密云学校",
    "北京市密云区溪翁庄镇中心小学",
    "北京市密云区溪翁庄镇东智小学",
    "北京市密云区西田各庄镇中心小学",
    "北京市密云区西田各庄镇卸甲山小学",
    "北京市密云区西田各庄镇太子务小学",
    "北京市密云区西田各庄镇疃里小学",
    "北京市密云区十里堡镇中心小学",
    "北京市密云区十里堡镇水杨红小学",
    "北京市密云区河南寨镇中心小学",
    "北京市密云区河南寨镇台上小学",
    "北京市密云区巨各庄镇中心小学",
    "北京市密云区巨各庄镇东白岩小学",
    "北京市密云区穆家峪镇中心小学",
    "北京市密云区穆家峪镇后栗园小学",
    "北京市密云区穆家峪镇新农村小学",
    "北京市密云区太师屯镇中心小学",
    "北京市密云区太师屯镇桑园小学",
    "北京市密云区太师屯镇东庄禾小学",
    "北京市密云区高岭镇上甸子小学",
    "北京市密云区不老屯镇中心小学",
    "北京市密云区冯家峪镇中心小学",
    "北京市密云区东邵渠镇中心小学",
    "北京市密云区北庄镇中心小学",
    "北京市密云区新城子镇中心小学",
    "北京市密云区石城镇中心小学",
    "北京市密云区第五小学(民族小学)",
    "北京市密云区河南寨镇提辖庄小学",
    "北京市密云区古北口镇中心小学",
    "北京市密云区檀营满族蒙古族乡中心小学",
    "北京市密云区第七小学",
    "北京市密云区职业学校",
    "北京市延庆区十一学校",
    "北京市延庆区香营学校",
    "北京市延庆区永宁学校",
    "北京市延庆区张山营学校",
    "北京市延庆区特殊教育中心",
    "北京市延庆区西屯中心小学",
    "北京市延庆 区莲花池小学",
    "北京市延庆 区司家营小学",
    "北京市延庆区八里庄中心小学",
    "北京市延庆区广积屯完小",
    "北京市延庆区榆林堡小学",
    "延庆区小丰营中心小学",
    "北京市延庆区太平庄中心小学",
    "北京市延庆区八达岭中心小学",
    "北京市延庆区旧县中心小学",
    "北京市延庆区大柏老中心小学",
    "北京市延庆区姚家营中心小学",
    "北京市延庆区四海中心小学",
    "北京市延庆区千家店学校",
    "北京市延庆区沈家营中心小学",
    "北京市延庆区大榆树中心小学",
    "北京市延庆区下屯中心小学",
    "北京市延庆区井家庄中心小学",
    "北京市延庆区西二道河中心小学",
    "北京市延庆区大庄科中心小学",
    "北京市延庆区刘斌堡中心小学",
    "北京市延庆区珍珠泉中心小学",
    "北京市延庆区第一小学",
    "北京市延庆区康庄中心小学",
    "北京市延庆区第二小学",
    "北京市延庆区第三小学",
    "北京市延庆区第四小学",
    "北京市延庆区靳家堡中心小学",
    "北京市前门外国语学校",
    "北京市第十一中学分校",
    "北京市第二中学分校",
    "北京市第十一中学实验学校",
    "北京市第五中学分校（地安门校区）",
    "北京市国子监中学",
    "北京市第五中学分校(鼓楼校区)",
    "北京市第五中学",
    "北京市第二中学",
    "北京市广渠门中学南校区",
    "北京汇文实验中学（125中学）",
    "北京市第五十中学",
    "北京汇文中学",
    "北京市第二十二中学交道口校区",
    "北京市第二十二中学安定门校区",
    "北京市第一六五中学",
    "北京市第二十五中学",
    "中央工艺美术学院附属中学（北京市国际美术学校）",
    "北京市第一四二中学（北京宏志中学）",
    "北京市第一六六中学（灯市口校区）",
    "北京市第一七一中学西校区",
    "北京市第一七一中学东校区",
    "北京市第九十六中学",
    "北京市第九十六中学南校区",
    "北京市第十一中学",
    "北京市第五十五中学",
    "北京市第五十中学分校",
    "北京市第二十四中学",
    "北京市第二十七中学",
    "北京市崇文门中学",
    "北京市第五十四中学",
    "北京市龙潭中学",
    "北京市广渠门中学",
    "北京市第六十五中学",
    "北京市第一六六中学(东四六条校区)",
    "北京市第五十四中学分校",
    "北京市第二十一中学",
    "北京市第八中学（东里校区）",
    "北京市第八中学（木樨地校区）",
    "北京市第十五中学初中部",
    "北京市第三十五中（初中部）",
    "北京市育才学校龙泉校区",
    "三帆中学裕中校区",
    "北京市三帆中学",
    "北京市西城区育华中学",
    "北京市第八中学（本部）",
    "北京市第八中学（西里校区）",
    "北京市第八中学（白云观校区）",
    "北京市第三十五中(高中部)",
    "北京市第三十五中（国际部）",
    "北京西城职业学校（西便门校区）",
    "北京市实美职业学校",
    "北京市实验职业学校",
    "北京市实验职业学校（东校区）",
    "北京市外事学校",
    "北京师范大学第二附属中学（国际部）",
    "北京市实美职业学校（百万庄校区）",
    "北京市实美职业学校（安德路校区）",
    "北京市第四中学",
    "北京市第四中学东校区",
    "北京市第四中学广外校区",
    "北京市第四中学复兴门国际校区",
    "北京市第七中学",
    "北京市第八中学（百万庄校区）",
    "北京市第十三中学",
    "北京市第三十一中学",
    "北京市第三十九中学",
    "北京市第四十一中学",
    "北京市第四十三中学东址",
    "北京市第四十三中学西址",
    "北京市第五十六中学",
    "北京市第六十六中学（北址）",
    "北京市第六十六中学（南址）",
    "北京市第一五九中学",
    "北京市第一六一中学（北校区）",
    "北京市第一六一中学（中校区）",
    "北京市第一六一中学（南校区）",
    "北京市第一六一中学分校",
    "北京市第三中学",
    "北京市北纬路中学",
    "北京师范大学第二附属中学西城实验学校",
    "北京师范大学实验二龙路中学",
    "北京市回民学校",
    "北京教育学院附属中学",
    "北京市鲁迅中学",
    "北京师范大学附属中学",
    "北京师范大学附属中学分校",
    "北京师范大学实验华夏女子中学",
    "北京师范大学附属实验中学",
    "北京市铁路第二中学",
    "北京市铁路第二中学南址",
    "北京市宣武外国语实验学校",
    "北京市月坛中学",
    "北京师范大学第二附属中学",
    "北京市劲松第一中学",
    "北京市楼梓庄中学",
    "北京工业大学附属中学双桥分校",
    "北京市第八十中学实验学校温榆河分校",
    "北京市黑庄户中学",
    "北京市日坛中学初中部",
    "北京市朝阳区教育研究中心附属学校",
    "北京市朝阳区教育研究中心附属学校（分部）",
    "北京市三里屯一中（幸福村校区）",
    "北京市第七十一中学",
    "北京市第十六中学",
    "北京市陈经纶中学本部初中",
    "北京市十八里店中学",
    "北京工业大学实验学校南校区",
    "北京工业大学实验学校北校区",
    "北京市高家园中学",
    "中国传媒大学附属中学",
    "北京市呼家楼中学",
    "北京市电气工程学校（将台路校区）",
    "北京市电气工程学校（甘露园校区）",
    "北京市电气工程学校（管庄校区）",
    "北京市求实职业学校安贞里校区",
    "北京市求实职业学校望京校区",
    "北京市求实职业学校亚运村校区",
    "北京市求实职业学校+团结湖校区一部",
    "北京市求实职业学校+团结湖校区二部",
    "北京市求实职业学校+机场校区",
    "北京市陈经纶中学本部高中",
    "北京市日坛中学高中部",
    "北京市三里屯一中（三里屯校区）",
    "北京市第十七中学高碑店校区",
    "北京市劲松职业高中常营校区",
    "北京市劲松职业高中劲松校区",
    "北京市劲松职业高中双龙校区",
    "北京市劲松职业高中综合高中部",
    "北京市第八十中学",
    "北京市垂杨柳中学",
    "对外经济贸易大学附属中学（北京市第九十四中学）",
    "东北师范大学附属中学朝阳学校",
    "北京市华侨城黄冈中学（华侨城校区）",
    "北京市华侨城黄冈中学（翠成校区）",
    "北京市华侨城黄冈中学（垡头校区）",
    "北京市团结湖第三中学（高中部）",
    "北京市十一学校龙樾实验中学",
    "北航实验学校分校",
    "首都师范大学附属中学第一分校",
    "清华大学附属中学上地学校",
    "北京市清河中学",
    "北京市上庄中学",
    "北京市上庄第二中学",
    "中国人民大学附属中学翠微学校初中校区",
    "北京市第一零一中学初中部",
    "北京市第一零一中学温泉校区",
    "北京市上地实验学校",
    "北京市第二十中学（新都分校）",
    "北京市海淀实验中学东校区",
    "北京市健翔学校",
    "中央民族大学附属中学",
    "中国人民大学附属中学翠微学校高中校区",
    "北京市海淀实验中学西校区",
    "北京市信息管理学校中关村校区",
    "中国人民大学附属中学",
    "中国人民大学附属中学第二分校",
    "人大附中西山学校",
    "北京市第一O一中学",
    "北京市十一学校",
    "北京十一实验中学",
    "北方交通大学附属中学北校区",
    "北京交通大学附属中学南校区",
    "北方交通大学附属中学东校区",
    "北方交通大学附属中学第二分校",
    "北方交通大学附属中学分校",
    "北京市八一学校附属玉泉中学",
    "北京市中关村中学",
    "北京市中关村中学双榆树校区",
    "北京市中关村中学清华园校区",
    "北京市中关村中学知春分校",
    "北京市第二十中学",
    "首都师范大学附属中学",
    "首都师范大学第二附属中学（花园村校区）",
    "首都师范大学第二附属中学（曙光校区）",
    "清华大学附属中学",
    "北京理工大学附属中学（南校区）",
    "北京大学附属中学",
    "北京医学院附属中学",
    "北京市第十九中学东校区",
    "北京市第十九中学阳春光华校区",
    "北京市海淀区教师进修学校附属实验学校北校区",
    "北京市海淀区教师进修学校附属实验学校南校区",
    "北京市第五十七中学",
    "北京市育英中学",
    "中国农业大学附属中学",
    "北京科技大学附属中学",
    "北京石油学院附属中学",
    "北京师范大学第三附属中学",
    "北京市玉渊潭中学",
    "中国矿业大学（北京）附属中学",
    "中国地质大学附属中学",
    "北大附中香山学校",
    "北京外国语大学附属中学",
    "北京市第四十七中学",
    "北京市丰台第八中学（北大地校区）",
    "北京市丰台第八中学（中海校区）",
    "北京市第十八中学左安门分校",
    "北京市芳星园中学",
    "北京十二中科丰校区",
    "北京市首都师范大学附属丽泽中学南校区",
    "北京市丰台区卢沟桥中学",
    "北京市丰台区看丹中学",
    "北京市丰台区东铁匠营第二中学",
    "北京市丰台区南苑中学",
    "北京市丰台区工读学校",
    "北京市丰台区丰台第二中学初中部",
    "北京市佟麟阁中学",
    "北京市右安门外国语学校",
    "北京市第十八中学西马金润校区",
    "北京教育学院附属丰台实验学校分校",
    "北京市赵登禹学校（本部）",
    "北京市丰台区黄土岗中学",
    "北京市丰台区槐树岭学校",
    "北京市第十中学",
    "北京市第十中学东校区",
    "北京市航天中学",
    "北京市第十二中学",
    "北京市首都师范大学附属丽泽中学",
    "北京市丰台区长辛店第一中学",
    "首都经济贸易大学附属中学",
    "首都师范大学附属云岗中学",
    "北京师范大学第四附属中学（高中）",
    "北京师范大学第四附属中学（初中）",
    "北京市丰台区丰台第二中学",
    "北京市丰台区东铁匠营第一中学",
    "北京市第十八中学",
    "北京市石景山区石景山中学",
    "北京市石景山区实验中学分校",
    "北京市第九中学分校",
    "北京市石景山区实验中学",
    "北京市石景山区实验中学东校区",
    "北方工业大学附属学校中学部",
    "北京市高井中学",
    "首都师范大学附属苹果园中学分校",
    "北京市同文中学",
    "北京佳汇中学",
    "首都师范大学附属苹果园中学",
    "北京市第九中学",
    "北京师范大学附属中学京西分校",
    "北京市古城中学（东）",
    "北京市古城中学（西）",
    "北京大学附属中学石景山学校",
    "北京市礼文中学",
    "北京市门头沟区新桥路中学",
    "北京市大峪中学分校",
    "北京市门头沟京师实验中学",
    "北京市王平中学",
    "北京市门头沟区潭柘寺中学",
    "北京市门头沟区军庄中学",
    "北京市门头沟区斋堂中学",
    "北京市门头沟区清水中学",
    "北京市门头沟区大峪中学西校区",
    "北京市育园中学",
    "北京市大峪中学",
    "首都师范大学附属中学永定分校",
    "北京市第八中学京西校区",
    "北京市房山区房山第三中学",
    "北京市房山区良乡第三中学",
    "北京市房山区良乡第四中学",
    "北京市房山区北洛中学",
    "北京市房山区南召中学",
    "北京市房山区南梨园中学",
    "北京市房山区晨曦中学",
    "北京市房山区窦店中学",
    "北京市房山区石楼中学",
    "北京市房山区夏村中学",
    "北京市房山区葫芦垡中学",
    "北京市房山区长阳中学",
    "北京市房山区石窝中学",
    "北京市房山区张坊中学",
    "北京市房山区青龙湖中学",
    "北京市房山区岳各庄中学",
    "北京市房山区房山第二中学",
    "北京市房山第四中学",
    "北京市房山区良乡第二中学",
    "北京市房山区良乡第五中学",
    "北京市房山区房山第五中学",
    "北京市房山区良乡第六中学",
    "北京市房山区韩村河中学",
    "北京市房山区南尚乐中学",
    "北京市房山区坨里中学（北京教育学院房山实验学校初中部）",
    "北京市燕山向阳中学",
    "北京市燕山前进中学",
    "北京市燕山星城中学",
    "北京市房山区坨里中学（北京教育学院房山实验学校高中部）",
    "北京师范大学良乡附属中学",
    "北京市房山区实验中学",
    "北京市房山区房山中学",
    "北京市房山区良乡中学",
    "北京师范大学燕化附属中学",
    "北京市房山区周口店中学",
    "北京市房山区琉璃河中学",
    "北京市房山区交道中学",
    "北京市房山区长沟中学",
    "首都师范大学附属房山中学",
    "北京四中房山分校",
    "北京市顺义区北务中学",
    "北京市顺义区第八中学",
    "北京市顺义区第三中学",
    "北京市顺义区第十三中学",
    "北京市顺义区第十五中学",
    "北京市顺义区第十一中学",
    "北京市顺义区第四学校",
    "北京市顺义区第五中学",
    "北京市顺义区李桥中学",
    "北京市顺义区李遂中学",
    "北京市顺义区南法信中学",
    "北京市顺义区牛山第二中学",
    "北京市顺义区仁和中学",
    "北京市顺义区天竺中学",
    "北京市顺义区沿河中学",
    "北京市顺义区杨镇第二中学",
    "北京市顺义区赵全营中学",
    "北京市顺义区第十二中学",
    "北京市顺义牛栏山第一中学",
    "北京市顺义区第九中学",
    "北京市顺义区第一中学",
    "北京市顺义区杨镇第一中学",
    "北京市第四中学顺义分校（北京市顺义区第十中学）",
    "北京市顺义区第二中学",
    "北京市第十四中学大兴安定分校",
    "大兴区狼垡中学",
    "北京市大兴区第七中学（东校区）",
    "北京市大兴区第七中学（西校区）",
    "北京市大兴区定福庄中学",
    "北京市大兴区凤河营中学",
    "北京市大兴区青云店中学",
    "北京市大兴区第一中学北校区",
    "北京市大兴区郭家务中学",
    "北京市第二中学亦庄学校（北校区）",
    "北京市大兴区第六中学",
    "北京市大兴区礼贤民族中学",
    "北京市大兴区红星中学",
    "北京市大兴区庞各庄中学",
    "北京市大兴区太和中学",
    "北京市大兴区垡上中学",
    "北京市大兴区大辛庄中学",
    "北京市大兴区长子营中学",
    "北京市第八中学亦庄分校",
    "北京市大兴区孙村中学",
    "北京大兴区第四中学",
    "北京市大兴区德茂中学",
    "北京市大兴区兴华中学",
    "北京建筑大学附属中学（新校区）",
    "首都师范大学大兴附属中学",
    "北京建筑大学附属中学",
    "北京市大兴区旧宫中学",
    "北京师范大学大兴附属中学",
    "北京市大兴区第一中学",
    "北京市大兴区第三中学",
    "北京亦庄实验中学",
    "北京市大兴区采育中学",
    "北京市第八中学大兴分校",
    "北京市大兴区魏善庄中学",
    "北京市大兴区榆垡中学",
    "华北电力大学附属中学",
    "北京市昌平区大东流中学",
    "北京市昌平区平西府中学",
    "中滩中学",
    "北京市昌平区南口铁道北中学",
    "中国石油大学附属中学",
    "北京市昌平区上苑中学",
    "北京市昌平区第一中学（天通苑校区）",
    "北京市昌平区崔村中学",
    "北京市昌平区流村中学",
    "北京市昌平区南邵中学",
    "北京市昌平职业学校",
    "北京市昌平职业学校南口校区",
    "北京市第十五中学南口学校",
    "首都师范大学附属中学昌平学校",
    "北京市昌平区第二中学",
    "北京市昌平区第一中学",
    "北京师范大学第二附属中学未来科技城学校",
    "北京实验学校附属中学",
    "北京市平谷区第三中学",
    "北京市平谷区第四中学",
    "北京市平谷区第七中学",
    "北京市平谷区刘家河中学",
    "北京市平谷区夏各庄联办中学",
    "北京市平谷区大华山中学",
    "北京市平谷区山东庄中学",
    "北京市平谷区峪口中学",
    "北京市平谷区门楼庄中学",
    "北京市平谷区马昌营中学",
    "北京市平谷区黄松峪中学",
    "北京市平谷中学",
    "北京市平谷区第五中学",
    "北京实验学校",
    "北京师范大学附属中学平谷第一分校",
    "北京市平谷区职业学校",
    "北京市怀柔区第三中学",
    "北京市怀柔区第四中学",
    "北京市怀柔区第五中学",
    "北京市怀柔区体育运动学校",
    "北京市怀柔区张各长中学",
    "北京市怀柔区北房中学",
    "北京市怀柔区杨宋中学",
    "北京市怀柔区桥梓中学",
    "北京市怀柔区汤河口中学",
    "北京市怀柔区渤海中学",
    "北京市怀柔区九渡河中学",
    "北京市怀柔区宝山中学",
    "北京市怀柔区长哨营满族中学",
    "北京市怀柔区喇叭沟门满族中学",
    "北京市怀柔区第一中学",
    "北京市怀柔区第二中学",
    "首都师范大学附属红螺寺中学",
    "北京市第一〇一中学怀柔分校",
    "北京市密云区第三中学",
    "北方交通大学附属中学密云分校",
    "北京市密云区第五中学",
    "北京市密云区第六中学",
    "北京市密云水库中学",
    "北京市密云区西田各庄中学",
    "北京市密云区十里堡中学",
    "北京市密云区河南寨中学",
    "北京市密云区新农村中学",
    "北京市密云区穆家峪中学",
    "北京市密云区不老屯中学",
    "北京市密云区古北口中学",
    "北京市密云区东邵渠中学",
    "北京市密云区北庄中学",
    "北京市密云区新城子中学",
    "北京市育英学校密云分校",
    "北京市密云区第二中学",
    "首都师范大学附属密云中学",
    "北京师范大学密云实验中学",
    "北京市密云区巨各庄中学",
    "北京市密云区太师庄中学",
    "北京市延庆区康庄中学",
    "北京市延庆区体育运动学校",
    "北京市延庆区八达岭中学",
    "北京市延庆区旧县中学",
    "北京市延庆区沈家营中学",
    "北京市延庆区大榆树中学",
    "北京市延庆区下屯中学",
    "北京市延庆区井庄中学",
    "北京市延庆区刘斌堡中学",
    "北京市延庆区第四中学",
    "北京市延庆区第八中学",
    "北京市延庆区第一中学",
    "北京市延庆区第五中学",
    "北京市延庆区第一职业学校",
    "北京市延庆区第三中学",
    "北京市延庆区第二中学",
    "北京市通州区漷县中学",
    "北京市通州区第六中学",
    "北京市通州区柴厂屯中学",
    "北京市通州区玉桥中学",
    "北京市通州区郎府中学",
    "北京市通州区大杜社中学",
    "北京市通州区北关中学",
    "北京市通州区龙旺庄中学",
    "北京市通州区西集中学",
    "北京市通州区甘棠中学",
    "北京市通州区小务中学",
    "北京市通州区觅子店中学",
    "北京市通州区于家务中学",
    "北京市通州区运河中学初中部",
    "北京市通州区宋庄中学",
    "北京市通州区次渠中学",
    "北京市通州区运河中学高中部",
    "北京市现代音乐学校",
    "北京市通州区新华学校",
    "北京市通州区第二中学",
    "北京市通州区永乐店中学",
    "首都师范大学附属中学（通州校区）",
    "北京市通州区张家湾中学",
    "北京理工大学附属中学通州校区",
    "人大附中通州校区",
    "北京市第二中学通州校区",
    "北京市通州区潞河中学",
    "北京潞河国际教育学园",
    "北京市第十五中学高中部",
    "北京市第二中学朝阳学校详情>>"
]
// 辅导类型
export const turtorList = ["线上一对一", "线下一对一", "线上班课", "线下班课", "家教"]
