const callData = {
    dev: {
        token: "2DD3E4675E4AD7C21018583089C4DD06",
        Host: "ctr1.diankongcloud.cn",
        WSPort: "8089",
        agentHost: "calltest.diankongcloud.cn",
        agentPort: "8310"
    },
    local: {
        token: "2DD3E4675E4AD7C21018583089C4DD06",
        Host: "ctr1.diankongcloud.cn",
        WSPort: "8089",
        agentHost: "calltest.diankongcloud.cn",
        agentPort: "8310"
    },
    test: {
        token: "2DD3E4675E4AD7C21018583089C4DD06",
        Host: "ctr1.diankongcloud.cn",
        WSPort: "8089",
        agentHost: "calltest.diankongcloud.cn",
        agentPort: "8310"
    },
    prod: {
        token: "FD3A9DC257E15F9D8DF16089C0337003",
        Host: "cti4.diankongcloud.cn",
        WSPort: "8089",
        agentHost: "dkyapi.diankongcloud.cn",
        agentPort: "8341"
    },
}
export const getCallData = () => {
    const envType = process.env.VUE_APP_SERVER_ENV ? process.env.VUE_APP_SERVER_ENV : 'test'
    return callData[envType]
}