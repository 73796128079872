import store from '@/store'
export default (el, binding) => {
    let moveDom = el
    if (binding.value && binding.value.bindParent) {
        for (let i = 0, len = binding.value.bindParent; i < len; i++) {
            moveDom = moveDom.parentNode;
        }
    }
    el.onmousedown = (event) => {
        let disX = event.clientX - moveDom.offsetLeft;
        let disY = event.clientY - moveDom.offsetTop;
        document.onmousemove = (event) => {
            let l = event.clientX - disX;
            let t = event.clientY - disY;
            let moveDomWidth = moveDom.offsetWidth 
            if(t < 0){
                t = 0
            }
            if(t > window.innerHeight - 40) {
                t = window.innerHeight - 40
            }
            if(l < -moveDomWidth / 2){
                l = -moveDomWidth / 2
            }
            if(l > window.innerWidth - moveDomWidth / 2){
                l = window.innerWidth - moveDomWidth / 2
            }
            if(binding.value && binding.value.dialogData){
                const position = {
                    left: l + 'px',
                    top: t + 'px'
                }
                store.commit(`dialogCont/${binding.value.dialogData}`, {
                    position,
                })
            }else{
                moveDom.style.left = l + "px";
                moveDom.style.top = t + "px";
                moveDom.style.bottom = 'auto'
                moveDom.style.bottom = 'auto'
                moveDom.style.margin = '0'
            }
        };
        document.onmouseup = function () {
            document.onmousemove = null;
            document.onmouseup = null;
        };
    }
}